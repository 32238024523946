import { createSvgIcon } from '@mui/material/utils'

export const ThumbsDown = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1953_2756)">
      <path
        d="M7.49978 11.25V14.25C7.49978 14.8468 7.73683 15.4191 8.15879 15.841C8.58075 16.263 9.15304 16.5 9.74978 16.5L12.7498 9.75003V1.50003H4.28978C3.92803 1.49594 3.577 1.62272 3.30135 1.85702C3.0257 2.09132 2.84402 2.41735 2.78978 2.77503L1.75478 9.52503C1.72215 9.74001 1.73665 9.95952 1.79727 10.1683C1.8579 10.3772 1.9632 10.5703 2.10588 10.7344C2.24857 10.8985 2.42522 11.0296 2.6236 11.1186C2.82198 11.2076 3.03735 11.2525 3.25478 11.25H7.49978ZM12.7498 1.50003H14.7523C15.1767 1.49252 15.5892 1.64113 15.9113 1.91764C16.2335 2.19415 16.4429 2.57932 16.4998 3.00003V8.25003C16.4429 8.67073 16.2335 9.0559 15.9113 9.33241C15.5892 9.60892 15.1767 9.75753 14.7523 9.75003H12.7498"
        fill="#FECDCA"
      />
      <path
        d="M12.7498 1.50003H14.7523C15.1767 1.49252 15.5892 1.64113 15.9113 1.91764C16.2335 2.19415 16.4429 2.57932 16.4998 3.00003V8.25003C16.4429 8.67073 16.2335 9.0559 15.9113 9.33241C15.5892 9.60892 15.1767 9.75753 14.7523 9.75003H12.7498M7.49978 11.25V14.25C7.49978 14.8468 7.73683 15.4191 8.15879 15.841C8.58075 16.263 9.15304 16.5 9.74978 16.5L12.7498 9.75003V1.50003H4.28978C3.92803 1.49594 3.577 1.62272 3.30135 1.85702C3.0257 2.09132 2.84402 2.41735 2.78978 2.77503L1.75478 9.52503C1.72215 9.74001 1.73665 9.95952 1.79727 10.1683C1.8579 10.3772 1.9632 10.5703 2.10588 10.7344C2.24857 10.8985 2.42522 11.0296 2.6236 11.1186C2.82198 11.2076 3.03735 11.2525 3.25478 11.25H7.49978Z"
        stroke="#912018"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1953_2756">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'ThumbsDown'
)

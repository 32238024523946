import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { AppBar, Box, Hidden, IconButton, Toolbar, SvgIcon, Link as MuiLink } from '@mui/material'
import { Menu as MenuIcon } from 'react-feather'
import Account from './Account'
import BreadCrumbs from '../../../components/breadcrumbs'

import Role from './Role'
import Notifications from './Notifications'
import FollowUps from './Followups'
import GlobalSearch from './GlobalSearch'

const PREFIX = 'TopBar'

const classes = {
  root: `${PREFIX}-root`,
  mobileToolbar: `${PREFIX}-mobileToolbar`,
  mobileAppBar: `${PREFIX}-mobileAppBar`,
  show: `${PREFIX}-show`,
  hide: `${PREFIX}-hide`,
  divider: `${PREFIX}-divider`
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    zIndex: theme.zIndex.drawer,
    bgcolor: theme.palette.mode === 'light' ? 'white' : 'background.dark'
  },

  [`& .${classes.mobileToolbar}`]: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  [`& .${classes.mobileAppBar}`]: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'black'
  },

  [`& .${classes.show}`]: {
    transform: 'translateY(0)',
    transition: 'transform .5s'
  },

  [`& .${classes.hide}`]: {
    transform: 'translateY(-110%)',
    transition: 'transform .5s'
  },

  [`& .${classes.divider}`]: {
    borderRight: 'solid white 1px',
    borderColor: 'white'
  }
}))

interface TopBarProps {
  className?: string
  toggleMobileNav?: () => void
}

function TopBar({ className, toggleMobileNav, ...rest }: TopBarProps) {
  return (
    <Root>
      <AppBar className={clsx(classes.root, className)} position="fixed" {...rest}>
        <Toolbar sx={{ minHeight: 64 }} className="bg-grey-800">
          <Hidden lgUp>
            <IconButton color="inherit" onClick={toggleMobileNav}>
              <SvgIcon fontSize="small">
                <MenuIcon className="text-grey-25" />
              </SvgIcon>
            </IconButton>
          </Hidden>
          <Box className="flex pl-3 align-middle w-[256px] tablet:hidden">
            <MuiLink href="/" className="flex items-center" underline="none">
              <img src="/static/images/logo-white.png" alt="logo" height="50px" />
            </MuiLink>
          </Box>
          <Box className="tablet:hidden">
            <BreadCrumbs />
          </Box>
          <Box flexGrow={5} />
          <GlobalSearch />
          <Notifications />
          <Role />
          <FollowUps />
          <Account />
        </Toolbar>
      </AppBar>
    </Root>
  )
}

export default TopBar

import useScrollReset from './hooks/useScrollReset'
import { trpc } from './core/trpc'
import { useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink, httpLink, splitLink } from '@trpc/client'
import { AppInner } from './AppInner'
function App() {
  useScrollReset()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        }
      })
  )

  const [trpcClient] = useState(
    trpc.createClient({
      links: [
        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return op.context.skipBatch === true || Boolean(window.Cypress)
          },
          // when condition is true, use normal request
          true: httpLink({
            url: import.meta.env.VITE_SERVER_URL || '/api'
          }),
          // when condition is false, use batching
          false: httpBatchLink({
            url: import.meta.env.VITE_SERVER_URL || '/api'
          })
        })
      ]
    })
  )

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <AppInner />
      </QueryClientProvider>
    </trpc.Provider>
  )
}

export default App

import { Typography, Box } from '@mui/material'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab'
import { capitalize, formatDistanceToNowCustom, isCurrencyString, isDateValid } from '../../../../utils'
import { AuditTrailLeadLog } from '../../../../types/procedureOutputs'
import { Edit3 } from 'react-feather'
import format from 'date-fns/format'
import { formatCurrency } from '../../../../utils/helpers'
import { Fragment } from 'react'

type LogRowProps = {
  last: boolean
  item: AuditTrailLeadLog
}

const LogRow = ({ last, item }: LogRowProps) => {
  if (!item) return

  const formatValue = (value: string) => {
    if (isCurrencyString(value)) return formatCurrency(value)
    if (isDateValid(value)) return format(new Date(value), 'M/d/yyyy')
    else return value
  }

  const getDetails = () => {
    const oldFields: { [key: string]: string } = item?.old_fields || {}
    const newFields: { [key: string]: string } = item?.new_fields || {}

    if ('followup_date' in newFields && (newFields.followup_date === null || oldFields.followup_date === null)) {
      if (newFields.followup_date === null) {
        return (
          <Typography variant="body1" className="text-grey-700">
            closed followup
          </Typography>
        )
      } else {
        return (
          <>
            <Typography variant="body1" className="text-grey-700">
              created followup
            </Typography>
            <Typography variant="body2" className="text-grey-500">
              {newFields.followup_details ? newFields.followup_details + ', ' : ''}due {format(new Date(newFields.followup_date), 'MMM d, yyyy')}
            </Typography>
          </>
        )
      }
    }

    return Object.keys(oldFields).map((key) => {
      const oldVal = oldFields[key]
      const newVal = newFields[key]

      return (
        <Fragment key={key}>
          <Typography variant="body1" className="text-grey-700">
            {' updated '}
          </Typography>
          <Typography variant="subtitle1" className="text-grey-700">
            {key
              .split('_')
              .map((w) => capitalize(w))
              .join(' ')}
          </Typography>
          {oldVal && (
            <Typography variant="body2" className="text-grey-500">
              {' '}
              from <strong>{formatValue(oldVal)}</strong>
            </Typography>
          )}
          <Typography variant="body2" className="text-grey-500">
            {' '}
            to <strong>{formatValue(newVal)}</strong>
          </Typography>
        </Fragment>
      )
    })
  }

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot className={`bg-success-50 m-0`}>
          <Edit3 size={18} className="text-success-600" />
        </TimelineDot>
        <TimelineConnector className={last ? 'bg-transparent' : 'bg-grey-300'} />
      </TimelineSeparator>
      <TimelineContent>
        <Box className="flex gap-2" sx={{ verticalAlign: 'center' }}>
          <Box className="flex gap-2 whitespace-nowrap items-center">
            <Typography variant="subtitle1" className="text-grey-700 leading-[30px]">
              {item?.name}
            </Typography>
            <Box className="flex items-center" gap={1}>
              {getDetails()}
            </Box>
          </Box>
        </Box>
        <Box className="flex gap-3 items-center pb-4">
          <Typography variant="body2" className="text-grey-500">
            {formatDistanceToNowCustom(item?.created_at)}
          </Typography>
        </Box>
      </TimelineContent>
      <TimelineOppositeContent />
    </TimelineItem>
  )
}

export default LogRow

import { format, intervalToDuration } from 'date-fns'
import { pluralize } from './helpers'

export const getDigitalTime = (seconds?: boolean) => format(new Date(), `h:mm${seconds ? ':ss' : ''} a`)

export const getTimeElapsed = ({ datetime }: { datetime: string }) =>
  intervalToDuration({
    start: new Date(datetime),
    end: new Date()
  })

export const formatTimeElapased = ({ datetime }: { datetime: string }) => {
  const { days, months, hours, minutes, seconds } = getTimeElapsed({ datetime })
  if (months) return `${months} ${pluralize('month', months)}`
  if (days) return `${days} ${pluralize('day', days)}`
  if (hours) return `${hours} ${pluralize('hour', hours)}`
  if (minutes) return `${minutes} ${pluralize('minute', minutes)}`
  return `${seconds} ${pluralize('second', seconds || 0)}`
}

export const greeting = (date: Date) => {
  // "Morning" is from 5am until noon
  // "Afternoon" is from noon until 5pm
  // "Evening" is from 5pm until 5am
  // I didn't include "Night" because "Good night" is not a saulutation. It conveys more of a farewell, which isn't the intent of this greeting.
  const hour = date.getHours()
  if (hour >= 5 && hour < 12) {
    return 'Good Morning'
  }
  if (hour >= 12 && hour < 17) {
    return 'Good Afternoon'
  }
  if ((hour >= 17 && hour <= 23) || hour < 5) {
    return 'Good Evening'
  }
  return ''
}

export const toLocalDate = (date: string | Date): Date => new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString())
export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions()?.timeZone

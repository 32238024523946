import { Box } from '@mui/material'
import { useLocation, useParams } from 'react-router'

const ContentLayout = ({ children }: { children: React.ReactNode }) => {
  const { leadId } = useParams()
  const location = useLocation()
  const pathsExcludedFromLayout = ['invoices', 'new-deals/', 'partners', 'commissions']
  const isExcludedFromLayout = leadId || pathsExcludedFromLayout.find((path) => location.pathname.includes(path))
  if (!isExcludedFromLayout) {
    return <Box className="p-6 pr-8 bg-grey-25 flex flex-col flex-grow">{children}</Box>
  }
  return <Box className="flex flex-col flex-grow">{children}</Box>
}

export default ContentLayout

import {
  Typography,
  Divider,
  TextField,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  ClickAwayListener,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useSession } from 'next-auth/react'
import { trpc } from '../../core/trpc'
import { X } from '../../icons/X'
import { PlusCircle } from 'react-feather'
import { Trash } from '../../icons/Trash'
import { useConfirm } from 'material-ui-confirm'
import { ChevronRight } from '../../icons/chevron-right'
import ChevronDown from '../../icons/ChevronDown'
import { Pencil } from '../../icons/pencil'
import toast from 'react-hot-toast'
import { groupBy } from 'remeda'

interface EmailTemplateButtonProps {
  emailSubject: string
  emailBody: string
  setEmailSubject: (s: string) => void
  setEmailBody: (s: string) => void
  variableMapping: { [k: string]: string }
}

const EmailTemplateButton = (props: EmailTemplateButtonProps) => {
  const { emailBody, emailSubject, setEmailBody, setEmailSubject, variableMapping } = props
  const [anchorElSubMenu, setAnchorElSubMenu] = useState<null | HTMLElement>(null)
  const [newTemplateName, setNewTemplateName] = useState('')
  const [isGlobalTemplate, setIsGlobalTemplate] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState(false)

  const { data } = useSession()

  const confirm = useConfirm()
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOpenSubMenu = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorElSubMenu(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAnchorElSubMenu(null)
    setNewTemplateName('')
    setIsGlobalTemplate(false)
  }

  const handleApplyTemplate = (t: {
    id: string
    created_at: string
    updated_at: string
    subject: string | null
    template_name: string
    message_body: string
    user_id: string
  }) => {
    if (t.message_body.includes('{{')) {
      const replacedHtml = Object.entries(variableMapping).reduce((prev, [key, value]) => {
        const keyToCheck = `{{${key}}}`

        if (t.message_body.includes(keyToCheck)) {
          const newText = prev.replaceAll(keyToCheck, value).replaceAll(keyToCheck.substring(2), '').replaceAll('class="mention"', '')
          return newText
        }
        return prev
      }, t.message_body)
      setEmailBody(replacedHtml)
    } else {
      setEmailBody(t.message_body)
    }
    if (t.subject) {
      setEmailSubject(t.subject)
    }
    handleClose()
  }

  const { data: templates, refetch } = trpc.emailTemplate.getMyEmailTemplates.useQuery()
  const createEmailTemplateMutation = trpc.emailTemplate.createEmailTemplate.useMutation({
    onSuccess: () => {
      toast.success('Email template saved.')
      refetch()
    },
    onError: () => toast.error('Failed to save template')
  })
  const deleteEmailTemplateMutation = trpc.emailTemplate.deleteEmailTemplate.useMutation({
    onSuccess: () => {
      toast.success('Email template deleted.')
      refetch()
    },
    onError: () => toast.error('Failed to delete template')
  })

  const updateEmailTemplateMutation = trpc.emailTemplate.updateEmailTemplate.useMutation({
    onSuccess: () => {
      toast.success('Email template updated.')
      refetch()
    },
    onError: () => toast.error('Failed to update template')
  })

  const editableTemplates = useMemo(() => {
    return data?.currentRole?.is_admin ? templates : templates?.filter((t) => !t.is_global)
  }, [templates, data?.currentRole?.is_admin])

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setAnchorElSubMenu(null)
          setAnchorEl(null)
        }}
      >
        <Box>
          <Button variant="outlined" size="small" onClick={handleClick} endIcon={<ChevronDown />}>
            Choose Template
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {templates &&
              Object.entries(groupBy(templates, (i) => i?.is_global?.toString()))?.map(([k, templates]) => (
                <Box>
                  <MenuItem dense disabled key={k}>
                    <Typography variant="caption">{k === 'true' ? 'GLOBAL TEMPLATES' : 'MY TEMPLATES'}</Typography>
                  </MenuItem>
                  {templates.map((t) => (
                    <MenuItem
                      key={t.id}
                      onClick={() => {
                        handleApplyTemplate(t)
                      }}
                    >
                      <Typography>{t.template_name}</Typography>
                    </MenuItem>
                  ))}
                </Box>
              ))}
            <Divider />
            <MenuItem disabled={!emailBody} onClick={() => setOpenModal(true)}>
              <PlusCircle className="w-4 h-4 mr-2" /> Save New Template
            </MenuItem>
            {Boolean(editableTemplates?.length) && (
              <Box>
                <MenuItem onClick={handleOpenSubMenu} className="flex justify-between">
                  <Box>
                    <Trash className="w-4 h-4 mr-2" /> Delete Template
                  </Box>
                  <ChevronRight className="w-4 h-4 ml-2 text-grey-600" />
                  <Menu
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                    anchorEl={anchorElSubMenu}
                    open={Boolean(anchorElSubMenu)}
                    onClose={() => setAnchorElSubMenu(null)}
                  >
                    {anchorElSubMenu && (
                      <ClickAwayListener onClickAway={() => setAnchorElSubMenu(null)}>
                        <Box>
                          {editableTemplates?.map((t) => (
                            <MenuItem
                              onClick={() => {
                                confirm({
                                  title: 'Delete Template',
                                  description: `Are you sure you want to delete template: ${t.template_name}?`
                                })
                                  .then(() => {
                                    deleteEmailTemplateMutation.mutate({ id: t.id })
                                  })
                                  .finally(() => {
                                    setAnchorElSubMenu(null)
                                    handleClose()
                                  })
                              }}
                            >
                              {t.template_name}
                            </MenuItem>
                          ))}
                        </Box>
                      </ClickAwayListener>
                    )}
                  </Menu>
                </MenuItem>
                <MenuItem onClick={handleOpenSubMenu} className="flex justify-between">
                  <Box>
                    <Pencil className="w-4 h-4 mr-2" /> Overwrite Template
                  </Box>
                  <ChevronRight className="w-4 h-4 ml-2 text-grey-600" />
                  <Menu
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                    anchorEl={anchorElSubMenu}
                    open={Boolean(anchorElSubMenu)}
                    onClose={() => setAnchorElSubMenu(null)}
                  >
                    {anchorElSubMenu && (
                      <ClickAwayListener onClickAway={() => setAnchorElSubMenu(null)}>
                        <Box>
                          {editableTemplates?.map((t) => (
                            <MenuItem
                              onClick={() => {
                                confirm({
                                  title: 'Overwrite Template',
                                  description: `Are you sure you want to overwrite template: ${t.template_name}?`
                                })
                                  .then(() => {
                                    updateEmailTemplateMutation.mutate({ id: t.id, message_body: emailBody, subject: emailSubject || undefined })
                                  })
                                  .finally(() => {
                                    setAnchorElSubMenu(null)
                                    handleClose()
                                  })
                              }}
                            >
                              {t.template_name}
                            </MenuItem>
                          ))}
                        </Box>
                      </ClickAwayListener>
                    )}
                  </Menu>
                </MenuItem>
              </Box>
            )}
          </Menu>
        </Box>
      </ClickAwayListener>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <Box className="bg-white px-10 py-5 flex flex-col gap-5">
          <Box className="flex justify-between">
            <Typography className="self-center" variant="h6" component="h2">
              Create Template
            </Typography>
            <IconButton size="small" onClick={() => setOpenModal(false)}>
              <X />
            </IconButton>
          </Box>
          <TextField
            className="mt-4"
            label="Template Name"
            fullWidth
            placeholder="Add Name"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
          {data?.currentRole?.is_admin && (
            <FormControlLabel
              control={<Checkbox value={isGlobalTemplate} onChange={(_, v) => setIsGlobalTemplate(v)} />}
              label="Create as Global Template"
            />
          )}
          <Divider />
          <Box className="flex justify-between">
            <Button
              onClick={() => {
                setOpenModal(false)
                handleClose()
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              disabled={!newTemplateName}
              loading={createEmailTemplateMutation.isLoading}
              onClick={() => {
                createEmailTemplateMutation.mutate({
                  template_name: newTemplateName,
                  message_body: emailBody,
                  subject: emailSubject || undefined,
                  is_global: isGlobalTemplate || false
                })
                setOpenModal(false)
                handleClose()
              }}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default EmailTemplateButton

import { Box } from '@mui/system'
import { GetStageAutoMoveRulesOutput } from '../../../types/procedureOutputs'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import AddEditAutoStageModal from './AddEditAutoStageModal'

type AutoStageProps = {
  data: GetStageAutoMoveRulesOutput | undefined
  refetch: () => void
}

const AutoStageList = ({ data, refetch }: AutoStageProps) => {
  const [autoStageToEdit, setAutoStageToEdit] = useState<string | null>(null)
  const confirm = useConfirm()

  const deleteMutation = trpc.stageAutoMove.deleteStageAutoMoveRule.useMutation({
    onSuccess: () => {
      refetch()
      toast.success('Auto-stage deleted')
    }
  })

  const tableCellData = useMemo<MRT_ColumnDef<GetStageAutoMoveRulesOutput[0]>[]>(() => {
    return [
      {
        header: 'Starting Stage',
        id: 'starting-stage',
        enableSorting: true,
        size: 70,
        accessorFn: (row: GetStageAutoMoveRulesOutput[0]) => (
          <Box>
            <Typography className="text-grey-700 font-bold">{row.starting_stage.name}</Typography>
            <Typography className="text-grey-700 text_md_normal">{row.starting_stage.pipeline.name}</Typography>
          </Box>
        )
      },
      {
        header: 'Target Stage',
        id: 'target-stage',
        enableSorting: true,
        size: 70,
        accessorFn: (row: GetStageAutoMoveRulesOutput[0]) => (
          <Box>
            <Typography className="text-grey-700 font-bold">{row.target_stage.name}</Typography>
            <Typography className="text-grey-700 text_md_normal">{row.target_stage.pipeline.name}</Typography>
          </Box>
        )
      },
      {
        header: 'Inactive Days',
        id: 'inactive-days',
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          return Number(rowA.original.days_of_inactivity) - Number(rowB.original.days_of_inactivity)
        },
        size: 30,
        accessorFn: (row: GetStageAutoMoveRulesOutput[0]) => (
          <Box>
            <Typography className="text-grey-600 text_md_normal">{row.days_of_inactivity}</Typography>
          </Box>
        )
      },
      {
        header: 'Reason',
        id: 'reason',
        enableSorting: true,
        size: 70,
        accessorFn: (row: GetStageAutoMoveRulesOutput[0]) => (
          <Box>
            <Typography className="text-grey-600 text_md_normal">{row.reason}</Typography>
          </Box>
        )
      },
      {
        header: '',
        id: 'actions',
        enableSorting: false,
        size: 70,
        accessorFn: (row: GetStageAutoMoveRulesOutput[0]) => (
          <Box className="flex gap-3">
            <IconButton onClick={() => setAutoStageToEdit(row.id)}>
              <EditOutlined />
            </IconButton>
            <IconButton
              onClick={() =>
                confirm({
                  title: 'Delete Auto-stage Rule?',
                  description: `Are you sure you want to delete this rule?`
                }).then(() => {
                  deleteMutation.mutate({ id: row.id })
                })
              }
            >
              <DeleteOutline />
            </IconButton>
          </Box>
        )
      }
    ]
  }, [data])
  return (
    <Box className="overflow-auto">
      <Box className="overflow-auto bg-white">
        <MaterialReactTable
          data={data || []}
          columns={tableCellData.flat()}
          enableColumnDragging={false}
          enableColumnOrdering={false}
          enableColumnFilters={false}
          enableDensityToggle={false}
          enableSortingRemoval={false}
          enableSelectAll={true}
          enableFilters={false}
          enableColumnActions={false}
          enableRowVirtualization
          enableTopToolbar={false}
          enableSorting
          enableMultiSort
          muiTableBodyRowProps={({ row }) => ({
            sx: { background: 'white', padding: 0 }
          })}
          enableTableFooter
        />
      </Box>
      {Boolean(autoStageToEdit) && (
        <AddEditAutoStageModal
          existingAutoStageRule={data?.find((autoStage) => autoStage.id === autoStageToEdit!)}
          open={Boolean(autoStageToEdit)}
          closeModal={() => setAutoStageToEdit(null)}
          refetch={refetch}
        />
      )}
    </Box>
  )
}
export default AutoStageList

import { Avatar, Tooltip, Typography } from '@mui/material'
// import { getBase64FromFile } from '../utils'
import { Box } from '@mui/system'
// import { useQuery } from '@tanstack/react-query'
// import { getMsUserPhoto } from '../services/azureServices'
import clsx from 'clsx'
import ContactAvatar from './ContactAvatar'

type UserAvatarProps = {
  microsoftUserId?: string
  name: string
  id: string
  className?: string
  dontDisplayName?: boolean
}

const UserAvatar = (props: UserAvatarProps) => {
  const { name, id, className } = props

  // const { data } = useQuery(
  //   ['photo', microsoftUserId],
  //   async () => {
  //     const { data } = await getMsUserPhoto({ userId: microsoftUserId || '' })
  //     return await getBase64FromFile(data)
  //   },
  //   { enabled: Boolean(microsoftUserId) }
  // )
  return (
    <Tooltip title={name}>
      <Box className="flex gap-2 items-center">
        {
          // data?.data ? (
          //   <Avatar className={clsx(className, 'bg-white')}>
          //     <Avatar className={className} src={data.data} alt={name || ''} />
          //   </Avatar>
          // ) :
          <Avatar key={id} className={clsx(className, 'bg-white')}>
            <ContactAvatar className={className} name={name} />
          </Avatar>
        }
        {!props.dontDisplayName && <Typography className="text-xs">{name}</Typography>}
      </Box>
    </Tooltip>
  )
}
export default UserAvatar

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/function-component-definition */
import { useState } from 'react'
import type { FC, ReactNode } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, Collapse, Link, ListItem } from '@mui/material'
import type { ListItemProps } from '@mui/material'
import { ChevronDown as ChevronDownIcon, ChevronRight as ChevronRightIcon } from 'react-feather'

interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean
  children?: ReactNode
  chip?: ReactNode
  depth: number
  icon?: ReactNode
  info?: ReactNode
  open?: boolean
  path?: string
  onClick?: () => void
  title: string
}

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = (props) => {
  const { active, children, chip, depth, icon, info, open: openProp, path, onClick, title, ...other } = props
  const [open, setOpen] = useState<boolean>(openProp || false)

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 10

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          mb: 0.5,
          py: 0,
          px: 2
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
          disableRipple
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            backgroundColor: active ? '#E3F2FD' : undefined,
            borderRadius: 1,
            color: active ? '#0D47A1' : '#364152',
            fontWeight: 400,
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '&:hover': {
              backgroundColor: '#E3F2FD'
            },
            '& .MuiButton-startIcon': active
              ? {
                  backgroundColor: '#BBDEFB',
                  borderRadius: '8px',
                  padding: '3px',
                  color: '#1976D2'
                }
              : { color: '#9AA4B2', padding: '3px' },
            '& .MuiButton-endIcon': {
              color: 'neutral.400'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open} sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </ListItem>
    )
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: '10px',
        py: 0,
        px: 2
      }}
    >
      <Link component={RouterLink} to={path || ''} sx={{ width: '100%' }}>
        <Button
          onClick={onClick && onClick}
          startIcon={icon}
          endIcon={chip}
          disableRipple
          sx={{
            backgroundColor: active && depth === 0 ? '#E3F2FD' : undefined,
            borderRadius: 1,
            color: active && depth === 0 ? '#0D47A1' : '#364152',
            fontWeight: active && depth > 0 ? 500 : 400,
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 4,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '& .MuiButton-startIcon': active
              ? {
                  backgroundColor: '#BBDEFB',
                  borderRadius: '8px',
                  padding: '3px',
                  color: '#1976D2'
                }
              : { color: '#9AA4B2', padding: '3px' },
            '&:hover': {
              backgroundColor: '#E3F2FD'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
      </Link>
    </ListItem>
  )
}

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
}

DashboardSidebarItem.defaultProps = {
  active: false,
  open: false
}

interface LogoProps {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

function Logo(props: LogoProps) {

  return <img alt="Logo" style={{ borderRadius: 10 }} height={50} src="/static/images/logo.png" {...props} />
}

export default Logo

import { Box, Chip } from '@mui/material'
import { priorityColors } from '../consts/colors'
import { ReassignmentPriority } from '@prisma/client'

type PriorityChipProps = {
  priority: ReassignmentPriority
  size?: "small" | "medium" | undefined
}

const PriorityChip = ({ size, priority }: PriorityChipProps) => {
  if (priority === 'NOT_REASSIGNABLE') return <Box />
  const colors = priorityColors[priority]
  return (
    <Chip
      size={size}
      className={`text_xs_medium tracking-wide w-20 rounded-[200px] border-[1px] border-solid text-${colors.text} border-${colors.border} bg-${colors.bg}`}
      label={priority}
    />
  )
}

export default PriorityChip

import { useEffect, useState } from "react";
import { trpc } from "../../../core/trpc";
import { AuditTrailOfferLog } from "../../../types/procedureOutputs";
import { Virtuoso } from "react-virtuoso";
import LogRow from "../../pipelines/leadDetails/activity/LogRow";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { pluralize } from "../../../utils";

interface OfferAuditLogListProps {
    offer_id: string
}

const OfferAuditLogList  = ({ offer_id }: OfferAuditLogListProps) => {
    const [offerLogs, setOfferLogs] = useState<{ results: AuditTrailOfferLog[]; count: number }>({ count: 0, results: [] })
    const [loading, setLoading] = useState(false)
    const [skip, setSkip] = useState(0)
    const trpcContext = trpc.useContext()
    const TAKE = 50 

    const fetchOfferLogs = async ({ concat }: { concat: boolean }) => {
        setLoading(true)
        const response = await trpcContext.auditTrail.getOfferLogs.fetch({ offer_id: offer_id, skip, take: TAKE })
        if (concat) setSkip(skip + TAKE)
        else setSkip(TAKE)
        const newData = concat ? [...offerLogs.results, ...response.results] : response.results
        setOfferLogs({ count: response.count, results: newData })
        setLoading(false)
    }

    useEffect(() => {
        fetchOfferLogs({ concat: false })
    }, [])

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollHeight, scrollTop, clientHeight } = e.currentTarget
        if (Math.abs(scrollHeight - scrollTop - clientHeight) < 1) {
           if (offerLogs.results.length < offerLogs.count && !loading) {
            fetchOfferLogs({ concat: true })
          }
        }
      }
    
    return <Box>
        <Box display="flex">
          <Typography className="self-center text-lg font-semibold">Edit History</Typography>
          <Typography className="self-center pl-2 text-grey-500 text-base font-normal">{`${offerLogs?.count || 0} ${pluralize(
            'edit',
            offerLogs?.count || 0
          )}`}</Typography>
        </Box>
        <Virtuoso
            onScroll={handleScroll}
            className="rounded-xl h-[300px]"
            data={offerLogs.results}
            itemContent={(index, item) => (
                <LogRow key={item?.id} item={item} last={index === offerLogs.results.length - 1} />
            )}
        />
    </Box>

}

export default OfferAuditLogList
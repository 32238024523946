import type { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useSession } from 'next-auth/react'

interface AdminGuardProps {
  children?: ReactNode
}

function AdminGuard({ children }: AdminGuardProps) {
  const { data, status } = useSession()
  const isAdmin = Boolean(data?.currentRole?.is_admin)
  if (status !== 'loading' && !isAdmin) {
    return <Navigate to="/" />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default AdminGuard

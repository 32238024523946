import toast from 'react-hot-toast'
import { sendEmail } from '../../../../services/mailer'
import { CallFeedbackContact } from './index'
import { NotReachedLeadRequestInfo } from './types'
import { LeadByIdOutput } from '../../../../types/procedureOutputs'

const DEFAULT_ADMIN_EMAIL = 'natalia@sunrisefundinggrp.com'

export async function autoRequestInfo(
  mailboxEmailAddress: string,
  infoType: NotReachedLeadRequestInfo,
  {
    contact,
    lead,
    company
  }: {
    // Contact is not always available. Occurs when using the "Call a Different Phone Number" feature
    contact?: CallFeedbackContact
    lead: LeadByIdOutput
    company: LeadByIdOutput['company']
  }
) {
  if (infoType === 'OTHER') throw new Error('useAutoRequestInfo unsupported info type OTHER')

  try {
    const from = {
      emailAddress: {
        address: mailboxEmailAddress || '',
        name: ''
      }
    }

    let contactInfoName = ''
    switch (infoType) {
      case 'PHONE':
        contactInfoName = 'phone number'
        break
      case 'EMAIL':
        contactInfoName = 'email'
        break
      case 'DOB':
        contactInfoName = 'date of birth'
        break
    }

    const subject = `Requesting information with ${company.name} - ${lead.name}`
    const msg = `Requesting info on this lead ${contact?.name ?? ''} ${company.name} — ${lead.name}. Need ${contactInfoName}.`

    const response = await sendEmail({
      leadId: lead.id,
      from,
      to: [{ emailAddress: { address: DEFAULT_ADMIN_EMAIL, name: '' } }],
      subject,
      body: msg,
      bodyType: 'html'
    })

    if (!response.sent) {
      toast.error(`An error has occurred: ${response.error}`)
      return
    }

    toast.success('Email sent successfully')
  } catch (e) {
    console.error('failed to send email:', e)
    toast.error('Failed to send email')
  }
}

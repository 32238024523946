import { Box } from '@mui/system'
import { Helmet } from 'react-helmet-async'
import AutoStageList from './autoStageList'
import { trpc } from '../../../core/trpc'
import Header from './Header'

const AutoStage = () => {
  const { data, refetch } = trpc.stageAutoMove.getStageAutoMoveRules.useQuery()
  return (
    <Box className="flex flex-col gap-6">
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Header refetch={refetch} />
      <AutoStageList refetch={refetch} data={data} />
    </Box>
  )
}
export default AutoStage

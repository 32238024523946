import { Drawer, Box, Typography, IconButton, Button, Badge } from '@mui/material'
import { useState } from 'react'
import { Calendar, PlusCircle, X } from 'react-feather'
import FollowUpList from '../../../views/home/components/FollowUps/FollowUpList'
import { addDays, addWeeks, format, startOfWeek, subWeeks } from 'date-fns'
import { range } from 'remeda'
import { ChevronLeft } from '../../../icons/chevron-left'
import { ChevronRight } from '../../../icons/chevron-right'
import AddFollowUp from '../../../components/AddFollowUp'
import { trpc } from '../../../core/trpc'

const FollowUps = () => {
  const [openFU, setOpenFU] = useState(false)
  const [dateSelected, setDateSelected] = useState(startOfWeek(new Date()))
  const [openAddFu, setOpenAddFu] = useState(false)

  const { data: todayFollowups } = trpc.followup.countMyFollowupLeadsForToday.useQuery({ trpc: { context: { skipBatch: true } } })

  return (
    <>
      <IconButton className="p-0 mr-4 cursor-pointer" onClick={() => setOpenFU(!openFU)}>
        <Badge badgeContent={todayFollowups?.count ?? 0} color="error">
          <Calendar className="text-white" />
        </Badge>
      </IconButton>
      <Drawer anchor="right" open={Boolean(openFU)} onClose={() => setOpenFU(!openFU)}>
        {openFU && (
          <Box className="p-6 min-w-[420px] h-full">
            <Box className="gap-6 flex flex-col justify-between h-full">
              <Box className="gap-6 flex flex-col">
                <Box className="flex justify-between">
                  <Typography className="text-grey-900 font-semibold text-2xl">Follow Ups</Typography>
                  <IconButton onClick={() => setOpenFU(!openFU)}>
                    <X />
                  </IconButton>
                </Box>
                <Box className="flex justify-between">
                  <Typography className="text-black font-medium text-[21px]">{format(dateSelected, 'MMMM yyyy')}</Typography>
                  <Button size="small" variant="outlined" startIcon={<Calendar />} onClick={() => setDateSelected(new Date())}>
                    Today
                  </Button>
                </Box>
                <Box className="flex justify-between">
                  <IconButton size="small" className="text-grey-600 cursor-pointer" onClick={() => setDateSelected(subWeeks(dateSelected, 1))}>
                    <ChevronLeft />
                  </IconButton>
                  {range(0, 6).map((dayOfWeek) => {
                    const isDateSelected = dateSelected.getDay() === dayOfWeek
                    const day = addDays(startOfWeek(dateSelected), dayOfWeek)
                    return (
                      <Box className="px-4 text-center" onClick={() => setDateSelected(day)}>
                        <Typography className="text-grey-600 text-sm pb-2">{format(day, 'EEEEE')}</Typography>
                        <Typography className={`${isDateSelected ? 'bg-secondary-300 rounded-full text-white h-6 w-6' : 'text-grey-800'}`}>
                          {format(day, 'dd')}
                        </Typography>
                      </Box>
                    )
                  })}
                  <IconButton size="small" className="text-grey-600 cursor-pointer" onClick={() => setDateSelected(addWeeks(dateSelected, 1))}>
                    <ChevronRight />
                  </IconButton>
                </Box>
                <Box className="pb-24">
                  <FollowUpList startDate={dateSelected} />
                </Box>
              </Box>
              <Box className="sticky bottom-0 py-6 bg-white flex ">
                <Button
                  onClick={() => setOpenAddFu(true)}
                  variant="outlined"
                  fullWidth
                  className="text-base font-normal"
                  startIcon={<PlusCircle className="h-[18px] w-[18px]" />}
                >
                  Add Follow Up
                </Button>
              </Box>
              {openAddFu && (
                <AddFollowUp
                  open={openAddFu}
                  setOpen={setOpenAddFu}
                  refetchFuProps={{ fuStartDate: dateSelected?.toISOString(), take: 30, skip: 0 }}
                />
              )}
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  )
}
export default FollowUps

import { Breadcrumbs } from '@mui/material'
import { ChevronRightIcon } from '../../assets/icons'
import { matchRoutes, useLocation } from 'react-router'
import { RouteWithBreadCrumb, dashboardRoutes, routesWithBreadCrumbs } from '../../routes'
import { uniq } from 'ramda'
import React from 'react'
import Crumb from './Crumb'

const BreadCrumbs = () => {
  const location = useLocation()
  const matches = matchRoutes(dashboardRoutes, location)

  const getAllPathsWithCrumbs = (route: RouteWithBreadCrumb, routes: RouteWithBreadCrumb[]): RouteWithBreadCrumb[] => {
    const routeWithCrumb = route.crumb ? [route] : []
    if (route.children) return uniq(route.children.flatMap((child) => getAllPathsWithCrumbs(child, [...routeWithCrumb, ...routes])))
    else return [...routes, ...routeWithCrumb]
  }

  const allRoutesWithCrumbs = routesWithBreadCrumbs.flatMap((route) => getAllPathsWithCrumbs(route, []))

  const crumbs = matches?.reduce((pre, cur) => {
    const matchedRoute = allRoutesWithCrumbs.find((route) => route.path === cur.route.path)
    if (matchedRoute) return [...pre, matchedRoute.crumb as React.JSX.Element]
    return pre
  }, [] as React.JSX.Element[])

  if (!crumbs?.length) return <Crumb text={window.document.title} path={location.pathname} />

  return <Breadcrumbs separator={<ChevronRightIcon className="text-grey-25" />}>{crumbs}</Breadcrumbs>
}

export default BreadCrumbs

import { Box, IconButton, Paper, Popper, Typography } from '@mui/material'
import { CloseIcon } from '../../../assets/icons'
import { ContactPhone } from '@prisma/client'
import PhoneRow from './PhoneRow'

type ContactPhonePopperProps = {
  name: string
  data: ContactPhone[]
  anchorEl: HTMLElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  handleCall: ({ phoneNumber, id }: { phoneNumber: string; id: string; thumbsUp: number; thumbsDown: number }) => void
  setOpenPhoneNote: (x: string | null) => void
}

const ContactPhonePopper = ({ name, data, anchorEl, setAnchorEl, handleCall, setOpenPhoneNote }: ContactPhonePopperProps) => {
  return (
    <Popper className="z-[2000]" open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
      <Paper className="w-[550px] p-5 shadow-md mt-1">
        <Box className="flex justify-between items-center mb-6">
          <Typography className="text_lg_semibold text-grey-900">{name}</Typography>
          <IconButton onClick={() => setAnchorEl(null)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <Box className="flex flex-col gap-3">
          {data.map((item) => (
            <PhoneRow
              isPopper={true}
              key={item.id}
              type="both"
              num={item}
              name={undefined}
              handleCall={() => handleCall({ phoneNumber: item.phone_number, id: item.id, thumbsUp: item.thumbs_up, thumbsDown: item.thumbs_down })}
              setOpenNote={setOpenPhoneNote}
            />
          ))}
        </Box>
      </Paper>
    </Popper>
  )
}

export default ContactPhonePopper

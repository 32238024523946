import { Box, Button, IconButton, Skeleton, TextField, Typography } from '@mui/material'
import { CallFeedbackContact } from '.'
import { useState } from 'react'
import { CheckIcon, CloseIcon, EditOutlinedIcon } from '../../../../assets/icons'
import { formatPhoneOnInput } from '../../../../utils'
import { trpc } from '../../../../core/trpc'
import clsx from 'clsx'

type ContactInfoProps = {
  title: string
  contactInfo: CallFeedbackContact
  setContactInfo: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
  leadId: string
}

const ContactInfo = ({ title, contactInfo, setContactInfo, leadId }: ContactInfoProps) => {
  const { data: lead, isLoading } = trpc.lead.getLeadById.useQuery({ id: leadId || '' }, { enabled: Boolean(leadId) })
  return (
    <Box className="flex flex-col gap-4">
      <Typography className="text-grey-900 text_lg_semibold">{title}</Typography>
      <Box className="flex justify-between flex-wrap">
        <ContactLine label="Contact Name" setContactInfo={setContactInfo} field="name" value={contactInfo.name} contactInfo={contactInfo} />
        <ContactLine
          readOnly
          label="Company Name"
          setContactInfo={setContactInfo}
          field="company"
          value={`${lead?.company.name} - ${lead?.name}`}
          contactInfo={contactInfo}
          isLoading={isLoading}
        />
        <ContactLine label="Email" setContactInfo={setContactInfo} field="email" value={contactInfo.email} contactInfo={contactInfo} />
        <ContactLine label="Phone" setContactInfo={setContactInfo} field="phone" value={contactInfo.phone.phoneNumber} contactInfo={contactInfo} />
      </Box>
    </Box>
  )
}

export default ContactInfo

type ContactLineProps = {
  value: string
  label: string
  field: keyof CallFeedbackContact | 'company'
  setContactInfo: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
  contactInfo: CallFeedbackContact
  readOnly?: boolean
  isLoading?: boolean
}

const ContactLine = ({ value, label, setContactInfo, field, contactInfo, readOnly, isLoading }: ContactLineProps) => {
  const [editMode, setEditMode] = useState(false)
  const [tempVal, setTempVal] = useState<string>()

  return (
    <Box>
      <Typography className="mb-1 text-grey-500">{label}</Typography>
      {isLoading && <Skeleton className="w-24 mt-1.5" />}
      {(!editMode || readOnly) && !isLoading && (
        <Box className="flex justify-between items-center gap-3">
          <Typography className={clsx({ 'pt-1.5': readOnly })}>{field === 'phone' ? formatPhoneOnInput(value) : value}</Typography>
          {!readOnly && (
            <IconButton onClick={() => setEditMode(true)}>
              <EditOutlinedIcon fontSize="small" className="text-primary-600" />
            </IconButton>
          )}
        </Box>
      )}
      {editMode && (
        <Box className="flex justify-between items-center gap-3">
          <TextField
            value={field === 'phone' ? formatPhoneOnInput(tempVal ?? value) : tempVal ?? value}
            onChange={(e) => setTempVal(field === 'phone' ? formatPhoneOnInput(e.target.value) : e.target.value)}
          />
          <Box className="flex gap-1">
            <Button
              onClick={() => {
                setContactInfo((data) => {
                  if (data) {
                    const val = field === 'phone' ? { id: contactInfo.phone.id, phoneNumber: tempVal, thumbsUp: contactInfo.phone.thumbsUp } : tempVal
                    if (val !== undefined) {
                      return { ...data, [field]: val }
                    } else {
                      return data
                    }
                  }
                })
                setTempVal(() => undefined)
                setEditMode(false)
              }}
              variant="outlined"
              className="min-w-fit px-2"
            >
              <CheckIcon fontSize="small" />
            </Button>
            <Button
              onClick={() => {
                setTempVal(undefined)
                setEditMode(false)
              }}
              variant="outlined"
              className="min-w-fit px-2"
            >
              <CloseIcon fontSize="small" />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

import { Box, Button, Dialog, Divider, IconButton, TextField, Typography } from '@mui/material'
import { CloseIcon } from '../assets/icons'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'

type EditNoteProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  note: string
  onSave: (note: string) => void
  loading: boolean
  isEditMode: boolean
}

const EditNote = ({ open, setOpen, note, onSave, loading, isEditMode }: EditNoteProps) => {
  const [noteVal, setNoteVal] = useState(note)

  return (
    <Dialog open={open}>
      <Box className="p-6 w-[400px] h-[400px] flex flex-col justify-between">
        <Box>
          <Box className="flex justify-between gap-6 items-center mb-5">
            <Typography className="text_xl_medium text-grey-900">{isEditMode ? 'Edit' : 'View'} Note</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon fontSize="small" className="text-grey-700" />
            </IconButton>
          </Box>
          {isEditMode ? (
            <TextField
              variant="standard"
              InputProps={{ disableUnderline: true }}
              fullWidth
              className="p-2 text_md_normal text-grey-600"
              value={noteVal}
              onChange={(e) => setNoteVal(e.target.value)}
              multiline
              autoFocus
            />
          ) : (
            <Typography component="span" variant="body2" className="p-2 text_md_normal text-grey-600">
              {noteVal}
            </Typography>
          )}
        </Box>
        {isEditMode && (
          <Box>
            <Divider className="my-8" />
            <Box className="flex justify-end gap-4">
              <Button onClick={() => setOpen(false)} variant="outlined">
                Cancel
              </Button>
              <LoadingButton variant="contained" loading={loading} onClick={() => onSave(noteVal)}>
                Save
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default EditNote

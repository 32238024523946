import { useState } from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import RenderTextfield from './RenderTextfield'

type AddressInputsProps = {
  onlyToRecipients?: boolean
  setToRecipients: (e: MicrosoftPerson[] | undefined) => void
  setCcRecipients?: (e: MicrosoftPerson[] | undefined) => void
  setBccRecipients?: (e: MicrosoftPerson[] | undefined) => void
  ccRecipients?: MicrosoftPerson[] | undefined
  bccRecipients?: MicrosoftPerson[] | undefined
  toRecipients: MicrosoftPerson[] | undefined
  toAutoFocus?: boolean
  setContactLead?: (leadId: string | null) => void
}

const AddressInputs = ({
  setToRecipients,
  setCcRecipients,
  ccRecipients,
  toRecipients,
  bccRecipients,
  setBccRecipients,
  onlyToRecipients,
  toAutoFocus,
  setContactLead
}: AddressInputsProps) => {
  const [toInput, setToInput] = useState({ value: '', error: false })
  const [ccInput, setCcInput] = useState({ value: '', error: false })
  const [bccInput, setBccInput] = useState({ value: '', error: false })

  return (
    <>
      <Box className="flex justify-between items-start">
        <Box className="flex gap-8 items-start w-full">
          <Typography className="text-grey-600">To</Typography>
          <Box data-cy="toInputWrapper" className="flex gap-2 flex-wrap w-full">
            <RenderTextfield
              input={toInput}
              setInput={setToInput}
              setRecipients={setToRecipients}
              recipients={toRecipients}
              autoFocus={toAutoFocus}
              setContactLead={setContactLead}
            />
          </Box>
          <Divider className="my-3" />
        </Box>
        {!onlyToRecipients && (
          <Box className="flex gap-2">
            {!ccRecipients && (
              <Button
                data-cy="ccButton"
                tabIndex={-1}
                onClick={(e) => {
                  e.stopPropagation()
                  setCcRecipients?.([])
                }}
                className="text-primary-500 p-1 min-w-fit"
              >
                Cc
              </Button>
            )}
            {!bccRecipients && (
              <Button
                data-cy="bccButton"
                tabIndex={-1}
                onClick={(e) => {
                  e.stopPropagation()
                  setBccRecipients?.([])
                }}
                className="text-primary-500 p-1 min-w-fit"
              >
                Bcc
              </Button>
            )}
          </Box>
        )}
      </Box>
      {Boolean(ccRecipients) && (
        <>
          <Divider className="my-3" />
          <Box className="flex gap-8 items-start w-full">
            <Typography className="text-grey-600">Cc</Typography>
            <Box data-cy="ccInputWrapper" className="flex gap-2 flex-wrap w-full">
              <RenderTextfield input={ccInput} setInput={setCcInput} setRecipients={setCcRecipients!} recipients={ccRecipients} />
            </Box>
          </Box>
        </>
      )}
      {Boolean(bccRecipients) && (
        <>
          <Divider className="my-3" />
          <Box className="flex items-start gap-8 w-full">
            <Typography className="text-grey-600">Bcc</Typography>
            <Box data-cy="bccInputWrapper" className="flex gap-2 flex-wrap w-full">
              <RenderTextfield input={bccInput} setInput={setBccInput} setRecipients={setBccRecipients!} recipients={bccRecipients} />
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default AddressInputs

import { Box, Button, Dialog, Divider, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { trpc } from '../../../core/trpc'
import { toast } from 'react-hot-toast'
import { ContactGetContactByCompanyOutput } from '../../../types/procedureOutputs'
import { CloseIcon } from '../../../assets/icons'

type AddPhoneNote = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<string | null>>
  note: string
  contact: ContactGetContactByCompanyOutput | undefined
  refetch?: () => void
  phoneId: string | null
}

const AddPhoneNote = ({ contact, open, setOpen, note, phoneId, refetch }: AddPhoneNote) => {
  const [val, setVal] = useState<string | null>(note)

  if (!contact) {
    return
  }
  const mutation = trpc.contactPhone.updatePhone.useMutation({
    onSuccess: (d) => {
      toast.success('Note saved!')
      refetch && refetch()
    },
    onError: (e) => toast.error(`Error saving note ${e.message}`),
    onSettled: () => setOpen(null)
  })

  const handleSave = () => {
    const phone = contact.phones.find((item) => item.id === phoneId)
    if (phone) {
      mutation.mutate({
        phoneId: phone.id,
        notes: val ?? ''
      })
    }
  }

  return (
    <Dialog open={open}>
      <Box className="w-[400px] p-6">
        <Box className="flex justify-between items-center mb-5">
          <Typography className="text-grey-900 text_xl_medium">{note ? 'Edit Note' : 'Add Note'}</Typography>
          <IconButton onClick={() => setOpen(null)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          InputProps={{ disableUnderline: true }}
          placeholder="Enter note..."
          autoFocus
          value={val}
          onChange={(e) => setVal(e.target.value)}
          variant="standard"
        />
        <Divider className="mb-6 mt-8" />
        <Box className="flex justify-end gap-3">
          <Button variant="outlined" onClick={() => setOpen(null)}>
            Cancel
          </Button>
          <Button disabled={note === val || mutation.isLoading} variant="contained" onClick={() => handleSave()}>
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddPhoneNote

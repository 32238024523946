import { Box, Button, Collapse, Typography } from '@mui/material'
import { ThumbsUp } from '../../../../../icons/ThumbsUp'
import { Check } from '../../../../../icons/Check'
import { CloseIcon } from '../../../../../assets/icons'
import { useEffect, useState } from 'react'
import { TermLength } from '@prisma/client'
import InterestedFields from './InterestedFields'
import { CallFeedbackContact, FollowupSchema } from '..'
import { UseFormReturn } from 'react-hook-form'
import NotInterested from './NotInterested'
import { trpc } from '../../../../../core/trpc'

export type InterestedFieldsType = {
  requestedAmount: { value: string }
  averageRevenue: { value: string }
  positions: { value: string }
  paymentHistory: { value: boolean }
  credit: { value: string }
  termAmount: { value: string }
  termLength: { value: TermLength }
  industry: { value: string }
  callNotes: { value: string }
}

type ReachedLeadProps = {
  interested: boolean | undefined
  setInterested: React.Dispatch<React.SetStateAction<boolean | undefined>>
  handleClose: () => void
  form: UseFormReturn<FollowupSchema>
  createTaskAndActivity: (data: FollowupSchema, callNotes?: string) => void
  setContactInfo: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
  contactInfo: CallFeedbackContact | undefined
  nextId?: string
  stageId?: string
  leadId: string
  relatedLeads?: boolean
  subsidiaryId: string | undefined
  stageAllowReassignment: boolean
}

const ReachedLead = ({
  contactInfo,
  setContactInfo,
  interested,
  setInterested,
  handleClose,
  form,
  createTaskAndActivity,
  nextId,
  stageId,
  leadId,
  relatedLeads,
  subsidiaryId,
  stageAllowReassignment
}: ReachedLeadProps) => {
  const trpcContext = trpc.useContext()
  const [interestedFields, setInterestedFields] = useState<InterestedFieldsType>({
    requestedAmount: { value: '' },
    averageRevenue: { value: '' },
    positions: { value: '' },
    paymentHistory: { value: false },
    credit: { value: '' },
    termAmount: { value: '' },
    termLength: { value: 'DAYS' as TermLength },
    industry: { value: '' },
    callNotes: { value: '' }
  })

  useEffect(() => {
    ;(async () => {
      if (leadId) {
        const data = await trpcContext.lead.getLeadById.fetch({ id: leadId })
        setInterestedFields((interestedFields) => ({
          ...interestedFields,
          requestedAmount: { value: data.merchant_request_amount ? String(data.merchant_request_amount) : '' },
          averageRevenue: { value: data.avg_revenue ? String(data.avg_revenue) : '' },
          positions: { value: data.num_of_positions ? String(data.num_of_positions) : '' },
          paymentHistory: { value: data.payment_history || false },
          credit: { value: data.merchant_credit ? String(data.merchant_credit) : '' },
          termAmount: { value: data.term_amount ? String(data.term_amount) : '' },
          termLength: { value: data.term_length || ('DAYS' as TermLength) },
          industry: { value: data.company.industry_title || '' }
        }))
      }
    })()
  }, [leadId, trpcContext])

  return (
    <>
      <Box className="flex justify-center gap-3 mt-5 w-full">
        <Button
          className={`flex items-center box-border p-2 rounded-xl border-solid border-[3px] border-primary-400 w-full flex-row justify-center gap-2`}
        >
          <ThumbsUp />
          <Typography className="text-grey-600 text-center text_sm_normal">I reached the lead</Typography>
        </Button>
        {interested === true && (
          <Button
            className={`flex items-center box-border p-2 rounded-xl border-solid border-[3px] border-primary-400 w-full flex-row justify-center gap-2`}
          >
            <Box className="flex items-center p-1 justify-center rounded-full bg-success-100 border-success-50 border-solid border-4">
              <Check fontSize="small" className="text-success-800" />
            </Box>
            <Typography className="text-grey-600 text_sm_normal">Lead interested</Typography>
          </Button>
        )}
        {interested === false && (
          <Button
            className={`flex items-center box-border p-2 rounded-xl border-solid border-[3px] border-primary-400 w-full flex-row justify-center gap-2`}
          >
            <Box className="flex items-center p-1 justify-center rounded-full bg-error-100 border-error-50 border-solid border-4">
              <CloseIcon fontSize="small" className="text-error-600" />
            </Box>
            <Typography className="text-grey-600 text_sm_normal">Lead not interested</Typography>
          </Button>
        )}
      </Box>
      {interested === undefined && (
        <Box className="flex flex-col gap-4">
          <Typography className="text_md_medium text-grey-800 mt-5">Is the lead interested?</Typography>
          <Box className="grid grid-cols-2 gap-5">
            <Button
              onClick={() => setInterested(true)}
              className={`p-[14px] border-[1px] border-solid border-grey-200 rounded-xl flex justify-start items-center gap-3`}
            >
              <Box className="flex items-center p-1.5 justify-center rounded-full bg-success-100 border-success-50 border-solid border-4">
                <Check fontSize="small" className="text-success-800" />
              </Box>
              <Typography className="text-grey-600">Yes</Typography>
            </Button>
            <Button
              onClick={() => setInterested(false)}
              className={`p-[14px] border-[1px] border-solid border-grey-200 rounded-xl flex justify-start items-center gap-3`}
            >
              <Box className="flex items-center p-1.5 justify-center rounded-full bg-error-100 border-error-50 border-solid border-4">
                <CloseIcon fontSize="small" className="text-error-600" />
              </Box>
              <Typography className="text-grey-600">No</Typography>
            </Button>
          </Box>
        </Box>
      )}
      <Collapse in={interested !== undefined}>
        {interested === true && (
          <InterestedFields
            leadId={leadId}
            interestedFields={interestedFields}
            setInterestedFields={setInterestedFields}
            handleClose={handleClose}
            form={form}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            createTaskAndActivity={createTaskAndActivity}
            nextId={nextId}
            stageId={stageId}
            relatedLeads={relatedLeads}
            subsidiaryId={subsidiaryId}
            stageAllowReassignment={stageAllowReassignment}
          />
        )}
        {interested === false && (
          <NotInterested
            nextId={nextId}
            leadId={leadId}
            handleClose={handleClose}
            form={form}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            createTaskAndActivity={createTaskAndActivity}
            stageId={stageId}
            subsidiaryId={subsidiaryId}
            relatedLeads={relatedLeads}
            stageAllowReassignment={stageAllowReassignment}
          />
        )}
      </Collapse>
    </>
  )
}

export default ReachedLead

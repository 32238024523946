import { Grid, Typography, IconButton, Button, Modal, Box, Divider, Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import { ExpandLess, PlaceOutlined } from '@mui/icons-material'
import { ExpandMoreIcon } from '../../../../assets/icons'
import { Edit2, FileText, Mail, User, X, Briefcase, Copy } from 'react-feather'
import { ContactGetContactByCompanyOutput } from '../../../../types/procedureOutputs'
import React, { useEffect, useState } from 'react'
import AddContactNote from './addContactNote'
import PhoneRow from '../../components/PhoneRow'
import ContactPhonePopper from '../../components/ContactPhonesPopper'
import CallFeedback, { CallFeedbackContact } from '../callFeedback'
import { trpc } from '../../../../core/trpc'
import toast from 'react-hot-toast'
import { ThumbsUp } from '../../../../icons/ThumbsUp'
import { isNil, pickAll } from 'ramda'
import CallSmsModal from '../CallSmsModal'
import { Lead } from '..'
import { useSearchParams } from 'react-router-dom'
import AddPhoneNote from '../../components/AddPhoneNote'
import { autoRequestInfo } from '../callFeedback/autoRequestInfo'
import { useEmailStore } from '../../../../stores/emailStore'

type ContactDetailsProps = {
  contact: ContactGetContactByCompanyOutput | undefined
  showExpanedIcon: boolean
  isPrimary: boolean
  onClose?: () => void
  refetch: () => void
  expanded: boolean | undefined
  setExpanded: React.Dispatch<React.SetStateAction<boolean>> | undefined
  setOpenContact: React.Dispatch<React.SetStateAction<ContactGetContactByCompanyOutput | undefined>>
  lead: Lead
  setNewEmailOpen: React.Dispatch<
    React.SetStateAction<
      | {
          isOpen: boolean
          requestInfo?: boolean | undefined
        }
      | undefined
    >
  >
  contactState: string | undefined
}
const ContactDetails = (props: ContactDetailsProps) => {
  const { mailboxEmailAddress } = useEmailStore()
  const { contact, showExpanedIcon, isPrimary, onClose, refetch, expanded, setExpanded, setOpenContact, lead, setNewEmailOpen } = props
  const [callModalType, setCallModalType] = useState<'SMS' | 'CALL'>()
  const [note, setNote] = useState('')
  const [openNote, setOpenNote] = useState(false)
  const [openPhoneNote, setOpenPhoneNote] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [callFeedbackType, setCallFeedbackType] = useState<'CALL' | 'SMS' | undefined>()
  const [feedbackContact, setFeedbackContact] = useState<CallFeedbackContact>()
  const [topThumbedPhoneIndex, setTopThumbedPhoneIndex] = useState<number>(0)
  const setSearchParams = useSearchParams()[1]
  const { data: otherCompany } = trpc.contact.getOtherCompanies.useQuery(
    { contactId: contact?.id || '', subsidiaryId: lead.company.subsidiary_id },
    { enabled: Boolean(contact) }
  )

  const { data: emailCount } = trpc.emails.getEmailReceivedCount.useQuery(
    { emailAddress: contact?.primary_email || '' },
    { enabled: Boolean(contact?.primary_email) }
  )

  useEffect(() => {
    if (contact?.notes) setNote(contact?.notes)
    const primaryIndex = contact?.phones.findIndex(p => p.is_primary_phone);
    let tempIndex = primaryIndex && primaryIndex !== -1 ? primaryIndex: 0
    contact?.phones.forEach((phone, index) => {
      if (phone.thumbs_up > contact?.phones[tempIndex].thumbs_up) tempIndex = index
    })
    setTopThumbedPhoneIndex(tempIndex)
  }, [contact])

  const handleCall = (contact: CallFeedbackContact | undefined) => {
    setAnchorEl(null)
    setCallFeedbackType('CALL')
    setFeedbackContact(contact)
  }

  const mutation = trpc.company.assignPrimaryContact.useMutation({
    onSuccess: () => {
      toast.success('Primary contact updated')
      if (onClose) onClose()
      refetch()
    },
    onError: (e) => {
      toast.error(`Error: ${e.message}`)
    }
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) return

    mutation.mutate({
      company_id: contact?.company_id || '',
      contact_id: contact?.id || ''
    })
  }

  return (
    <>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Typography data-cy="contactTitle" className="text-grey-900 font-semibold text-lg">
          {isPrimary ? 'Primary ' : ''}Contact
        </Typography>
        {(showExpanedIcon || onClose) && (
          <IconButton size="small" onClick={() => (onClose ? onClose() : setExpanded ? setExpanded(!expanded) : '')}>
            {onClose ? <X /> : expanded ? <ExpandMoreIcon /> : <ExpandLess className="rotate-90" />}
          </IconButton>
        )}
      </Grid>
      {expanded && (
        <>
          <Box className="flex flex-wrap gap-1 items-center">
            {Boolean(contact) && (
              <>
                <Grid onClick={() => setOpenContact(contact)} item className="cursor-pointer">
                  <Typography data-cy="contactName" className="text-grey-700 text-base font-normal">
                    <User className="h-4 w-4 align-middle text-grey-500 mr-4" /> {contact?.name || ''}
                  </Typography>
                </Grid>
                {otherCompany && (
                  <Tooltip
                    slotProps={{ tooltip: { className: 'bg-white shadow-sm px-3 py-1.5' } }}
                    title={<Typography className="text_xs_medium text-grey-700 mb-1.5">Contact Exists for Another Company</Typography>}
                  >
                    <Box className="rounded-full bg-secondary-50 p-1 w-fit h-fit flex items-center justify-center">
                      <Copy size="15" className="text-secondary-600" />
                    </Box>
                  </Tooltip>
                )}
                <Divider orientation="vertical" flexItem className="mx-2" />
              </>
            )}
            {contact?.primary_email && (
              <>
                <Grid item className="flex items-center gap-2">
                  <Typography data-cy="primaryEmail" className="text-grey-700 text-base font-normal">
                    <Mail className="h-4 w-4 align-middle mr-3 text-grey-500" /> {contact?.primary_email || ''}
                  </Typography>
                  {!isNil(emailCount) && (
                    <Box className="flex gap-1 items-center">
                      <ThumbsUp />
                      <Typography className="text_xs_medium text-grey-500">{emailCount}</Typography>
                    </Box>
                  )}
                </Grid>
                <Divider orientation="vertical" flexItem className="mx-2" />
              </>
            )}
            {Number(contact?.phones?.length) > 0 && (
              <Grid item className="flex flex-nowrap">
                <PhoneRow
                  dontShowNotes={true}
                  num={contact?.phones[topThumbedPhoneIndex]}
                  type="both"
                  handleCall={() =>
                    handleCall(
                      contact
                        ? {
                            contactId: contact.id,
                            name: contact.name,
                            birthday: contact.birthday ? new Date(contact.birthday as string) : null,
                            email: contact.primary_email || '',
                            phone: {
                              id: contact.phones[topThumbedPhoneIndex].id,
                              phoneNumber: contact.phones[topThumbedPhoneIndex].phone_number,
                              thumbsUp: contact.phones[topThumbedPhoneIndex].thumbs_up,
                              thumbsDown: contact.phones[topThumbedPhoneIndex].thumbs_down
                            },
                            other_phones: (contact.phones.filter((_, index) => index !== topThumbedPhoneIndex) || [])?.map((p) => pickAll(['id', 'thumbs_up', 'thumbs_down', 'phone_number'], p))
                          }
                        : undefined
                    )
                  }
                  name={undefined}
                />
                {contact?.phones?.length && contact?.phones?.length > 1 && (
                  <IconButton
                    className="h-7 w-7 border-solid border border-grey-300 rounded-full text-base text-grey-600"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    +{contact?.phones?.length - 1}
                  </IconButton>
                )}
                {Boolean(anchorEl) && (
                  <ContactPhonePopper
                    anchorEl={anchorEl}
                    setOpenPhoneNote={(x: string | null) => {
                      setOpenPhoneNote(x)
                      setAnchorEl(null)
                    }}
                    setAnchorEl={setAnchorEl}
                    data={contact?.phones || []}
                    name={contact?.name || ''}
                    handleCall={(phone) =>
                      handleCall(
                        contact
                          ? {
                              contactId: contact.id,
                              email: contact.primary_email || '',
                              name: contact.name,
                              birthday: contact.birthday ? new Date(contact.birthday as string) : null,
                              phone,
                              other_phones: contact.phones
                                ?.filter((p) => p.id !== phone.id)
                                ?.map((p) => pickAll(['id', 'thumbs_up', 'thumbs_down', 'phone_number'], p))
                            }
                          : undefined
                      )
                    }
                  />
                )}
              </Grid>
            )}
          </Box>
          <Box className="flex flex-wrap gap-1">
            {props.contactState && (
              <>
                <Grid item>
                  <Typography className="text-grey-700 text-base font-normal flex">
                    <PlaceOutlined className="align-middle mr-3 text-grey-500" />
                    {props.contactState}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem className="mx-2" />
              </>
            )}
            {contact?.role && (
              <>
                <Grid item>
                  <Typography data-cy="contactRole" className="text-grey-700 text-base font-normal">
                    <Briefcase className="h-4 w-4 align-middle mr-3 text-grey-500" /> {contact?.role || ''}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem className="mx-2" />
              </>
            )}
            {Boolean(contact) && (
              <Grid item>
                <Typography className="text-grey-700 text-base italic inline">
                  <FileText className="h-4 w-4 align-middle mr-3 text-grey-500" />
                  {note || ''}
                </Typography>
                <Button className="p-0 text-primary-500" variant="text" onClick={() => setOpenNote(true)}>
                  {note ? <Edit2 className="text-primary-500 align-middle p-0" size="14" /> : 'Add Note'}
                </Button>
              </Grid>
            )}
          </Box>

          {!isPrimary && Boolean(contact) && <FormControlLabel control={<Checkbox onChange={handleChange} />} label="Set as Primary Contact" />}

          <Modal open={Boolean(openNote)} onClose={() => setOpenNote(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                borderRadius: '8px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
              }}
            >
              <AddContactNote contact={contact} open={openNote} setOpen={setOpenNote} note={note} setNote={setNote} />
            </Box>
          </Modal>

          {callFeedbackType && (
            <CallFeedback
              handleClose={async (newAction) => {
                if (!newAction) return
                if (newAction.name === 'CALL' || newAction.name === 'SMS') {
                  setCallModalType(newAction.name)
                }
                if (newAction.name === 'EMAIL') {
                  setNewEmailOpen({ isOpen: true })
                }
                if (newAction.name === 'REQUEST_INFO') {
                  if (newAction.requestInfo === 'OTHER') {
                    setSearchParams({
                      contactName: feedbackContact?.name || '',
                      contactPhone: feedbackContact?.phone.phoneNumber || ''
                    })
                    setNewEmailOpen({ isOpen: true })
                  } else {
                    await autoRequestInfo(mailboxEmailAddress, newAction.requestInfo, {
                      contact: feedbackContact,
                      lead: lead,
                      company: lead.company
                    })
                  }
                }
              }}
              type={callFeedbackType}
              setType={setCallFeedbackType}
              feedbackContact={feedbackContact}
              setFeedbackContact={setFeedbackContact}
              stageId={lead.stage_id}
            />
          )}
        </>
      )}
      {Boolean(callModalType) && (
        <CallSmsModal
          type={callModalType}
          setType={setCallModalType}
          lead={lead}
          setCallFeedbackType={setCallFeedbackType}
          setFeedbackContact={setFeedbackContact}
        />
      )}
      <Modal open={Boolean(openPhoneNote)} onClose={() => setOpenPhoneNote(null)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            borderRadius: '8px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <AddPhoneNote
            open={openPhoneNote !== null}
            setOpen={setOpenPhoneNote}
            phoneId={openPhoneNote}
            note={contact?.phones.find((item) => item.id === openPhoneNote)?.notes ?? ''}
            contact={contact}
            refetch={refetch}
          />
        </Box>
      </Modal>
    </>
  )
}

export default ContactDetails

import { Tooltip, Typography, IconButton, Box } from '@mui/material'
import { CloseIcon } from '../../assets/icons'
import File from '../../icons/File'

const FileChip = ({ fileName, handleDelete }: { fileName: string; handleDelete: () => void }) => {
  return (
    <Tooltip title={fileName} key={fileName}>
      <Box
        key={fileName}
        className="px-2 py-1 w-[250px] truncate flex justify-between items-center border-solid border-[1px] border-grey-200 rounded-full"
      >
        <Box className="flex gap-2.5 items-center">
          <File fontSize="small" sx={{ color: '#697586' }} />
          <Typography className="truncate max-w-[175px]">{fileName}</Typography>
        </Box>
        <IconButton className="p-1" onClick={handleDelete}>
          <CloseIcon className="text-sm" />
        </IconButton>
      </Box>
    </Tooltip>
  )
}

export default FileChip

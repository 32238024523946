import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'
import type { Theme } from '@mui/material'
import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import DashboardSidebar from './DashboardSidebar/DashboardSidebar'
import TopBar from './TopBar'
import { signIn, useSession } from 'next-auth/react'
import ContentLayout from './ContentLayout'
import { trpc } from '../../core/trpc'
import { getUnreadEmailsCount, graphAxios } from '../../services/azureServices'
import { useEmailStore } from '../../stores/emailStore'
import LeadDetailsModal from '../../components/LeadDetailsModal'

const Root = styled(Box)(() => ({
  display: 'flex',
  overflow: 'hidden',
  width: '100%'
}))

interface DashboardLayoutProps {
  children?: ReactNode
}

function DashboardLayout(_props: DashboardLayoutProps) {
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false)
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const session = useSession()
  const { setUnreadEmailCount, mailboxEmailAddress, setMailboxEmailAddress, setHasGraphHeader } = useEmailStore()

  const { data: token, refetch } = trpc.auth.getSessionToken.useQuery()
  const refreshTokenMutation = trpc.auth.refreshProviderToken.useMutation()

  useEffect(() => {
    if (token) {
      if (token.expired || !token.access_token) {
        setHasGraphHeader(false)
        refreshTokenMutation.mutateAsync().then((res) => {
          if (res.accessToken) {
            graphAxios.defaults.headers.common['Authorization'] = res.accessToken
            setHasGraphHeader(true)
            refetch()
          }
        })
      } else {
        graphAxios.defaults.headers.common['Authorization'] = token.access_token
        graphAxios.interceptors.response.use(
          (response) => {
            return response
          },
          (error) => {
            if (error.response && error.response.status === 401) {
              setHasGraphHeader(false)
              refreshTokenMutation.mutateAsync().then((res) => {
                if (res.accessToken) {
                  graphAxios.defaults.headers.common['Authorization'] = res.accessToken
                  setHasGraphHeader(true)
                  refetch()
                } else {
                  signIn('azure-ad')
                }
              })
            }
            return error
          }
        )
        setHasGraphHeader(true)
      }
    }
  }, [token, token?.access_token, token?.expired])

  useEffect(() => {
    if (session?.data?.user.email) {
      setMailboxEmailAddress(mailboxEmailAddress || (session?.data?.user.email as string))
    }
  }, [session?.data?.user.email])

  useEffect(() => {
    if (mailboxEmailAddress && token?.access_token) {
      const setEmailCount = async () => {
        const count = await getUnreadEmailsCount({
          search: '',
          mailFolder: 'Inbox',
          mailboxEmailAddress: mailboxEmailAddress
        })
        setUnreadEmailCount(count)
      }
      setEmailCount()
    }
  }, [mailboxEmailAddress, token?.access_token])

  useEffect(() => {
    if (token?.expires_at) {
      const refreshTokenTimeout = setTimeout(() => {
        refreshTokenMutation.mutateAsync().then((res) => {
          if (res.accessToken) {
            graphAxios.defaults.headers.common['Authorization'] = res.accessToken
            setHasGraphHeader(true)
            refetch() // refetch so the set-timeout is called again
          }
        })
      },  new Date(token.expires_at).getTime() - new Date().getTime() - 600000) // refresh token 10 min before it expires 
      return () => {
        clearTimeout(refreshTokenTimeout);
      }
    }
  }, [token?.expires_at])

  return (
    <Root className="flex flex-col flex-grow min-h-screen">
      <LeadDetailsModal />
      <DashboardSidebar onClose={() => setMobileNavOpen(false)} open={isMobileNavOpen} />
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingLeft: lgUp ? '256px' : 0
        }}
      >
        <Box className="flex-grow flex-col flex" overflow="hidden">
          <Box className="flex flex-grow flex-col" overflow="hidden">
            <TopBar toggleMobileNav={() => setMobileNavOpen(!isMobileNavOpen)} />
            <Box className="pt-16">
              <ContentLayout>
                <Outlet />
              </ContentLayout>
            </Box>
          </Box>
        </Box>
      </Box>
    </Root>
  )
}

export default DashboardLayout

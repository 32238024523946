import { Grid } from '@mui/material'
import ReassignmentCard from './ReassignmentCard'
import StageCard from './StageCard'
import Contacts from './contacts'
import Offers from './offers'
import LeadActivity from './activity'
import LeadDetailsCard from './leadDetails'
import Tasks from './tasks'
import { trpc } from '../../../core/trpc'
import { useState } from 'react'
import clsx from 'clsx'
import { Lead } from '.'

type LeadDetailsContentProps = {
  leadId: string | undefined
  setNewEmailOpen: React.Dispatch<
    React.SetStateAction<
      | {
          isOpen: boolean
          requestInfo?: boolean | undefined
        }
      | undefined
    >
  >
  isNewEmailOpen:
    | {
        isOpen: boolean
        requestInfo?: boolean | undefined
      }
    | undefined
  isModal: boolean
  lead: Lead
  refetchLead: () => void
}

const LeadDetailsContent = ({ leadId, isNewEmailOpen, setNewEmailOpen, isModal, lead, refetchLead }: LeadDetailsContentProps) => {
  const [showReassignmentMsg, setShowReassignmentMsg] = useState(true)
  const { data: contacts, refetch } = trpc.contact.getContactsByCompanyId.useQuery({ companyId: lead?.company_id || '' })
  const { data: columnValues, refetch: refetchColValues } = trpc.lead.getLeadRequiredColumnValues.useQuery({ leadId: lead.id })

  return (
    <Grid
      container
      spacing={2}
      className={`bg-grey-25 sm_laptop:mt-0 ${clsx({ 'mt-0 px-6': isModal, 'mt-24 p-6 pr-16': !isModal })}`}
      sx={{ borderTop: isModal ? 'none' : '1px solid #E3E8EF' }}
    >
      <Grid xs={12} item>
        {lead.daysUntilRiskOfReassignment !== null && Number(lead.daysUntilRiskOfReassignment) <= 10 && showReassignmentMsg && (
          <ReassignmentCard daysUntilRiskOfReassignment={lead.daysUntilRiskOfReassignment} setShowMsg={setShowReassignmentMsg} />
        )}
      </Grid>
      <Grid item container spacing={2}>
        <Grid item container md={8} xs={12}>
          <Grid item xs={12} className="space-y-4">
            <StageCard lead={lead} primaryContact={contacts?.find((c) => !!c.primary_contact_for) || contacts?.[0]} columnValues={columnValues} />
            <Contacts company_id={lead.company_id} lead={lead} setNewEmailOpen={setNewEmailOpen} data={contacts} refetch={refetch} />
            <Offers total_offer_count={lead._count.offers || 0} leadId={leadId} pipelineId={lead.stage.pipeline_id} stageId={lead.stage.id} />
            {leadId && (
              <LeadActivity
                isNewEmailOpen={isNewEmailOpen}
                setNewEmailOpen={setNewEmailOpen}
                leadId={leadId}
                refetchRequiredColValues={refetchColValues}
              />
            )}
          </Grid>
        </Grid>
        <Grid item container md={4} xs={12}>
          <Grid item xs={12} className="space-y-4">
            <Tasks />
            <LeadDetailsCard lead={lead} refetchLead={refetchLead} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LeadDetailsContent

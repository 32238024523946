import { Menu, MenuItem } from '@mui/material'
import { addHours, addWeeks, nextMonday, set, startOfTomorrow } from 'date-fns'

type Props = {
  anchorEl: HTMLElement | undefined
  onChange: (date: Date) => void
  onClose: () => void
}

const AddFollowUpDateSuggestions = ({ anchorEl, onChange, onClose }: Props) => {
  const options = [
    { label: 'One hour from now', value: addHours(new Date(), 1) },
    { label: 'Tomorrow at 10am', value: set(startOfTomorrow(), { hours: 10, minutes: 0 }) },
    { label: 'Next Monday at 10am', value: set(nextMonday(new Date()), { hours: 10, minutes: 0 }) },
    { label: 'In one week', value: addWeeks(new Date(), 1) }
  ]
  return (
    <Menu onClose={onClose} open={Boolean(anchorEl)} anchorEl={anchorEl}>
      {options.map(({ label, value }) => (
        <MenuItem key={label} onClick={() => onChange(value)}>
          {label}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default AddFollowUpDateSuggestions

import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import AddressInputs from '../../../../components/email/AddressInputs'
import { QuillEditor } from '../../../../components/QuillEditor'
import Quill from 'react-quill'
import { useEffect, useRef, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { EmailById } from '../../../../types/procedureOutputs'
import { deleteMsAttachment, forwardMsEmail, replyMsEmail, uploadEmailContentToAzure, uploadFileToAzure } from '../../../../services/azureServices'
import toast from 'react-hot-toast'
import { trpc } from '../../../../core/trpc'
import { ActivityType } from '@prisma/client'
import { FileAttachment } from '../../../../components/email/ComposeEmail'
import ReactDropzone from 'react-dropzone'
import { AttachFileIcon } from '../../../../assets/icons'
import { getBase64FromFile } from '../../../../utils/readFile'
import { map, omit } from 'remeda'
import FileChip from '../../../../components/email/FileChip'
import { MicrosoftAttachment } from '@libs/azure-graph'
import { associateMessageReplyAndForward } from '../../../../utils/email'
import { v4 as uuidv4 } from 'uuid'

type PartialFile = Pick<File, 'name' | 'size' | 'type'>

type EmailDrawerReplyProps = {
  sendType: 'reply' | 'replyAll' | 'forward' | undefined
  setSendType: React.Dispatch<React.SetStateAction<'reply' | 'replyAll' | 'forward' | undefined>>
  leadId?: string
  email: EmailById | undefined
  emailBody: string
  setEmailId: React.Dispatch<React.SetStateAction<string | undefined>>
  refetch?: ({ concat, activityType }: { concat: boolean; activityType: string | undefined }) => void
  currentUserEmailId: string
  originalAttachments: MicrosoftAttachment[] | undefined
  mailboxEmailAddress: string
}

const EmailDrawerReply = ({
  sendType,
  setSendType,
  leadId,
  email,
  emailBody,
  setEmailId,
  refetch,
  currentUserEmailId,
  originalAttachments,
  mailboxEmailAddress
}: EmailDrawerReplyProps) => {
  const inputRef = useRef<Quill>(null)
  const [messageVal, setMessageVal] = useState('')
  const [updatedAttachments, setUpdatedAttachments] = useState<{ file: PartialFile; contentBytes: string }[]>([])
  const [deletedOriginalAttachments, setDeletedOriginalAttachments] = useState<string[]>()
  const defaultRecipients = sendType === 'forward' ? undefined : ([email?.from_address] as MicrosoftPerson[])
  const [toRecipients, setToRecipients] = useState<MicrosoftEmail['toRecipients'] | undefined>(defaultRecipients)
  const [ccRecipients, setCcRecipients] = useState<MicrosoftEmail['ccRecipients'] | undefined>(
    email?.cc_recipients.length ? (email?.cc_recipients as MicrosoftPerson[]) : undefined
  )
  const [bccRecipients, setBccRecipients] = useState<MicrosoftEmail['bccRecipients'] | undefined>(
    email?.bcc_recipients.length ? (email?.bcc_recipients as MicrosoftPerson[]) : undefined
  )
  const [emailBodyUpdated, setEmailBodyUpdated] = useState(emailBody || '')
  const [isLoading, setLoading] = useState(false)
  const getLinks = trpc.file.createUploadFileLink.useMutation()

  const MAX_SIZE = 3000000 // based on https://learn.microsoft.com/en-us/graph/api/resources/attachment?view=graph-rest-1.0

  const associatedLeadMutation = trpc.activity.saveEmailToLeadActivity.useMutation({
    onSuccess: () => refetch?.({ concat: true, activityType: ActivityType.EMAIL }),
    onError: (e) => toast.error(e.message)
  })

  useEffect(() => {
    if (inputRef.current && sendType !== 'forward') inputRef.current.focus()
  }, [sendType])

  const handleDropAccepted = (files: File[]) => {
    files.forEach(async (file) => {
      const { data } = await getBase64FromFile(file)
      if (data) {
        setUpdatedAttachments((attachments) => [...attachments, { file, contentBytes: (data as string).split(',')[1] }])
      }
    })
  }

  const getFilePath = ({ leadId }: { leadId: string }) => `Lead/${leadId}/${new Date().getTime()}-${uuidv4()}`

  const uploadAttachments = async ({ attachments }: { attachments: (FileAttachment & { url: string })[] | undefined }) => {
    if (!attachments) return undefined

    const { containerUrl, sasQuery } = (await getLinks.mutateAsync()) || {}

    const fileUploadResponse = attachments.map((attachment) => {
      return uploadFileToAzure({
        containerUrl,
        sasQuery,
        filePath: attachment.url,
        contents: `data:${attachment.contentType};base64,${attachment.contentBytes}`,
        size: attachment.size
      }).catch((e) => toast.error(e))
    })

    const resolvedPromises = await Promise.all(fileUploadResponse)
    return resolvedPromises.every((promise) => !!promise)
  }

  const uploadBodyContentToAzure = async (email: MicrosoftEmail): Promise<string> => {
    const filePath = `Emails/MSFT-${email.id}`
    const { containerUrl, sasQuery } = (await getLinks.mutateAsync()) || {}

    await uploadEmailContentToAzure({
      contents: email.body.content,
      size: email.body.content.length,
      filePath: filePath,
      containerUrl,
      sasQuery
    })
    return filePath
  }

  const mapToFileAttachments = (updateAttchments: { file: PartialFile; contentBytes: string }[]) => {
    return updateAttchments?.map((attach) => ({
      contentType: attach.file.type,
      name: attach.file.name,
      size: attach.file.size,
      contentBytes: attach.contentBytes,
      url: getFilePath({ leadId: String(leadId) })
    }))
  }

  const associateLead = async (email: MicrosoftEmail) => {
    const attachments = mapToFileAttachments(updatedAttachments)
    const azureUploadRes = await uploadAttachments({ attachments })
    const files =
      attachments?.length && azureUploadRes
        ? attachments.map(({ contentType, name, url, size }) => ({
            type: contentType,
            name,
            lead_id: String(leadId),
            url,
            size
          }))
        : undefined
    const filePath = await uploadBodyContentToAzure(email)
    associatedLeadMutation.mutateAsync({
      lead_id: leadId ?? '',
      email: {
        microsoft_id: email.id,
        subject: email.subject || '',
        created_date_time: email.createdDateTime,
        body_preview: email.bodyPreview,
        parent_folder_id: email.parentFolderId,
        conversation_id: email.conversationId,
        body_content_url: filePath,
        from_address: email.from,
        to_addresses: email.toRecipients,
        cc_recipients: email.ccRecipients,
        bcc_recipients: email.bccRecipients,
        internet_message_id: email.internetMessageId,
        files
      }
    })
  }

  const handleSend = async () => {
    if (!email) return null
    setLoading(true)
    const pubId = `${new Date()} ${email?.subject}`
    const now = new Date()
    if (deletedOriginalAttachments?.length) {
      const promises = deletedOriginalAttachments.map((attachment) =>
        deleteMsAttachment({ emailId: currentUserEmailId, id: attachment, mailboxEmailAddress })
      )
      await Promise.all(promises)
    }
    const response =
      sendType === 'forward'
        ? await forwardMsEmail({
            mailboxEmailAddress,
            emailId: currentUserEmailId || '',
            toRecipients: toRecipients || [],
            body: emailBodyUpdated,
            attachments: map(mapToFileAttachments(updatedAttachments), (attach) => omit(attach, ['url']))
          })
        : await replyMsEmail({
            mailboxEmailAddress,
            emailId: currentUserEmailId || '',
            comment: messageVal,
            replyAll: sendType === 'replyAll',
            attachments: map(mapToFileAttachments(updatedAttachments), (attach) => omit(attach, ['url'])),
            message: {
              toRecipients,
              ccRecipients,
              bccRecipients,
              internetMessageHeaders: [
                {
                  name: 'x-pub-id',
                  value: pubId
                }
              ]
            }
          })
    setLoading(false)
    setEmailId(undefined)
    if (response?.status === 202) {
      toast.success('Message successfully sent')
      if (leadId) {
        if (sendType === 'forward') {
          associateMessageReplyAndForward({ now, pubId, associateLead, sendType: 'forward', mailboxEmailAddress })
        } else {
          associateMessageReplyAndForward({ now, pubId, associateLead, sendType: 'reply', mailboxEmailAddress })
        }
      }
    } else {
      const err = response as {statusText?: string,  response?: { data?: { error?: { message?: string, code?: string }}} }
      toast.error(`Error sending message: ${err?.statusText || ''} ${err?.response?.data?.error?.code || ''} ${err?.response?.data?.error?.message || ''}`)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        pt: 3,
        flexWrap: 'wrap',
        gap: 2
      }}
    >
      <Paper
        sx={{
          flexGrow: 1,
          p: 2
        }}
        variant="outlined"
      >
        <AddressInputs
          onlyToRecipients={sendType === 'forward'}
          bccRecipients={bccRecipients}
          setBccRecipients={setBccRecipients}
          ccRecipients={ccRecipients}
          setCcRecipients={setCcRecipients}
          toRecipients={toRecipients}
          setToRecipients={setToRecipients}
          toAutoFocus
        />
        <Divider className="my-3" />
        <QuillEditor
          ref={inputRef}
          placeholder={sendType !== 'forward' ? 'Leave a message' : ''}
          value={sendType !== 'forward' ? messageVal : emailBodyUpdated}
          onChange={(value) => {
            if (sendType !== 'forward') {
              setMessageVal(value)
            } else {
              setEmailBodyUpdated(value)
            }
          }}
        />
        <ReactDropzone
          multiple
          maxSize={MAX_SIZE}
          onDropAccepted={handleDropAccepted}
          onDropRejected={(rejections) => {
            const rejectionMessages = rejections[0].errors.map((err) => err.message).join('\n')
            toast.error(rejectionMessages)
          }}
        >
          {({ getRootProps }) => (
            <Button {...getRootProps()} variant="outlined" className="w-fit">
              <AttachFileIcon className="text-[20px]" />
              <Typography className="text-grey-800 ml-1">Attach File</Typography>
            </Button>
          )}
        </ReactDropzone>
        {Boolean(updatedAttachments.length || originalAttachments) && (
          <Box className="flex gap-2 flex-wrap mt-3">
            {originalAttachments
              ?.filter((attach) => !deletedOriginalAttachments?.includes(attach.id))
              .map((attachment) => (
                <FileChip
                  key={attachment.id}
                  fileName={attachment.name}
                  handleDelete={() => setDeletedOriginalAttachments([...(deletedOriginalAttachments || []), attachment.id])}
                />
              ))}
            {updatedAttachments.map((attachment, index) => (
              <FileChip
                key={index}
                fileName={attachment.file.name}
                handleDelete={() => setUpdatedAttachments((attachments) => attachments.filter((_, i) => i !== index))}
              />
            ))}
          </Box>
        )}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            mt: 2,
            gap: '8px'
          }}
        >
          <Button onClick={() => setSendType(undefined)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            disabled={sendType === 'forward' && !toRecipients?.length}
            loading={isLoading}
            onClick={handleSend}
            sx={{ mr: 1 }}
            variant="contained"
          >
            Send
          </LoadingButton>
        </Box>
      </Paper>
    </Box>
  )
}

export default EmailDrawerReply

import { Box, Pagination } from '@mui/material'

type StyledPaginationProps = {
  take?: number
  skip: number
  dataCount: number | undefined
  boundryCount?: number
  onChange: (e: React.ChangeEvent<unknown>, page: number) => void
}

const StyledPagination = ({ take = 10, dataCount, skip, onChange, boundryCount = 2 }: StyledPaginationProps) => {
  return (
    <Box className="flex justify-center mt-4">
      <Pagination
        count={Math.ceil((dataCount || 0) / take)}
        boundaryCount={boundryCount}
        variant="outlined"
        shape="rounded"
        onChange={(e, page) => onChange(e, page)}
        page={Math.ceil(skip / take) + 1}
      />
    </Box>
  )
}

export default StyledPagination

import { Box } from '@mui/system'
import { useState } from 'react'
import SettingsButtonGroup from '../components/SettingsButtonGroup'
import { Button, Typography } from '@mui/material'
import { AddCircleOutlineOutlinedIcon } from '../../../assets/icons'
import EditRuleModal from './EditRuleModal'
import { InformationForValidationOutput } from '../../../types/procedureOutputs'

type props = {
  refetch: () => void
  information: InformationForValidationOutput | undefined
}

const Header = ({ refetch, information }: props) => {
  const [openAddModal, setOpenAddModal] = useState(false)
  return (
    <Box className="flex justify-between items-center mobile:flex-col mobile:gap-6">
      <SettingsButtonGroup defaultVal="validation" />
      <Box className="flex gap-4">
        <Button variant="contained" onClick={() => setOpenAddModal(true)}>
          <AddCircleOutlineOutlinedIcon fontSize="small" />
          <Typography className="text_md_medium ml-2.5">New Rule</Typography>
        </Button>
      </Box>
      {openAddModal && (
        <EditRuleModal
          closeModal={() => {
            setOpenAddModal(false)
            refetch()
          }}
          fieldNames={information?.requiredFields ?? []}
          stages={information?.stageNames ?? []}
        />
      )}
    </Box>
  )
}
export default Header

import { Link as RouterLink } from 'react-router-dom'
import { AppBar, Toolbar, Link, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import Logo from '../../../components/Logo'

type TopbarProps = {
  sx?: SxProps<Theme>
}

function Topbar(props: TopbarProps) {
  const { ...rest } = props

  return (
    <AppBar {...rest}>
      <Toolbar>
        <Link component={RouterLink} to="/" underline="none">
          <Logo sx={{ borderRadius: 10 }} />
        </Link>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar

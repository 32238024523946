import { Box, Dialog, FormControlLabel, IconButton, Link, Stack, Tooltip, Typography, Checkbox } from '@mui/material'
import { CloseIcon, StarRounded } from '../../../assets/icons'
import { Briefcase, Edit2, FileText, Mail, Phone, Trash, User } from 'react-feather'
import { formatPhoneOnInput } from '../../../utils'
import { Funder } from '.'
import { Can } from '../../../contexts/AbilityContext'
import { useState } from 'react'
import EditContactModal from './EditContactModal'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import { useConfirm } from 'material-ui-confirm'
import { AutoMode } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { SendFunderOnBoardingEmail } from '../../../utils/sendFunderOnBoardingEmailUtils'
import { useSession } from 'next-auth/react'

type ContactModalProps = {
  contactId: string | null
  setContactDetailsId: React.Dispatch<React.SetStateAction<string | null>>
  funder: Funder
  refetch: () => void
}

const ContactModal = ({ contactId, setContactDetailsId, funder, refetch }: ContactModalProps) => {
  const contact = funder?.contacts.find((contact) => contact.id === contactId)
  const confirm = useConfirm()
  const [loading, setLoading] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const deleteMutation = trpc.funderContact.deleteFunderContact.useMutation({
    onError: (e) => toast.error('error deleting contact ' + String(e)),
    onSuccess: () => {
      toast.success('contact deleted successfully')
      refetch()
      setContactDetailsId(null)
    }
  })

  const primaryContactMutation = trpc.funder.assignPrimaryContact.useMutation({
    onSuccess: () => {
      toast.success('Primary contact updated')
      refetch()
      setContactDetailsId(null)
    },
    onError: (e) => {
      toast.error(`Error: ${e.message}`)
    }
  })


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) return

    primaryContactMutation.mutate({
      funder_id: funder?.id || '',
      funder_contact_id: contact?.id || ''
    })
  }
  const { data } = useSession()
  const funderTokenMutation = trpc.funder.saveTokenToFunder.useMutation()

  const handleSend = async () => {
    setLoading(true)
    const tokenRes = await funderTokenMutation.mutateAsync({ id: contact?.funder_id || '', name: funder?.name ?? '' })

    await SendFunderOnBoardingEmail({
      funder_id: contact?.funder_id ?? '', current_user_email: data?.user.email, current_user_name: data?.user.name, token: tokenRes.token ?? '',
      contact: { name: contact?.name ?? '', email: contact?.email ?? '' }, funder_name: funder?.name ?? ''
    })
    setLoading(false)
    toast.success('Successfully sent the questionnaire email')
  }


  const isPrimary = funder?.primary_contact_id === contact?.id

  if (!contact) return <Typography>No Contact found</Typography>



  return (
    <Dialog open={Boolean(contactId)}>
      <Box className="p-5 w-96">
        <Box className="flex justify-between items-start mb-5">
          <Box>
            <Typography className="text-grey-900 text_lg_semibold">Contact</Typography>
            {isPrimary && (
              <Box className="border-solid border-grey-300 border-[1px] rounded-3xl flex items-center gap-2 py-0.5 px-2">
                <StarRounded fontSize="small" className="fill-warning-300" />
                <Typography className="text_xs_medium text-grey-600 tracking-widest">PRIMARY</Typography>
              </Box>
            )}
          </Box>
          <Can I="edit" a="Funder">
            <Box className="flex justify-end w-full gap-2 items-center">
              <IconButton className="rounded-full " onClick={() => setEditModalOpen(true)}>
                <Edit2 className="text-primary-500 w-[18px] h-[18px]" />
              </IconButton>
              <Can I="full" a="Funder">
                <IconButton
                  onClick={() =>
                    confirm({ title: 'Are you sure you want to delete the contact?' }).then(() => deleteMutation.mutate({ id: contactId || '' }))
                  }
                >
                  <Trash className="w-[18px] text-grey-500" />
                </IconButton>
              </Can>
            </Box>
          </Can>
          <IconButton onClick={() => setContactDetailsId(null)}>
            <CloseIcon fontSize="small" className="text-grey-500" />
          </IconButton>
        </Box>
        <Stack gap="16px">
          <Box className="flex gap-3 items-center">
            <User className="w-4 h-4 text-grey-500" />
            <Typography className="text-grey-700">{contact.name}</Typography>
          </Box>
          <Box className="flex gap-3 items-center">
            <Mail className="w-4 h-4 text-grey-500" />
            <Link href={`mailto:${contact.email}`} className="text-grey-700 text_md_normal hover:text-primary-600 cursor-pointer">
              {contact.email}
            </Link>
          </Box>
          <Box className="flex gap-3 items-center">
            <Phone className="w-4 h-4 text-grey-500" />
            <Typography className="text-grey-700">{formatPhoneOnInput(contact.phone || '')}</Typography>
          </Box>
          <Box className="flex gap-3 items-center">
            <Briefcase className="w-4 h-4 text-grey-500" />
            <Typography className="text-grey-700">{contact.title}</Typography>
          </Box>
          <Box className="flex gap-3 items-center justify-between">
            <Box className="flex gap-3 items-center ">
              <FileText className="w-4 h-4 text-grey-500" />
              <Tooltip title={contact.notes}>
                <Typography className="text-grey-700 truncate w-64">{contact.notes}</Typography>
              </Tooltip>
            </Box>
          </Box>
          <Box className="flex gap-3 items-center">
            <AutoMode className="w-4 h-4 text-grey-500" />
            <Typography className="text-grey-700">{contact.auto_submission ? 'Auto submits' : 'Does not auto submit'}</Typography>
          </Box>

          {!isPrimary && Boolean(contact) && <FormControlLabel control={<Checkbox onChange={handleChange} />} label="Set as Primary Contact" />}
          <Box className='flex justify-end'>
            <Tooltip title={funder?.questionnaire_filled_out_on ? 'Funder already submitted a questionniare': ''}>
              <LoadingButton disabled={Boolean(funder?.questionnaire_filled_out_on)} loading={loading} variant='contained' onClick={() => handleSend()}>Send Questionnaire</LoadingButton>
            </Tooltip>
          </Box>
        </Stack>
      </Box>
      {editModalOpen && (
        <EditContactModal
          closeModal={() => setEditModalOpen(false)}
          initialValues={{
            email: contact?.email ?? '',
            name: contact?.name ?? '',
            phone: contact?.phone ?? '',
            notes: contact?.notes ?? '',
            title: contact?.title ?? '',
            id: contact?.id ?? '',
            funder_id: contact?.funder_id,
            autoSubmit: contact?.auto_submission
          }}
          refetch={refetch}
        />
      )}
    </Dialog>
  )
}

export default ContactModal

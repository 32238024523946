import { useSession } from 'next-auth/react';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface GuestGuardProps {
  children: ReactNode;
}

function GuestGuard({ children }: GuestGuardProps) {
  const { status } = useSession();

  if (status === 'authenticated') {
    return <Navigate to="/" />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default GuestGuard;

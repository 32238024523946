import { Typography, Divider, TextField, Box, Button, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useSession } from 'next-auth/react'
import AddressInputs from './AddressInputs'
import AssociateLead from './AssociateLead'
import { useParams } from 'react-router'
import ReactDropzone from 'react-dropzone'
import toast from 'react-hot-toast'
import { AttachFileIcon, CloseIcon } from '../../assets/icons'
import File from '../../icons/File'
import { getBase64FromFile } from '../../utils'
import { trpc } from '../../core/trpc'
import { useSearchParams } from 'react-router-dom'
import { LeadByIdOutput } from '../../types/procedureOutputs'
import { format } from 'date-fns'
import EmailTemplateButton from './emailTemplate'
import { QuillEditor } from '../QuillEditor'
import { useConfirm } from 'material-ui-confirm'
import { useEmailStore } from '../../stores/emailStore'
import { sum } from 'ramda'

export type FileAttachment = {
  contentBytes: string // base-64 encoded
  name: string
  size: number
  contentType: string //mime-type
}

export type EmailPayload = {
  from: MicrosoftPerson
  to: MicrosoftPerson[]
  cc: MicrosoftPerson[] | undefined
  bcc: MicrosoftPerson[] | undefined
  subject: string
  body: string
  bodyType?: 'text' | 'html'
  attachments?: FileAttachment[]
  leadId?: string
}

type ComposeEmailProps = {
  handleSend?: (props: EmailPayload & { leadId?: string }) => void
  setClearEmailState?: React.Dispatch<React.SetStateAction<boolean>>
  loading?: boolean
  clearEmailState: boolean
  open: boolean
  leadIdPassedIn?: string
  contactPassedIn?: string
  phonePassedIn?: string
  pipelineIdPassedIn?: string
  defaultData?: {
    subject: string
    to: string[]
    body: string
  }
}

const ComposeEmail = ({
  handleSend,
  loading,
  setClearEmailState,
  clearEmailState,
  open,
  leadIdPassedIn,
  contactPassedIn,
  phonePassedIn,
  pipelineIdPassedIn,
  defaultData
}: ComposeEmailProps) => {
  const { leadId: leadIdQueryParam, pipelineId } = useParams()
  const { mailboxEmailAddress } = useEmailStore()
  const [searchParams] = useSearchParams()
  const { data } = useSession()
  const from = mailboxEmailAddress
    ? { emailAddress: { address: mailboxEmailAddress || '', name: '' } }
    : { emailAddress: { address: data?.user.email || '', name: data?.user.name || '' } }
  const [toRecipients, setToRecipients] = useState<MicrosoftPerson[] | undefined>()
  const [ccRecipients, setCcRecipients] = useState<MicrosoftPerson[]>()
  const [bccRecipients, setBccRecipients] = useState<MicrosoftPerson[]>()
  const [emailSubject, setEmailSubject] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [leadId, setLeadId] = useState(leadIdPassedIn || leadIdQueryParam || '')
  const [attachments, setAttachments] = useState<{ file: File; contentBytes: string }[]>([])
  const [lead, setLead] = useState<LeadByIdOutput>()
  const [contactLead, setContactLead] = useState<string | null>(null)
  const isEmailTooLarge = useMemo(() => {
    // 35 mb is the limit, but we are allowing 1mb for the email itself
    return !attachments ? false : sum(attachments?.map((a) => a.file.size || 0)) / 1000000 > 34
  }, [attachments])

  const t = trpc.useContext()
  const MAX_SIZE = Infinity // based on https://learn.microsoft.com/en-us/graph/api/resources/attachment?view=graph-rest-1.0

  const handleDropAccepted = (files: File[]) => {
    files.forEach(async (file) => {
      const { data } = await getBase64FromFile(file)
      if (data) {
        setAttachments((attachments) => [...attachments, { file, contentBytes: (data as string).split(',')[1] }])
      }
    })
  }

  useEffect(() => {
    if (clearEmailState) {
      setToRecipients([])
      setCcRecipients(undefined)
      setBccRecipients(undefined)
      setEmailSubject('')
      setEmailBody('')
      setAttachments([])
      setClearEmailState && setClearEmailState(false)
    }
  }, [clearEmailState])

  useEffect(() => {
    if (defaultData) {
      const defaultTo = defaultData?.to ? defaultData.to.map((address) => ({ emailAddress: { address, name: '' } })) : undefined
      setToRecipients(defaultTo)
      setEmailSubject(defaultData.subject)
      setEmailBody(defaultData.body)
    }
  }, [defaultData])

  useEffect(() => {
    ;(async () => {
      if (leadIdQueryParam || leadIdPassedIn) {
        const leadIdToUse = leadIdQueryParam ?? leadIdPassedIn
        const res = await t.lead.getLeadById.fetch({ id: leadIdToUse || '' })
        const searchParamsContact = searchParams.get('contactName')
        const searchParamsPhone = searchParams.get('contactPhone')
        const contactToUse = contactPassedIn ?? searchParamsContact
        const phoneToUse = phonePassedIn ?? searchParamsPhone
        if (contactToUse) {
          setEmailSubject(`Request TLO with ${res.company.name} - ${res.name}`)
          setEmailBody(
            `Requesting TLO on this lead ${contactToUse} <a target="_blank" href='https://${window.location.hostname}/pipeline/${
              pipelineIdPassedIn ?? pipelineId
            }/lead/${leadId}'> ${res.company.name} - ${res.name}.</a> <br /> ${phoneToUse} was the wrong number.`
          )
          setToRecipients([{ emailAddress: { name: 'Natali', address: 'natalia@sunrisefundinggrp.com' } }])
        } else if (res.company.primary_contact?.primary_email) {
          setEmailBody(`Hi ${res.company.primary_contact.name.split(/\s/)[0]}, `)
          setToRecipients([{ emailAddress: { name: res.company.primary_contact?.name, address: res.company.primary_contact?.primary_email } }])
        }
        setLead(res)
      }
    })()
  }, [leadIdQueryParam, searchParams, leadIdPassedIn, contactPassedIn])

  const confirm = useConfirm()

  const variableMapping: Record<string, string> = {
    CurrentDate: `${new Date().toDateString()}`,
    LeadCreateDate: format(new Date(lead?.created_at ?? '03/27/2023'), 'MM/dd/yyyy'),
    LeadName: `${lead?.company.name}-${lead?.name || ''}`,
    BrokerOnLead: lead?.company.companyToUser[0]?.assigned_to?.name ?? '',
    TeamLeadOnLead: lead?.company.companyToUser[0]?.assigned_to?.manager?.name ?? '',
    LeadType: lead?.type ?? '',
    PrimaryContactFirstName: lead?.company?.primary_contact?.name.substring(0, lead?.company?.primary_contact?.name?.indexOf(' ')) || ''
  }

  const handleSendFunc = () => {
    handleSend &&
      handleSend({
        attachments: attachments.length
          ? attachments.map((attach) => ({
              contentBytes: attach.contentBytes,
              contentType: attach.file.type,
              name: attach.file.name,
              size: attach.file.size
            }))
          : undefined,
        leadId,
        from,
        to: toRecipients || [],
        cc: ccRecipients,
        bcc: bccRecipients,
        subject: emailSubject,
        body: emailBody,
        bodyType: 'html'
      })
  }

  return (
    <Box className="p-6 flex flex-col justify-between h-full">
      <Box className="flex flex-grow">
        <Box className="flex flex-grow flex-col">
          <Box className="flex justify-between items-center">
            <Box className="flex gap-8">
              <Typography className="text-grey-600">From</Typography>
              <Typography data-cy="fromAddress">{from.emailAddress.address}</Typography>
            </Box>
          </Box>
          <Divider className="my-3" />
          <AddressInputs
            setToRecipients={setToRecipients}
            setBccRecipients={setBccRecipients}
            setCcRecipients={setCcRecipients}
            setContactLead={setContactLead}
            ccRecipients={ccRecipients}
            toRecipients={toRecipients}
            bccRecipients={bccRecipients}
          />
          <Divider className="my-3" />
          <TextField
            data-cy="subject"
            placeholder="Subject"
            value={emailSubject}
            variant="standard"
            InputProps={{ disableUnderline: true }}
            fullWidth
            onChange={(e) => setEmailSubject(e.target.value)}
          />
          <Divider className="my-3" />
          <EmailTemplateButton
            variableMapping={variableMapping}
            emailBody={emailBody}
            setEmailBody={setEmailBody}
            emailSubject={emailSubject}
            setEmailSubject={setEmailSubject}
          />
          {open && (
            <Box className="h-full mt-6">
              <QuillEditor
                value={emailBody}
                onChange={(val) => {
                  setEmailBody(val)
                }}
              />
            </Box>
          )}
          <ReactDropzone
            multiple
            maxSize={MAX_SIZE}
            onDropAccepted={handleDropAccepted}
            onDropRejected={(rejections) => {
              const rejectionMessages = rejections[0].errors.map((err) => err.message).join('\n')
              toast.error(rejectionMessages)
            }}
          >
            {({ getRootProps, isDragActive }) => (
              <Box
                sx={{
                  width: 'fit-content',
                  ...(isDragActive && {
                    backgroundColor: 'action.hover',
                    opacity: 0.5
                  })
                }}
                {...getRootProps()}
              >
                <Button variant="outlined" className="w-fit">
                  <AttachFileIcon className="text-[20px]" />
                  <Typography className="text-grey-800 ml-1">Attach File</Typography>
                </Button>
              </Box>
            )}
          </ReactDropzone>
          {Boolean(attachments.length) && (
            <Box className="flex gap-2 flex-wrap mt-3">
              {attachments.map((attachment, index) => (
                <Tooltip title={attachment.file.name} key={attachment.file.name}>
                  <Box
                    key={attachment.file.name}
                    className="px-2 py-1 w-[250px] truncate flex justify-between items-center border-solid border-[1px] border-grey-200 rounded-full"
                  >
                    <Box className="flex gap-2.5 items-center">
                      <File fontSize="small" sx={{ color: '#697586' }} />
                      <Typography className="truncate max-w-[175px]">{attachment.file.name}</Typography>
                    </Box>
                    <IconButton className="p-1" onClick={() => setAttachments(attachments.filter((_, i) => i !== index))}>
                      <CloseIcon className="text-sm" />
                    </IconButton>
                  </Box>
                </Tooltip>
              ))}
              {isEmailTooLarge && (
                <Typography className="text-warning-700">This email is too large to send. Please remove some attachments.</Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {handleSend && (
        <Box>
          {!leadIdQueryParam && !leadIdPassedIn && (
            <>
              <Divider className="my-3" />
              <AssociateLead leadId={contactLead} setLeadId={setLeadId} />
              <Divider className="my-3" />
            </>
          )}
          <LoadingButton
            data-cy="sendButton"
            className="w-fit float-right"
            disabled={!from || !toRecipients || !emailSubject || isEmailTooLarge}
            variant="contained"
            loading={loading}
            onClick={() =>
              !emailBody
                ? confirm({
                    title: 'Empty Email Body',
                    description: `Are you sure you want to send this email without any content?`
                  }).then(() => {
                    handleSendFunc()
                  })
                : handleSendFunc()
            }
          >
            Send
          </LoadingButton>
        </Box>
      )}
    </Box>
  )
}

export default ComposeEmail

import { Box, Button, CircularProgress, Divider, FormControlLabel, Pagination, Typography } from '@mui/material'
import StyledCheckbox from '../../StyledCheckbox'
import { useState } from 'react'
import { trpc } from '../../../core/trpc'
import { ArrowBackIcon } from '../../../assets/icons'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'

type Props = {
  srcLeadId: string
  destinationLeadId: string
  setStage: React.Dispatch<React.SetStateAction<'selectLead' | 'selectFile'>>
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>
  refetch: () => void
}

const SelectFiles = ({ srcLeadId, setStage, setAnchorEl, refetch, destinationLeadId }: Props) => {
  const [selectAll, setSelectAll] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<string[]>([])
  const [skip, setSkip] = useState(0)
  const TAKE = 50
  const { data, isLoading } = trpc.file.getFilesByLeadId.useQuery({ lead_id: srcLeadId, take: TAKE, skip })
  const mutation = trpc.file.copyFilesToLead.useMutation({
    onSuccess: () => {
      toast.success('Files successfully imported')
      setAnchorEl(undefined)
      refetch()
    },
    onError: () => toast.error('An error has occurred')
  })
  const handleCheck = (id: string, checked: boolean) => {
    if (checked) return setSelectedFiles([...selectedFiles, id])
    return setSelectedFiles(selectedFiles.filter((fileId) => fileId !== id))
  }

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked)
    if (!checked) setSelectedFiles([])
  }

  return (
    <Box>
      {!isLoading && !data?.count && <Typography className="text-center">There are no files to import</Typography>}
      {isLoading && <CircularProgress />}
      {!isLoading && Boolean(data?.results.length) && (
        <Box className="overflow-auto max-h-[400px] pl-1">
          <FormControlLabel
            onChange={(_, checked) => handleSelectAll(checked)}
            label={<Typography>Import all files</Typography>}
            control={<StyledCheckbox size="small" checked={selectAll} />}
          />
          <Typography className="my-4">Or select files</Typography>
          {data?.results.map((file) => (
            <FormControlLabel
              onChange={(_, checked) => handleCheck(file.id, checked)}
              label={<Typography>{file.name}</Typography>}
              control={<StyledCheckbox size="small" checked={selectedFiles.includes(file.id) || selectAll} />}
            />
          ))}
        </Box>
      )}
      <Box className="mt-2 flex justify-center">
        <Pagination
          size="small"
          count={Math.ceil((data?.count || 0) / TAKE)}
          boundaryCount={2}
          variant="outlined"
          shape="rounded"
          onChange={(_e, page) => setSkip((page - 1) * TAKE)}
          page={Math.ceil(skip / TAKE) + 1}
        />
      </Box>
      <Divider className="my-6" />
      <Box className="flex justify-between">
        <Button onClick={() => setStage('selectLead')} startIcon={<ArrowBackIcon />}>
          Back
        </Button>
        <Box className="flex gap-2">
          <Button onClick={() => setAnchorEl(undefined)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            disabled={!selectedFiles.length && !selectAll}
            loading={mutation.isLoading}
            onClick={() => {
              mutation.mutate({
                lead_id: destinationLeadId,
                files: selectedFiles.length ? selectedFiles : undefined,
                srcLead: selectAll ? srcLeadId : undefined
              })
            }}
            variant="contained"
          >
            Import
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectFiles

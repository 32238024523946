/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/function-component-definition */
import { Fragment, type FC, type ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Divider, List, ListSubheader } from '@mui/material'
import type { ListProps } from '@mui/material'
import { DashboardSidebarItem } from './dashboard-sidebar-item'

interface Item {
  path?: string
  icon?: ReactNode
  chip?: ReactNode
  info?: ReactNode
  onClick?: () => void
  children?: Item[]
  title: string
  hidden?: boolean
  divider?: boolean
}

interface DashboardSidebarSectionProps extends ListProps {
  items: Item[]
  path: string
  title: string
}

const renderNavItems = ({ depth = 0, items, path }: { depth?: number; items: Item[]; path: string }): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          depth,
          path
        }),
      [] as JSX.Element[]
    )}
  </List>
)

const reduceChildRoutes = ({ acc, item, depth, path }: { acc: JSX.Element[]; depth: number; item: Item; path: string }): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`

  const isMatch = item?.path
    ? path?.startsWith(item?.path || '') || (item.path === '/contacts' && path.includes('/companies'))
    : path?.startsWith('/' + item?.children?.[0]?.path?.split('/')?.filter((n) => n)?.[0])

  if (item.hidden) return acc

  acc.push(
    <Fragment key={key}>
      {item.divider && <Divider className="mb-2" />}
      <DashboardSidebarItem
        active={isMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        onClick={item.onClick}
        path={item.path}
        title={item.title}
        open={item.children && isMatch}
      >
        {item.children &&
          renderNavItems({
            depth: depth + 1,
            items: item.children,
            path
          })}
      </DashboardSidebarItem>
    </Fragment>
  )

  return acc
}

export const DashboardSidebarSection: FC<DashboardSidebarSectionProps> = (props) => {
  const { items, path, title, ...other } = props

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'neutral.500',
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 2.5,
            ml: 4,
            textTransform: 'uppercase'
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        path
      })}
    </List>
  )
}

DashboardSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

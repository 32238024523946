import debounce from 'lodash.debounce'
import { trpc } from '../../../core/trpc'
import { Link } from '../../../icons/link'
import { useNavigate } from 'react-router'
import { useMemo, useState } from 'react'
import { Autocomplete, TextField, MenuItem, Typography, CircularProgress } from '@mui/material'

import { Search as SearchIcon, Users } from 'react-feather'
import { Box } from '@mui/system'
import { StageChip } from '../../../components/Chips'

type SearchItem = {
  label: string
  id: string
  path: string
  type: 'Company' | 'Contact' | 'Lead'
  icon: React.ReactNode
  stage?: React.ReactNode
}

const GlobalSearch = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState<SearchItem | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [search, setSearch] = useState('')

  const debouncedSearch = useMemo(() => debounce((search: string) => setSearch(search), 700), [])

  const { data, isLoading } = trpc.globalSearch.search.useQuery({ search }, { enabled: Boolean(search) })

  const contacts =
    data?.contacts.map((contact) => ({
      label: contact.name,
      id: contact.id,
      path: `/contacts/${contact.id}`,
      type: 'Contact' as const,
      icon: <Users className="text-grey-500" />
    })) || []

  const leads =
    data?.leads.map((lead) => ({
      label: `${lead.company_name}-${lead.name}`,
      id: lead.id,
      path: `/pipeline/${lead.stage_pipeline_id}/lead/${lead.id}`,
      type: 'Lead' as const,
      icon: <Link className="text-grey-500" />,
      stage: <StageChip color={lead.stage_color} textColor={lead.stage_text_color} stageId={lead.stage_id} stageName={lead.stage_name} />
    })) || []

  const dataArray = [...leads, ...contacts] as SearchItem[]
  return (
    <Autocomplete
      data-cy="global-search"
      value={value}
      inputValue={inputValue}
      slotProps={{ paper: { sx: { mt: '10px', minWidth: 'fit-content' } } }}
      options={dataArray}
      onInputChange={(_, val) => {
        setInputValue(val)
        debouncedSearch(val)
      }}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} data-cy="global-search-option">
          {option.icon}
          <Box className="flex gap-2 pr-2">
            <Typography className="ml-3 text-grey-900">{option.label}</Typography>
            <Box className="pr-3"> {option.stage}</Box>
          </Box>
        </MenuItem>
      )}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(props, val) => {
        setValue(val)
        if (val?.path) {
          setInputValue('')
          setValue(null)
          navigate(val?.path || '')
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className="bg-grey-600 rounded-[200px]  border-none w-80 h-9 px-4 mobile:w-52 mr-4"
          placeholder="Search Leads or Contacts"
          variant="standard"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            startAdornment: <SearchIcon className="mr-4 text-grey-100" />,
            endAdornment: (
              <>
                {isLoading && inputValue ? <CircularProgress className="text-white" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          sx={{
            '.MuiSvgIcon-root ': {
              fill: 'rgb(248 250 252)'
            },
            input: {
              marginTop: '2px',
              color: 'rgb(248 250 252)',
              '&::placeholder': {
                color: 'rgb(248 250 252)',
                fontSize: '14px'
              }
            }
          }}
        />
      )}
    />
  )
}

export default GlobalSearch

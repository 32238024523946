import { Box, Drawer, IconButton } from '@mui/material'
import ComposeEmail, { EmailPayload, FileAttachment } from './ComposeEmail'
import toast from 'react-hot-toast'
import { trpc } from '../../core/trpc'
import { getEmailId, sendMsEmail, uploadFileToAzure, uploadEmailContentToAzure } from '../../services/azureServices'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useState } from 'react'
import { useEmailStore } from '../../stores/emailStore'
import { v4 as uuidv4 } from 'uuid'

type NewEmailProps = {
  open: boolean
  setOpen: (o: boolean) => void
  refetch?: () => void
  leadIdPassedIn?: string
  markFollowUpComplete?: () => void
  contact?: string
  phone?: string
  pipelineId?: string
  defaultData?:
    | {
        subject: string
        to: string[]
        body: string
      }
    | undefined
}

const NewEmailDrawer = ({ open, setOpen, refetch, leadIdPassedIn, markFollowUpComplete, contact, phone, pipelineId, defaultData }: NewEmailProps) => {
  const [isSaving, setSaving] = useState(false)
  const [clearEmailState, setClearEmailState] = useState(false)
  const { mailboxEmailAddress } = useEmailStore()
  const associatedLeadMutation = trpc.activity.saveEmailToLeadActivity.useMutation({
    onError: (e) => toast.error(e.message)
  })
  const getLinks = trpc.file.createUploadFileLink.useMutation()

  const uploadBodyContentToAzure = async (email: MicrosoftEmail): Promise<string> => {
    const filePath = `Emails/MSFT-${email.id}`
    const { containerUrl, sasQuery } = (await getLinks.mutateAsync()) || {}

    await uploadEmailContentToAzure({
      contents: email.body.content,
      size: email.body.content.length,
      filePath: filePath,
      containerUrl,
      sasQuery
    })
    return filePath
  }
  const getFilePath = ({ leadId }: { leadId: string }) => `Lead/${leadId}/${new Date().getTime()}-${uuidv4()}`

  const uploadAttachments = async ({ leadId, attachments }: { attachments: (FileAttachment & { url: string })[] | undefined; leadId: string }) => {
    if (!attachments) return undefined

    const { containerUrl, sasQuery } = (await getLinks.mutateAsync()) || {}

    const fileUploadResponse = attachments.map((attachment) => {
      return uploadFileToAzure({
        containerUrl,
        sasQuery,
        filePath: attachment.url,
        contents: `data:${attachment.contentType};base64,${attachment.contentBytes}`,
        size: attachment.size
      }).catch((e) => toast.error(e))
    })

    const resolvedPromises = await Promise.all(fileUploadResponse)
    return resolvedPromises.every((promise) => !!promise)
  }

  const sendEmail = async (emailPayload: EmailPayload & { leadId?: string }) => {
    setSaving(true)
    const pubId = `${new Date()} ${emailPayload.subject}`
    const response = await sendMsEmail({ emailPayload, pubId, mailboxEmailAddress })
    if (response.status === 202 || response.status === 201) {
      if (emailPayload.leadId) {
        const attachments = emailPayload.attachments?.map((attach) => ({
          ...attach,
          url: getFilePath({ leadId: String(emailPayload.leadId) })
        }))
        const azureUploadRes = await uploadAttachments({ attachments, leadId: emailPayload.leadId })
        const files =
          attachments?.length && azureUploadRes
            ? attachments.map(({ contentType, name, url, size }) => ({
                type: contentType,
                name,
                lead_id: String(emailPayload.leadId),
                url,
                size
              }))
            : undefined
        let timesTried = 0
        const interval = setInterval(() => {
          getEmailId(pubId, mailboxEmailAddress).then(async (res: MicrosoftEmail | null) => {
            if (res !== null) {
              const filePath = await uploadBodyContentToAzure(res)
              associatedLeadMutation
                .mutateAsync({
                  lead_id: emailPayload.leadId ?? '',
                  email: {
                    subject: res.subject,
                    from_address: res.from,
                    microsoft_id: res.id,
                    parent_folder_id: res.parentFolderId,
                    to_addresses: res.toRecipients,
                    internet_message_id: res.internetMessageId,
                    body_preview: res.bodyPreview,
                    bcc_recipients: res.bccRecipients,
                    cc_recipients: res.ccRecipients,
                    conversation_id: res.conversationId,
                    created_date_time: res.createdDateTime,
                    body_content_url: filePath,
                    files
                  }
                })
                .then(() => {
                  if (refetch) {
                    refetch()
                  }
                })

              clearInterval(interval)
            }
          })
          timesTried++
          if (timesTried === 120) {
            clearInterval(interval)
          }
        }, 500)
      } else {
        if (refetch) {
          setTimeout(() => {
            refetch()
          }, 500)
        }
      }

      setOpen(false)
      setClearEmailState(true)
      markFollowUpComplete && markFollowUpComplete()
      toast.success('Email sent successfully')
    } else {
      toast.error(`An error has occurred: ${response.statusText}`)
    }
    setSaving(false)
  }

  return (
    <Drawer ModalProps={{ keepMounted: true }} onClose={() => setOpen(false)} anchor="right" open={open}>
      <Box className="w-[700px] mobile:w-full flex flex-grow flex-col">
        <IconButton sx={{ justifyContent: 'flex-start', display: { xs: 'flex', md: 'none' } }} onClick={() => setOpen(false)}>
          <ArrowBack />
        </IconButton>
        <ComposeEmail
          open={open}
          defaultData={defaultData}
          setClearEmailState={setClearEmailState}
          clearEmailState={clearEmailState}
          handleSend={sendEmail}
          loading={isSaving}
          leadIdPassedIn={leadIdPassedIn}
          contactPassedIn={contact}
          phonePassedIn={phone}
          pipelineIdPassedIn={pipelineId}
        />
      </Box>
    </Drawer>
  )
}

export default NewEmailDrawer

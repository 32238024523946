import { Box, Button, IconButton, Typography } from '@mui/material'
import { Offer } from '.'
import { OfferChip } from '../../../components/Chips'
import { Can } from '../../../contexts/AbilityContext'
import { Trash } from 'react-feather'
import { CloseIcon } from '../../../assets/icons'
import { useNavigate } from 'react-router'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import { format } from 'date-fns'
import { useConfirm } from 'material-ui-confirm'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { features } from '../../../consts/features'

type HeaderProps = {
  offer: Offer
  refetch: () => void
  handleClose: () => void
}

const Header = ({ offer, refetch, handleClose }: HeaderProps) => {
  const navigate = useNavigate()
  const confirm = useConfirm()
  const client = useQueryClient()

  const serviceFeeInvoiceId = useMemo(() => offer?.invoices?.find((i) => i.type === 'SERVICE_FEE')?.id, [offer?.invoices])
  const commissionInvoiceId = useMemo(() => offer?.invoices?.find((i) => i.type === 'COMMISSION')?.id, [offer?.invoices])
  const bonusInvoices = useMemo(() => offer?.invoices?.filter((i) => i.type === 'BONUS'), [offer?.invoices])

  const mutation = trpc.offer.deleteOffer.useMutation({
    onSuccess: () => {
      refetch()
      client.refetchQueries([['offer', 'getOffers'], { input: { leadId: offer?.lead_id, search: '', skip: 0, take: 20 }, type: 'query' }])
      handleClose()
    },
    onError: (e) => toast.error(e.message)
  })

  return (
    <Box>
      <Box className="flex justify-between items-center">
        <Box className="flex gap-2 items-center">
          <Typography className="display_xs_semibold text-grey-900">{`${offer?.lead.company.name} - ${offer?.lead.name}`}</Typography>
          <OfferChip status={offer?.status} />
        </Box>
        <Box className="flex items-center gap-0">
          <Can I="full" a="Offer">
            <IconButton
              onClick={() => {
                if (offer?.status === 'ACCEPTED') return toast.error('An Accepted offer cannot be deleted')
                if (offer?._count.invoices) return toast.error('An offer with invoices cannot be deleted')
                confirm({ description: 'This will permanently delete the offer.' }).then(() => mutation.mutate({ id: offer?.id || '' }))
              }}
            >
              <Trash className="text-grey-700 w-[18px]" />
            </IconButton>
          </Can>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
      </Box>
      <Box className="gap-1">
        <Typography className="text_xs_normal text-grey-500 italic">{`created by ${offer?.created_by_user.name} on ${format(
          new Date(offer?.created_at || ''),
          'EEE MMM dd yyyy, h:mm a'
        )}`}</Typography>
        {offer?.updated_at !== offer?.created_at && (
          <Typography className="text_xs_normal text-grey-500 italic">{`updated by ${offer?.updated_by_user?.name} on ${format(
            new Date(offer?.updated_at || ''),
            'EEE MMM dd yyyy, h:mm a'
          )}`}</Typography>
        )}
      </Box>
      <Button
        className="text-primary-600 text_sm_medium pl-1"
        variant="text"
        onClick={(e) => {
          e.stopPropagation()
          navigate(`/pipeline/${offer?.lead?.stage.pipeline_id}/lead/${offer?.lead_id}`)
        }}
      >
        View Lead
      </Button>
      {features.accounting.sidebar && (
        <Can I="view" a="Invoice">
          <>
            {serviceFeeInvoiceId && (
              <Button
                className="text-primary-600 text_sm_medium pl-1"
                variant="text"
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/accounting/invoices/${serviceFeeInvoiceId}`)
                }}
              >
                View Service Fee Invoice
              </Button>
            )}
            {commissionInvoiceId && (
              <Button
                className="text-primary-600 text_sm_medium pl-1"
                variant="text"
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/accounting/invoices/${commissionInvoiceId}`)
                }}
              >
                View Commission Invoice
              </Button>
            )}
            {!!bonusInvoices?.length &&
              bonusInvoices.map((invoice) => (
                <Button
                  key={invoice.id}
                  className="text-primary-600 text_sm_medium pl-1"
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/accounting/invoices/${invoice.id}`)
                  }}
                >
                  View Bonus Invoice {format(new Date(invoice.created_at), 'P')}
                </Button>
              ))}
          </>
        </Can>
      )}
    </Box>
  )
}

export default Header

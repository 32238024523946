import { Box } from '@mui/material'

type IconCircleBackgroundProps = {
  children: React.ReactNode
}

const IconCircleBackground = ({ children }: IconCircleBackgroundProps) => {
  return (
    <Box className="flex items-center w-fit justify-center rounded-full bg-success-100 border-[8px] border-success-50 border-solid p-1.5">
      {children}
    </Box>
  )
}

export default IconCircleBackground

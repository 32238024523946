import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  debounce
} from '@mui/material'
import { Stack } from '@mui/system'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { AddCircleOutlineOutlinedIcon, CloseIcon } from '../../../assets/icons'
import { formatPhoneOnInput, formatZipOnInput, getAllStates, getSplitPhones, validateAddress, validateEmail } from '../../../utils/helpers'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import { CompanyOutput, CreateContactOutput } from '../../../types/procedureOutputs'
import { omit } from 'remeda'
import { DatePicker } from '@mui/x-date-pickers'

type AddContactProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  refetchContacts: () => void
  companyId: string | undefined
  onCreateSuccess?: (contact: CreateContactOutput) => void
}

const AddContact = ({ open, setOpen, refetchContacts, companyId, onCreateSuccess }: AddContactProps) => {
  const initValues = {
    name: '',
    company: companyId || '',
    role: '',
    phoneNumbers: [''],
    primary_email: '',
    other_emails: [] as string[],
    birthday: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    primary: false
  }

  const [contactData, setContactData] = useState(initValues)
  const [emailErr, setEmailErr] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [search, setSearch] = useState('')
  const states = getAllStates()
  const debouncedSetSearchFilter = useMemo(() => debounce((s: string) => setSearch(s), 500), [])
  const companies = companyId ? [] : trpc.company.getCompanies.useQuery({ skip: 0, take: 10, orderBy: undefined, search }).data?.results

  useEffect(() => {
    const validate = () => {
      if (!contactData.name) return false
      const validEmail = !contactData.primary_email || validateEmail(contactData.primary_email)
      const validAddress = validateAddress(contactData.address, contactData.city, contactData.state, contactData.zip)
      return validEmail && validAddress
    }
    setIsValid(validate)
  }, [contactData])

  const mutation = trpc.contact.create.useMutation({
    onSuccess: (contact) => {
      refetchContacts()
      toast.success('Contact created successfully')
      setContactData(initValues)
      onCreateSuccess?.(contact)
      setOpen(false)
    },
    onError: (e) => {
      toast.error(e.message)
    }
  })

  const saveContact = async () => {
    const phones = contactData.phoneNumbers?.filter((p: string) => p).map((phone, index) => ({
      phone_number: phone,
      is_primary_phone: index === 0 
    }))

    mutation.mutate({
      ...omit(contactData, ['company']),
      companyId: companyId || contactData?.company,
      phones: phones.length > 0 ? phones : undefined,
      birthday: contactData.birthday ? new Date(contactData.birthday) : undefined
    })
  }

  const handleCompanyChange = (c: string) => {
    setContactData((prev) => ({
      ...prev,
      company: c
    }))
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>, fieldName: string, index: number) => {
    const { value } = e.target
    const validatedVal = fieldName === 'zip' ? formatZipOnInput(value) : value
    if (fieldName === 'phoneNumbers') {
      const updatedPhoneArray = [...contactData.phoneNumbers]
      const phoneNumbers = getSplitPhones(value)
      updatedPhoneArray[index] = phoneNumbers[0]
      const others = phoneNumbers.slice(1)
      setContactData((prev) => ({
        ...prev,
        phoneNumbers: [...updatedPhoneArray, ...others]
      }))
    } else {
      setContactData((prev) => ({
        ...prev,
        [fieldName]: validatedVal
      }))
    }
  }

  const handleAddPhone = () => {
    setContactData((prev) => ({
      ...prev,
      phoneNumbers: [...prev.phoneNumbers, '']
    }))
  }

  const handleAddEmail = () => {
    setContactData((prev) => ({
      ...prev,
      other_emails: [...prev.other_emails, '']
    }))
  }

  const getFieldName = (name: string) => {
    return name === 'phoneNumbers'
      ? 'Phone'
      : name === 'company'
      ? 'Company'
      : name === 'primary_email'
      ? 'Primary Email'
      : name === 'other_emails'
      ? 'Additional Emails'
      : name
  }

  const getInput = (index: number, name: string, value: string) => {
    const val = name === 'phoneNumbers' ? formatPhoneOnInput(value) : value

    return name === 'state' ? (
      <Select fullWidth value={val} onChange={(e) => handleChange(e, name, index)}>
        {states.map((state) => (
          <MenuItem key={state.value} value={state.value}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
    ) : (
      <TextField
        onBlur={() => {
          if (name === 'primary_email') {
            const isValidEmail = validateEmail(val) || !val
            setEmailErr(!isValidEmail)
          }
        }}
        error={name === 'primary_email' && emailErr}
        helperText={name === 'primary_email' && emailErr && 'Please enter a valid email'}
        key={index}
        name={name}
        value={val}
        onChange={(e) => handleChange(e, name, index)}
        fullWidth
        variant="outlined"
        className="mb-1"
      />
    )
  }

  const getFormField = (index: number, name: string, value: string | string[] | boolean | CompanyOutput) => {
    if (name === 'zip') return
    if (name === 'company' && companyId) return
    return (
      <Box pb={2} key={index} pr={3}>
        {name !== 'primary' && name !== 'state' && <Typography className="text-grey-700 mb-1.5 capitalize">{getFieldName(name)}</Typography>}
        {name === 'birthday' ? (
          <DatePicker
            slotProps={{ textField: { fullWidth: true, error: false } }}
            disableFuture
            value={contactData.birthday ? new Date(contactData.birthday) : null}
            onChange={(value) => setContactData((contact) => ({ ...contact, birthday: value && value.getTime() ? value.toISOString() : '' }))}
          />
        ) : name === 'phoneNumbers' ? (
          <>
            {Array.isArray(value) && value.map((phoneValue: string, phoneIndex: number) => getInput(phoneIndex, name, phoneValue))}
            <Button onClick={handleAddPhone}>
              <AddCircleOutlineOutlinedIcon className="text-primary-500 mr-2" />
              <Typography className="text_sm_medium text-primary-500">Add Phone</Typography>
            </Button>
          </>
        ) : name === 'other_emails' ? (
          <Stack gap="6px">
            {contactData.other_emails.map((email, index) => (
              <TextField
                key={index}
                value={email}
                onChange={(e) => {
                  setContactData((data) => ({
                    ...data,
                    other_emails: data.other_emails.map((mail, idx) => {
                      if (idx === index) return e.target.value
                      return mail
                    })
                  }))
                }}
              />
            ))}
            <Button className="w-fit" onClick={handleAddEmail}>
              <AddCircleOutlineOutlinedIcon className="text-primary-500 mr-2" />
              <Typography className="text_sm_medium text-primary-500">Add Email</Typography>
            </Button>
          </Stack>
        ) : name === 'state' || name === 'zip' ? (
          <Box className="grid grid-cols-2 gap-6">
            <Box>
              <Typography className="text-grey-700 mb-1.5 capitalize">State</Typography>
              {getInput(index, name, value.toString())}
            </Box>
            <Box>
              <Typography className="text-grey-700 mb-1.5 capitalize">Zip</Typography>
              {getInput(index + 1, 'zip', contactData.zip)}
            </Box>
          </Box>
        ) : name === 'company' ? (
          <Autocomplete
            options={companies || []}
            isOptionEqualToValue={(o, v) => o?.id === v?.id}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
            value={companies?.find((c) => c.id === value) || null}
            onChange={(_e, v) => handleCompanyChange(v?.id || '')}
            onInputChange={(_, newInputValue) => debouncedSetSearchFilter(newInputValue)}
            getOptionLabel={(o) => o?.name || ''}
          />
        ) : name === 'primary' ? (
          <FormControlLabel
            className="text-grey-700"
            label="Primary Contact"
            control={
              <Checkbox
                checked={Boolean(value)}
                onChange={(e) => {
                  setContactData((prev) => ({
                    ...prev,
                    [name]: e.target.checked
                  }))
                }}
              />
            }
          />
        ) : (
          getInput(index, name, value.toString())
        )}
      </Box>
    )
  }

  return (
    <Dialog open={open}>
      <Box className="p-6 w-full">
        <Box className="flex justify-between items-center mb-5">
          <Typography className="text-grey-900 text_xl_medium">Add Contact</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <Stack className="mb-1">
          {Object.entries(contactData).map(([fieldName, fieldValue], index) => getFormField(index, fieldName, fieldValue))}
        </Stack>
        <Divider className="mb-6 mt-4" />
        <Box className="flex justify-end gap-3" pr={3}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false)
              setContactData(initValues)
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" disabled={!isValid || mutation.isLoading} onClick={saveContact}>
            Add Contact
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddContact

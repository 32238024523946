export const priorityColors = {
  HIGH: {
    text: 'success-800',
    bg: 'success-50',
    border: 'success-50'
  },
  MEDIUM: {
    text: 'grey-600',
    bg: 'white',
    border: 'grey-200'
  },
  LOW: {
    text: 'grey-500',
    bg: 'grey-100',
    border: 'grey-100'
  },
  NOT_REASSIGNABLE: {
    text: 'transparent',
    bg: 'transparent',
    border: 'transparent'
  }
}

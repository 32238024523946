import { Box } from '@mui/material'
import { trpc } from '../core/trpc'

const TaggedText = ({ text }: { text: string }) => {
  const { data } = trpc.auth.getCurrentUser.useQuery()
  return (
    <Box>
      {text.split(/(\s+)/).map((word, index) => {
        if (word.at(0) === '@' && word.slice(1) === data?.email) {
          return (
            <span key={index} className="font-medium text-error-500">
              {word}
            </span>
          )
        }
        if (word.at(0) === '@') {
          return (
            <span key={index} className="font-semibold">
              {word}
            </span>
          )
        }
        return <span key={index}>{word}</span>
      })}
    </Box>
  )
}

export default TaggedText

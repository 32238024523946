import { Autocomplete, Button, TextField } from '@mui/material'
import { useMemo, useState } from 'react'
import { trpc } from '../../core/trpc'
import debounce from 'lodash.debounce'
import { OfferGetOffersOutput } from '../../types/procedureOutputs'

type AssociateOfferProps = {
  onChange: (offerId: string) => void
  leadId: string
  emailId: string
}

const AssociateOffer = ({ onChange, leadId, emailId }: AssociateOfferProps) => {
  const [offer, setOffer] = useState<OfferGetOffersOutput['results'][number]>()
  const [showSelect, setShowSelect] = useState(false)
  const [search, setSearch] = useState('')
  const { data: offers } = trpc.offer.getOffers.useQuery({ search, leadId })

  const debouncedSearch = useMemo(() => debounce((input: string) => setSearch(input), 300), [])

  if (!showSelect) {
    return (
      <Button onClick={() => setShowSelect(true)} className="shadow-none w-fit text_sm_medium text-primary-500 normal-case whitespace-nowrap">
        Link To An Offer
      </Button>
    )
  }

  return (
    <Autocomplete
      className="w-64"
      placeholder="Search Offers"
      options={offers?.results.filter((offer) => !offer.emails.find((email) => email.id === emailId)) || []}
      onInputChange={(_, newInputValue) => debouncedSearch(newInputValue)}
      onChange={(_e, v) => {
        if (v) {
          onChange(v.id)
          setOffer(v)
        }
      }}
      noOptionsText="No offers"
      filterOptions={(x) => x}
      slotProps={{
        popper: {
          sx: {
            zIndex: 10000
          }
        }
      }}
      getOptionLabel={(option) => option.funder.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={offer || null}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

export default AssociateOffer

import { Virtuoso } from 'react-virtuoso'
import Message from './Message'
import { GetMessagesOutput } from '../../../../../types/procedureOutputs'
import { Box } from '@mui/material'
import { useState } from 'react'
import ReactMentionsInput from '../../../../../components/ReactMentionsInput'
import { LoadingButton } from '@mui/lab'
import { trpc } from '../../../../../core/trpc'
import toast from 'react-hot-toast'

type Props = {
  messages: GetMessagesOutput
  fetchMessages: ({ concat }: { concat: boolean }) => Promise<void>
  leadId: string | undefined
}

const Messages = ({ messages, fetchMessages, leadId }: Props) => {
  const [newMessage, setNewMessage] = useState('')
  const createMutation = trpc.message.createMessage.useMutation({
    onSuccess: () => {
      toast.success('Comment created')
      fetchMessages({ concat: false })
      setNewMessage('')
    }
  })
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget
    if (Math.abs(scrollHeight - scrollTop - clientHeight) < 1 && messages.results.length < messages.count) {
      fetchMessages({ concat: true })
    }
  }

  return (
    <Box>
      <Box className="flex items-center gap-4 mb-9">
        <Box className="border-[1px] border-grey-300 border-solid py-3 px-[14px] rounded-lg w-full shadow-sm">
          <ReactMentionsInput value={newMessage} onChange={(val) => setNewMessage(val)} placeholder="Add comment" />
        </Box>
        <LoadingButton
          onClick={() => {
            if (leadId) {
              createMutation.mutate({ message: newMessage, leadId })
            }
          }}
          loading={createMutation.isLoading}
          disabled={!newMessage}
          variant="contained"
        >
          Send
        </LoadingButton>
      </Box>
      <Virtuoso
        onScroll={handleScroll}
        className="rounded-xl h-[300px]"
        data={messages.results}
        itemContent={(index, item) => <Message key={index} message={item} fetchMessages={fetchMessages} leadId={leadId} />}
      />
    </Box>
  )
}

export default Messages

import { SendEmailPayload, EmailProvider, SendEmailResult, GetEmailResult } from './provider'
import * as azureSvc from '../../azureServices'

export class MicrosoftProviderError extends Error {
  constructor(
    message?: string,
    public readonly rawResponse?: unknown
  ) {
    super(message)
  }
}

export class MicrosoftProvider implements EmailProvider {
  public async sendEmail(emailPayload: SendEmailPayload): Promise<SendEmailResult> {
    const pubId = `${new Date()} ${emailPayload.subject}`

    const response = await azureSvc.sendMsEmail({ emailPayload, pubId, mailboxEmailAddress: emailPayload.from.emailAddress.address })

    if (response.status !== 202 && response.status !== 201) {
      return {
        sent: false,
        error: new MicrosoftProviderError(`Error occurred: ${response.statusText}`, response)
      }
    }

    return {
      id: pubId,
      payload: response,
      sent: true
    }
  }
  public async getEmail(emailId: string, senderEmail: string): Promise<GetEmailResult | null> {
    let timesTried = 0

    while (timesTried < 120) {
      const res = await azureSvc.getEmailId(emailId, senderEmail)
      if (res !== null) {
        return res
      }

      timesTried++

      // Wait for 500ms before the next attempt
      await new Promise(resolve => setTimeout(resolve, 500))
    }

    return null
  }
}

import { Badge, Box, Chip, Collapse, IconButton, Typography } from '@mui/material'
import clsx from 'clsx'
import { ActivityFilter } from '.'
import { useSession } from 'next-auth/react'
import { ChevronRightIcon, KeyboardArrowUpIcon } from '../../../../assets/icons'
import { ActivityType } from '@prisma/client'

type HeaderProps = {
  filters: ActivityFilter
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  collapsible?: boolean
  setFilters: React.Dispatch<React.SetStateAction<ActivityFilter>>
  displayType: 'activity' | 'file' | 'edit' | 'assignment' | 'message'
  setDisplayType: React.Dispatch<React.SetStateAction<'activity' | 'file' | 'edit' | 'assignment' | 'message'>>
  refetch: ({ concat, activityType }: { concat: boolean; activityType: string | undefined }) => void
  messageCount: number
}

const Header = ({ filters, setFilters, displayType, setDisplayType, refetch, expanded, setExpanded, collapsible, messageCount }: HeaderProps) => {
  const session = useSession()
  const activityTypes = [
    {
      label: 'CALL LOGS',
      type: 'CALL'
    },
    {
      label: 'NOTES',
      type: 'NOTE'
    },
    {
      label: 'EMAILS',
      type: 'EMAIL'
    },
    {
      label: 'STAGE UPDATES',
      type: 'STAGE_UPDATE'
    }
  ] as { label: string; type: ActivityType }[]

  const nonActivityChips = [
    {
      label: 'COMMENTS',
      type: 'message'
    },
    {
      label: 'FILES',
      type: 'file'
    },
    {
      label: 'EDITS',
      type: 'edit'
    },
    {
      label: 'ASSIGNMENT HISTORY',
      type: 'assignment'
    }
  ] as { label: string; type: typeof displayType }[]

  const handleActivityTypeClick = (type: string) => {
    if (['file', 'edit', 'assignment', 'message'].includes(type)) {
      setDisplayType(type as 'file' | 'edit' | 'assignment' | 'message')
      setFilters((filters) => ({ ...filters, activityType: undefined }))
    } else {
      refetch({ concat: false, activityType: type === 'all' ? undefined : type })
      setDisplayType('activity')
      setFilters((filters) => {
        return { ...filters, skip: 0, activityType: type === 'all' ? undefined : type }
      })
    }
  }

  return (
    <Box>
      <Box className={`flex justify-between items-center ${clsx({ 'mb-6': collapsible && expanded })}`}>
        <Typography variant="h6" sx={{ color: '#121926' }}>
          Activity
        </Typography>
        {collapsible && <IconButton onClick={() => setExpanded(!expanded)}> {expanded ? <KeyboardArrowUpIcon /> : <ChevronRightIcon />}</IconButton>}
      </Box>
      <Collapse in={!collapsible || expanded}>
        <Box className="flex items-center gap-2.5 mt-1 mobile:items-start mobile:flex-col-reverse">
          <Box className="flex items-center gap-2.5 mobile:grid mobile:grid-cols-2 overflow-x-auto pt-2.5 pb-3">
            <Chip
              className={`border-grey-300 border-[1px] tracking-widest border-solid text_sm_medium ${clsx({
                'bg-white text-grey-600': filters.activityType !== undefined || displayType !== 'activity',
                'bg-secondary-500 text-white': filters.activityType === undefined && displayType === 'activity'
              })}`}
              label="ALL"
              onClick={() => handleActivityTypeClick('all')}
            />
            {activityTypes.map((activityType) => (
              <Chip
                data-cy={`button-${activityType.type}`}
                onClick={() => handleActivityTypeClick(activityType.type)}
                className={`border-grey-300 tracking-widest border-[1px] border-solid text_sm_medium ${clsx({
                  'bg-white text-grey-600': filters?.activityType !== activityType.type || displayType !== 'activity',
                  'bg-secondary-500 text-white': filters?.activityType === activityType.type && displayType === 'activity'
                })}`}
                key={activityType.type}
                label={activityType.label}
              />
            ))}
            {nonActivityChips.map((chip) => (
              <Badge key={chip.label} badgeContent={messageCount} color="error" invisible={chip.type !== 'message'}>
                <Chip
                  key={chip.label}
                  className={`border-grey-300 tracking-widest border-[1px] border-solid text_sm_medium ${clsx({
                    'bg-white text-grey-600': displayType !== chip.type,
                    'bg-secondary-500 text-white': displayType === chip.type,
                    hidden: displayType === 'assignment' && !session.data?.currentRole?.is_admin && !session.data?.user.subordinatesCount
                  })}`}
                  label={chip.label}
                  onClick={() => handleActivityTypeClick(chip.type)}
                />
              </Badge>
            ))}
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default Header

import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { Eye, EyeOff } from 'react-feather'
import { GetReadReceipt } from '../types/procedureOutputs'
import { formatDurationShorthand } from '../utils/helpers'
import { format } from 'date-fns'
import clsx from 'clsx'

type Props = {
  readReceipt: GetReadReceipt | undefined
  smallIcon?: boolean
}

const ReadReceiptPopper = ({ readReceipt, smallIcon }: Props) => {
  if (!readReceipt) {
    return <EyeOff className={`text-grey-700 ${clsx({ 'w-2.5': smallIcon })}`} />
  }
  return (
    <Tooltip
      slotProps={{
        tooltip: { className: 'bg-white text-grey-700 border-[1px] border-solid border-grey-300 p-4 shadow-md w-[300px]' },
        popper: { className: 'z-[10000]' }
      }}
      title={
        <Box>
          <Box className="flex justify-between">
            <Box>
              <Typography className="inline-block text_sm_medium mr-1">{readReceipt.read_count}</Typography>
              <Typography className="inline-block text_sm_normal text-grey-500">Views</Typography>
            </Box>
            <Typography className="text_sm_normal text-grey-500">
              Latest: {formatDurationShorthand(new Date(readReceipt.last_read_at))} ago
            </Typography>
          </Box>
          <Divider className="my-3" />
          <Stack gap="8px">
            {readReceipt.view_history?.map((history) => (
              <Typography className="text_sm_normal text-grey-500">{format(new Date(history.date_read), 'P')}</Typography>
            ))}
          </Stack>
        </Box>
      }
    >
      <Eye className={`text-success-800 ${clsx({ 'w-2.5': smallIcon })}`} />
    </Tooltip>
  )
}

export default ReadReceiptPopper

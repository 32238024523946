import { Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'

const Crumb = ({ text, path }: { text: string | undefined; path?: string | undefined }) => {
  const location = useLocation()
  if (!text) return <Skeleton width={100} height={30} className="bg-[#D3D3D3]" />

  if (!path) return <Typography className="text-grey-25">{text}</Typography>

  return (
    <Link className={`text-grey-25 truncate no-underline ${clsx({ text_lg_semibold: location.pathname === path })}`} to={path}>
      {text.substring(0, 50)}{text.length > 50 ? '...' : ''}
    </Link>
  )
}

export default Crumb

import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Offer } from '.'
import { ReactNode, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { trpc } from '../../core/trpc'
import DeclineReason from './DeclineReason'
import { GetOffersRequiringInvoiceOutput, OfferGetOfferById } from '../../types/procedureOutputs'
import AcceptOffer from './AcceptOffer'
import { OfferChip } from '../../components/Chips'

type OfferActionsProps = {
  offer: Offer | OfferGetOfferById | GetOffersRequiringInvoiceOutput['results'][number]
  refetch: () => void
  pipelineId?: string
  stageId?: string
  compact?: boolean
}

const OfferActions = ({ offer, refetch, compact }: OfferActionsProps) => {
  const offerOptions = ['PENDING', 'ACCEPTED', 'APPROVED', 'DENIED'] as Offer['status'][]
  const [acceptOfferOpen, setAcceptOfferOpen] = useState(false)
  const [reasonAnchorEl, setReasonAnchorEl] = useState<HTMLElement | null>(null)
  const ref = useRef(null)

  const mutation = trpc.offer.updateOffer.useMutation({
    onSuccess: () => {
      toast.success('Offer status updated')
      refetch()
    },
    onError: () => toast.error('Offer update failed')
  })
  const handleChange = async (e: SelectChangeEvent<'ACCEPTED' | 'APPROVED' | 'DENIED' | 'PENDING' | 'NONE'>, child: ReactNode) => {
    if (e.target.value === 'PENDING') {
      return mutation.mutate({ id: offer?.id || '', status: 'PENDING', reason_for_decline: '' })
    }
    if (e.target.value === 'ACCEPTED') {
      return setAcceptOfferOpen(true)
    }
    e.target.value !== 'DENIED' && (await mutation.mutateAsync({ id: offer?.id || '', status: e.target.value as Offer['status'] }))
    if (e.target.value === 'DENIED' && offer) {
      setReasonAnchorEl(ref.current)
    }
  }

  const selectStyle = compact ? { '.MuiSelect-select': { paddingY: 0, paddingX: '4px' }, '&.MuiInputBase-root': { height: '35px' } } : undefined

  return (
    <Box>
      <Select
        slotProps={{ input: compact ? { height: '10px' } : undefined }}
        sx={selectStyle}
        ref={ref}
        value={offer?.status || 'NONE'}
        disabled={mutation.isLoading}
        onChange={handleChange}
      >
        {offerOptions.map((option) => (
          <MenuItem onClick={(e) => e.stopPropagation()} value={option} key={option}>
            <OfferChip status={option} />
          </MenuItem>
        ))}
      </Select>
      {Boolean(reasonAnchorEl) && <DeclineReason offer={offer} anchorEl={reasonAnchorEl} setAnchorEl={setReasonAnchorEl} refetch={refetch} />}
      {acceptOfferOpen && <AcceptOffer refetch={refetch} open={acceptOfferOpen} setOpen={setAcceptOfferOpen} offer={offer} />}
    </Box>
  )
}

export default OfferActions

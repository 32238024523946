import { Checkbox, CheckboxProps, createSvgIcon } from '@mui/material'

const StyledCheckbox = (props: CheckboxProps) => {
  return <Checkbox {...props} icon={<CheckboxIcon />} checkedIcon={<CheckedCheckboxIcon />} />
}

export default StyledCheckbox

export const CheckboxIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="white" />
    <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#CDD5DF" />
  </svg>,
  'CheckboxIcon'
)

export const CheckedCheckboxIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="#E3F2FD" />
    <path d="M14.6668 6.5L8.25016 12.9167L5.3335 10" stroke="#42A5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#42A5F5" />
  </svg>,
  'CheckedCheckboxIcon'
)

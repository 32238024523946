import { Box, Typography } from '@mui/material'
import { Lead } from '..'
import { Fragment } from 'react'

type OfferDetailsProps = {
  lead: Lead
}

const OfferDetails = ({ lead }: OfferDetailsProps) => {
  const offer = lead.offers[0]
  const details = [
    {
      label: 'Offer Notes',
      value: offer.notes
    },
    {
      label: 'Funder Notes',
      value: offer.funder_notes
    }
  ] as const

  return (
    <Fragment>
      {details.map((detail) => (
        <Box key={detail.label} className="grid grid-cols-2 items-center">
          <Typography className="text-grey-500">{detail.label}</Typography>
          <Typography className="text-grey-700">
            {detail.value || ''}
          </Typography>
        </Box>
      ))}
    </Fragment>
  )
}

export default OfferDetails

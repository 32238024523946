import { Box } from '@mui/system'
import { useState } from 'react'
import SettingsButtonGroup from '../components/SettingsButtonGroup'
import { Button, Typography } from '@mui/material'
import { AddCircleOutlineOutlinedIcon } from '../../../assets/icons'
import AddEditAutoStageModal from './AddEditAutoStageModal'

type props = {
  refetch: () => void
}

const Header = ({ refetch }: props) => {
  const [openAddModal, setOpenAddModal] = useState(false)
  return (
    <Box className="flex justify-between items-center mobile:flex-col mobile:gap-6">
      <SettingsButtonGroup defaultVal="auto-stage" />
      <Box className="flex gap-4">
        <Button variant="contained" onClick={() => setOpenAddModal(true)}>
          <AddCircleOutlineOutlinedIcon fontSize="small" />
          <Typography className="text_md_medium ml-2.5">New Rule</Typography>
        </Button>
      </Box>
      {openAddModal && <AddEditAutoStageModal open={openAddModal} closeModal={() => setOpenAddModal(false)} refetch={refetch} />}
    </Box>
  )
}
export default Header

import { createTRPCReact } from '@trpc/react-query'
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import { TrpcRouter } from '@libs/trpc-router'

export const trpc = createTRPCReact<TrpcRouter>()
export const standaloneClient = createTRPCProxyClient<TrpcRouter>({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_SERVER_URL || '/api'
      // You can pass any HTTP headers you wish here
    })
  ]
})

import { Account, ContactPhone, RequiredFields } from '@prisma/client'
import { sortBy } from 'remeda'
import {
  format,
  formatDistanceToNowStrict,
  formatDuration,
  intervalToDuration,
  isSameYear,
  isToday,
  isTomorrow,
  isWithinInterval,
  startOfToday,
  sub
} from 'date-fns'
import { LeadFilter } from '../views/pipelines/pipelineLeads'
import qs from 'qs'
import { DraftFilter } from '../views/shopDeals'
import { repeat } from 'ramda'
import { Decimal } from 'decimal.js'

export const getInitials = (name = ''): string =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('')

type TrucateOptions = { length?: number; omission?: string }
export const truncate = (str: string, options?: TrucateOptions) => {
  const length = options?.length || 30
  const omission = options?.omission || '...'
  return str.length > length ? `${str.substring(0, length)}${omission}` : str
}

export const tryJsonParse = (data: string) => {
  try {
    return JSON.parse(data) as unknown
  } catch (error) {
    return null
  }
}

export const getFiltersSearchParams = (filters: LeadFilter | DraftFilter) => {
  return qs.stringify(filters, { addQueryPrefix: true })
}

export const stripHtml = (html: string) => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const getAzureAccount = (accounts: Account[] | undefined) => accounts?.find((account) => account.provider === 'azure-ad')

export const capitalize = (str: string) => {
  const words = str.split(' ')
  return words
    .map((word) => {
      const first = word.charAt(0).toUpperCase()
      const rest = word.slice(1).toLowerCase()
      return `${first}${rest}`
    })
    .join(' ')
}

export const formatPhoneOnInput = (phoneNumber: string) => {
  const input = phoneNumber ? phoneNumber.trim().replace(/\D/g, '') : ''
  const size = input.length
  if (size > 6) {
    return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`
  }
  if (size > 3) {
    return `(${input.slice(0, 3)}) ${input.slice(3)}`
  }
  if (size > 0) {
    return `(${input}`
  }
  return input
}

export const getStrippedPhone = (phoneNumber: string, doNotSlice?: boolean) => {
  if (doNotSlice) {
    return phoneNumber.replace(/\D/g, '')
  } else {
    return phoneNumber.replace(/\D/g, '').slice(0, 10)
  }
}

export const getSplitPhones = (phone: string) => {
  if (phone?.length > 10) {
    const onlyNumbers = getStrippedPhone(phone, false)
    if (onlyNumbers.length % 10 === 0) {
      return handleMultiplePhones(onlyNumbers)
    }
    return [onlyNumbers]
  }

  return [phone]
}
const handleMultiplePhones = (phoneString: string) => {
  const res: string[] = []
  for (let i = 0; i < phoneString.length; i += 10) {
    res.push(phoneString.substring(i, i + 10))
  }
  return res
}

export const formatZipOnInput = (zip: string) => {
  return zip.replace(/\D/g, '').slice(0, 5)
}

export const pluralize = (str: string, num: number) => {
  const suffix = num === 1 ? '' : 's'
  return str + suffix
}

export const formatDurationShorthand = (date: Date) => {
  const { years, months, days, hours, minutes, seconds } = intervalToDuration({ start: new Date(), end: date })
  const maxDurationUnit = years ? { years } : months ? { months } : days ? { days } : hours ? { hours } : minutes ? { minutes } : { seconds }
  return (
    formatDuration(maxDurationUnit, { format: ['hours', 'minutes', 'days', 'months', 'years', 'seconds'] }).replace(/ second(s)?/, 's') ||
    '0s'
      .replace(/ minute(s)?/, 'm')
      .replace(/ hour(s)?/, 'h')
      .replace(/ days(s)?/, 'd')
      .replace(/ month(s)?/, 'mo')
      .replace(/ year(s)?/, 'y')
  )
}

export const secondsToHms = (seconds: number | string) => {
  const d = Number(seconds)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? `${h} h ` : ''
  const mDisplay = m > 0 ? `${m} m ` : ''
  const sDisplay = s > 0 ? `${s} s ` : ''
  return hDisplay + mDisplay + sDisplay
}

export const formatDistanceToNowCustom = (dateString: string | undefined) => {
  if (!dateString) return
  const now = new Date()
  const date = new Date(dateString)
  return isWithinInterval(date, { start: sub(now, { weeks: 1 }), end: now })
    ? formatDistanceToNowStrict(date, { addSuffix: true })
    : isSameYear(date, now)
    ? format(date, 'MMMM d')
    : format(date, 'MMMM d, yyyy')
}

export const formatDistanceFromNowCustom = (dateString: string | undefined) => {
  if (!dateString) return
  const date = new Date(dateString)
  if (isToday(date)) return `Today ${format(date, 'h:mm a')}`
  else if (isTomorrow(date)) return `Tomorrow ${format(date, 'h:mm a')}`
  else return format(date, 'MMM d, h:mm a')
}

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isExcelFile = (file: { type: string }) => {
  return file.type === 'application/vnd.ms-excel' || file.type.includes('application/vnd.openxmlformats-officedocument')
}

export const validateAddress = (street: string, city: string, state: string, zip: string) => {
  if (!street && !city && !state && !zip) return true
  if (!street || !city || !state || !zip) return false
  const validZip = /^\d{5}$/.test(zip)
  const validState = /^[A-Za-z]{2}$/.test(state)
  return validZip && validState
}

export const downloadBlob = (blob: Blob, name: string) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = name
  link.click()
  window.URL.revokeObjectURL(link.href)
}

export const openBlob = (blob: Blob, name: string) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = name
  window.open(window.URL.createObjectURL(blob))
  window.URL.revokeObjectURL(link.href)
}

export const getAllStates = () => {
  const statesArray = [
    { value: 'AL', name: 'Alabama' },
    { value: 'AK', name: 'Alaska' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DE', name: 'Delaware' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'ME', name: 'Maine' },
    { value: 'MD', name: 'Maryland' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MI', name: 'Michigan' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'OR', name: 'Oregon' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' },
    { value: 'VA', name: 'Virginia' },
    { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WY', name: 'Wyoming' }
  ]

  return statesArray
}

export const getPrimaryPhone = (phones: ContactPhone[]) => {
  const sorted = sortBy(phones, [(phone) => phone.thumbs_up, 'desc'])
  return sorted[0]
}

export const isOverdue = (date: Date) => new Date(date) < startOfToday()

export const formatCurrency = (amount: number | string | Decimal) => {
  const decimalAmount = new Decimal(amount || 0)
  return decimalAmount.toNumber().toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
}

export const formatDate = (val: string) => {
  return new Date(val).toLocaleDateString()
}

export const validateBuyRate = (input: string) => {
  return +input ? +input > 0 && +input <= 100 : false
}

export const stripFileNameExt = (filename: string) => {
  const index = filename.lastIndexOf('.')
  if (index > -1) return filename.slice(0, index)
  return filename
}

export const getFileNameExtension = (filename: string) => {
  const index = filename.lastIndexOf('.')
  if (index > -1) return filename.slice(index, filename.length)
  return ''
}

export const validateRequiredFieldsForStageUpdate = ({
  requiredFields,
  requiredValues
}: {
  requiredFields: RequiredFields[]
  requiredValues: { [key in RequiredFields]: string | boolean | null | number | Date | undefined }
}) => {
  return requiredFields.every((rf) => requiredValues[rf] !== undefined && requiredValues[rf] !== null)
}

export const getMostRelevantMsEmailAddress = (contact: MicrosoftPeople) => {
  return contact.scoredEmailAddresses?.sort((a, b) => b.relevanceScore - a.relevanceScore)[0].address ?? ''
}

export const calculatePercentage = (total: Decimal | string, partial: Decimal | string): Decimal => {
  const totalDecimal = new Decimal(total)
  const partialDecimal = new Decimal(partial)
  if (totalDecimal.isZero()) return new Decimal(0)

  const percentage = partialDecimal.div(totalDecimal).times(100)
  const roundedPercentage = percentage.toDecimalPlaces(2)

  return roundedPercentage
}

export const maskNumbers = (digits: string) => {
  if (!digits) return ''
  const maskedBumbers = repeat('*', digits.length - 4).join('')
  return `${maskedBumbers}${digits.slice(-4)}`
}

import { trpc } from '../../core/trpc'
import { validateEmail } from '../../utils/helpers'
import GraphPeopleAutocomplete from './GraphPeopleAutocomplete'

type EmailInput = {
  value: string
  error: boolean
}

const RenderTextfield = ({
  recipients,
  input,
  setInput,
  setRecipients,
  autoFocus = false,
  setContactLead
}: {
  recipients: MicrosoftPerson[] | undefined
  input: EmailInput
  setInput: (input: EmailInput) => void
  setRecipients: (e: MicrosoftPerson[] | undefined) => void
  autoFocus?: boolean
  setContactLead?: (leadId: string | null) => void
}) => {
  const trpcCtx = trpc.useContext()

  const onEmailSelected = async (emails: string[]) => {
    const isValidEmails = emails.every((e) => validateEmail(e))
    if (isValidEmails) {
      setRecipients(emails.map((e) => ({ emailAddress: { address: e, name: '' } })))
      setInput({ value: '', error: false })
      if (setContactLead && emails[0]) {
        const data = await trpcCtx.contact.getContactByEmailAddress.fetch({ emailAddress: emails[0] })
        if (data?.company?.leads[0]?.id) {
          setContactLead(data.company.leads[0].id)
        }
      } else if (setContactLead) {
        setContactLead(null)
      }
    } else {
      setRecipients(emails.map((e) => ({ emailAddress: { address: e, name: '' } })))
      setInput({ value: input.value, error: true })
    }
  }
  return (
    <GraphPeopleAutocomplete
      autoFocus={autoFocus}
      isValidEmails={!input.error}
      emailsSelected={recipients?.map((e) => e.emailAddress.address) || []}
      onEmailSelected={onEmailSelected}
    />
  )
}

export default RenderTextfield

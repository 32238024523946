import { StarRounded } from '@mui/icons-material'
import { Dialog, Typography, IconButton, Tooltip, Link, Box, FormControlLabel, Checkbox } from '@mui/material'
import { Stack } from '@mui/system'
import { User, Mail, Phone, Briefcase, FileText, Edit2 } from 'react-feather'
import { CloseIcon } from '../../../../assets/icons'
import { ContactGetContactByCompanyOutput } from '../../../../types/procedureOutputs'
import { trpc } from '../../../../core/trpc'
import { useState } from 'react'
import toast from 'react-hot-toast'
import PhoneRow from '../../components/PhoneRow'
import CallFeedback, { CallFeedbackContact } from '../callFeedback'
import EditContactModal from '../../../../components/editContactModal'
import { Phone_Type } from '@prisma/client'
import { useSearchParams } from 'react-router-dom'
import { pickAll } from 'ramda'
import { autoRequestInfo } from '../callFeedback/autoRequestInfo'
import { useEmailStore } from '../../../../stores/emailStore'
import { Lead } from '../index'

type ContactModalProps = {
  contact: ContactGetContactByCompanyOutput | undefined
  lead: Lead
  isPrimary: boolean
  setContact: React.Dispatch<React.SetStateAction<ContactGetContactByCompanyOutput | undefined>>
  refetch: () => void
  feedbackContact: CallFeedbackContact | undefined
  setFeedbackContact: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
  callFeedbackType: 'CALL' | 'SMS' | undefined
  setCallFeedbackType: React.Dispatch<React.SetStateAction<'SMS' | 'CALL' | undefined>>
  setCallModalType: React.Dispatch<React.SetStateAction<'SMS' | 'CALL' | undefined>>
  setNewEmailOpen: React.Dispatch<
    React.SetStateAction<
      | {
          isOpen: boolean
          requestInfo?: boolean | undefined
        }
      | undefined
    >
  >
  stageId: string
}

const ContactModal = ({
  contact,
  lead,
  isPrimary,
  setContact,
  refetch,
  feedbackContact,
  callFeedbackType,
  setFeedbackContact,
  setCallFeedbackType,
  setCallModalType,
  setNewEmailOpen,
  stageId
}: ContactModalProps) => {
  const { mailboxEmailAddress } = useEmailStore()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const setSearchParams = useSearchParams()[1]

  const initialPhone = {
    id: '',
    type: Phone_Type.CALL,
    contact_id: contact?.id ?? '',
    phone_number: '',
    thumbs_up: 0,
    thumbs_down: 0,
		is_primary_phone: false
  }

  const primaryContactMutation = trpc.company.assignPrimaryContact.useMutation({
    onSuccess: () => {
      toast.success('Primary contact updated')
      refetch()
    },
    onError: (e) => {
      toast.error(`Error: ${e.message}`)
    }
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) return

    primaryContactMutation.mutate({
      company_id: contact?.company_id || '',
      contact_id: contact?.id || ''
    })
    setContact(undefined)
  }

  return (
    <Dialog open={Boolean(contact)}>
      <Box className="p-5">
        <Box className="flex justify-between items-center mb-5">
          <Box>
            <Typography className="text-grey-900 text_lg_semibold">Contact</Typography>
            {isPrimary && (
              <Box className="border-solid border-grey-300 border-[1px] rounded-3xl flex items-center gap-2 py-0.5 px-2">
                <StarRounded fontSize="small" className="fill-warning-300" />
                <Typography className="text_xs_medium text-grey-600 tracking-widest">PRIMARY</Typography>
              </Box>
            )}
          </Box>
          <Box>
            <IconButton className="rounded-full" onClick={() => setEditModalOpen(true)}>
              <Edit2 className="text-grey-500 w-[18px] h-[18px]" />
            </IconButton>
            <IconButton onClick={() => setContact(undefined)}>
              <CloseIcon fontSize="small" className="text-grey-500" />
            </IconButton>
          </Box>
        </Box>
        <Stack gap="16px">
          <Box className="flex gap-3 items-center">
            <User className="w-4 h-4 text-grey-500" />
            <Typography className="text-grey-700">{contact?.name}</Typography>
          </Box>
          <Box className="flex gap-3 items-start">
            <Mail className="w-4 h-4 text-grey-500" />
            <Box>
              {contact?.primary_email &&
                [contact.primary_email].concat(contact.other_emails || []).map((email) => (
                  <Link key={email} href={`mailto:${email}`} className="text-grey-700 text_md_normal block hover:text-primary-600 cursor-pointer">
                    {email}
                  </Link>
                ))}
            </Box>
          </Box>
          <Box className="flex gap-3 items-center">
            <Phone className="w-4 h-4 text-grey-500" />
            <Box>
              {contact?.phones.map((phone) => (
                <PhoneRow
                  key={phone.id}
                  num={phone}
                  name={undefined}
                  type="both"
                  handleCall={(type) => {
                    setFeedbackContact({
                      contactId: contact.id,
                      email: contact.primary_email || '',
                      name: contact.name,
                      birthday: contact.birthday ? new Date(contact.birthday as string) : null,
                      phone: { id: phone.id, phoneNumber: phone.phone_number, thumbsDown: phone.thumbs_down, thumbsUp: phone.thumbs_up },
                      other_phones: contact.phones
                        ?.filter((p) => p.id !== phone.id)
                        ?.map((p) => pickAll(['id', 'phone_number', 'thumbs_down', 'thumbs_up'], p))
                    })
                    setCallFeedbackType(type)
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box className="flex gap-3 items-center">
            <Briefcase className="w-4 h-4 text-grey-500" />
            <Typography className="text-grey-700">{contact?.role}</Typography>
          </Box>
          <Box className="flex gap-3 items-center justify-between">
            <Box className="flex gap-3 items-center ">
              <FileText className="w-4 h-4 text-grey-500" />
              <Tooltip title={contact?.notes}>
                <Typography className="text-grey-700 truncate w-64">{contact?.notes}</Typography>
              </Tooltip>
            </Box>
          </Box>
          {!isPrimary && Boolean(contact) && <FormControlLabel control={<Checkbox onChange={handleChange} />} label="Set as Primary Contact" />}
        </Stack>
      </Box>
      {callFeedbackType && (
        <CallFeedback
          stageId={stageId}
          type={callFeedbackType}
          setType={setCallFeedbackType}
          feedbackContact={feedbackContact}
          setFeedbackContact={setFeedbackContact}
          handleClose={async (newAction) => {
            if (!newAction) return
            if (newAction.name === 'CALL' || newAction.name === 'SMS') {
              setCallModalType(newAction.name)
            }
            if (newAction.name === 'EMAIL') {
              setNewEmailOpen({ isOpen: true })
            }
            if (newAction.name === 'REQUEST_INFO') {
              if (newAction.requestInfo === 'OTHER') {
                setSearchParams({
                  contactName: feedbackContact?.name || '',
                  contactPhone: feedbackContact?.phone.phoneNumber || ''
                })
                setNewEmailOpen({ isOpen: true })
              } else {
                await autoRequestInfo(mailboxEmailAddress, newAction.requestInfo, {
                  contact: feedbackContact,
                  lead: lead,
                  company: lead.company
                })
              }
            }
            setContact(undefined)
          }}
        />
      )}
      {editModalOpen && (
        <EditContactModal
          closeModal={() => {
            setEditModalOpen(false)
            setContact(undefined)
          }}
          initialValues={{
            primary_email: contact?.primary_email ?? '',
            other_emails: contact?.other_emails ?? [],
            name: contact?.name ?? '',
            notes: contact?.notes ?? '',
            phones: contact?.phones && contact.phones.length > 0 ? contact?.phones : [initialPhone],
            role: contact?.role ?? '',
            id: contact?.id ?? ''
          }}
          refetch={refetch}
        />
      )}
    </Dialog>
  )
}

export default ContactModal

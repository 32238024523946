import { Box, Dialog, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { X } from 'react-feather'
import SelectLead from './SelectLead'
import SelectFiles from './SelectFiles'

type Props = {
  anchorEl: HTMLElement | undefined
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>
  leadId: string
  refetch: () => void
}

const ImportRelatedFiles = ({ anchorEl, setAnchorEl, leadId, refetch }: Props) => {
  const [selectedLead, setSelectedLead] = useState('')
  const [stage, setStage] = useState<'selectLead' | 'selectFile'>('selectLead')

  return (
    <Dialog open={Boolean(anchorEl)}>
      <Box className="p-5 w-[340px]">
        <Box className="flex justify-between items-center mb-6">
          <Typography className="text-grey-900 text_lg_semibold">Import Files</Typography>
          <IconButton onClick={() => setAnchorEl(undefined)}>
            <X className="w-4" />
          </IconButton>
        </Box>
        {stage === 'selectLead' && (
          <SelectLead setAnchorEl={setAnchorEl} setStage={setStage} leadId={leadId} setSelectedLead={setSelectedLead} selectedLead={selectedLead} />
        )}
        {stage === 'selectFile' && (
          <SelectFiles destinationLeadId={leadId} srcLeadId={selectedLead} setStage={setStage} setAnchorEl={setAnchorEl} refetch={refetch} />
        )}
      </Box>
    </Dialog>
  )
}

export default ImportRelatedFiles

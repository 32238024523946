import { Box, Button, Dialog, Divider, FormControlLabel, IconButton, SelectChangeEvent, TextField, Typography, capitalize } from '@mui/material'
import { Stack } from '@mui/system'
import { ChangeEvent, useMemo, useState } from 'react'
import { CloseIcon } from '../../../assets/icons'
import { formatPhoneOnInput, getStrippedPhone, validateEmail } from '../../../utils/helpers'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import StyledCheckbox from '../../../components/StyledCheckbox'
import { FunderContact } from '@prisma/client'

type AddContactDialogProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  refetch?: () => void
  funderId: string
  onCreateSuccess?: (contact: FunderContact) => void
}

const AddContactDialog = ({ open, setOpen, funderId, refetch, onCreateSuccess }: AddContactDialogProps) => {
  const client = useQueryClient()
  const initValues = {
    name: '',
    role: '',
    phone: '',
    email: '',
    isPrimary: false,
    autoSubmits: true
  }

  const [contactData, setContactData] = useState(initValues)

  const isValid = useMemo(() => {
    if (!contactData.name) return false
    const validEmail = !contactData.email || validateEmail(contactData.email)
    return validEmail
  }, [contactData])

  const mutation = trpc.funderContact.createFunderContact.useMutation({
    onSuccess: (data) => {
      client.refetchQueries([['funder', 'getFunderById'], { input: { id: funderId }, type: 'query' }])
      refetch && refetch()
      toast.success('Contact created successfully')
      onCreateSuccess?.(data)
      setOpen(false)
    },
    onError: (e) => {
      toast.error(e.message)
    }
  })

  const saveContact = async () => {
    mutation.mutate({ ...contactData, funder_id: funderId, phone: contactData.phone ? getStrippedPhone(contactData.phone) : undefined })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { value } = e.target
    setContactData((prev) => ({
      ...prev,
      [e.target.name]: value
    }))
  }

  return (
    <Dialog open={open}>
      <Box className="w-[610px] p-6">
        <Box className="flex justify-between items-center mb-5">
          <Typography className="text-grey-900 text_xl_medium">Add Contact</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <Stack className="mb-1" gap="24px">
          {Object.entries(contactData).map(([fieldName, fieldValue]) => {
            if (fieldName === 'isPrimary') {
              return (
                <FormControlLabel
                  key={fieldName}
                  className="text-grey-700"
                  label="Primary Contact"
                  control={
                    <StyledCheckbox
                      checked={contactData.isPrimary}
                      onChange={(e) => setContactData({ ...contactData, isPrimary: e.target.checked })}
                    />
                  }
                />
              )
            }
            if (fieldName === 'autoSubmits') {
              return (
                <FormControlLabel
                  key={fieldName}
                  className="text-grey-700"
                  label="Auto Submits"
                  control={
                    <StyledCheckbox
                      checked={contactData.autoSubmits}
                      onChange={(e) => setContactData({ ...contactData, autoSubmits: e.target.checked })}
                    />
                  }
                />
              )
            }
            const value = fieldName === 'phone' ? formatPhoneOnInput(String(fieldValue)) : fieldValue
            return (
              <Box key={fieldName}>
                <Typography className="text-grey-700 mb-1.5">{capitalize(fieldName)}</Typography>
                <TextField name={fieldName} value={value} onChange={handleChange} fullWidth variant="outlined" />
              </Box>
            )
          })}
        </Stack>
        <Divider className="mb-6 mt-4" />
        <Box className="flex justify-end gap-3" pr={3}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false)
              setContactData(initValues)
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" disabled={!isValid || mutation.isLoading} onClick={saveContact}>
            Add Contact
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddContactDialog

import { Autocomplete, TextField } from '@mui/material'
import { useMemo, useState } from 'react'
import { getMsUserContacts } from '../../services/azureServices'
import { useEmailStore } from '../../stores/emailStore'
import debounce from 'lodash.debounce'

type GraphContactPopperProps = {
  emailsSelected: string[]
  onEmailSelected: (address: string[]) => void
  isValidEmails: boolean
  autoFocus: boolean
}

const GraphPeopleAutocomplete = ({ onEmailSelected, emailsSelected, isValidEmails, autoFocus }: GraphContactPopperProps) => {
  const [contacts, setContacts] = useState<MicrosoftPeople[]>([])
  const { mailboxEmailAddress } = useEmailStore()

  const debounceSearch = useMemo(() => debounce((s) => getMsUserContacts(s, mailboxEmailAddress).then(r => setContacts(r.value)), 500), [])

  const getAutocompleteEmail = (o: string | MicrosoftPeople) => {
    return typeof o ==='string' ? o?.trim() : o?.scoredEmailAddresses?.[0].address?.trim()
  }

  return (
    <Autocomplete
      autoFocus={autoFocus}
      fullWidth
      className='w-full'
      disableClearable
      placeholder="Search People"
      freeSolo
      options={contacts || []}
      onInputChange={(_, newInputValue) => debounceSearch(newInputValue)}
      onChange={(_e, v) => {
        if (v) {
          onEmailSelected(v?.map(e => getAutocompleteEmail(e)))
          debounceSearch('')
        }
      }}
      noOptionsText="No People"
      filterOptions={(x) => x}
      slotProps={{
        popper: {
          sx: {
            zIndex: 10000
          }
        }
      }}
      multiple
      getOptionLabel={(option: string | MicrosoftPeople) => getAutocompleteEmail(option) || ''}
      isOptionEqualToValue={(option: string | MicrosoftPeople, value: string | MicrosoftPeople) => getAutocompleteEmail(option) === getAutocompleteEmail(value)}
      value={emailsSelected || null}
      renderInput={(params) => <TextField
        FormHelperTextProps={{ className: 'text-warning-700' }}
        error={isValidEmails}
        helperText={isValidEmails ? '': 'Please remove the invalid email addresses'}
        variant='standard'
        {...params}
        InputProps={{  ...params.InputProps, disableUnderline: true }}
      />}
    />
  )
}

export default GraphPeopleAutocomplete

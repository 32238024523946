import { Button, Dialog, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CloseIcon } from '../../../assets/icons'
import { useState } from 'react'
import { LeadRequiredColumnValues, PipelineStagesOutput } from '../../../types/procedureOutputs'
import { capitalize } from '../../../utils'
import { trpc } from '../../../core/trpc'
import { useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { RequiredFields, TermLength } from '@prisma/client'
import { DatePicker } from '@mui/x-date-pickers'
import { omit } from 'remeda'
import { Decimal } from 'decimal.js'

type props = {
  closeModal: () => void
  stage: PipelineStagesOutput[0]['stages'][0] | undefined
  columnValues: LeadRequiredColumnValues
}

const ValidateModal = ({ closeModal, stage, columnValues }: props) => {
  const queryClient = useQueryClient()
  const updateRequiredFieldsMutation = trpc.lead.updateLeadRequiredFields.useMutation({
    onSuccess: () => {
      queryClient.refetchQueries([['offer', 'getOffers'], { input: { leadId: columnValues.leadId, search: '', skip: 0, take: 10 }, type: 'query' }])
      toast.success('Lead successfully updated')
    },
    onError: () => toast.error('Lead update failed')
  })

  const client = useQueryClient()
  const [mapper, setMapper] = useState<LeadRequiredColumnValues['mapper']>(columnValues.mapper)

  const mutation = trpc.lead.updateLead.useMutation({
    onSuccess: () => {
      client.refetchQueries([['lead', 'getLeadById'], { input: { id: columnValues.leadId }, type: 'query' }])
      client.refetchQueries([
        ['activity', 'getActivitiesByLeadId'],
        { input: { activityTypes: [], leadId: columnValues.leadId, skip: 0, take: 10 }, type: 'query' }
      ])
      toast.success('Successfully updated stage')
      closeModal()
    },
    onError: (e) => {
      toast.error(e?.message ?? 'Stage update failed')
    }
  })

  const handleSubmitValue = async () => {
    if (mapper !== columnValues.mapper) {
      await updateRequiredFieldsMutation.mutateAsync({
        leadId: columnValues.leadId,
        declinedOfferId: columnValues.deniedOfferId,
        offerId: columnValues.offerId,
        mapper: omit(
          {
            ...mapper,
            DATE_FUNDED: mapper['DATE_FUNDED'] ? new Date(mapper['DATE_FUNDED']) : undefined,
            FOLLOW_UP_DATE: mapper['FOLLOW_UP_DATE'] ? new Date(mapper['FOLLOW_UP_DATE']) : undefined,
            BUY_RATE: mapper['BUY_RATE'] ? new Decimal(mapper['BUY_RATE']) : undefined,
            COMMISSION_POINTS: mapper['COMMISSION_POINTS'] ? new Decimal(mapper['COMMISSION_POINTS']) : undefined,
            FUNDED_AMOUNT: mapper['FUNDED_AMOUNT'] ? new Decimal(mapper['FUNDED_AMOUNT']) : undefined,
            SERVICE_FEE: mapper['SERVICE_FEE'] ? new Decimal(mapper['SERVICE_FEE']) : undefined,
            FINAL_PURCHASE_PRICE: mapper['FINAL_PURCHASE_PRICE'] ? new Decimal(mapper['FINAL_PURCHASE_PRICE']) : undefined
          },
          ['FUNDER_NAME', 'OFFER_STATUS_APPROVED', 'CONTRACT_FILE']
        )
      })
    }
    mutation.mutate({ id: columnValues.leadId || '', stage_id: stage?.id })
  }

  const renderCorrectInput = (rf: RequiredFields) => {
    const numberFields: RequiredFields[] = ['TERM_AMOUNT']
    const decimalFields: RequiredFields[] = ['COMMISSION_POINTS', 'BUY_RATE', 'FUNDED_AMOUNT', 'SERVICE_FEE', 'FINAL_PURCHASE_PRICE']

    if (rf === 'DATE_FUNDED' || rf === 'FOLLOW_UP_DATE') {
      return (
        <DatePicker
          slotProps={{ textField: { fullWidth: true, error: false } }}
          value={new Date(mapper[rf] ?? '') ?? null}
          onChange={(value: Date | null) => setMapper({ ...mapper, [rf]: value as Date })}
        />
      )
    } else if (rf === 'LEAD_TYPE') {
      return (
        <Select
          error={mapper[rf] === null || mapper[rf] === undefined}
          fullWidth
          value={mapper['LEAD_TYPE'] || ''}
          onChange={(e) => setMapper({ ...mapper, LEAD_TYPE: e.target.value })}
        >
          {['New deal', 'Add on', 'Refinance', 'Reverse', 'Buy out'].map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      )
    } else if (rf === 'TERM_LENGTH') {
      return (
        <Select
          error={mapper[rf] === null || mapper[rf] === undefined}
          fullWidth
          value={mapper['TERM_LENGTH']}
          onChange={(e) => setMapper({ ...mapper, TERM_LENGTH: e.target.value as TermLength })}
        >
          {Object.values(TermLength).map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      )
    } else if (numberFields.includes(rf)) {
      return (
        <TextField
          className="mt-1"
          fullWidth
          error={mapper[rf] === null || mapper[rf] === undefined}
          value={mapper[rf]}
          type={'number'}
          onChange={(e) => setMapper({ ...mapper, [rf]: +e.target.value })}
        />
      )
    } else if (decimalFields.includes(rf)) {
      return (
        <TextField
          className="mt-1"
          fullWidth
          error={mapper[rf] === null || mapper[rf] === undefined}
          value={mapper[rf]}
          type={'number'}
          onChange={(e) => setMapper({ ...mapper, [rf]: e.target.value })}
        />
      )
    }

    return (
      <TextField
        className="mt-1"
        fullWidth
        error={mapper[rf] === null || mapper[rf] === undefined}
        value={mapper[rf]}
        onChange={(e) => setMapper({ ...mapper, [rf]: e.target.value })}
      />
    )
  }

  if (!stage) {
    return <></>
  }

  const funderColumns: RequiredFields[] = [
    'COMMISSION_POINTS',
    'DATE_FUNDED',
    'BUY_RATE',
    'FUNDED_AMOUNT',
    'FUNDER_NAME',
    'OFFER_STATUS_APPROVED',
    'TERM_AMOUNT',
    'TERM_LENGTH',
    'SERVICE_FEE',
    'FINAL_PURCHASE_PRICE',
    'CONTRACT_FILE'
  ]

  const missingOffer = stage.required_fields.some((r) => {
    return (
      (r === 'OFFER_STATUS_APPROVED' && mapper['OFFER_STATUS_APPROVED'] === false) ||
      (r === 'FUNDER_NAME' && mapper['FUNDER_NAME'] === undefined) ||
      (!columnValues.offerId && funderColumns.includes(r)) ||
      (!columnValues.deniedOfferId && r === 'OFFER_REASON_FOR_DECLINE') ||
      (r === 'OFFER_STATUS_ACCEPTED' && mapper['OFFER_STATUS_ACCEPTED'] === false)
    )
  })

  const isMissingContractFile = stage.required_fields.includes('CONTRACT_FILE') && !mapper.CONTRACT_FILE

  const accepted = stage.required_fields.some((r) => r === 'OFFER_STATUS_ACCEPTED')
  return (
    <Dialog open={true}>
      <Box className="p-5 min-w-[540px]">
        <Box className="flex justify-between items-start mb-5">
          <Typography className="text-grey-900 text_lg_semibold">Confirm Required Information</Typography>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon fontSize="small" className="text-grey-500" />
          </IconButton>
        </Box>
        <Box className="flex gap-4 items-center mb-6">
          <Typography className="text-grey-900 text_lg_semibold">Stage</Typography>
          <Box
            key={stage.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: stage.color,
              borderRadius: '200px',
              py: '6px',
              px: '12px'
            }}
          >
            <Typography className="text_sm_medium text-[black]">{stage.name}</Typography>
          </Box>
        </Box>
        {isMissingContractFile && (
          <Typography className="text-error-500">Missing contract file. Please mark a file as contract and try again</Typography>
        )}
        {missingOffer ? (
          <Typography className="text-error-500">
            There are no {accepted ? 'accepted' : 'approved'} offers yet, please {accepted ? 'accept' : 'approve'} one and then try to change the
            stage again
          </Typography>
        ) : (
          stage.required_fields
            .filter((rf) => rf !== 'OFFER_STATUS_APPROVED' && rf !== 'FUNDER_NAME' && rf !== 'OFFER_STATUS_ACCEPTED' && rf !== 'CONTRACT_FILE')
            .map((rf) => (
              <Box key={rf} className="mb-4">
                <Typography> {capitalize(rf).replaceAll('_', ' ')}</Typography>
                {renderCorrectInput(rf)}
                {mapper[rf] === null || mapper[rf] === undefined ? (
                  <Typography className="text-error-500">Required to update stage</Typography>
                ) : undefined}
              </Box>
            ))
        )}
        <Box className="flex justify-end gap-3">
          <Button
            variant="outlined"
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSubmitValue()}
            disabled={stage.required_fields.some((rf) => mapper[rf] === undefined || mapper[rf] === null)}
          >
            Update Stage
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
export default ValidateModal

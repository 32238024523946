import { Dialog, Box, Typography, IconButton, TextField, Button, FormControlLabel, Checkbox } from '@mui/material'
import { CloseIcon } from '../../../assets/icons'
import { useState } from 'react'
import { trpc } from '../../../core/trpc'
import { formatPhoneOnInput, getStrippedPhone } from '../../../../../web-client/src/utils'

type ModalProps = {
  closeModal: () => void
  initialValues: ContactToEdit
  refetch: () => void
}

type ContactToEdit = {
  email: string | undefined
  name: string
  phone: string | undefined
  notes: string | undefined
  title: string | undefined
  funder_id: string | undefined
  id: string
  autoSubmit: boolean | undefined
}
const EditContactModal = ({ closeModal, initialValues, refetch }: ModalProps) => {
  const [editContact, setEditContact] = useState<ContactToEdit>(initialValues)

  const editContactMutation = trpc.funderContact.updateFunderContact.useMutation()

  const handleTextFieldChange = (fieldName: string, value: string) => {
    setEditContact({ ...editContact, [fieldName]: value })
  }

  const onSave = async () => {
    await editContactMutation.mutateAsync({ ...editContact })
    refetch()
    closeModal()
  }

  return (
    <Dialog open={true}>
      <Box className="p-5 min-w-[340px]">
        <Box className="flex justify-between items-start mb-5">
          <Typography className="text-grey-900 text_lg_semibold">Edit Contact</Typography>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon fontSize="small" className="text-grey-500" />
          </IconButton>
        </Box>
        <Box>
          <Typography className="text-grey-700 mb-1.5">Name</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="name"
            value={editContact.name}
            onChange={(e) => handleTextFieldChange('name', e.target.value)}
          />
          <Typography className="text-grey-700 mb-1.5">Email</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="email"
            value={editContact.email}
            onChange={(e) => handleTextFieldChange('email', e.target.value)}
          />
          <Typography className="text-grey-700 mb-1.5">Phone</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="phone"
            value={formatPhoneOnInput(editContact.phone || '')}
            onChange={(e) => handleTextFieldChange('phone', getStrippedPhone(e.target.value))}
          />

          <Typography className="text-grey-700 mb-1.5">Title</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="role"
            value={editContact.title}
            onChange={(e) => handleTextFieldChange('title', e.target.value)}
          />
          <Typography className="text-grey-700 mb-1.5">Notes</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="note"
            value={editContact.notes}
            onChange={(e) => handleTextFieldChange('notes', e.target.value)}
          />
          {
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setEditContact({ ...editContact, autoSubmit: e.target.checked })
                  }}
                  checked={editContact.autoSubmit}
                />
              }
              label="Auto Submission"
            />
          }
        </Box>
        <Box className="flex justify-end gap-3">
          <Button
            variant="outlined"
            onClick={() => {
              closeModal()
              setEditContact(initialValues)
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => onSave()}>
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
export default EditContactModal

import { Avatar } from '@mui/material'
import { getInitials } from '../utils/helpers'
import { getAvatarColors } from '../utils/avatarColors'

type ContactAvatarProps = {
  className?: string
  name: string
}

const ContactAvatar = ({ className, name }: ContactAvatarProps) => {
  return (
    <Avatar className={className} sx={{ color: getAvatarColors(name).color, backgroundColor: getAvatarColors(name).backgroundColor }}>
      {getInitials(name)}
    </Avatar>
  )
}

export default ContactAvatar

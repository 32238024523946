import { Popper, Paper, Box, IconButton, Typography, TextField, Stack } from '@mui/material'
import { Lead } from '../leadDetails'
import { CloseIcon } from '../../../assets/icons'
import { Search } from '../../../icons/Search'
import { trpc } from '../../../core/trpc'
import { useState } from 'react'
import clsx from 'clsx'
import { PipelineStagesOutput } from '../../../types/procedureOutputs'

type UpdateStagePopperProps = {
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  anchorEl: HTMLElement | null
  lead: Lead
  setShowValidateModal: (stage: PipelineStagesOutput[0]['stages'][0] | undefined) => void
}

const UpdateStagePopper = ({ anchorEl, setAnchorEl, lead, setShowValidateModal }: UpdateStagePopperProps) => {
  const [selectedStage, setSelectedStage] = useState(lead?.stage_id)
  const [searchVal, setSearchVal] = useState('')
  const { data } = trpc.stage.getStagesGroupedByPipeline.useQuery({ pipelineId: lead.stage.pipeline_id })

  const filteredStages = data?.[0]?.stages?.filter((stage) => stage.name.toLowerCase().includes(searchVal.toLowerCase() || ''))

  const handleStageClick = async (stage: { id: string }) => {
    const selectedStageId = stage?.id || ''
    setSelectedStage(selectedStageId)
    setShowValidateModal(filteredStages?.find((s) => s.id === selectedStageId))
    setAnchorEl(null)
  }
  return (
    <Popper sx={{ zIndex: 1500 }} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
      <Paper className="w-[340px] p-5 shadow-md mt-1">
        <Box className="flex justify-between items-center mb-6">
          <Typography className="text_lg_semibold text-grey-900">Update Lead Stage</Typography>
          <IconButton onClick={() => setAnchorEl(null)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <TextField
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
          className="mb-6"
          InputProps={{ startAdornment: <Search className="text-grey-600" /> }}
          placeholder="Search Stage"
          fullWidth
        />
        <Stack gap="16px">
          {filteredStages?.map((stage) => (
            <Box
              data-cy="stageContainer"
              key={stage.id}
              onClick={() => handleStageClick(stage)}
              className={`cursor-pointer ${clsx({
                'border-2 border-grey-400 border-solid rounded-[200px] bg-grey-100 p-2': selectedStage === stage.id
              })}`}
            >
              <Box
                key={stage.id}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: stage.color, borderRadius: '200px', py: '6px' }}
              >
                <Typography className="text_sm_medium text-[black]">{stage.name}</Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Paper>
    </Popper>
  )
}

export default UpdateStagePopper

import { TextField, Typography } from '@mui/material'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { trpc } from '../../core/trpc'
import { Task } from '../../types/procedureOutputs'

type TaskNameProps = {
  editMode: boolean
  task: Task
  setEditMode: React.Dispatch<React.SetStateAction<Task | null>>
}

const TaskName = ({ editMode, task, setEditMode }: TaskNameProps) => {
  const [value, setValue] = useState(task.title)
  const updateMutation = trpc.task.updateTask.useMutation({
    onError: () => toast.error('task update failed')
  })

  const handleBlur = () => {
    setEditMode(null)
    updateMutation.mutate({ id: task.id, title: value.toString() })
  }

  if (!editMode) {
    return (
      <Typography onClick={() => setEditMode(task)} className={'mt-[9px] cursor-pointer text-grey-700'}>
        {value}
      </Typography>
    )
  }

  return <TextField value={value} onChange={(e) => setValue(e.target.value)} autoFocus variant="standard" onBlur={handleBlur} />
}

export default TaskName

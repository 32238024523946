import { Box, Divider, FormControlLabel, MenuItem, Radio, TextField, Typography } from '@mui/material'
import { InterestedFieldsType } from '.'
import { TermLength } from '@prisma/client'
import { capitalize } from '../../../../../utils/helpers'
import IndustryAutocomplete from '../../../../../components/IndustryAutocomplete'
import Review from './Review'
import { CallFeedbackContact, FollowupSchema } from '..'
import { UseFormReturn } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import { trpc } from '../../../../../core/trpc'
import { useNavigate, useParams } from 'react-router'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import LeadActivity from '../../activity'

type InterestedFieldsProps = {
  interestedFields: InterestedFieldsType
  setInterestedFields: React.Dispatch<React.SetStateAction<InterestedFieldsType>>
  handleClose: () => void
  form: UseFormReturn<FollowupSchema>
  createTaskAndActivity: (data: FollowupSchema, callNotes?: string) => void
  contactInfo: CallFeedbackContact | undefined
  setContactInfo: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
  nextId?: string
  stageId?: string
  leadId: string
  relatedLeads?: boolean
  subsidiaryId: string | undefined
  stageAllowReassignment: boolean
}

const InterestedFields = ({
  interestedFields,
  setInterestedFields,
  handleClose,
  contactInfo,
  setContactInfo,
  form,
  createTaskAndActivity,
  nextId,
  stageId,
  leadId,
  relatedLeads,
  subsidiaryId,
  stageAllowReassignment
}: InterestedFieldsProps) => {
  const [error, setError] = useState(false)
  const [reassign, setReassign] = useState(false)
  const termLengths = Object.values(TermLength)
  const [initialPhone] = useState(contactInfo?.phone.phoneNumber)
  const { pipelineId } = useParams()
  const { getValues } = form
  const client = useQueryClient()

  const handleChange = <T extends keyof InterestedFieldsType>(field: T, value: InterestedFieldsType[T]['value']) => {
    setInterestedFields((fields) => ({ ...fields, [field]: { value } }))
  }

  const updateLeadMutation = trpc.lead.updateLead.useMutation({
    onSuccess: () => {
      toast.success('Lead successfully updated')
      client.refetchQueries([['lead', 'getLeadById'], { input: { id: leadId }, type: 'query' }])
      client.refetchQueries([['lead', 'getLeadsByPipeline'], { input: { lead_id: leadId }, type: 'query' }])
    },
    onError: () => toast.error('Lead update failed')
  })

  const contactUpdateMutation = trpc.contact.update.useMutation({
    onSuccess: (contact) => {
      toast.success('Contact successfully updated')
      client.refetchQueries([['contact', 'getContactsByCompanyId'], { input: { companyId: contact.contactResponse.company_id }, type: 'query' }])
      handleClose()
    },
    onError: () => toast.error('Contact update failed')
  })

  const thumbsUpMutation = trpc.contactPhone.thumbsUp.useMutation()
  const editContactPhoneMutation = trpc.contactPhone.updatePhone.useMutation()

  const navigate = useNavigate()
  const reassignLead = trpc.lead.moveLeadToReassignmentPipeline.useMutation({
    onSuccess: () => {
      toast.success('Lead moved to Reassignment Pipeline')
      client.refetchQueries([['stage', 'getStagesGroupedByPipeline'], { input: {}, type: 'query' }])
      client.refetchQueries([['lead', 'getLeadById'], { input: { id: leadId }, type: 'query' }])
      if (nextId) {
        navigate(`/pipeline/${pipelineId}/lead/${nextId}`)
      } else {
        navigate(`/subsidiary/${subsidiaryId}/${pipelineId}/leads/view/default?stage_id=${stageId}`)
      }
    },
    onError: (e) => toast.error(String(e))
  })

  const hasError = () => {
    const addFollowup = getValues('addFollowup')
    const followupDateVal = getValues('followupDateVal')
    const followupTimeVal = getValues('followupTimeVal')
    return (!reassign && !addFollowup) || (addFollowup && (!followupDateVal || !followupTimeVal))
  }

  const handleSave = async (data: FollowupSchema) => {
    if (hasError()) {
      return setError(true)
    }
    setError(false)
    updateLeadMutation
      .mutateAsync({
        id: leadId || '',
        avg_revenue: Number(interestedFields.averageRevenue.value) || undefined,
        merchant_request_amount: Number(interestedFields.requestedAmount.value) || undefined,
        positions: Number(interestedFields.positions.value) || undefined,
        payment_history: interestedFields.paymentHistory.value,
        merchant_credit: Number(interestedFields.credit.value) || undefined,
        term_amount: Number(interestedFields.termAmount.value) || undefined,
        term_length: interestedFields.termLength.value,
        industry_title: interestedFields.industry.value
      })
      .then(() => createTaskAndActivity(data, interestedFields.callNotes.value))
      .then(() => {
        if (contactInfo) {
          thumbsUpMutation.mutate({ contactPhoneId: contactInfo?.phone.id })
          contactUpdateMutation.mutate({
            id: contactInfo.contactId,
            name: contactInfo.name,
            primary_email: contactInfo.email
          })
          if (contactInfo.phone.phoneNumber !== initialPhone) {
            editContactPhoneMutation.mutate({ phoneNumber: contactInfo.phone.phoneNumber, phoneId: contactInfo.phone.id })
          }
        }
        if (reassign) {
          reassignLead.mutate({ leadId: leadId || '' })
        }
      })
  }

  return (
    <form onSubmit={form.handleSubmit(handleSave)}>
      <Box className="mt-4 grid grid-cols-[minmax(0,_1fr)_5px_minmax(0,_1fr)] gap-6 mobile:grid-cols-1">
        <Box className="flex flex-col gap-4 mt-8">
          <Box>
            <Typography className="text-grey-700 mb-1.5">Industry</Typography>
            <IndustryAutocomplete fullWidth onChange={(newVal) => handleChange('industry', newVal || '')} value={interestedFields.industry.value} />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">How much is the merchant looking for?</Typography>
            <TextField
              fullWidth
              type="number"
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                }
              }}
              value={interestedFields.requestedAmount.value}
              onChange={(e) => handleChange('requestedAmount', e.target.value)}
              InputProps={{ startAdornment: <Typography className="mr-0.5 mt-[2px]">$</Typography> }}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">How many positions does he have?</Typography>
            <TextField
              fullWidth
              type="number"
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                }
              }}
              value={interestedFields.positions.value}
              onChange={(e) => {
                handleChange('positions', e.target.value)
                if (e.target.value) {
                  handleChange('paymentHistory', true)
                }
              }}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700">Does he have a payment history?</Typography>
            <Box>
              <FormControlLabel
                label="Yes"
                control={<Radio onChange={() => handleChange('paymentHistory', true)} checked={interestedFields.paymentHistory.value} />}
              />
              <FormControlLabel
                label="No"
                control={<Radio onChange={() => handleChange('paymentHistory', false)} checked={interestedFields.paymentHistory.value === false} />}
              />
            </Box>
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">What is the merchant credit?</Typography>
            <TextField
              fullWidth
              type="number"
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                }
              }}
              value={interestedFields.credit.value}
              onChange={(e) => handleChange('credit', e.target.value)}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">What is the merchant average revenue?</Typography>
            <TextField
              fullWidth
              InputProps={{ startAdornment: <Typography className="mr-0.5 mt-[2px]">$</Typography> }}
              type="number"
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                }
              }}
              value={interestedFields.averageRevenue.value}
              onChange={(e) => handleChange('averageRevenue', e.target.value)}
            />
          </Box>
          <Box>
            <Typography className="text_grey-700 mb-1.5">Request Term Length</Typography>
            <Box className="grid grid-cols-2 gap-3">
              <TextField
                type="number"
                placeholder="Enter an amount"
                value={interestedFields.termAmount.value}
                onChange={(e) => handleChange('termAmount', e.target.value)}
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none'
                  }
                }}
              />
              <TextField
                value={interestedFields.termLength.value}
                fullWidth
                select
                onChange={(e) => handleChange('termLength', e.target.value as TermLength)}
              >
                {termLengths.map((length) => (
                  <MenuItem key={length} value={length}>
                    {capitalize(length)}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
        <Divider orientation="vertical" />
        <Review
          relatedLeads={relatedLeads}
          leadId={leadId}
          contact={contactInfo}
          interestedFields={interestedFields}
          setInterestedFields={setInterestedFields}
          setContactInfo={setContactInfo}
          form={form}
          reassign={reassign}
          setReassign={setReassign}
          stageAllowReassignment={stageAllowReassignment}
        />
      </Box>
      <LeadActivity collapsible leadId={leadId || ''} />
      <Divider className="my-6" />

      <Box className="flex items-center justify-between gap-3">
				<Typography className="text_xs_medium text-error-600 mb-1">
					{error && "Followup and Industry fields are required. Or choose Lead Reassignment."}
				</Typography>
        <LoadingButton
          className={clsx({ 'border-error-600': error })}
          type="submit"
          loading={contactUpdateMutation.isLoading || updateLeadMutation.isLoading}
          variant="contained"
        >
          Save
        </LoadingButton>
      </Box>
    </form>
  )
}

export default InterestedFields

import { CssBaseline, ThemeProvider } from '@mui/material'
import { wrapUseRoutes } from '@sentry/react'
import { useRoutes } from 'react-router'
import { createCustomTheme } from './theme'
import useSettings from './hooks/useSettings'
import routes from './routes'
import useScrollReset from './hooks/useScrollReset'
import { trpc } from './core/trpc'
import { AbilityContext } from './contexts/AbilityContext'
import { defineAbility } from '@casl/ability'
import { ConfirmProvider } from 'material-ui-confirm'
import { useSession } from 'next-auth/react'
import { clarity } from 'react-microsoft-clarity'
import { useEffect } from 'react'

const useSentryRoutes = wrapUseRoutes(useRoutes)

export function AppInner() {
  const { settings } = useSettings()
  const content = useSentryRoutes(routes)
  const session = useSession()
  const { data: rules } = trpc.auth.getCastlRules.useQuery()

  useScrollReset()

  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes
  })

  const ability = defineAbility((can) => {
    rules?.permissions?.map((r) => {
      can(r?.actions, r?.subject)
    })
    rules?.actions.map((r) => {
      r.subject.map((s) => can(r.actions, s))
    })
  })

  useEffect(() => {
    clarity.init('iu536gzld9')
  }, [])

  useEffect(() => {
    if (session?.data?.user.email && session?.data?.user?.name) {
      clarity.setTag('email', session?.data?.user.email)
      clarity.setTag('name', session?.data?.user?.name || '')
    }
  }, [session?.data?.user?.email])

  return (
    <AbilityContext.Provider value={ability}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <CssBaseline />
          {content}
        </ConfirmProvider>
      </ThemeProvider>
    </AbilityContext.Provider>
  )
}

import {
  Box,
  Divider,
  Drawer,
  Tooltip,
  Typography,
  Avatar,
  Chip,
  Skeleton,
  IconButton,
  Modal,
  ClickAwayListener,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { getInitials } from '../../../../utils/helpers'
import DOMPurify from 'dompurify'
import File from '../../../../icons/File'
import { trpc } from '../../../../core/trpc'
import { EmailById } from '../../../../types/procedureOutputs'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import AssociateOffer from '../../../../components/email/AssociateOffer'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import EmailDrawerReply from './EmailDrawerReply'
import ReplyAllIcon from '@mui/icons-material/ReplyAll'
import ReplyIcon from '@mui/icons-material/Reply'
import ShortcutIcon from '@mui/icons-material/Shortcut'
import clsx from 'clsx'
import { getMsEmailAttachments, getMyEmailByInternetMessageId } from '../../../../services/azureServices'
import { WarningAmberOutlinedIcon } from '../../../../assets/icons'
import { MicrosoftAttachment } from '@libs/azure-graph'
import { useEmailStore } from '../../../../stores/emailStore'
import { getContentWithImages } from '../../../../utils/email'
import { useSession } from 'next-auth/react'

type EmailDrawerProps = {
  emailId: string | undefined
  setEmailId: React.Dispatch<React.SetStateAction<string | undefined>>
  maxZindex?: boolean
  refetch?: ({ concat, activityType }: { concat: boolean; activityType: string | undefined }) => void
}

const EmailDrawer = ({ emailId, setEmailId, maxZindex, refetch: refetchActivities }: EmailDrawerProps) => {
  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });
  
  const navigate = useNavigate()
  const user = useSession().data?.user
  const { leadId } = useParams()
  const { mailboxEmailAddress: currentMailboxAddress } = useEmailStore()
  const [emailBody, setEmailBody] = useState('')
  const [originalAttachments, setOriginalAttachments] = useState<MicrosoftAttachment[]>()
  const [inlineAttachments, setInlineAttachments] = useState<MicrosoftAttachment[]>()
  const [sendType, setSendType] = useState<'forward' | 'reply' | 'replyAll'>()
  const [attachmentsLoading, setAttachmentsLoading] = useState(false)
  const [contentLoading, setContentLoading] = useState(false)
  const [preview, setPreview] = useState('')
  const { data } = trpc.emails.getEmailById.useQuery({ emailId: emailId || '' }, { enabled: Boolean(emailId) })
  const { data: lead, refetch } = trpc.lead.getLeadById.useQuery({ id: leadId || '', includeOffers: true }, { enabled: Boolean(leadId) })
  const viewFileMutation = trpc.emails.getEmailContentDownloadLink.useMutation()
  const client = useQueryClient()
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.down(800))
  const [thisEmailIdInMyMessages, setThisEmailIdInMyMessages] = useState<string | null | undefined>(undefined)
  const [emailIdInDifferentMailbox, setEmailIdInDifferentMailbox] = useState(false)
  const [sharedMailboxAddress, setSharedMailboxAddress] = useState(currentMailboxAddress)

  useEffect(() => {
    const setMessageId = async () => {
      if (data?.internet_message_id) {
        getMyEmailByInternetMessageId(data?.internet_message_id, currentMailboxAddress).then((emails) => {
          if (emails?.data.value.length) {
            setThisEmailIdInMyMessages(emails?.data?.value?.[0]?.id)
          } else {
            const allAddresses = user?.email ? user?.shared_mailboxes.concat(user.email) : user?.shared_mailboxes
            const otherEmailAddresses = allAddresses?.filter((address) => address !== currentMailboxAddress)
            if (otherEmailAddresses?.length) {
              otherEmailAddresses.forEach((address) => {
                if (data.internet_message_id) {
                  getMyEmailByInternetMessageId(data.internet_message_id, address).then((emails) => {
                    if (emails?.data.value.length) {
                      setThisEmailIdInMyMessages(emails?.data?.value?.[0]?.id)
                      setEmailIdInDifferentMailbox(true)
                      setSharedMailboxAddress(address)
                    }
                  })
                }
              })
            }
          }
        })
      }
    }
    setMessageId()
  }, [data?.internet_message_id, user, currentMailboxAddress])

  const handlePreviewFile = (attachment: MicrosoftAttachment) => {
    setPreview(`data:${attachment.contentType};base64,${attachment.contentBytes}`)
  }

  const associateOffer = trpc.offer.associateOfferToEmail.useMutation({
    onSuccess: () => {
      refetch()
      client.refetchQueries([['offer', 'getOffers'], { input: { leadId, skip: 0, take: 20 }, type: 'query' }])
    },
    onError: (e) => {
      toast.error(String(e))
    }
  })
  const unassociateOffer = trpc.offer.unassociateOfferToEmail.useMutation({
    onSuccess: () => {
      refetch()
      client.refetchQueries([['offer', 'getOffers'], { input: { leadId, skip: 0, take: 20 }, type: 'query' }])
    },
    onError: (e) => {
      toast.error(String(e))
    }
  })

  useEffect(() => {
    ;(async () => {
      if (emailId) {
        setContentLoading(true)
        const res = await viewFileMutation.mutateAsync({ emailId })
        if (res) {
          const content = await axios.get(res)
          setEmailBody(content.data)
        }
        setContentLoading(false)
      }
    })()
  }, [emailId])

  useEffect(() => {
    ;(async () => {
      if (thisEmailIdInMyMessages) {
        setAttachmentsLoading(true)
        const res = await getMsEmailAttachments({ includeInline: true, emailId: thisEmailIdInMyMessages, mailboxEmailAddress: sharedMailboxAddress })
        setAttachmentsLoading(false)
        if (res.data) {
          setOriginalAttachments(res.data.value?.filter((a) => !a.isInline))
          setInlineAttachments(res.data.value?.filter((a) => a.isInline))
        }
      }
    })()
  }, [thisEmailIdInMyMessages])

  const renderChips = (email: EmailById | undefined, options: 'to_addresses' | 'bcc_recipients' | 'cc_recipients') => {
    return email?.[options]?.map((recipient) => (
      <Tooltip
        key={(recipient as { emailAddress: { address: string } })?.emailAddress?.address}
        title={(recipient as { emailAddress: { address: string } })?.emailAddress?.address}
      >
        <Chip
          className="bg-white border-[1px] border-solid border-grey-300 shadow-sm"
          label={(recipient as { emailAddress: { address: string } })?.emailAddress?.address}
          avatar={<Avatar>{getInitials((recipient as { emailAddress: { address: string } })?.emailAddress?.address)}</Avatar>}
        />
      </Tooltip>
    ))
  }

  const associatedOffers = lead?.offers.filter((offer) => offer.emails.find((email) => Boolean(email) && email.id === emailId))

  const handleClose = () => {
    setThisEmailIdInMyMessages(undefined)
    setSendType(undefined)
    setEmailId(undefined)
  }

  return (
    <Drawer sx={{ zIndex: maxZindex ? 9999 : 'initial' }} anchor="right" open={Boolean(emailId)} onClose={handleClose}>
      <Box className="w-[700px] flex flex-grow flex-col">
        <Box className="p-6 flex flex-col justify-between h-full">
          <Box className="flex flex-grow">
            <Box className="flex flex-grow flex-col">
              <Box className="flex justify-between items-center">
                <Box className="flex gap-8">
                  <Typography className="text-grey-600">From</Typography>
                  <Typography>{(data?.from_address as { emailAddress: { address: string } })?.emailAddress?.address || ''}</Typography>
                </Box>
              </Box>
              <Divider className="my-3" />
              <Box className="flex gap-8">
                <Typography className="text-grey-600">To</Typography>
                <Box className="flex gap-2 flex-wrap">{renderChips(data, 'to_addresses')}</Box>
              </Box>
              <Divider className="my-3" />
              {Boolean(data?.cc_recipients.length) && (
                <>
                  <Box className="flex gap-8">
                    <Typography className="text-grey-600">Cc</Typography>
                    <Box className="flex gap-2 flex-wrap">{renderChips(data, 'cc_recipients')}</Box>
                  </Box>
                  <Divider className="my-3" />
                </>
              )}
              {Boolean(data?.bcc_recipients.length) && (
                <>
                  <Box className="flex gap-8">
                    <Typography className="text-grey-600">Bcc</Typography>
                    <Box className="flex gap-2 flex-wrap">{renderChips(data, 'bcc_recipients')}</Box>
                  </Box>
                  <Divider className="my-3" />
                </>
              )}
              <Typography>{data?.subject}</Typography>
              <Divider className="my-3" />
              {contentLoading && <Skeleton width={100} />}
              <iframe title="email-body" className='w-full h-full border-none'
                srcDoc={DOMPurify.sanitize(
                    getContentWithImages({
                      content: emailBody,
                      attachments: inlineAttachments || [],
                      from: (data?.from_address as { emailAddress: { address: string } })?.emailAddress?.address || '',
                      userEmail: user?.email || ''
                    }),
                    {
                      USE_PROFILES: { html: true }
                    }
                  )
                }
              />
              {attachmentsLoading && <Skeleton width={175} height={40} />}
              {Boolean(originalAttachments?.length) && (
                <Box className="flex gap-2 flex-wrap mt-3">
                  {originalAttachments?.map((attachment) => (
                    <Tooltip title={attachment.name}>
                      <Box
                        onClick={() => handlePreviewFile(attachment)}
                        key={attachment.name}
                        className="px-2 py-1 w-[250px] cursor-pointer truncate flex justify-between items-center border-solid border-[1px] border-grey-200 rounded-full"
                      >
                        <Box className="flex gap-2.5 items-center">
                          <File fontSize="small" sx={{ color: '#697586' }} />
                          <Typography className="truncate max-w-[175px]">{attachment.name}</Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  ))}
                </Box>
              )}
              <Box className="flex gap-2 items-center justify-start">
                <Tooltip title="Reply">
                  <IconButton
                    disabled={(sendType && sendType !== 'reply') || !thisEmailIdInMyMessages}
                    className={clsx({ 'bg-grey-100': sendType === 'reply' })}
                    onClick={() => setSendType('reply')}
                  >
                    <ReplyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reply all">
                  <IconButton
                    disabled={(sendType && sendType !== 'replyAll') || !thisEmailIdInMyMessages}
                    className={clsx({ 'bg-grey-100': sendType === 'replyAll' })}
                    onClick={() => setSendType('replyAll')}
                  >
                    <ReplyAllIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Forward">
                  <IconButton
                    disabled={(sendType && sendType !== 'forward') || !thisEmailIdInMyMessages}
                    className={clsx({ 'bg-grey-100': sendType === 'forward' })}
                    onClick={() => setSendType('forward')}
                  >
                    <ShortcutIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              {Boolean(thisEmailIdInMyMessages && emailIdInDifferentMailbox) && (
                <Typography className="text-warning-700 text_sm_medium">
                  <WarningAmberOutlinedIcon className="text-sm align-middle" />
                  You are replying/forwarding a message from a different shared mailbox.
                </Typography>
              )}
              {thisEmailIdInMyMessages === null && (
                <Typography className="text-warning-700 text_sm_medium">
                  <WarningAmberOutlinedIcon className="text-sm align-middle" />
                  You are only allowed to reply or forward an email that was sent to you.
                </Typography>
              )}
              {sendType && thisEmailIdInMyMessages && (
                <EmailDrawerReply
                  mailboxEmailAddress={sharedMailboxAddress}
                  originalAttachments={originalAttachments}
                  currentUserEmailId={thisEmailIdInMyMessages}
                  refetch={refetchActivities}
                  leadId={leadId}
                  setEmailId={setEmailId}
                  emailBody={emailBody}
                  sendType={sendType}
                  setSendType={setSendType}
                  email={data}
                />
              )}
            </Box>
          </Box>
          {Boolean(lead?._count.offers) && Number(lead?._count.offers) > Number(associatedOffers?.length) && (
            <AssociateOffer
              onChange={(offerId) => associateOffer.mutate({ offerId, emailId: emailId || '' })}
              leadId={leadId || ''}
              emailId={emailId || ''}
            />
          )}
          {Boolean(associatedOffers?.length) && (
            <Box className="flex gap-2 mt-2">
              {associatedOffers?.map((associatedOffer) => (
                <Chip
                  key={associatedOffer.id}
                  onClick={() => navigate(`/offers/${associatedOffer?.id}`)}
                  className="shadow-sm text-grey-600 bg-white border-solid border-[1px] border-grey-300 w-fit"
                  onDelete={() => unassociateOffer.mutate({ offerId: associatedOffer.id, emailId: emailId || '' })}
                  label={<Typography className="text_sm_normal text-grey-600">Offer - {associatedOffer?.funder.name}</Typography>}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      {Boolean(preview) && (
        <Modal style={{ zIndex: 10000 }} open={Boolean(preview)} className="justify-center flex">
          <ClickAwayListener
            onClickAway={() => {
              setPreview('')
            }}
          >
            <iframe title="attachment preview" src={preview} width={smScreen ? '80%' : '800px'} height="100%" />
          </ClickAwayListener>
        </Modal>
      )}
    </Drawer>
  )
}

export default EmailDrawer

import { Box, Button, IconButton, Typography } from '@mui/material'
import ReactDropzone from 'react-dropzone'
import { UploadCloud } from 'react-feather'
import toast from 'react-hot-toast'
import { InsertDriveFileOutlinedIcon, DeleteOutlinedIcon } from '../assets/icons'
import bytesToSize from '../utils/bytesToSize'

type FilePickerProps = {
  files: File[] | undefined
  setFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>
}

const FilePicker = ({ files, setFiles }: FilePickerProps) => {
  const MAX_SIZE = 2000000
  const handleDropAccepted = (accepted: File[]) => {
    setFiles((prev) => (prev ? [...prev, ...accepted] : accepted))
  }
  return (
    <Box>
      {files?.map((file, index) => {
        return (
          <Box key={file.name} className="my-2 w-full border-grey-200 border-solid border-[1px] rounded-lg p-2 flex items-start justify-between">
            <Box className="flex gap-1 items-start">
              <Box className="bg-primary-100 flex justify-center items-center p-1 border-solid border-primary-50 border-[4px] rounded-full">
                <InsertDriveFileOutlinedIcon fontSize="small" className="text-primary-600" />
              </Box>
              <Box>
                <Typography className="text_sm_medium text-grey-700">{file?.name}</Typography>
                <Typography className="text_sm_normal text-grey-500">{bytesToSize(file.size)}</Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => {
                setFiles((prev) => prev?.filter((_, i) => i !== index))
              }}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Box>
        )
      })}
      <ReactDropzone
        multiple
        maxSize={MAX_SIZE}
        onDropAccepted={handleDropAccepted}
        onDropRejected={(rejections) => {
          const rejectionMessages = rejections[0].errors.map((err) => err.message).join('\n')
          toast.error(rejectionMessages)
        }}
      >
        {({ getRootProps, getInputProps, open }) => (
          <Box
            {...getRootProps()}
            className={`flex flex-col items-center justify-center w-full cursor-pointer h-[128px] rounded-lg border-[1px] border-dashed border-grey-200`}
          >
            <Box className="bg-grey-100 w-fit p-2.5 rounded-full flex items-center justify-center border-[8px] border-solid border-grey-50">
              <UploadCloud className="text-grey-600" />
            </Box>
            <Box className="flex items-center">
              <Button className="p-0 text-primary-700 text_sm_medium" onClick={open}>
                <input {...getInputProps()} />
                Click to upload
              </Button>
              <Typography className="inline-block text_sm_normal text-grey-500 ml-1">or drag and drop</Typography>
            </Box>
          </Box>
        )}
      </ReactDropzone>
    </Box>
  )
}

export default FilePicker

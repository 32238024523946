import { LoadingButton } from '@mui/lab'
import { SxProps, Typography } from '@mui/material'
import clsx from 'clsx'
import { PhoneOff } from 'react-feather'

type IProps = {
  buttonSx?: SxProps
  selected: boolean
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
}

const DoNotCallButton = ({ selected, setSelected, buttonSx }: IProps) => {
  return (
    <LoadingButton
      className={`${clsx({ 'border-success-800 bg-success-100': selected })}`}
      sx={{ ...(buttonSx || {}) }}
      onClick={() => setSelected(!selected)}
      fullWidth
      variant="outlined"
    >
      <PhoneOff className="text-grey-700" fontSize="small" />
      <Typography className="text-grey-700 text_sm_medium ml-1.5">Do Not Call</Typography>
    </LoadingButton>
  )
}

export default DoNotCallButton

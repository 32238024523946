import { Box, Divider, TextField, Typography } from '@mui/material'
import ContactInfo from '../ContactInfo'
import { useEffect, useState } from 'react'
import { CallFeedbackContact, FollowupSchema } from '..'
import TaskFollowup from '../TaskFollowup'
import { UseFormReturn } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'
import { trpc } from '../../../../../core/trpc'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router'
import LeadReassignmentButton from '../../../../../components/LeadReassignmentButton'
import clsx from 'clsx'
import DoNotCallButton from '../../../../../components/DoNotCallButton'

type NotInterestedProps = {
  form: UseFormReturn<FollowupSchema>
  createTaskAndActivity: (data: FollowupSchema, callNotes?: string) => void
  handleClose: () => void
  contactInfo: CallFeedbackContact | undefined
  setContactInfo: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
  nextId?: string
  stageId?: string
  leadId: string
  relatedLeads?: boolean
  subsidiaryId: string | undefined
  stageAllowReassignment: boolean
}

const NotInterested = ({
  leadId,
  contactInfo,
  setContactInfo,
  form,
  createTaskAndActivity,
  handleClose,
  nextId,
  stageId,
  relatedLeads,
  subsidiaryId,
  stageAllowReassignment
}: NotInterestedProps) => {
  const { pipelineId } = useParams()
  const client = useQueryClient()
  const [reassign, setReassign] = useState(false)
  const [dnc, setDnc] = useState(false)
  const [addFollowupNote, setAddFollowupNote] = useState(false)
  const [callNotes, setCallNotes] = useState('')
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const { handleSubmit, reset, getValues } = form
  const [initialPhone] = useState(contactInfo?.phone.phoneNumber)

  useEffect(() => {
    return () => reset()
  }, [reset])

  const contactUpdateMutation = trpc.contact.update.useMutation({
    onSuccess: (contact) => {
      toast.success('Contact successfully updated')
      client.refetchQueries([['contact', 'getContactsByCompanyId'], { input: { companyId: contact.contactResponse.company_id }, type: 'query' }])
      handleClose()
    },
    onError: () => toast.error('Contact update failed')
  })

  const reassignLead = trpc.lead.moveLeadToReassignmentPipeline.useMutation({
    onSuccess: () => {
      toast.success('Lead moved to Reassignment Pipeline')
      client.refetchQueries([['stage', 'getStagesGroupedByPipeline'], { input: {}, type: 'query' }])
      client.refetchQueries([['lead', 'getLeadById'], { input: { id: leadId }, type: 'query' }])
      if (nextId) {
        navigate(`/pipeline/${pipelineId}/lead/${nextId}`)
      } else {
        navigate(`/subsidiary/${subsidiaryId}/pipeline/${pipelineId}/leads/view/default?stage_id=${stageId}`)
      }
    },
    onError: (e) => toast.error(String(e))
  })

  const doNotCallLead = trpc.lead.moveLeadToDncPipeline.useMutation({
    onSuccess: () => {
      toast.success('Lead moved to Do Not Call Pipeline')
      client.refetchQueries([['stage', 'getStagesGroupedByPipeline'], { input: {}, type: 'query' }])
      client.refetchQueries([['lead', 'getLeadById'], { input: { id: leadId }, type: 'query' }])
    },
    onError: (e) => toast.error(String(e))
  })
  const editContactPhoneMutation = trpc.contactPhone.updatePhone.useMutation()

  const handleSave = async (data: FollowupSchema) => {
    if (hasError()) {
      return setError(true)
    }
    setError(false)
    createTaskAndActivity(data, `NOT INTERESTED - ${callNotes}`)
    if (contactInfo) {
      contactUpdateMutation.mutate({
        id: contactInfo.contactId,
        name: contactInfo.name,
        primary_email: contactInfo.email
      })
      if (contactInfo.phone.phoneNumber !== initialPhone) {
        editContactPhoneMutation.mutate({ phoneNumber: contactInfo.phone.phoneNumber, phoneId: contactInfo.phone.id })
      }
    }
    if (reassign) {
      reassignLead.mutate({ leadId: leadId || '' })
    }
    if (dnc) {
      doNotCallLead.mutate({ leadId: leadId || '' })
    }
  }

  const hasError = () => {
    const addFollowup = getValues('addFollowup')
    const followupDateVal = getValues('followupDateVal')
    const followupTimeVal = getValues('followupTimeVal')
    return (!reassign && !dnc && !addFollowup) || (addFollowup && (!followupDateVal || !followupTimeVal))
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Box className="mt-6">
        {contactInfo && (
          <>
            <ContactInfo leadId={leadId} contactInfo={contactInfo} setContactInfo={setContactInfo} title="Review Contact Info" />
            <Divider className="my-5" />
          </>
        )}
        <TaskFollowup form={form} setAddFollowupNote={setAddFollowupNote} addFollowupNote={addFollowupNote} />
        <Typography className="text-center my-3 text-grey-600 text_sm_normal">or</Typography>
        <Box className="grid grid-cols-2 gap-3">
          <LeadReassignmentButton
            relatedLeads={relatedLeads}
            selected={reassign}
            setSelected={setReassign}
            stageAllowReassignment={stageAllowReassignment}
          />
          <DoNotCallButton selected={dnc} setSelected={setDnc} />
        </Box>
        <Box className="mt-4">
          <TextField
            fullWidth
            minRows={1}
            multiline
            label="Call Notes"
            placeholder="Enter a note..."
            value={callNotes}
            onChange={(e) => setCallNotes(e.target.value)}
          />
        </Box>
        <Divider className="my-5" />
        <Box className="flex justify-end">
          <LoadingButton className={clsx({ 'border-error-600 border-[1px] border-solid': error })} type="submit" variant="contained">
            Save
          </LoadingButton>
        </Box>
        {error && (
          <Typography className="text_xs_medium text-error-600 float-right mt-1">Either a Followup or Reassignment must be selected</Typography>
        )}
      </Box>
    </form>
  )
}

export default NotInterested

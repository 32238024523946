import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Check, Edit2, PlusCircle } from 'react-feather'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import { CloseIcon } from '../../../assets/icons'
import { formatCurrency, validateBuyRate } from '../../../utils/helpers'
import { Can } from '../../../contexts/AbilityContext'
import { useQueryClient } from '@tanstack/react-query'
import { DatePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'

type EditableFieldProps = {
  id: string
  title: string
  tooltip?: React.ReactNode
  value: string | number
  field: string
  refetch: () => void
  leadId: string
}

const EditableField = ({ id, title, value, field, refetch, leadId, tooltip }: EditableFieldProps) => {
  const [editMode, setEditMode] = useState(false)
  const [updatedValue, setUpdatedValue] = useState<string | number | null>('')
  const queryClient = useQueryClient()

  const mutation = trpc.offer.updateOffer.useMutation({
    onSuccess: () => {
      refetch()
      queryClient.refetchQueries([['offer', 'getOffers'], { input: { leadId, search: '', skip: 0, take: 10 }, type: 'query' }])
      queryClient.refetchQueries([['lead', 'getLeadRequiredColumnValues'], { input: { leadId }, type: 'query' }])
      queryClient.refetchQueries([['lead', 'getLeadsByPipeline'], { input: { lead_id: leadId }, type: 'query' }])
      queryClient.refetchQueries([['lead', 'getLeadById'], { input: { id: leadId }, type: 'query' }])
      setEditMode(false)
      refetch()
      queryClient.refetchQueries([['offer', 'getOffers'], { input: { leadId, skip: 0, take: 20 }, type: 'query' }])
    },
    onError: (e) => toast.error(`Error: ${e.message}`)
  })

  useEffect(() => {
    setUpdatedValue(value)
  }, [value])

  const update = (val: string | number | null) => {
    if (['amount_offered', 'commission_points', 'buy_rate', 'final_purchase_price'].includes(field))
      val = val?.toString()?.replace(/[^0-9.]/g, '') || null
    setUpdatedValue(val)
  }

  return (
    <Box>
      <Box className="flex">
        <Typography className="text-grey-700 text_lg_semibold mb-2">{title}</Typography>
        {tooltip && tooltip}
      </Box>
      <Box className="flex flex-wrap 2 gap-y-4 gap-x-[60px]">
        {editMode ? (
          <Box className="w-full">
            {field === 'date_funded' ? (
              <DatePicker
                value={updatedValue ? new Date(updatedValue) : updatedValue}
                onChange={(value) => update(value ? new Date(value).toISOString() : value)}
              />
            ) : (
              <TextField
                fullWidth
                placeholder={`Enter ${title.toLowerCase()}...`}
                autoFocus
                variant="standard"
                multiline
                onFocus={(e) => e.target.setSelectionRange(-1, -1)}
                InputProps={{
                  disableUnderline: true,
                  className: 'text_md_normal text-grey-600',
                  endAdornment: field === 'commission_points' ? '%' : ''
                }}
                value={updatedValue}
                onChange={(e) => update(e.target.value)}
              />
            )}
            <Box className="mt-1 flex justify-start gap-2">
              <LoadingButton
                disabled={updatedValue === value}
                onClick={() => {
                  if (field === 'commission_points' && Number(updatedValue) > 100) {
                    toast.error('commission points cannot be more than 100%')
                  } else if (field === 'buy_rate' && (!updatedValue || !validateBuyRate(updatedValue?.toString()))) {
                    toast.error('Buy rate must be between 1 and 100')
                  } else {
                    mutation.mutate({ id: id, [field]: updatedValue })
                  }
                }}
                loading={mutation.isLoading}
                className="p-2 border-grey-300 border-solid border-[1px] rounded-lg min-w-fit"
              >
                <Check className="text-grey-700 w-[18px] h-[18px]" />
              </LoadingButton>
              <IconButton
                onClick={() => {
                  setUpdatedValue(value)
                  setEditMode(false)
                }}
                className="p-2 border-grey-300 border-solid border-[1px] rounded-lg"
              >
                <CloseIcon className="text-grey-700 w-[18px] h-[18px]" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box className="flex items-center gap-2">
            <Typography className="text_md_normal text-grey-600 wrap">
              {field === 'amount_offered' || field === 'final_purchase_price' || field === 'period_payment_amount' || field === 'service_fee'
                ? formatCurrency(value || 0)
                : field === 'date_funded' && value
                ? format(new Date(value), 'MMM dd, yyyy')
                : value}
              {field === 'commission_points' && '%'}
            </Typography>
            {value ? (
              <Can I="edit" a="Offer">
                <IconButton onClick={() => setEditMode(true)}>
                  <Edit2 className="text-primary-600 w-[15px]" />
                </IconButton>
              </Can>
            ) : (
              <Can I="edit" a="Offer">
                <Button
                  startIcon={<PlusCircle className="h-4 w-4" />}
                  className="text-primary-600 text_md_medium"
                  variant="text"
                  onClick={() => {
                    setEditMode(true)
                  }}
                >
                  Add {field === 'notes' || field === 'funder_notes' ? 'Note' : field === 'date_funded' ? 'Date Funded' : 'Amount'}
                </Button>
              </Can>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default EditableField

import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Header from './Header'
import RuleList from './RuleList'
import { trpc } from '../../../core/trpc'

export type ValidationFilter = {
  skip: number
  take: number
  sort: {
    name: 'asc' | 'desc'
  }
}

const Validation = () => {
  const [filters, setFilters] = useState<ValidationFilter>({ skip: 0, take: 10, sort: { name: 'asc' } })
  const { data, refetch } = trpc.stage.getStagesForValidation.useQuery()
  const { data: information } = trpc.stage.getValidationPageDetails.useQuery()

  return (
    <Box className="flex flex-col gap-6">
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Header information={information} refetch={refetch} />
      <RuleList refetch={refetch} filters={filters} setFilters={setFilters} data={data} information={information} />
    </Box>
  )
}
export default Validation

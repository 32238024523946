import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Check, Edit2 } from 'react-feather'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import { CloseIcon } from '../../../assets/icons'
import { capitalize } from '../../../utils/helpers'
import { TermFrequency, TermLength } from '@prisma/client'
import { Can } from '../../../contexts/AbilityContext'
import { useQueryClient } from '@tanstack/react-query'
import { OfferGetOfferById } from '../../../types/procedureOutputs'

type TermsProps = {
  term_amount: string | undefined
  term_length: TermLength | null
  term_frequency: TermFrequency | null
  refetch: () => void
  offer: OfferGetOfferById
}

const Terms = ({ offer, term_amount, term_length, term_frequency, refetch }: TermsProps) => {
  const [editMode, setEditMode] = useState(false)
  const [amount, setAmount] = useState<string>(term_amount || '')
  const [length, setLength] = useState<TermLength | null>(term_length)
  const [frequency, setFrequency] = useState<TermFrequency | null>(term_frequency)
  const queryClient = useQueryClient()

  const { data: termFrequencies } = trpc.offer.getTermFrequencies.useQuery()
  const termLengths = Object.values(TermLength || {})
  const termLengthOptions = ['30', '60', '90', '180', '365'] as const

  const mutation = trpc.offer.updateOffer.useMutation({
    onSuccess: () => {
      refetch()
      queryClient.refetchQueries([['offer', 'getOffers'], { input: { leadId: offer?.lead_id, search: '', skip: 0, take: 20 }, type: 'query' }])
      setEditMode(false)
    },
    onError: (e) => toast.error(`Error: ${e.message}`)
  })

  const reset = () => {
    setAmount(term_amount || '')
    setLength(term_length)
    setFrequency(term_frequency)
  }

  useEffect(() => {
    reset()
  }, [term_amount, term_length, term_frequency])

  return (
    <Box>
      <Typography className="text-grey-700 text_lg_semibold mb-2">Terms</Typography>
      <Box className="flex flex-wrap 2 gap-y-4 gap-x-[60px]">
        {editMode ? (
          <Box className="w-full">
            <Box className="flex gap-2">
              <Autocomplete
                freeSolo
                options={termLengthOptions}
                value={amount}
                onInputChange={(e, val) => {
                  setAmount(val)
                }}
                onChange={(_, val) => setAmount(val || '')}
                renderInput={(params) => (
                  <TextField
                    type="number"
                    {...params}
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none'
                      }
                    }}
                  />
                )}
              />
              <TextField value={length} fullWidth select onChange={(e) => setLength(e.target.value as TermLength)}>
                {termLengths.map((length) => (
                  <MenuItem key={length} value={length}>
                    {capitalize(length)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField value={frequency} fullWidth select onChange={(e) => setFrequency(e.target.value as TermFrequency)}>
                {termFrequencies?.map((frequency) => (
                  <MenuItem key={frequency} value={frequency}>
                    {capitalize(frequency)}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box className="mt-1 flex justify-start gap-2">
              <LoadingButton
                disabled={amount === term_amount && length === term_length && frequency === term_frequency}
                onClick={() =>
                  mutation.mutate({
                    id: offer?.id || '',
                    term_amount: parseInt(amount),
                    term_length: length || undefined,
                    term_frequency: frequency || undefined
                  })
                }
                loading={mutation.isLoading}
                className="p-2 border-grey-300 border-solid border-[1px] rounded-lg min-w-fit"
              >
                <Check className="text-grey-700 w-[18px] h-[18px]" />
              </LoadingButton>
              <IconButton
                onClick={() => {
                  reset()
                  setEditMode(false)
                }}
                className="p-2 border-grey-300 border-solid border-[1px] rounded-lg"
              >
                <CloseIcon className="text-grey-700 w-[18px] h-[18px]" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box className="flex items-center gap-2">
            <Typography className="text_md_normal text-grey-600 wrap">{`${amount} ${length ? `${length?.toLowerCase()},` : ''} ${
              frequency ? frequency?.toLowerCase() : ''
            }`}</Typography>
            <Can I="edit" a="Offer">
              <IconButton onClick={() => setEditMode(true)}>
                <Edit2 className="text-primary-600 w-[15px]" />
              </IconButton>
            </Can>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Terms

import { Box, Button, Dialog, Divider, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../../assets/icons'
import { useState } from 'react'
import { useParams } from 'react-router'
import { trpc } from '../../../core/trpc'
import { toast } from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import ReactMentionsInput from '../../../components/ReactMentionsInput'
import { LoadingButton } from '@mui/lab'

type AddNoteProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  refetch?: () => void
}

const AddNote = ({ open, setOpen, refetch }: AddNoteProps) => {
  const client = useQueryClient()
  const [val, setVal] = useState('')
  const { leadId } = useParams()
  const mutation = trpc.activity.createActivity.useMutation({
    onSuccess: () => {
      refetch?.()
      client.refetchQueries([['activity', 'getActivitiesByLeadId'], { input: { activityTypes: [], leadId, skip: 0, take: 10 }, type: 'query' }])
      toast.success('Note successfully created')
      setOpen(false)
    },
    onError: () => toast.error('Note creation failed')
  })
  return (
    <Dialog open={open}>
      <Box className="w-[400px] p-6 h-[420px] flex-col flex justify-between">
        <Box>
          <Box className="flex justify-between items-center mb-5">
            <Typography className="text-grey-900 text_xl_medium">Add Note</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon fontSize="small" className="text-grey-700" />
            </IconButton>
          </Box>
          <ReactMentionsInput value={val} onChange={(val) => setVal(val)} autoFocus placeholder="Enter note..." />
        </Box>
        <Box>
          <Divider className="mb-6 mt-8" />
          <Box className="flex justify-end gap-3">
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <LoadingButton
              loading={mutation.isLoading}
              disabled={!val}
              variant="contained"
              onClick={() => mutation.mutate({ lead_id: String(leadId), type: 'NOTE', notes: val })}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddNote

import { Box, Button, Divider, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { AddCircleOutlineOutlinedIcon, CloseIcon, NotificationsOutlinedIcon } from '../../../../assets/icons'
import clsx from 'clsx'
import { DatePicker, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FollowupSchema } from '.'
import { useState } from 'react'
import StyledCheckbox from '../../../../components/StyledCheckbox'
import AddFollowUpDateSuggestions from '../AddFollowUpDateSuggestions'
import { addHours, isWithinInterval } from 'date-fns'

type TaskFollowupProps = {
  form: UseFormReturn<FollowupSchema>
  setAddFollowupNote: React.Dispatch<React.SetStateAction<boolean>>
  addFollowupNote: boolean
}

const TaskFollowup = ({ form, setAddFollowupNote, addFollowupNote }: TaskFollowupProps) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = form
  const addFollowup = watch('addFollowup')
  const addTask = watch('addTask')
  const timeVal = watch('followupTimeVal')
  const [dateOpen, setDateOpen] = useState(false)
  const [suggestionMenuAnchorEl, setSuggestionMenuAnchorEl] = useState<HTMLElement>()

  return (
    <Box>
      {addFollowup && (
        <Box>
          <Divider className="mb-4 mt-5" />
          <Typography className="text-grey-700 mb-3">Follow up</Typography>
          {Boolean(errors.followupType) && <Typography className="text_xs_normal text-error-600">Follow type is required</Typography>}
          <Controller
            control={control}
            name="followupType"
            render={({ field: { onChange, onBlur, value: followupTypeVal } }) => (
              <RadioGroup row onChange={onChange} onBlur={onBlur} value={followupTypeVal}>
                <FormControlLabel
                  value="CALL"
                  control={<Radio />}
                  label={
                    <Typography className={clsx({ 'text-grey-900': followupTypeVal === 'CALL', 'text-grey-600': followupTypeVal !== 'CALL' })}>
                      Call
                    </Typography>
                  }
                />
                <FormControlLabel
                  className="ml-1"
                  value="EMAIL"
                  control={<Radio />}
                  label={
                    <Typography className={clsx({ 'text-grey-900': followupTypeVal === 'EMAIL', 'text-grey-600': followupTypeVal !== 'EMAIL' })}>
                      Email
                    </Typography>
                  }
                />
              </RadioGroup>
            )}
          />
          <Box className="grid grid-cols-2 gap-4">
            <Controller
              control={control}
              name="followupDateVal"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  open={dateOpen}
                  onClose={() => setDateOpen(false)}
                  disablePast
                  value={value || null}
                  onChange={onChange}
                  slotProps={{
                    openPickerButton: {
                      onClick: (e) => {
                        e.stopPropagation()
                        setDateOpen(true)
                      }
                    },
                    textField: {
                      error: Boolean(errors.followupDateVal),
                      helperText: errors.followupDateVal?.message || '',
                      onClick: (e) => {
                        setSuggestionMenuAnchorEl(e.currentTarget)
                      }
                    }
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="followupTimeVal"
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  value={value}
                  onChange={onChange}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                  }}
                />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="followupUrgent"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel onChange={onChange} label="Urgent" control={<StyledCheckbox checked={value} />} />
            )}
          />
          <Controller
            control={control}
            name="followupReminder"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel onChange={onChange} label="Reminder Email" disabled={isWithinInterval(timeVal || new Date(), { start: new Date(), end: addHours(new Date(), 1) })} control={<StyledCheckbox checked={value ?? false} />} />
            )}
          />
          {!addFollowupNote && (
            <Button className="text_sm_medium text-primary-500 mt-4 px-0" onClick={() => setAddFollowupNote(true)}>
              Add A Note
            </Button>
          )}
          {addFollowupNote && (
            <Controller
              control={control}
              name="followupNote"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextField
                  className="mt-4"
                  placeholder="Enter a followup note..."
                  fullWidth
                  autoFocus
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          onChange(() => '')
                          setAddFollowupNote(false)
                        }}
                      >
                        <CloseIcon className="text-base" />
                      </IconButton>
                    )
                  }}
                />
              )}
            />
          )}
          <Divider className="mt-4" />
        </Box>
      )}
      {addTask && (
        <Box className="mt-5">
          {!addFollowup && <Divider className="mb-4" />}
          <Typography className="text-grey-700 mb-3">Tasks</Typography>
          <Controller
            control={control}
            name="taskTitle"
            render={({ field: { onChange, value, onBlur } }) => (
              <TextField
                error={Boolean(errors.taskTitle)}
                helperText={errors.taskTitle?.message || ''}
                placeholder="Enter a task..."
                fullWidth
                autoFocus
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              />
            )}
          />

          <Box className="grid grid-cols-2 gap-4 mt-4">
            <Controller
              control={control}
              name="taskDateVal"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  slotProps={{
                    textField: { error: Boolean(errors.taskDateVal), helperText: errors.taskDateVal?.message || '' }
                  }}
                  disablePast
                  value={value || null}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="taskTimeVal"
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  value={value}
                  onChange={onChange}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="taskReminder"
              render={({ field: { onChange, value } }) => (
                <FormControlLabel onChange={onChange} label="Reminder Email" control={<StyledCheckbox checked={value ?? false} />} />
              )}
            />
          </Box>
          <Divider className="mt-4" />
        </Box>
      )}
      <Box className={`grid ${clsx({ 'grid-cols-2': !addFollowup && !addTask, 'grid-cols-1': addFollowup || addTask })} gap-3 mt-5`}>
        {!addFollowup && (
          <Button onClick={() => setValue('addFollowup', true)} variant="outlined" className="text-grey-700 py-2">
            <NotificationsOutlinedIcon />
            <Typography className="text_sm_medium ml-2">Add Follow Up</Typography>
          </Button>
        )}
        {!addTask && (
          <Button onClick={() => setValue('addTask', true)} variant="outlined" className="text-grey-700 py-2">
            <AddCircleOutlineOutlinedIcon />
            <Typography className="text_sm_medium ml-2">Add Task</Typography>
          </Button>
        )}
      </Box>
      {Boolean(suggestionMenuAnchorEl) && (
        <AddFollowUpDateSuggestions
          anchorEl={suggestionMenuAnchorEl}
          onClose={() => setSuggestionMenuAnchorEl(undefined)}
          onChange={(date) => {
            setValue('followupDateVal', date)
            setValue('followupTimeVal', date)
            setSuggestionMenuAnchorEl(undefined)
          }}
        />
      )}
    </Box>
  )
}

export default TaskFollowup

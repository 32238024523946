import { Box, Button, Divider, MenuItem, TextField } from '@mui/material'
import { trpc } from '../../../core/trpc'

type Props = {
  selectedLead: string
  setSelectedLead: React.Dispatch<React.SetStateAction<string>>
  leadId: string
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>
  setStage: React.Dispatch<React.SetStateAction<'selectLead' | 'selectFile'>>
}

const SelectLead = ({ setSelectedLead, leadId, selectedLead, setAnchorEl, setStage }: Props) => {
  const { data: leadById } = trpc.lead.getLeadById.useQuery({ id: leadId })
  const { data: companyLeads } = trpc.lead.getLeadsByCompany.useQuery({ companyId: leadById?.company_id || '' }, { enabled: Boolean(leadById) })
  const leadOptions = companyLeads?.filter((lead) => lead.id !== leadId)

  return (
    <Box>
      <TextField label="Select Lead" fullWidth value={selectedLead} select onChange={(e) => setSelectedLead(e.target.value)}>
        {leadOptions?.map((lead) => (
          <MenuItem key={lead.id} value={lead.id}>
            {lead.company.name} - {lead.name}
          </MenuItem>
        ))}
      </TextField>
      <Divider className="my-6" />
      <Box className="flex justify-end gap-2">
        <Button onClick={() => setAnchorEl(undefined)} variant="outlined">
          Cancel
        </Button>
        <Button disabled={!selectedLead} onClick={() => setStage('selectFile')} variant="contained">
          Continue
        </Button>
      </Box>
    </Box>
  )
}

export default SelectLead

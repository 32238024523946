import { Box, Typography, Paper, FormControlLabel, Button, useTheme, useMediaQuery, Dialog, IconButton, MenuItem, TextField } from '@mui/material'
import { CloseIcon } from '../../../assets/icons'
import { useEffect, useState } from 'react'
import { RequiredFields } from '@prisma/client'
import StyledCheckbox from '../../../components/StyledCheckbox'
import clsx from 'clsx'
import { StagesForValidationOutput } from '../../../types/procedureOutputs'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'

type EditRuleModalProps = {
  closeModal: () => void
  stageData?: StagesForValidationOutput[0] | undefined
  fieldNames: RequiredFields[]
  stages: { name: string; id: string; pipeline_id: string }[]
  currentStageId?: string
}

const EditRuleModal = ({ closeModal, stageData, fieldNames, stages, currentStageId }: EditRuleModalProps) => {
  const theme = useTheme()
  const lgScreen = useMediaQuery(theme.breakpoints.up(1600))
  const [selectedColumns, setSelectedColumns] = useState<RequiredFields[] | undefined>([])
  const [stageId, setStageId] = useState(stageData?.id)
  const [selectedPipelineId, setSelectedPipelineId] = useState(stageData?.pipeline_id || '')

  const pipelines = trpc.pipeline.getPipelines.useQuery({})

  const updateFieldsMutation = trpc.stage.updateStage.useMutation({
    onSuccess: async () => {
      toast.success('Stage update successful')
      closeModal()
    },
    onError: () => toast.error('Stage update failed')
  })

  useEffect(() => {
    setSelectedColumns(fieldNames?.filter((c) => stageData?.required_fields.includes(c)))
  }, [fieldNames])

  const handleCheck = (checked: boolean, column: RequiredFields) => {
    if (selectedColumns?.includes(column)) {
      setSelectedColumns((columns) => columns?.filter((col) => col !== column))
    } else if (fieldNames) {
      setSelectedColumns((columns) => [...(columns as RequiredFields[]), column])
    }
  }

  const handleApply = () => {
    if (stageId) {
      updateFieldsMutation.mutate({ required_fields: selectedColumns, id: stageId })
    }
    if (currentStageId && stageId !== currentStageId) {
      updateFieldsMutation.mutate({ required_fields: [], id: currentStageId ?? '' })
    }
  }

  const handleStageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStageId(e?.target.value)
  }

  const handlePipelineChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedPipelineId(e.target.value)
  }

  const filteredStages = stages.filter((stage) => stage.pipeline_id === selectedPipelineId)

  return (
    <Dialog open={true}>
      <Box className="p-5 min-w-[340px]">
        <Box className="flex justify-between items-start mb-5">
          <Typography className="text-grey-900 text_lg_semibold">{!currentStageId ? 'Add' : 'Edit'} Validation Rule</Typography>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon fontSize="small" className="text-grey-500" />
          </IconButton>
        </Box>
        <Box>
          <TextField label={'Select pipeline'} select className="mb-4" fullWidth value={selectedPipelineId} onChange={(e) => handlePipelineChange(e)}>
            {pipelines.data?.results.map((pipeline) => (
              <MenuItem key={pipeline.id} value={pipeline.id}>
                {pipeline.name}
              </MenuItem>
            ))}
          </TextField>
          {selectedPipelineId && (
            <TextField label="Select stage" select className="mb-4" fullWidth value={stageId} onChange={(e) => handleStageChange(e)}>
              {filteredStages.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {/* <Popper sx={{ zIndex: 1500 }} open={open} anchorEl={anchorEl}> */}
          <Paper className={`w-[576px] mobile:w-full p-5 shadow-md overflow-scroll ${clsx({ 'max-h-[398px]': !lgScreen })}`}>
            <Box className="grid grid-cols-2 max-h-[450px] overflow-y-auto p-1">
              {fieldNames?.map((column) => (
                <FormControlLabel
                  onChange={(_, checked) => handleCheck(checked, column)}
                  checked={selectedColumns?.includes(column)}
                  key={column}
                  label={<Typography className="text-grey-700">{column.replace(/_/g, ' ')}</Typography>}
                  control={<StyledCheckbox size="small" />}
                />
              ))}
            </Box>
            <Box className="flex justify-end mt-6">
              <Box className="grid grid-cols-2">
                <Button variant="outlined" className="mr-2.5" onClick={() => setSelectedColumns([])}>
                  Clear All
                </Button>
                <Button onClick={handleApply} variant="contained">
                  Apply
                </Button>
              </Box>
            </Box>
          </Paper>
          {/* </Popper> */}
        </Box>
      </Box>
    </Dialog>
  )
}

export default EditRuleModal

import { Box, Button, Typography } from '@mui/material'
import { Funder } from '.'
import UserAvatar from '../../../components/UserAvatar'
import { AddCircleOutlineOutlinedIcon } from '../../../assets/icons'
import ContactModal from './ContactModal'
import { useState } from 'react'
import { Can } from '../../../contexts/AbilityContext'
import AddContactDialog from './AddContactDialog'

type FunderContactsProps = {
  funder: Funder
  titleText?: string
  refetch: () => void
}

const FunderContacts = ({ funder, titleText, refetch }: FunderContactsProps) => {
  const [contactDetailsId, setContactDetailsId] = useState<string | null>(null)
  const [addContactOpen, setAddContactOpen] = useState(false)

  return (
    <Box>
      <Typography className="text_lg_semibold text-grey-700 mb-6">{titleText ? titleText : 'Contacts'}</Typography>
      <Box className="flex flex-wrap gap-4">
        {funder?.contacts.map((contact) => (
          <Button
            key={contact.id}
            onClick={() => setContactDetailsId(contact.id)}
            variant="outlined"
            className="rounded-3xl p-1.5 border-grey-300 shadow-sm"
          >
            <UserAvatar dontDisplayName={true} id={contact.id} name={contact.name} className="w-[22px] h-[22px] text_xs_normal" />
            <Typography className="text_md_medium text-grey-600 ml-2.5">{contact.name}</Typography>
          </Button>
        ))}
      </Box>
      <Can I="edit" a="Funder">
        <Button onClick={() => setAddContactOpen(true)} className="mt-6 text-primary-500 px-0">
          <AddCircleOutlineOutlinedIcon fontSize="small" />
          <Typography className="text_sm_medium ml-2">Add New Contact</Typography>
        </Button>
      </Can>
      {Boolean(contactDetailsId) && (
        <ContactModal setContactDetailsId={setContactDetailsId} contactId={contactDetailsId} funder={funder} refetch={refetch} />
      )}
      {Boolean(addContactOpen && funder) && (
        <AddContactDialog refetch={refetch} open={addContactOpen} setOpen={setAddContactOpen} funderId={funder?.id || ''} />
      )}
    </Box>
  )
}

export default FunderContacts

import { Box, Button, Dialog, Divider, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { trpc } from '../../core/trpc'
import { toast } from 'react-hot-toast'
import { useState } from 'react'
import { Clipboard } from 'react-feather'
import { useQueryClient } from '@tanstack/react-query'
import ReactMentionsInput from '../../components/ReactMentionsInput'
import { Task } from '../../types/procedureOutputs'

type TaskCompleteDialogProps = {
  task: Task
  setTask: () => void
  refetch: () => void
}

const TaskCompleteDialog = ({ task, setTask, refetch }: TaskCompleteDialogProps) => {
  const client = useQueryClient()
  const [note, setNote] = useState('')
  const mutation = trpc.task.completeTask.useMutation({
    onSuccess: () => {
      refetch()
      client.refetchQueries([
        ['activity', 'getActivitiesByLeadId'],
        { input: { activityTypes: [], leadId: task?.lead_id, skip: 0, take: 10 }, type: 'query' }
      ])
      setTask()
    },
    onError: () => toast.error('Error completing task')
  })

  return (
    <Dialog open={task !== null}>
      <Box className="p-6 min-h-fit" sx={{ width: { xs: '100%', md: '400px' } }}>
        <Box className="flex justify-between items-center mb-5 flex-col gap-2">
          <Box className="bg-secondary-50 w-16 h-16 flex items-center justify-center rounded-full">
            <Box className="bg-secondary-100 w-12 h-12 flex items-center justify-center rounded-full">
              <Clipboard className="text-primary-800" />
            </Box>
          </Box>
          <Typography className="text_xl_medium text-grey-900">Task Completed</Typography>
          <Typography className="text_md_normal text-grey-800">Great job! Have you completed the task?</Typography>
        </Box>
        <Divider className="my-6" />
        <Box className="pb-6">
          <Typography className="text_md_normal text-grey-700">Would you like to add a note about this task?</Typography>
          <Box className="py-2.5 px-[14px] w-full rounded-lg border-grey-300 active:border-grey-300">
            <ReactMentionsInput value={note} onChange={(val) => setNote(val)} autoFocus />
          </Box>
        </Box>
        <Box className="flex justify-between gap-3">
          <Button onClick={() => setTask()} variant="outlined" className="w-[48%]">
            Cancel
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            onClick={() => mutation.mutate({ task_id: task?.id || '', note })}
            variant="contained"
            className="w-[50%]"
          >
            Mark As Completed
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}

export default TaskCompleteDialog

import { Box, Button, Dialog, Divider, IconButton, TextField, Typography } from '@mui/material'
import { CloseIcon } from '../../assets/icons'
import { FileOutput } from '../../types/procedureOutputs'
import { useState } from 'react'
import { trpc } from '../../core/trpc'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'

type AddDescriptionProps = {
  setFile: React.Dispatch<React.SetStateAction<FileOutput | undefined>>
  file: FileOutput
  refetch: () => void
}

const AddDescription = ({ setFile, file, refetch }: AddDescriptionProps) => {
  const [description, setDescription] = useState('')
  const updateFileMutation = trpc.file.updateFile.useMutation({
    onSuccess: () => {
      refetch()
      toast.success('Description added')
      setFile(undefined)
    }
  })

  return (
    <Dialog open>
      <Box className="p-5 w-[350px]">
        <Box className="flex justify-between items-center mb-4">
          <Typography className="text_xl_medium">Add Description</Typography>
          <IconButton onClick={() => setFile(undefined)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <Typography className="text-grey-700 mb-5">Enter description for {file.name}</Typography>
        <TextField
          autoFocus
          placeholder="Enter description..."
          fullWidth
          variant="standard"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value)
          }}
        />
        <Divider className="my-5" />
        <Box className="flex justify-end gap-3">
          <Button onClick={() => setFile(undefined)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={() => updateFileMutation.mutate({ id: file.id, description })}>
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddDescription

import { Box, Button, Dialog, Divider, IconButton, TextField, Typography } from '@mui/material'
import { CloseIcon } from '../../../../assets/icons'
import React, { useEffect, useState } from 'react'
import { trpc } from '../../../../core/trpc'
import { toast } from 'react-hot-toast'
import { ContactGetContactByCompanyOutput } from '../../../../types/procedureOutputs'

type AddContactNoteProps = {
  contact: ContactGetContactByCompanyOutput | undefined
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  note: string
  setNote: React.Dispatch<React.SetStateAction<string>>
}

const AddContactNote = ({ contact, open, setOpen, note, setNote }: AddContactNoteProps) => {
  const [val, setVal] = useState(note)

  useEffect(() => {
    setVal(note)
  }, [note])

  const mutation = trpc.contact.update.useMutation({
    onSuccess: (d) => {
      toast.success('Note saved!')
      setNote(val || '')
    },
    onError: (e) => toast.error(`Error saving note ${e.message}`),
    onSettled: () => setOpen(false)
  })

  return (
    <Dialog open={open}>
      <Box className="w-[400px] p-6">
        <Box className="flex justify-between items-center mb-5">
          <Typography className="text-grey-900 text_xl_medium">{note ? 'Edit Note' : 'Add Note'}</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          InputProps={{ disableUnderline: true }}
          placeholder="Enter note..."
          autoFocus
          value={val}
          onChange={(e) => setVal(e.target.value)}
          variant="standard"
        />
        <Divider className="mb-6 mt-8" />
        <Box className="flex justify-end gap-3">
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={note === val || mutation.isLoading}
            variant="contained"
            onClick={() => mutation.mutate({ id: contact?.id || '', notes: val || '' })}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddContactNote

import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://d6a90f74b2449349143918b665199bb5@o4507744922566656.ingest.us.sentry.io/4507745011433472',
  tunnel: '/sentry',
  enabled: import.meta.env.PROD,
  environment: process.env.NODE_ENV,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',

    /^https:\/\/fundhub.app\/api/,
    /^https:\/\/sandbox.fundhub.app\/api/,

    /^https:\/\/fundhub-qa.azurewebsites.net\/api/
  ],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

import { LoadingButton } from '@mui/lab'
import { CachedIcon } from '../assets/icons'
import { SxProps, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import { Box } from '@mui/system'
import { useReassignmentAllowed } from '../views/pipelines/hooks/useReassignmentAllowed'

type IProps = {
  buttonSx?: SxProps
  selected: boolean
  relatedLeads?: boolean
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  stageAllowReassignment: boolean
}

const LeadReassignmentButton = ({ selected, setSelected, buttonSx, relatedLeads, stageAllowReassignment }: IProps) => {
  const [disabled, disabledMessage] = useReassignmentAllowed(relatedLeads ?? false, stageAllowReassignment)

  return (
    <Tooltip title={disabled && disabledMessage} placement="bottom">
      <Box>
        <LoadingButton
          className={`${clsx({ 'border-success-800 bg-success-100': selected })}`}
          sx={{ ...(buttonSx || {}) }}
          onClick={() => setSelected(!selected)}
          fullWidth
          variant="outlined"
          disabled={disabled}
        >
          <CachedIcon className="text-grey-700" fontSize="small" />
          <Typography className="text-grey-700 text_sm_medium ml-1.5">Get Lead Reassigned</Typography>
        </LoadingButton>
      </Box>
    </Tooltip>
  )
}

export default LeadReassignmentButton

import { Box, Divider, Stack, Typography } from '@mui/material'
import { trpc } from '../../../../core/trpc'
import { useParams } from 'react-router'
import { Lead } from '..'
import DetailRow from './DetailRow'
import { useState } from 'react'
import { useSession } from 'next-auth/react'
import OfferDetails from './OfferDetails'
import { uniq } from 'ramda'
import { getEditableLeadCols } from '../../../../utils/column'

type LeadDetailsCardProps = {
  lead: Lead // PipelineLead
  refetchLead: () => void
}

const LeadDetailsCard = ({ lead, refetchLead }: LeadDetailsCardProps) => {
  const { pipelineId } = useParams()
  const [editRow, setEditRow] = useState('')
  const { data } = trpc.stage.getStagesGroupedByPipeline.useQuery({ pipelineId })
  const roles = useSession().data
  const isAdmin = roles?.currentRole?.is_admin
  const allColumns = uniq(data?.[0]?.stages.filter((stage) => stage.id === lead.stage_id).flatMap((s) => s.columns) || [])
  const { data: leadDetailsData } = trpc.lead.getLeadsByPipeline.useQuery({ lead_id: lead.id, columns: allColumns, subsidiary_id: lead.company.subsidiary_id })
  const editableLeadCols = getEditableLeadCols(isAdmin || false)
  const merchantRequestMaxCol =
    allColumns.includes('MERCHANT_REQUEST_AMOUNT') && !allColumns.includes('MERCHANT_REQUEST_AMOUNT_IS_MAX')
      ? ['MERCHANT_REQUEST_AMOUNT_IS_MAX' as const]
      : []
  const editables = allColumns.filter((column) => editableLeadCols.includes(column)).concat(merchantRequestMaxCol)
  const nonEditables = allColumns.filter((column) => !editableLeadCols.includes(column))

  if (!editables?.length && !nonEditables.length && !leadDetailsData) {
    return null
  }
  return (
    <Box className="border-[1px] border-solid border-grey-200 bg-white rounded-xl p-4">
      <Typography className="text_lg_semibold text-grey-900 mb-6">Lead Details</Typography>
      <Stack gap="16px">
        {editables?.map((col) => {
          return (
            <DetailRow
              key={col}
              isEditableField={editableLeadCols.includes(col)}
              editRow={editRow}
              setEditRow={setEditRow}
              lead={leadDetailsData?.results[0] ?? undefined}
              col={col}
              refetchLead={refetchLead}
            />
          )
        })}
        {Boolean(editables?.length && nonEditables?.length) && <Divider />}
        {nonEditables?.map((col) => {
          return (
            <DetailRow
              key={col}
              isEditableField={editableLeadCols.includes(col)}
              editRow={editRow}
              setEditRow={setEditRow}
              lead={leadDetailsData?.results[0] ?? undefined}
              col={col}
              refetchLead={refetchLead}
            />
          )
        })}
        {Boolean(lead.offers.length) && <OfferDetails lead={lead} />}
      </Stack>
    </Box>
  )
}

export default LeadDetailsCard

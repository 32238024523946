import { Box, Chip, IconButton, Typography } from '@mui/material'
import { AddCircleOutlineOutlinedIcon, ErrorOutlineOutlinedIcon } from '../../../../assets/icons'
import { useState } from 'react'
import { trpc } from '../../../../core/trpc'
import { useParams } from 'react-router'
import { TaskGetTasksByLeadOutput } from '../../../../types/procedureOutputs'
import { isOverdue } from '../../../../utils'
import TaskList from '../../../../components/tasks/TaskList'
import AddTask from '../../../../components/tasks/AddTask'
import clsx from 'clsx'
import { useQueryClient } from '@tanstack/react-query'

export type Task = TaskGetTasksByLeadOutput['results'][number]

const Tasks = () => {
  const [addModalOpen, setAddModalOpen] = useState(false)
  const { leadId } = useParams()
  const { data, refetch } = trpc.task.getTasksByLead.useQuery({ leadId: leadId || '' }, { enabled: Boolean(leadId) })
  const client = useQueryClient()

  const overdueTasks = data?.results.filter((task) => isOverdue(new Date(task.due_date)))

  return (
    <Box className="p-5 rounded-xl border-[1px] border-solid border-grey-200 bg-white">
      <Box className={`flex gap-4 ${clsx({ 'mb-5': data?.results.length })} `}>
        <Typography className="text_lg_semibold">Tasks</Typography>
        {Boolean(overdueTasks?.length) && (
          <Chip
            className="py-1 bg-error-400 text-white"
            label={
              <Box className="flex gap-2.5 items-center">
                <ErrorOutlineOutlinedIcon />
                {Boolean(overdueTasks?.length) && (
                  <Typography data-cy="overdueChip" className="text_xs_medium tracking-widest uppercase">
                    {overdueTasks?.length} Overdue
                  </Typography>
                )}
              </Box>
            }
          />
        )}
      </Box>
      <TaskList
        refetch={() => {
          refetch()
          client.refetchQueries([['lead', 'getLeadsByPipeline'], { input: { lead_id: leadId }, type: 'query' }])
        }}
        tasks={data?.results}
      />
      <IconButton onClick={() => setAddModalOpen(true)} className="text-primary-500 mt-6 px-0">
        <AddCircleOutlineOutlinedIcon fontSize="small" />
        <Typography className="text_sm_medium ml-2">New Task</Typography>
      </IconButton>
      {addModalOpen && (
        <AddTask
          refetch={() => {
            refetch()
            client.refetchQueries([['lead', 'getLeadsByPipeline'], { input: { lead_id: leadId }, type: 'query' }])
          }}
          open={addModalOpen}
          setOpen={setAddModalOpen}
        />
      )}
    </Box>
  )
}

export default Tasks

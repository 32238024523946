import { useSession } from 'next-auth/react'
import { useContext, type ReactNode, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { AbilityContext } from '../contexts/AbilityContext'
import { trpc } from '../core/trpc'
import { defineAbility } from '@casl/ability'

type RequiredRole = 'ADMIN' | 'USER'
interface AuthGuardProps {
  children?: ReactNode
  requiredRole?: RequiredRole | RequiredRole[]
}

function AuthGuard({ children, requiredRole }: AuthGuardProps) {
  const { status, data } = useSession()
  const ability = useContext(AbilityContext);
  const { data: caslRules } = trpc.auth.getCastlRules.useQuery()

  useEffect(() => {
    if (caslRules) {
      const newAbility = defineAbility((can) => {
          caslRules?.permissions?.map((r) => {
              can(r?.actions, r?.subject)
          })
          caslRules?.actions.map(r => {
            r.subject.map(s => can(r.actions, s))
          })
      })
      ability?.update(newAbility?.rules)
    }
  }, [caslRules])

  if (status !== 'loading' && !data) return <Navigate to='/auth/login' replace />
  if (
    status === 'authenticated' &&
    requiredRole &&
    ![requiredRole].flat().some((role) => role === data?.currentRole?.name)
  ) {
    return <Navigate to="/errors/404" replace />
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>

}

export default AuthGuard

import { Typography, Box, Chip, Button } from '@mui/material'
import { FiberManualRecordOutlined } from '../../../../assets/icons'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab'
import { formatDistanceToNowCustom, formatPhoneOnInput, secondsToHms } from '../../../../utils'
import { ActivityGetActivityByLeadIdResults, ActivityGetActivityByUserResults, GetReadReceipt } from '../../../../types/procedureOutputs'
import { useEffect, useState } from 'react'
import EditNote from '../../../../components/EditNote'
import { trpc } from '../../../../core/trpc'
import toast from 'react-hot-toast'
import TaggedText from '../../../../components/TaggedText'
import { Edit, Mail, MessageSquare, Phone, Paperclip } from 'react-feather'
import EmailDrawer from './EmailDrawer'
import clsx from 'clsx'
import { drop } from 'ramda'
import { convert } from 'html-to-text'
import { ActivityFilter } from '.'
import { KixieCallStatus } from '@prisma/client'
import { useEmailStore } from '../../../../stores/emailStore'
import { getMsEmailById } from '../../../../services/azureServices'
import { getMessageTrackingId } from '../../../../utils/readReceipt'
import ReadReceiptPopper from '../../../../components/ReadReceiptPopper'

type ActivityRowProps = {
  last: boolean
  item: ActivityGetActivityByLeadIdResults | ActivityGetActivityByUserResults
  viewType: 'user' | 'lead'
  refetch: ({ concat, activityType }: { concat: boolean; activityType: string | undefined }) => void
  expandedEmailItems?: ActivityGetActivityByLeadIdResults[] | ActivityGetActivityByUserResults[]
  filters: ActivityFilter
}

const ActivityRow = ({ last, item, viewType, refetch, expandedEmailItems, filters }: ActivityRowProps) => {
  const [editNoteOpen, setEditNoteOpen] = useState(false)
  const [emailId, setEmailId] = useState<string>()
  const [isEditMode, setIsEditMode] = useState(false)
  const [readReceipt, setReadReceipt] = useState<GetReadReceipt>()
  const { mailboxEmailAddress } = useEmailStore()
  const trpcCtx = trpc.useContext()
  const smsType = item.type === 'KIXIE_LOG' && item.kixie_log?.type === 'SMS'
  const callType = item.type === 'CALL' || (item.type === 'KIXIE_LOG' && item.kixie_log?.type === 'CALL')

  useEffect(() => {
    const getAndSetReadReceipt = async () => {
      if (!item.email) return
      const message = await getMsEmailById({ microsoftId: item.email.microsoft_id, mailboxEmailAddress })
      const trackingId = getMessageTrackingId(message.data.internetMessageHeaders)
      if (trackingId) {
        const readReceiptRes = await trpcCtx.emails.getEmailReadReceiptByTrackingId.fetch({ trackingId })
        if (readReceiptRes) setReadReceipt(readReceiptRes)
      }
    }
    getAndSetReadReceipt()
  }, [item, mailboxEmailAddress, trpcCtx])

  if (!item) return

  const mutation = trpc.activity.updateActivity.useMutation({
    onSuccess: () => {
      refetch({ concat: true, activityType: filters.activityType })
      setEditNoteOpen(false)
    },
    onError: (e) => toast.error(e.message)
  })

  const bgColor = callType
    ? 'bg-primary-50'
    : item?.type === 'NOTE'
    ? 'bg-secondary-50'
    : item?.type === 'EMAIL'
    ? 'bg-error-50'
    : smsType
    ? 'bg-warning-100'
    : 'bg-transparent'

  const icon = callType ? (
    <Phone className="text-primary-500" size={18} />
  ) : item?.type === 'NOTE' ? (
    <Edit className="text-secondary-500" size={18} />
  ) : item?.type === 'EMAIL' ? (
    <Mail className="text-error-500" size={18} />
  ) : smsType ? (
    <MessageSquare className="text-warning-500" size={18} />
  ) : (
    <FiberManualRecordOutlined className="fill-grey-500" />
  )
  const title =
    (item.type === 'CALL'
      ? `Logged a call ${item.phone_number ? `to ${item.phone_number}` : ''}`
      : item?.type === 'NOTE'
      ? 'Added a note'
      : item?.type === 'STAGE_UPDATE'
      ? 'Changed stage to'
      : item?.type === 'CREATE_LEAD'
      ? 'Created lead'
      : item.type === 'KIXIE_LOG' && item.kixie_log?.type === 'CALL'
      ? 'Call via kixie'
      : item.type === 'KIXIE_LOG' && item.kixie_log?.type === 'SMS'
      ? 'Text via kixie'
      : item?.type) + (viewType === 'user' && item?.type !== 'CREATE_LEAD' ? ' on' : '')

  const details =
    item?.type === 'KIXIE_LOG' && item.kixie_log?.type === 'CALL' ? (
      <Box className="flex gap-2 items-center">
        <Typography variant="caption">
          {item.kixie_log.phone_number ? formatPhoneOnInput(item.kixie_log.phone_number) : ''} Call Duration:{' '}
          {secondsToHms(item.kixie_log?.call_seconds_duration || 0)}
        </Typography>
        {item.kixie_log.call_status && <Chip size="small" label={item.kixie_log.call_status} />}
        {item.kixie_log.recording_url && item.kixie_log.call_status !== KixieCallStatus.MISSED && (
          <a target="_blank" href={item.kixie_log.recording_url} rel="noreferrer">
            recording link
          </a>
        )}
      </Box>
    ) : item?.type === 'KIXIE_LOG' && item.kixie_log?.type === 'SMS' ? (
      <Typography variant="caption">
        {item.kixie_log.phone_number ? formatPhoneOnInput(item.kixie_log.phone_number) : ''} {item.kixie_log.message}
      </Typography>
    ) : item?.type === 'NOTE' ? (
      <TaggedText text={item?.notes || ''} />
    ) : item?.type === 'STAGE_UPDATE' ? (
      <Box>
        <Chip
          label={item?.new_stage?.name}
          size="small"
          sx={{ color: item?.new_stage?.text_color || 'white', backgroundColor: `${item?.new_stage?.color}  !important` }}
        />{' '}
        <Typography variant="caption" className="ml-2">
          {item?.notes || ''}
        </Typography>
      </Box>
    ) : item?.type === 'EMAIL' ? (
      <Typography variant="caption">{item.email?.body_preview || ''}</Typography>
    ) : null

  return (
    <>
      <TimelineItem className="w-full">
        <TimelineSeparator>
          <TimelineDot sx={{ boxShadow: 'none' }} className={`${bgColor} m-0`}>
            {icon}
          </TimelineDot>
          {viewType === 'lead' && <TimelineConnector className={last ? 'bg-transparent' : 'border border-dashed border-grey-300 bg-white'} />}
        </TimelineSeparator>
        <TimelineContent className=" pb-4  flex justify-between items-center">
          <Box>
            <Box
              className={`mobile:flex-wrap gap-2 ${clsx({ flex: item.type !== 'EMAIL' })} `}
              sx={{ verticalAlign: 'center' }}
              onClick={() => {
                if (item.type === 'EMAIL') setEmailId(item.email?.id)
              }}
            >
              <Box className="flex gap-2 whitespace-nowrap items-center">
                {viewType === 'lead' && (
                  <Typography variant="subtitle1" className="text-grey-700 leading-[30px]">
                    {item?.created_by?.name}
                  </Typography>
                )}
                {item.type === 'EMAIL' && Boolean(item.email?._count.files) && <Paperclip className="text-grey-500 w-4" />}
                <Typography
                  data-cy="activityTitle"
                  className={`${viewType === 'lead' ? 'lowercase' : ''} text-grey-700 leading-[30px]`}
                  variant="body1"
                >
                  {title}
                </Typography>
                {
                  <Typography data-cy="emailSubject" className={` text-grey-800 leading-[30px]`} variant="body1">
                    {item?.email?.subject}
                  </Typography>
                }

                <Typography
                  onClick={() => {
                    if (item.type === 'CALL') {
                      setIsEditMode(false)
                      setEditNoteOpen(true)
                    }
                  }}
                  className="text-grey-500 cursor-pointer"
                >
                  {item.type === 'CALL' && item?.notes ? convert(item?.notes) : ''}
                </Typography>
                {viewType === 'user' && (
                  <Typography variant="subtitle1" className="text-grey-700 leading-[30px]">
                    {`${item?.lead?.company?.name} - ${item?.lead?.name}`}
                  </Typography>
                )}
              </Box>
              <Typography
                data-cy="activityDetails"
                component="span"
                variant="body2"
                className={`truncate w-[${viewType === 'lead' ? '400px' : '200px'}] mobile:w-[100px] text-grey-500 leading-[30px] ${
                  item.type === 'NOTE' ? 'cursor-pointer' : ''
                }`}
                onClick={
                  item.type === 'NOTE'
                    ? () => {
                        setIsEditMode(false)
                        setEditNoteOpen(true)
                      }
                    : () => {}
                }
              >
                {details}
              </Typography>
            </Box>
            <Box className="flex gap-3 items-center">
              {item.type === 'EMAIL' && <ReadReceiptPopper readReceipt={readReceipt} smallIcon />}
              <Typography variant="body2" className="text-grey-500">
                {formatDistanceToNowCustom(item?.date_of_activity)}
              </Typography>
              {item?.type === 'NOTE' && (
                <Button
                  className="text-primary-500 text-sm p-0"
                  variant="text"
                  onClick={() => {
                    setIsEditMode(true)
                    setEditNoteOpen(true)
                  }}
                >
                  Edit Note
                </Button>
              )}
            </Box>
            {expandedEmailItems &&
              expandedEmailItems.length > 1 &&
              drop(1, expandedEmailItems).map((i) => (
                <Box key={i.id} className="flex flex-wrap gap-2 w-[100%]" onClick={() => setEmailId(i.email?.id)}>
                  <Box className="rounded-full bg-primary-600 h-2 w-2 self-center" />
                  <Typography className="text-grey-700 leading-[30px] self-center max-w-[20%] truncate" variant="body1">
                    {(i?.email?.from_address as { emailAddress: { address: string } })?.emailAddress?.address}
                  </Typography>
                  <Typography className="text-grey-800 leading-[30px] self-center max-w-[50%] truncate" variant="body1">
                    {i?.email?.subject}
                  </Typography>
                  <Typography variant="caption" className="truncate text-grey-500 self-center max-w-[45%]">
                    {i?.email?.body_preview}
                  </Typography>
                  <Typography className="text-grey-500 text_xs_normal self-center">
                    {i?.email?.created_date_time && formatDistanceToNowCustom(i?.email?.created_date_time)}
                  </Typography>
                </Box>
              ))}
          </Box>
        </TimelineContent>
        <TimelineOppositeContent />
      </TimelineItem>
      <EmailDrawer maxZindex emailId={emailId} setEmailId={setEmailId} refetch={refetch} />
      {editNoteOpen && (
        <EditNote
          open={editNoteOpen}
          setOpen={setEditNoteOpen}
          note={item?.notes || ''}
          onSave={(notes) => mutation.mutate({ id: item?.id || '', notes })}
          loading={mutation.isLoading}
          isEditMode={isEditMode}
        />
      )}
    </>
  )
}

export default ActivityRow

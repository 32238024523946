import { Box, Button, Paper, Popper, TextField } from '@mui/material'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Offer } from '.'
import { trpc } from '../../core/trpc'
import toast from 'react-hot-toast'
import { GetOffersRequiringInvoiceOutput, OfferGetOfferById } from '../../types/procedureOutputs'

type DeclineReasonProps = {
  anchorEl: HTMLElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  offer: Offer | OfferGetOfferById | GetOffersRequiringInvoiceOutput['results'][number]
  refetch: () => void
}

const DeclineReason = ({ anchorEl, setAnchorEl, offer, refetch }: DeclineReasonProps) => {
  const [reason, setReason] = useState(offer?.reason_for_decline || '')
  const mutation = trpc.offer.updateOffer.useMutation({
    onSuccess: () => {
      toast.success('Offer status updated')
      refetch()
      setAnchorEl(null)
    },
    onError: () => toast.error('Offer update failed')
  })

  return (
    <Popper className="z-[2000]" open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
      <Paper onClick={(e) => e.stopPropagation()} className="w-[350px] p-5 shadow-md mt-1">
        <TextField
          fullWidth
          placeholder="Add a Reason for Decline"
          multiline
          variant="standard"
          autoFocus
          InputProps={{ disableUnderline: true }}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <Box className="flex justify-end gap-2.5 mt-5">
          <Button
            onClick={() => {
              toast.error('Offer status has not been updated')
              refetch()
              setAnchorEl(null)
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={!reason}
            onClick={() => mutation.mutate({ id: offer?.id || '', reason_for_decline: reason, status: 'DENIED' })}
            loading={mutation.isLoading}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Paper>
    </Popper>
  )
}

export default DeclineReason

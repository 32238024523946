import { SecurityOutlined } from "@mui/icons-material"
import {   MenuItem, Tooltip, Typography } from "@mui/material"
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { Box } from "@mui/system";
import { useSession } from "next-auth/react";
import { trpc } from "../../../core/trpc";
import { Check } from "react-feather";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from "react";

const Role = () => {
    const session = useSession()
    const [open, setOpen] = useState(false)
    const { data } = trpc.auth.getCurrentUser.useQuery()
    const changeRoleMutation = trpc.auth.changeRole.useMutation()

    const changeRole = (roleId: string) => {
        if (roleId === session?.data?.currentRole?.id) return
        changeRoleMutation.mutateAsync({ roleId }).then(() => {
            window.location.reload()
        })
    }


    return (data?.user_roles?.length && data?.user_roles?.length === 1) ? <Box /> : <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box>
            <Dropdown open={open} onOpenChange={(e, o) => setOpen(o)}>
                <Tooltip title={open? '': "Change Role"}>
                    <MenuButton className="bg-transparent border-none cursor-pointer">
                        <SecurityOutlined className="text-grey-50 mx-4" />
                    </MenuButton>
                </Tooltip>
                <Menu className="bg-white rounded shadow-sm z-[1300]" slots={{ listbox: Box }}>
                    {data?.user_roles?.map(r => <MenuItem
                        key={r.id} 
                        onClick={() => changeRole(r.id)}
                        className="flex justify-between"
                    >
                        <Typography className="mr-6 capitalize">{r.name?.toLowerCase()}</Typography>
                        {r.id === session?.data?.currentRole?.id && <Check />}
                    </MenuItem>)}
                </Menu>
            </Dropdown>
        </Box>
    </ClickAwayListener>
}
export default Role
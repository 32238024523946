import { TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab'
import { Typography, Box } from '@mui/material'
import { AssignmentIndOutlinedIcon } from '../../../../assets/icons'
import { FlatAssignmentHistory } from './index'
import { format } from 'date-fns'

type AssignmentRowProps = {
  last: boolean
  item: FlatAssignmentHistory
  type: 'unassigned' | 'assigned'
  lead_name: string
}

const AssignmentRow = ({ item, last, type, lead_name }: AssignmentRowProps) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot className={type === 'assigned' ? `bg-success-50 m-0`: `bg-warning-50`}>
          <AssignmentIndOutlinedIcon className={type === 'assigned' ? "text-success-600" : "text-warning-600"} />
        </TimelineDot>
        <TimelineConnector className={last ? 'bg-transparent' : 'bg-grey-300'} />
      </TimelineSeparator>
      <TimelineContent>
        <Box className="flex gap-2" sx={{ verticalAlign: 'center' }}>
          <Box className="flex gap-2 whitespace-nowrap items-center">
            <Typography variant="subtitle1" className="text-grey-700 leading-[30px]">
              {item?.assignee}
            </Typography>
            <Box className="flex items-center" gap={1}>
              <Typography variant="body2" className="text-grey-500">
                was {type} {type === 'assigned' ? 'to' : 'from'}
              </Typography>
              <Typography variant="subtitle1" className="text-grey-700 leading-[30px]">
                {lead_name} 
                </Typography>
                <Typography variant="body2" className="text-grey-500">
                {item.by ? `by ${item.by}` :''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="flex gap-3 items-center pb-4">
          <Typography variant="body2" className="text-grey-500">
            {format(item.date, 'MMM do, yyyy h:mm aaa')}
          </Typography>
        </Box>
      </TimelineContent>
      <TimelineOppositeContent />
    </TimelineItem>
  )
}

export default AssignmentRow

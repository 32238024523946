import { Dialog, Box, Typography, IconButton, TextField, MenuItem, Divider, Button, Stack, Tooltip } from '@mui/material'
import { AddCircleOutlineOutlinedIcon, CloseIcon } from '../../assets/icons'
import { Offer } from '.'
import { OfferGetOfferById, GetOffersRequiringInvoiceOutput } from '../../types/procedureOutputs'
import { File as PrismaFile, TermFrequency, TermLength } from '@prisma/client'
import { useEffect, useState } from 'react'
import { capitalize, pluralize } from '../../utils/helpers'
import { LoadingButton } from '@mui/lab'
import ReactDropzone from 'react-dropzone'
import toast from 'react-hot-toast'
import { trpc } from '../../core/trpc'
import { uploadFileToAzure } from '../../services/azureServices'
import { formatTimeElapased } from '../../utils/timeHelpers'
import bytesToSize from '../../utils/bytesToSize'
import { File } from 'react-feather'
import { DatePicker } from '@mui/x-date-pickers'
import { v4 as uuidv4 } from 'uuid'
import { InformationCircleOutlined } from '../../icons/information-circle-outlined'

type AcceptOfferProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  offer: Offer | OfferGetOfferById | GetOffersRequiringInvoiceOutput['results'][number]
  refetch: () => void
}

type AcceptedOffer = {
  amount_offered: string | undefined | null
  term_amount: string | null | undefined
  term_length: TermLength | null | undefined
  term_frequency: TermFrequency | null | undefined
  commission_points: string | null | undefined
  buy_rate: string | null | undefined
  funder_notes: string | null | undefined
  date_funded: string | null | undefined
  service_fee: string | null | undefined
  period_payment_amount: string | null | undefined
}

const AcceptOffer = ({ open, setOpen, offer, refetch }: AcceptOfferProps) => {
  const [acceptedOffer, setAcceptedOffer] = useState<AcceptedOffer>({
    amount_offered: offer?.amount_offered?.toString(),
    term_frequency: offer?.term_frequency,
    term_amount: offer?.term_amount?.toString(),
    term_length: offer?.term_length,
    commission_points: offer?.commission_points?.toString(),
    funder_notes: offer?.funder_notes,
    buy_rate: offer?.buy_rate?.toString(),
    date_funded: offer?.date_funded,
    service_fee: offer?.service_fee?.toString(),
    period_payment_amount: offer?.period_payment_amount?.toString()
  })
  const [offerFiles, setOfferFiles] = useState<
    (Omit<PrismaFile, 'created_at' | 'updated_at'> & { created_at: string; updated_at: string })[] | undefined
  >()

  const { data: termFrequencies } = trpc.offer.getTermFrequencies.useQuery()
  const getLinks = trpc.file.createUploadFileLink.useMutation()
  const createOfferFileMutation = trpc.file.createOfferFile.useMutation()
  const getViewFileLink = trpc.file.viewFileLink.useMutation()
  const trpcContext = trpc.useContext()

  useEffect(() => {
    ;(async () => {
      const data = await trpcContext.file.getFilesByOfferId.fetch({ offer_id: offer?.id || '' })
      setOfferFiles(data.results)
    })()
  }, [offer, trpcContext])

  const acceptOfferMutation = trpc.offer.updateOffer.useMutation({
    onSuccess: () => {
      toast.success('Offer status updated')
      refetch()
      setOpen(false)
    },
    onError: () => toast.error('Offer update failed')
  })

  const handleChange = <T extends keyof AcceptedOffer>(field: T, value: AcceptedOffer[T]) => {
    setAcceptedOffer((acceptedOffer) => ({ ...acceptedOffer, [field]: value }))
  }

  const termLengths = Object.values(TermLength)

  const MAX_SIZE = 3000000 // based on https://learn.microsoft.com/en-us/graph/api/resources/attachment?view=graph-rest-1.0

  const handleDropAccepted = async (files: File[]) => {
    const { containerUrl, sasQuery } = (await getLinks.mutateAsync()) || {}
    if (offer) {
      files.forEach(async (file) => {
        const filePath = `Offer/${offer.id}/${new Date().getTime()}-${uuidv4()}`
        const fr = new FileReader()
        fr.readAsDataURL(file)
        fr.onload = (ev) => {
          uploadFileToAzure({
            containerUrl,
            sasQuery,
            filePath,
            contents: ev.target?.result as string,
            size: file.size
          })
            .then(async () => {
              const createdFile = await createOfferFileMutation.mutateAsync({
                offer_id: offer.id,
                name: file.name,
                size: file.size,
                type: file.type,
                url: filePath
              })
              setOfferFiles([...(offerFiles || []), createdFile])
            })
            .catch((e) => toast.error(e))
        }
      })
    }
  }

  const downloadFile = (fileId: string) => {
    getViewFileLink
      .mutateAsync({ id: fileId })
      .then((res) => {
        window.open(res)
      })
      .catch((e) => toast.error(`Failed to download file. ${e}`))
  }

  return (
    <Dialog open={open} onClick={(e) => e.stopPropagation()}>
      <Box className="p-5 flex flex-col gap-6 mobile:w-full w-[600px]">
        <Box className="flex justify-between items-center">
          <Typography className="text-grey-900 text_xl_medium">Accept Offer</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Typography className="text_lg_semibold text-grey-900 mb-3">{offer?.lead.company.name}</Typography>
          <Typography className="inline-block text-grey-700 mr-1">Funder Name:</Typography>
          <Typography className="inline-block text-grey-900 text_md_medium">{offer?.funder.name}</Typography>
        </Box>
        <Box>
          <Typography className="text_grey-700 mb-1.5">Amount Offered</Typography>
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              }
            }}
            fullWidth
            onWheel={(e) => e.preventDefault()}
            type="number"
            value={acceptedOffer.amount_offered || ''}
            onChange={(e) => handleChange('amount_offered', e.target.value)}
          />
        </Box>
        <Box>
          <Box className="flex">
            <Typography className="text_grey-700 mb-1.5">Period Payment Amount</Typography>
            <Tooltip title="How much you pay per period">
              <InformationCircleOutlined fontSize="small" className="ml-2" />
            </Tooltip>
          </Box>
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              }
            }}
            fullWidth
            onWheel={(e) => e.preventDefault()}
            type="number"
            value={acceptedOffer.period_payment_amount || ''}
            onChange={(e) => handleChange('period_payment_amount', e.target.value)}
          />
        </Box>
        <Box>
          <Typography className="text_grey-700 mb-1.5">Request Term Length</Typography>
          <Box className="grid grid-cols-2 gap-3">
            <TextField
              type="number"
              value={acceptedOffer.term_amount}
              onChange={(e) => handleChange('term_amount', e.target.value || '')}
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                }
              }}
            />
            <TextField value={acceptedOffer.term_length} fullWidth select onChange={(e) => handleChange('term_length', e.target.value as TermLength)}>
              {termLengths.map((length) => (
                <MenuItem key={length} value={length}>
                  {capitalize(length)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Box>
          <Typography className="text_grey-700 mb-1.5">Payment Frequency</Typography>
          <TextField
            value={acceptedOffer.term_frequency}
            fullWidth
            select
            onChange={(e) => handleChange('term_frequency', e.target.value as TermFrequency)}
          >
            {termFrequencies?.map((frequency) => (
              <MenuItem key={frequency} value={frequency}>
                {capitalize(frequency)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="grid grid-cols-2 gap-3">
          <Box>
            <Typography className="text_grey-700 mb-1.5">Commission Points</Typography>
            <TextField
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                }
              }}
              fullWidth
              onWheel={(e) => e.preventDefault()}
              type="number"
              value={acceptedOffer.commission_points || ''}
              onChange={(e) => handleChange('commission_points', e.target.value)}
            />
          </Box>
          <Box>
            <Typography className="text_grey-700 mb-1.5">Buy Rate</Typography>
            <TextField
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                }
              }}
              fullWidth
              onWheel={(e) => e.preventDefault()}
              type="number"
              value={acceptedOffer.buy_rate || ''}
              onChange={(e) => handleChange('buy_rate', e.target.value)}
            />
          </Box>
        </Box>
        <Box>
          <Typography className="text_grey-700 mb-1.5">Service Fee</Typography>
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              }
            }}
            fullWidth
            onWheel={(e) => e.preventDefault()}
            type="number"
            value={acceptedOffer.service_fee || ''}
            onChange={(e) => handleChange('service_fee', e.target.value)}
          />
        </Box>
        <Box>
          <Typography className="text_grey-700 mb-1.5">Date Funded</Typography>
          <DatePicker
            className="w-full"
            value={acceptedOffer.date_funded ? new Date(acceptedOffer.date_funded) : acceptedOffer.date_funded}
            onChange={(value) => handleChange('date_funded', value ? new Date(value).toISOString() : value)}
          />
        </Box>
        <Box>
          <Typography className="text_grey-700 mb-1.5">Funder's Notes</Typography>
          <TextField fullWidth value={acceptedOffer.funder_notes || ''} onChange={(e) => handleChange('funder_notes', e.target.value)} />
        </Box>
        <Box>
          <Box className="flex justify-between items-center">
            <Box className="flex items-center gap-4">
              <Typography className="text-grey-900 text_lg_semibold">Files</Typography>
              <Typography className="text-grey-500">
                {offerFiles?.length || 0} {pluralize('file', offerFiles?.length || 0)}
              </Typography>
            </Box>
            <ReactDropzone
              multiple
              maxSize={MAX_SIZE}
              onDropAccepted={handleDropAccepted}
              onDropRejected={(rejections) => {
                const rejectionMessages = rejections[0].errors.map((err) => err.message).join('\n')
                toast.error(rejectionMessages)
              }}
            >
              {({ getRootProps }) => (
                <Button {...getRootProps()} variant="contained" className="w-fit">
                  <AddCircleOutlineOutlinedIcon className="text-[20px]" />
                  <Typography className="ml-1">Add File</Typography>
                </Button>
              )}
            </ReactDropzone>
          </Box>
          <Stack gap="12px" className="mt-4" divider={<Divider />}>
            {offerFiles?.map((file) => (
              <Box key={file.id} className="flex items-center justify-between">
                <Box className="flex items-center gap-3">
                  <Button
                    onClick={() => downloadFile(file.id)}
                    className="mr-3 bg-white border-solid border-[lightgrey] border-[1px] p-2 min-w-fit h-fit rounded-full flex justify-center items-center"
                  >
                    <File className="bg-white text-grey-600 h-3 w-3" />
                  </Button>
                  <Typography>{file.name}</Typography>
                  <Typography>{bytesToSize(file.size)}</Typography>
                </Box>
                <Typography>{formatTimeElapased({ datetime: file.created_at })} ago</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
        <Divider />
        <Box className="justify-end flex gap-3">
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            onClick={() => {
              if (acceptedOffer.commission_points && (Number(acceptedOffer.commission_points) > 99 || Number(acceptedOffer.commission_points) < 0)) {
                return toast.error('commission points must be between 0 and 100')
              }
              acceptOfferMutation.mutate({
                id: offer?.id || '',
                status: 'ACCEPTED',
                funder_notes: acceptedOffer.funder_notes || undefined,
                amount_offered: Number(acceptedOffer.amount_offered) || undefined,
                term_amount: Number(acceptedOffer.term_amount) || undefined,
                term_frequency: acceptedOffer.term_frequency || undefined,
                commission_points: Number(acceptedOffer.commission_points) || undefined,
                term_length: acceptedOffer.term_length || undefined,
                buy_rate: Number(acceptedOffer.buy_rate) || undefined,
                service_fee: Number(acceptedOffer.service_fee) || undefined,
                date_funded: acceptedOffer.date_funded ? new Date(acceptedOffer.date_funded) : undefined,
                period_payment_amount: Number(acceptedOffer.period_payment_amount)
              })
            }}
            loading={acceptOfferMutation.isLoading}
            variant="contained"
          >
            Accept Offer
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AcceptOffer

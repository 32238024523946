import 'react-quill/dist/quill.snow.css'
import Quill, { ReactQuillProps } from 'react-quill'
import { styled } from '@mui/material'
import { forwardRef, useCallback } from 'react'
import 'quill-mention'

const QuillEditorRoot = styled(Quill)(() => {
  return {
    border: 0,
    borderColor: 'black',
    borderRadius: '4px',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '& .ql-mention-list-container': {
      borderRadius: '8px',
      boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
      paddingRight: '10px',
      backgroundColor: 'white'
    },
    '& .ql-mention-list': {
      paddingLeft: '10px',
      listStyle: 'none',
      gap: '6px'
    },
    '& .ql-mention-list-item': {
      fontSize: '16px',
      cursor: 'pointer',
      paddingLeft: '4px'
    },
    '& .ql-mention-list-item:hover': {
      backgroundColor: 'lightGrey'
    },
    '& .ql-snow.ql-toolbar': {
      border: 0,
      borderColor: 'black',
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      '& .ql-picker-label:hover': {
        color: 'black'
      },
      '& .ql-picker-label.ql-active': {
        color: 'black'
      },
      '& .ql-picker-item:hover': {
        color: 'black'
      },
      '& .ql-picker-item.ql-selected': {
        color: 'black'
      },
      '& button:hover': {
        color: 'black',
        '& .ql-stroke': {
          stroke: 'black'
        }
      },
      '& button:focus': {
        color: 'black',
        '& .ql-stroke': {
          stroke: 'black'
        }
      },
      '& button.ql-active': {
        '& .ql-stroke': {
          stroke: 'blue'
        }
      },
      '& .ql-stroke': {
        stroke: 'black'
      },
      '& .ql-picker': {
        color: 'black'
      },
      '& .ql-picker-options': {
        backgroundColor: 'white',
        border: 'none',
        borderRadius: '4px',
        padding: '16px'
      }
    },
    '& .ql-snow.ql-container': {
      borderBottom: 'none',
      borderColor: 'black',
      borderLeft: 'none',
      borderRight: 'none',
      flexGrow: 1,
      overflow: 'hidden',
      minHeight: '400px',
      '& .ql-editor': {
        color: 'black',
        // fontFamily: theme.typography.body1.fontFamily,
        fontSize: '16px',
        padding: '16px',
        '&.ql-blank::before': {
          color: 'grey',
          fontStyle: 'normal',
          left: '8px'
        }
      }
    }
  }
})

type MentionItem = {
  index: string
  denotationChar: string
  id: string
  value: string
}

export const QuillEditor = forwardRef<Quill, ReactQuillProps>((props: ReactQuillProps, ref) => {
  const { theme, ...rest } = props

  const suggestVars = (searchTerm: string) => {
    const variables = [
      { id: 1, value: 'CurrentDate' },
      { id: 2, value: 'LeadCreateDate' },
      { id: 3, value: 'LeadName' },
      { id: 4, value: 'BrokerOnLead' },
      { id: 5, value: 'TeamLeadOnLead' },
      { id: 6, value: 'LeadType' },
      { id: 7, value: 'PrimaryContactFirstName' }
    ]

    return variables.filter((variable) => variable.value.toLowerCase().includes(searchTerm.toLowerCase()))
  }

  const onSelect = useCallback((item: MentionItem, insertItem: (item: MentionItem) => void) => {
    const { index, denotationChar, id } = item
    insertItem({ index, denotationChar, id, value: item.value + '}}' })
  }, [])

  const source = useCallback((searchTerm: string, renderList: (items?: unknown[]) => void) => {
    const matchedPeople = suggestVars(searchTerm)
    renderList(matchedPeople)
  }, [])

  return (
    <QuillEditorRoot
      {...rest}
      ref={ref}
      modules={{
        mention: {
          mentionDenotationChars: ['{{'],
          onSelect,
          source
        }
      }}
    />
  )
})

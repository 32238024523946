import React, { ReactNode, createContext } from 'react'
import { createContextualCan } from '@casl/react'
import { AnyAbility, defineAbility } from '@casl/ability'
import { CastlActions, CastlRoleActionSubjects, CastlSubjects } from '../types/procedureOutputs'

export const AbilityContext = createContext<AnyAbility>(defineAbility(() => {}))

export const Can = createContextualCan(AbilityContext.Consumer) as React.FunctionComponent<{
  I: CastlActions | CastlRoleActionSubjects[number]
  a: CastlSubjects
  children: ReactNode
}>

import { Box, Button, Grid, IconButton, Modal, Pagination, TextField, useMediaQuery, useTheme } from '@mui/material'
import { trpc } from '../../core/trpc'
import { useEffect, useState } from 'react'
import { Trash2 } from 'react-feather'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { ContainerClient } from '@azure/storage-blob'
import { toast } from 'react-hot-toast'
import { getFileNameExtension, isExcelFile, stripFileNameExt } from '../../utils/helpers'
import { FileOutput } from '../../types/procedureOutputs'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm'
import RenameFile from './RenameFile'
import AddDescription from './AddDescription'
import mime from 'mime'
import { LoadingButton } from '@mui/lab'
import ReactDropzone from 'react-dropzone'
import { useSession } from 'next-auth/react'
import FileItem from './FileItem'
import FileHeader from './FileHeader'

type FilesProps = {
  id: string
  type: 'Lead' | 'Offer'
  onContractFileUpdateSuccess?: () => void
}

const Files = ({ id, type, onContractFileUpdateSuccess }: FilesProps) => {
  const viewFilePreference = useSession().data?.user.file_view_preference
  const confirm = useConfirm()
  const [skip, setSkip] = useState(0)
  const [preview, setPreview] = useState<{ fileId: string; objectUrl: string }>()
  const [fileNotFoundDialogOpen, setFileNotFoundDialogOpen] = useState(false)
  const [fileToRename, setFileToRename] = useState<FileOutput>()
  const [fileToRenameInViewer, setFileToRenameInViewer] = useState<FileOutput>()
  const [fileToDescribe, setFileToDescribe] = useState<FileOutput>()
  const [renamedFileInViewer, setRenamedFileInViewer] = useState('')
  const [search, setSearch] = useState('')
  const [isContract, setContract] = useState(false)
  const TAKE = 10

  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.down(800))

  const { data, refetch, isLoading } =
    type === 'Lead'
      ? trpc.file.getFilesByLeadId.useQuery({ lead_id: id, skip, take: TAKE, search, onlyContract: isContract })
      : trpc.file.getFilesByOfferId.useQuery({ offer_id: id, skip, take: TAKE, search, onlyContract: isContract })
  const getLinks = trpc.file.createUploadFileLink.useMutation()
  const getViewFileLink = trpc.file.viewFileLink.useMutation()

  const onComplete = {
    onSuccess: () => {
      refetch()
      toast.success('Successfully added file')
    },
    onError: () => {
      toast.error('File upload failed')
    }
  }

  const deleteFileMutation = trpc.file.deleteFile.useMutation({
    onSuccess: () => {
      toast.success('Succesfully deleted file')
      refetch()
    },
    onError: () => toast.error('File deletion failed')
  })
  const createLeadFileMutation = trpc.file.createLeadFile.useMutation(onComplete)
  const createOfferFileMutation = trpc.file.createOfferFile.useMutation(onComplete)

  const updateFileMutation = trpc.file.updateFile.useMutation({
    onSuccess: (_, variables) => {
      refetch()
      toast.success(
        variables.name
          ? 'File name updated'
          : variables.description
          ? 'Description updated'
          : variables.is_contract !== undefined
          ? 'Contract file updated'
          : ''
      )
      setFileToRenameInViewer(undefined)
    }
  })

  const previewFile = (file: FileOutput) => {
    if (file.is_file_in_azure === false) {
      setFileNotFoundDialogOpen(true)
      return
    }
    if (!mime.getType(file.type) && !mime.getExtension(file.type)) return toast.error('Unable to preview file')
    const type = mime.getExtension(file.type) ? file.type : (mime.getType(file.type) as string)
    if (!isExcelFile(file)) {
      getViewFileLink
        .mutateAsync({ id: file.id })
        .then(async (res) => {
          const response = await axios.get<ArrayBuffer>(res, { responseType: 'arraybuffer' })
          const objectUrl = URL.createObjectURL(new Blob([response.data], { type }))
          if (viewFilePreference === 'NEW_TAB') {
            const el = document.createElement('a')
            el.href = objectUrl
            el.hidden = true
            el.target = '_blank'
            el.click()
            el.remove()
          } else {
            setPreview({ objectUrl, fileId: file.id })
          }
        })
        .catch((e) => toast.error(`Failed to get file. ${e}`))
    }
  }

  const addFile = async (file: File) => {
    const { containerUrl, sasQuery } = (await getLinks.mutateAsync()) || {}
    if (containerUrl && sasQuery) {
      const arrayBufferFile = await file.arrayBuffer()
      const filePath = `${type}/${id}/${new Date().getTime()}-${file.name}`
      const container = new ContainerClient(containerUrl.concat('?').concat(sasQuery))
      return container
        .uploadBlockBlob(filePath, arrayBufferFile, arrayBufferFile.byteLength)
        .then(() => {
          if (type === 'Lead') {
            createLeadFileMutation.mutate({
              lead_id: id,
              name: file.name,
              url: filePath,
              type: file.type,
              size: arrayBufferFile.byteLength
            })
          } else {
            createOfferFileMutation.mutate({
              offer_id: id,
              name: file.name,
              url: filePath,
              type: file.type,
              size: arrayBufferFile.byteLength
            })
          }
        })
        .catch((e) => toast.error(`Failed to create file. ${e}`))
    }
    throw Error('Failed to get secure link to upload file.')
  }

  const handleDelete = (file: FileOutput, callback?: () => void) => {
    confirm({ title: 'Delete File', description: `Are you sure you want to delete ${file.name}?` }).then(() => {
      deleteFileMutation.mutate({ id: file.id })
      callback?.()
    })
  }

  const handleDropAccepted = (files: File[]) => {
    files.forEach(async (file) => {
      addFile(file)
    })
  }

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (!data || preview === undefined || isLoading) return
      event.preventDefault()

      const isLeft = event.key === 'ArrowLeft'
      const isRight = event.key === 'ArrowRight'
      const fileIndex = data?.results.findIndex((file) => file.id === preview?.fileId)
      if (
        (isLeft && skip === 0 && Number(fileIndex) < 0) ||
        (isRight && Number(fileIndex) + skip >= Number(data?.count) - 1) ||
        (!isLeft && !isRight)
      ) {
        return
      }
      const index = data.results.findIndex((file) => file.id === preview.fileId)
      const file = isLeft ? data.results[index - 1] : data.results[index + 1]
      if (file) {
        previewFile(file)
      } else {
        try {
          setSkip((skip) => (skip > 0 ? skip - TAKE : 10))
          const res = await refetch()
          if (res.data !== undefined && (res.data.results.length ?? 0 > 0)) {
            if (isLeft) {
              previewFile(res.data.results[TAKE - 1])
            } else {
              previewFile(res.data.results[0])
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [data, preview, refetch, TAKE, skip])

  return (
    <ReactDropzone
      multiple
      maxSize={Infinity}
      noClick
      onDropAccepted={handleDropAccepted}
      onDropRejected={(rejections) => {
        const rejectionMessages = rejections[0].errors.map((err) => err.message).join('\n')
        toast.error(rejectionMessages)
      }}
    >
      {({ getRootProps, open }) => (
        <Grid {...getRootProps()} container className="flex flex-col gap-6">
          <FileHeader
            fileCount={data?.count}
            setSearch={setSearch}
            open={open}
            isGetLinksLoading={getLinks.isLoading}
            type={type}
            addFile={addFile}
            isCreateLeadFileMutationLoading={createLeadFileMutation.isLoading}
            isCreateOfferFileMutationLoading={createOfferFileMutation.isLoading}
            id={id}
            refetch={refetch}
            isContract={isContract}
            setContract={setContract}
          />
          <Grid container item>
            {data?.results.map((f: FileOutput) => (
              <FileItem
                key={f.id}
                file={f}
                fileNotFoundDialogOpen={fileNotFoundDialogOpen}
                setFileNotFoundDialogOpen={setFileNotFoundDialogOpen}
                handleDelete={handleDelete}
                setFileToRename={setFileToRename}
                setFileToDescribe={setFileToDescribe}
                previewFile={previewFile}
                updateFileContract={async ({ is_contract, id }) => {
                  await updateFileMutation.mutateAsync({ id, is_contract })
                  onContractFileUpdateSuccess?.()
                }}
                updateIsLoading={updateFileMutation.isLoading}
              />
            ))}
          </Grid>
          <Grid item display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil((data?.count || 0) / TAKE)}
              boundaryCount={2}
              variant="outlined"
              shape="rounded"
              onChange={(_e, page) => setSkip((page - 1) * TAKE)}
              page={Math.ceil(skip / TAKE) + 1}
            />
          </Grid>
          {Boolean(fileToRename) && <RenameFile refetch={refetch} file={fileToRename!} setFile={setFileToRename} />}
          {Boolean(fileToDescribe) && <AddDescription refetch={refetch} file={fileToDescribe!} setFile={setFileToDescribe} />}
          <Modal open={Boolean(preview)} onClose={() => setPreview(undefined)} className="flex justify-center">
            <Box>
              {(skip > 0 || Number(data?.results.findIndex((file) => file.id === preview?.fileId)) > 0) && (
                <LoadingButton
                  loading={isLoading || getViewFileLink.isLoading}
                  onClick={async (e) => {
                    e.stopPropagation()
                    const index = data?.results.findIndex((file) => file.id === preview?.fileId)
                    const prevFile = data?.results[Number(index) - 1]
                    if (prevFile) {
                      //prev file is within current data set
                      previewFile(prevFile)
                    } else {
                      setSkip((skip) => skip - TAKE)
                      const res = await refetch()
                      if (res.data) previewFile(res.data.results[TAKE - 1])
                    }
                  }}
                  className="text-white absolute left-2 top-1/2"
                >
                  <ChevronLeft sx={{ fontSize: '50px' }} />
                </LoadingButton>
              )}

              <iframe
                style={{ zIndex: 100 }}
                title={data?.results.find((result) => result.id === preview?.fileId)?.name || ''}
                src={preview?.objectUrl}
                width={smScreen ? '80%' : '800px'}
                height="100%"
                className="relative"
              />
              <Box sx={{ position: 'absolute', marginTop: 16, right: { lg: 442, xl: 1005 }, top: -113 }}>
                {fileToRenameInViewer ? (
                  <Box
                    className="flex items-center gap-2"
                    style={{ zIndex: 500, position: 'absolute', top: -4, right: -121, backgroundColor: '#323639' }}
                  >
                    <TextField
                      value={renamedFileInViewer}
                      onChange={(e) => setRenamedFileInViewer(stripFileNameExt(e.target.value))}
                      autoFocus
                      style={{ backgroundColor: 'white', borderRadius: 8, width: 210, zIndex: 600, top: -4 }}
                    />
                    <Button
                      onClick={() => {
                        const fileExt = getFileNameExtension(fileToRenameInViewer.name)
                        updateFileMutation.mutate({ id: fileToRenameInViewer.id, name: renamedFileInViewer?.concat(fileExt ? `${fileExt}` : '') })
                      }}
                      className="bg-primary-400 text-white"
                      style={{ zIndex: 700, top: -4 }}
                    >
                      Save
                    </Button>
                    <Button onClick={() => setFileToRenameInViewer(undefined)} className=" text-white" style={{ zIndex: 700, top: -4 }}>
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  <Box className="flex items-center gap-2">
                    <Button
                      onClick={() => {
                        const currFile = data?.results.find((result) => result.id === preview?.fileId)
                        if (currFile) {
                          setFileToRenameInViewer(currFile)
                          setRenamedFileInViewer(currFile.name)
                        }
                      }}
                      className="bg-black text-white border-solid border border-white h-6"
                      style={{ zIndex: 200 }}
                    >
                      Rename
                    </Button>
                    <IconButton
                      onClick={() => {
                        const currFile = data?.results.find((result) => result.id === preview?.fileId)
                        if (currFile) {
                          handleDelete(currFile, () => setPreview(undefined))
                        }
                      }}
                      className="z-[500] p-0.5"
                    >
                      <Trash2 className="text-white w-4" />
                    </IconButton>
                  </Box>
                )}
              </Box>
              {Number(data?.results.findIndex((file) => file.id === preview?.fileId)) + skip < Number(data?.count) - 1 && (
                <LoadingButton
                  loading={isLoading || getViewFileLink.isLoading}
                  onClick={async (e) => {
                    e.stopPropagation()
                    const index = data?.results.findIndex((file) => file.id === preview?.fileId)
                    const nextFile = data?.results[Number(index) + 1]
                    if (nextFile) {
                      //next file is within current data set
                      previewFile(nextFile)
                    } else {
                      setSkip((skip) => skip + TAKE)
                      const res = await refetch()
                      if (res.data) previewFile(res.data.results[0])
                    }
                  }}
                  className="text-white absolute right-2 top-1/2"
                >
                  <ChevronRight sx={{ fontSize: '50px' }} />
                </LoadingButton>
              )}
            </Box>
          </Modal>
        </Grid>
      )}
    </ReactDropzone>
  )
}

export default Files

import { Box, Button, Typography } from '@mui/material'
import { GetMessagesOutput } from '../../../../../types/procedureOutputs'
import UserAvatar from '../../../../../components/UserAvatar'
import { formatDurationShorthand, getAzureAccount } from '../../../../../utils/helpers'
import { LoadingButton } from '@mui/lab'
import clsx from 'clsx'
import { Edit2, Trash } from 'react-feather'
import { trpc } from '../../../../../core/trpc'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { useSession } from 'next-auth/react'
import ReactMentionsInput from '../../../../../components/ReactMentionsInput'
import TaggedText from '../../../../../components/TaggedText'

type Props = {
  childMessage: GetMessagesOutput['results'][number]['child_messages'][number]
  fetchMessages: ({ concat }: { concat: boolean }) => Promise<void>
}

const ChildMessage = ({ childMessage, fetchMessages }: Props) => {
  const user = useSession().data?.user
  const [isEdit, setEdit] = useState(false)
  const [editedMessageVal, setEditedMessageVal] = useState(childMessage.message)
  const deleteMutation = trpc.message.deleteMessage.useMutation({
    onSuccess: () => {
      toast.success('Comment deleted')
      fetchMessages({ concat: false })
    },
    onError: (e) => toast.error(String(e.message))
  })

  const updateMutation = trpc.message.updateMessage.useMutation({
    onSuccess: () => {
      toast.success('Comment updated')
      setEdit(false)
      fetchMessages({ concat: false })
    },
    onError: (e) => toast.error(String(e.message))
  })
  const actionButtons = [
    {
      id: 'edit',
      icon: <Edit2 className="w-4 text-grey-700" />,
      action: () => setEdit(true),
      hidden: childMessage.created_by_id !== user?.id
    },
    {
      id: 'delete',
      icon: <Trash className="w-4 text-grey-700" />,
      action: () => deleteMutation.mutate({ id: childMessage.id }),
      loading: deleteMutation.isLoading,
      hidden: childMessage.created_by_id !== user?.id
    }
  ]
  return (
    <Box key={childMessage.id} className="flex gap-2 items-start">
      <UserAvatar
        id={childMessage.created_by_id}
        name={childMessage.created_by.name || ''}
        microsoftUserId={getAzureAccount(childMessage.created_by.accounts)?.microsft_user_id || undefined}
        dontDisplayName
      />
      <Box className="w-full">
        <Box className="flex gap-5">
          {isEdit ? (
            <Box className="w-full">
              <Box className="border-[1px] border-grey-300 border-solid py-3 px-[14px] rounded-lg w-full shadow-sm">
                <ReactMentionsInput autoFocus value={editedMessageVal} onChange={(val) => setEditedMessageVal(val)} />
              </Box>
              <Box className="flex gap-3 mt-3 justify-end">
                <Button onClick={() => setEdit(false)} size="small" variant="outlined">
                  Cancel
                </Button>
                <LoadingButton
                  loading={updateMutation.isLoading}
                  disabled={!editedMessageVal}
                  onClick={() => {
                    updateMutation.mutate({ message: editedMessageVal, id: childMessage.id })
                  }}
                  size="small"
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </Box>
            </Box>
          ) : (
            <TaggedText text={childMessage.message} />
          )}
          {!isEdit && (
            <Box className="flex items-center gap-2 actions" sx={{ visibility: 'hidden' }}>
              {actionButtons.map((actionButton) => (
                <LoadingButton
                  loading={actionButton.loading}
                  onClick={actionButton.action}
                  className={`p-1 min-w-fit ${clsx({ hidden: actionButton.hidden })}`}
                  key={actionButton.id}
                >
                  {actionButton.icon}
                </LoadingButton>
              ))}
            </Box>
          )}
        </Box>
        <Typography className="text-grey-500 text_sm_normal">{formatDurationShorthand(new Date(childMessage.created_at))} ago</Typography>
      </Box>
    </Box>
  )
}

export default ChildMessage

import { Box, Button, Chip, Typography } from '@mui/material'
import { Lead } from '.'
import { useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import UpdateStagePopper from '../pipelineLeads/UpdateStagePopper'
import { Calendar } from 'react-feather'
import { formatDistanceFromNowCustom } from '../../../utils/helpers'
import { Can } from '../../../contexts/AbilityContext'
import clsx from 'clsx'
import ValidateModal from '../pipelineLeads/ValidateModal'
import { ContactGetContactByCompanyOutput, LeadRequiredColumnValues, PipelineStagesOutput } from '../../../types/procedureOutputs'
import NewEmailDrawer from '../../../components/email/NewEmailDrawer'
import AddFollowUp from '../../../components/AddFollowUp'
import { ErrorOutlineOutlinedIcon } from '../../../assets/icons'

type StageCardProps = {
  lead: Lead
  primaryContact?: ContactGetContactByCompanyOutput | undefined
  columnValues: LeadRequiredColumnValues | undefined
}
const StageCard = ({ lead, primaryContact, columnValues }: StageCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [followUpOpen, setFollowUpOpen] = useState(false)
  const [showValidateModal, setShowValidateModal] = useState<PipelineStagesOutput[0]['stages'][0] | undefined>(undefined)
  const isOverdue = lead?.followup_date && new Date(lead?.followup_date) < new Date() ? true : false
  const [openEmailDrawer, setOpenEmailDrawer] = useState<boolean>(false)

  return (
    <Box className="border-[1px] border-solid bg-white border-grey-200 rounded-xl p-4 flex gap-8 align-middle">
      <Box className="flex flex-wrap gap-1 justify-between w-full">
        <Box className="flex flex-wrap flex-row gap-[24px] items-center">
          <Typography className="text_lg_semibold text-grey-900">Stage</Typography>
          <Chip
            className="py-1.5 px-3"
            sx={{ backgroundColor: `${lead?.stage.color} !important`, color: `${lead.stage.text_color || 'white'} !important` }}
            label={
              <Typography data-cy="stageName" className="text_sm_medium tracking-[1px] uppercase">
                {lead?.stage?.name}
              </Typography>
            }
          />
          <Can I="edit" a="Lead">
            <Button
              data-cy="moveStageButton"
              onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
              className="shadow-sm border-[1px] border-solid border-grey-300 rounded-lg px-5 py-3 bg-white"
            >
              <Typography className="text_md_medium text-grey-600">Move to Stage</Typography>
              {anchorEl ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </Can>
          {Boolean(anchorEl) && (
            <UpdateStagePopper
              lead={lead}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              setShowValidateModal={(stage) => setShowValidateModal(stage)}
            />
          )}
        </Box>
        <Button
          variant="outlined"
          className="rounded-xl py-[5px] px-[12px] gap-[12px] flex justify-end items-center"
          onClick={() => setFollowUpOpen(!followUpOpen)}
        >
          <Calendar className="h-[16px] w-[16px] text-grey-700" />
          <Box className="flex-col">
            <Typography className="text_sm_medium">{lead?.followup_date ? 'Next Follow Up' : 'No Scheduled Follow Up'}</Typography>
            {lead?.followup_date && (
              <Typography data-cy="followupDate" className={clsx('text_sm_normal', isOverdue ? 'text-error-600' : 'text-grey-700')}>
                {formatDistanceFromNowCustom(lead?.followup_date)}
              </Typography>
            )}
            {!lead?.followup_date && <Typography className="text-sm font-medium text-primary-500">Add Follow-Up</Typography>}
          </Box>
          {lead.followup_urgent && (
            <Chip
              className="py-0.5 bg-error-400 text-white"
              label={
                <Box className="flex gap-2 items-center">
                  <ErrorOutlineOutlinedIcon fontSize="small" />
                  <Typography className="text_xs_medium tracking-widest uppercase">Urgent</Typography>
                </Box>
              }
            />
          )}
        </Button>
      </Box>
      {followUpOpen && (
        <AddFollowUp
          lead={{
            followup_date: lead.followup_date,
            followup_type: lead.followup_type,
            followup_details: lead.followup_details,
            lead_id: lead.id,
            is_urgent: lead.followup_urgent
          }}
          open={followUpOpen}
          setOpen={setFollowUpOpen}
          contact={primaryContact}
          setOpenEmailDrawer={setOpenEmailDrawer}
        />
      )}
      {openEmailDrawer && <NewEmailDrawer open={true} setOpen={() => setOpenEmailDrawer(false)} />}

      {showValidateModal && columnValues && (
        <ValidateModal stage={showValidateModal} columnValues={columnValues} closeModal={() => setShowValidateModal(undefined)} />
      )}
    </Box>
  )
}
export default StageCard

import { Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import Search from '../Search'
import ImportRelatedFiles from './ImportRelatedFiles'
import { AddCircleOutlineOutlined } from '@mui/icons-material'
import { UploadCloud } from 'react-feather'
import { pluralize } from '../../utils/helpers'
import { useState } from 'react'
import { trpc } from '../../core/trpc'

type FileHeaderProps = {
  fileCount: number | undefined
  setSearch: React.Dispatch<React.SetStateAction<string>>
  open: () => void
  isGetLinksLoading: boolean
  type: 'Lead' | 'Offer'
  addFile: (file: File) => Promise<string | void>
  isCreateOfferFileMutationLoading: boolean
  isCreateLeadFileMutationLoading: boolean
  id: string
  refetch: () => void
  isContract: boolean
  setContract: React.Dispatch<React.SetStateAction<boolean>>
}

const FileHeader = ({
  fileCount,
  setSearch,
  open,
  isGetLinksLoading,
  type,
  addFile,
  isCreateLeadFileMutationLoading,
  isCreateOfferFileMutationLoading,
  id,
  refetch,
  isContract,
  setContract
}: FileHeaderProps) => {
  const [importFilesAnchorEl, setImportFilesAnchorEl] = useState<HTMLElement>()
  const { data: leadById } = trpc.lead.getLeadById.useQuery({ id }, { enabled: Boolean(type === 'Lead') })
  const { data: companyLeads } = trpc.lead.getLeadsByCompany.useQuery({ companyId: leadById?.company_id || '' }, { enabled: Boolean(leadById) })

  return (
    <Grid container item justifyContent="space-between">
      <Grid item display="flex">
        <Typography className="self-center text-lg font-semibold">Files</Typography>
        <Typography className="self-center pl-2 text-grey-500 text-base font-normal">{`${fileCount || 0} ${pluralize(
          'file',
          fileCount || 0
        )}`}</Typography>
      </Grid>
      <Grid container item width="initial">
        <Grid item>
          <Search sx={{ width: '175px', mr: '8px' }} onSearch={(input) => setSearch(input)} isDebounced />
        </Grid>
        <Grid item>
          <FormControlLabel label="Show contract only" checked={isContract} onChange={(_, checked) => setContract(checked)} control={<Switch />} />
        </Grid>
        <Grid item>
          <Button
            component="label"
            disabled={isGetLinksLoading || type === 'Lead' ? isCreateLeadFileMutationLoading : isCreateOfferFileMutationLoading}
            startIcon={<AddCircleOutlineOutlined />}
            variant="contained"
            onClick={open}
          >
            New File
            <input
              hidden
              disabled={isGetLinksLoading || type === 'Lead' ? isCreateLeadFileMutationLoading : isCreateOfferFileMutationLoading}
              type="file"
              onChange={(e) => {
                const file = e.target?.files?.[0] as File
                addFile(file)
                e.target.value = ''
              }}
            />
          </Button>
        </Grid>
        {companyLeads && companyLeads?.length > 1 && (
          <Grid item className="ml-2">
            <Button onClick={(e) => setImportFilesAnchorEl(e.currentTarget)} variant="contained" startIcon={<UploadCloud className="w-5" />}>
              Import Files
            </Button>
            {Boolean(importFilesAnchorEl) && (
              <ImportRelatedFiles leadId={id} anchorEl={importFilesAnchorEl} setAnchorEl={setImportFilesAnchorEl} refetch={refetch} />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default FileHeader

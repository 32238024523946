import Mailer from './mailer'
import { SendEmailPayload, SendEmailResult } from "./provider";

export async function sendEmail(payload: SendEmailPayload): Promise<SendEmailResult> {
  const mailer = new Mailer();
  const result = await mailer.sendEmail(payload);
  return result;
}

export { default as Mailer } from './mailer'

import { getEmailId, getForwardedMessageId } from '../services/azureServices'
import { MicrosoftAttachment } from '@libs/azure-graph'
import { range } from 'ramda'

const associateMessageReplyAndForward = async ({
  now,
  associateLead,
  sendType,
  pubId,
  mailboxEmailAddress
}: {
  now: Date
  associateLead: (email: MicrosoftEmail) => void
  sendType: 'reply' | 'replyAll' | 'forward' | undefined
  pubId: string
  mailboxEmailAddress: string
}) => {
  let timesTried = 0
  const interval = setInterval(() => {
    if (sendType === 'forward') {
      getForwardedMessageId(now, mailboxEmailAddress).then(async (message) => {
        if (message) {
          associateLead(message)
          clearInterval(interval)
        }
      })
    } else {
      getEmailId(pubId, mailboxEmailAddress).then(async (res) => {
        if (res !== null) {
          associateLead(res)
          clearInterval(interval)
        }
      })
    }

    timesTried++
    if (timesTried === 120) {
      clearInterval(interval)
    }
  }, 5000)
}

const getContentWithImages = ({
  content,
  attachments,
  from,
  userEmail
}: {
  content: string
  attachments: MicrosoftAttachment[]
  from: string
  userEmail: string
}): string => {
  const el = document.createElement('html')
  el.innerHTML = content
  const imgEls = el.getElementsByTagName('img')
  range(0, imgEls.length).map((i) => {
    const imgEl = imgEls.item(i)!
    const srcContentId = imgEls.item(i)!.src?.replace('cid:', '')
    const attachmentObj = attachments.find((a) => a.contentId === srcContentId)
    const isTracking = isTrackingImg({ img: imgEl, from, userEmail })
    if (isTracking) {
      imgEl.src = ''
      return ''
    }
    const imgBase64 = attachmentObj ? `data:${attachmentObj.contentType};base64,${attachmentObj.contentBytes}` : ''
    if (!imgBase64) return ''
    imgEl.src = imgBase64
    return ''
  })
  return el.innerHTML
}

const isTrackingImg = ({ img, from, userEmail }: { img: HTMLImageElement; from: string; userEmail: string }) => {
  if (img.id === 'trackingImg' && from.toLowerCase() === userEmail.toLowerCase()) {
    return true
  } else {
    return false
  }
}

export { associateMessageReplyAndForward, getContentWithImages }

import { Globe, Mail, Phone, User } from 'react-feather'
import { Offer } from '.'
import { OfferGetOfferById } from '../../../types/procedureOutputs'
import { Box, Stack, Typography } from '@mui/material'

export const getOfferFields = (offer: OfferGetOfferById) =>
  [
    {
      value: offer?.funder.name,
      icon: <User className="text-grey-500 w-[18px]" />,
      label: 'Funder Name',
      field: 'name'
    },
    {
      value: offer?.funder.industry_title,
      icon: <Globe className="text-grey-500 w-[18px]" />,
      label: 'Industry',
      field: 'industry'
    },
    {
      value: offer?.funder?.primary_contact?.phone,
      icon: <Phone className="text-grey-500 w-[18px]" />,
      label: 'Phone',
      field: 'phone'
    },
    {
      value: offer?.funder?.primary_contact?.email,
      icon: <Mail className="text-grey-500 w-[18px]" />,
      label: 'Email',
      field: 'email'
    }
  ] as const

type InfoProps = {
  offer: Offer
}

const Info = ({ offer }: InfoProps) => {
  const offerData = getOfferFields(offer)

  const valueDisplay = (data: (typeof offerData)[number]) => {
    if (data.field === 'industry') {
      return (
        <Box className="flex gap-1">
          <Typography className="text_md_medium text-grey-800">{data.value}</Typography>
        </Box>
      )
    }

    return <Typography className="text-grey-900">{data.value}</Typography>
  }
  return (
    <Stack gap="24px">
      {offerData.map((data) => (
        <Box data-cy={`offerDetails-${data.field}`} key={data.label} className={`grid grid-cols-[135px_1fr] items-start`}>
          <Box className="flex gap-3 items-center">
            {data.icon}
            <Typography className="text-grey-500">{data.label}</Typography>
          </Box>
          {valueDisplay(data)}
        </Box>
      ))}
    </Stack>
  )
}

export default Info

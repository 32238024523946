import React, { useMemo } from 'react'
import { Badge, Box, Typography } from '@mui/material'
import { NotificationUserNotificationOutput } from '../../types/procedureOutputs'
import UserAvatar from '../UserAvatar'
import { LoadingButton } from '@mui/lab'
import { formatDistanceToNowCustom, getAzureAccount } from '../../utils/helpers'
import { useNavigate } from 'react-router'
import { trpc } from '../../core/trpc'
import toast from 'react-hot-toast'
import clsx from 'clsx'
import { useQueryClient } from '@tanstack/react-query'

type Notification = NotificationUserNotificationOutput['results'][number]

type NotificationCardProps = {
  notification: Notification
  origin: 'page' | 'topBar'
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  updateNotification?: (ids: string[]) => void
}

const NotificationCard = ({ notification, origin, setAnchorEl, updateNotification }: NotificationCardProps) => {
  const navigate = useNavigate()
  const client = useQueryClient()

  const title = useMemo(() => {
    if (!notification) return ''
    if (notification.ref_type === 'NOTE') {
      return 'tagged you on a note'
    }
    if (notification.ref_type === 'COMMENT') {
      return 'tagged you on a comment'
    }
    if (notification.ref_type === 'OFFER') {
      return 'lead was submitted for offers'
    }
    if (notification.ref_type === 'ASSIGNED_TO') {
      if (notification.lead != null) {
        return `assigned you to ${notification.company?.name.toUpperCase()} - ${notification.lead.name}`
      }

      return `assigned you to ${notification.company?.name.toUpperCase()}`
    }
    if (notification.ref_type === 'OFFER_APPROVED') {
      return 'offer accepted'
    }
  }, [notification])

  const path = useMemo(() => {
    if (notification.ref_type === 'COMMENT' || notification.ref_type === 'NOTE') {
      if (notification.activity?.lead.stage.pipeline_id) {
        return `/pipeline/${notification.activity?.lead.stage.pipeline_id}/lead/${notification.activity?.lead_id}`
      }
      return `/pipeline/${notification.lead?.stage.pipeline_id}/lead/${notification.lead_id}`
    } else if (notification.ref_type === 'OFFER' || notification.ref_type === 'OFFER_APPROVED') {
      return `/pipeline/${notification.offer?.lead.stage.pipeline_id}/lead/${notification.offer?.lead?.id}`
    } else if (notification.ref_type === 'ASSIGNED_TO') {
      if (notification.lead != null) {
        return `/pipeline/${notification.lead?.stage.pipeline_id}/lead/${notification.lead.id}`
      }
      return `/pipeline/${notification.company?.leads[0]?.stage.pipeline_id}/lead/${notification.company?.leads[0]?.id}`
    } else {
      return `/pipeline/${notification.lead?.stage?.pipeline_id}/lead/${notification.lead?.id}`
    }
  }, [notification])

  const mutation = trpc.notification.dismissNotification.useMutation({
    onSuccess: () => {
      client.refetchQueries({ queryKey: [['notification', 'getUserNotifications'], { type: 'query' }] })
      if (updateNotification) updateNotification([notification.id])
    },
    onError: (e) => toast.error(`Failed to dismiss: ${e.message}`)
  })

  return (
    <Box
      className={`${clsx({
        'bg-[#F4FAFE]': origin === 'page' && !notification.dismissed_at,
        'bg-white': origin === 'page' && notification.dismissed_at,
        'px-5 py-4': origin === 'page',
        'pl-0': origin === 'topBar'
      })} flex w-full justify-between hover:bg-grey-100 p-2`}
    >
      <Box
        onClick={() => {
          navigate(path)
          if (setAnchorEl) setAnchorEl(null)
        }}
        className="flex gap-3 cursor-pointer"
      >
        <UserAvatar
          dontDisplayName={true}
          className="w-8 h-8"
          id={notification.id}
          name={notification.created_by?.name || ''}
          microsoftUserId={getAzureAccount(notification.created_by?.accounts || [])?.microsft_user_id || undefined}
        />
        <Box>
          <Typography component="span" className="text-grey-700 text_sm_medium mr-1">
            {notification.created_by?.name || ''}
          </Typography>
          <Typography component="span" className="text-grey-700 text_sm_normal">
            {title}
          </Typography>
          <Typography className="text_xs_normal text-grey-500">{formatDistanceToNowCustom(notification.created_at)}</Typography>
          {notification.ref_type === 'NOTE' && (
            <>
              <Typography className="text-grey-500 text_sm_normal mt-2 font-bold ">
                {notification?.activity?.lead?.company?.name} - {notification?.activity?.lead?.name}
              </Typography>
              <Typography className="text-grey-500 text_sm_normal mt-2">{notification?.activity?.notes}</Typography>
            </>
          )}
          {notification.ref_type === 'COMMENT' && (
            <>
              <Typography className="text-grey-500 text_sm_normal mt-2 font-bold ">
                {notification?.lead?.company.name} - {notification?.lead?.name}
              </Typography>
              <Typography className="text-grey-500 text_sm_normal mt-2">{notification?.message?.message}</Typography>
            </>
          )}
          {(notification.ref_type === 'OFFER' || notification.ref_type === 'OFFER_APPROVED') && (
            <>
              <Typography className="text-grey-500 text_sm_normal mt-2 font-bold ">
                {notification?.offer?.lead?.company?.name} - {notification?.offer?.lead?.name}
              </Typography>
              <Typography className="text-grey-500 text_sm_normal mt-2">{notification?.offer?.funder?.name}</Typography>
            </>
          )}
          {notification.ref_type === 'SHOP_DEAL_DRAFT' && (
            <Typography className="text-grey-500 text_sm_normal mt-2 font-bold ">
              {notification.created_by?.name} submitted a shop deal draft for review on
              {notification?.lead?.company?.name} - {notification?.lead?.name} with funder
              {notification?.lead?.offer_drafts?.length ?? 0 > 1 ? 's' : ''} {notification.lead?.offer_drafts.map((o) => o.funder?.name).join(', ')}
            </Typography>
          )}
        </Box>
      </Box>
      {!notification.dismissed_at && (
        <Box>
          <LoadingButton
            loading={mutation.isLoading}
            onClick={() => mutation.mutate({ id: notification.id })}
            className="text_sm_medium text-primary-600"
          >
            {origin === 'topBar' ? 'Dismiss' : 'Mark as Read'}
          </LoadingButton>
          {origin === 'page' && <Badge variant="dot" color="success" />}
        </Box>
      )}
    </Box>
  )
}

export default NotificationCard

const textColors = {
  A: '#6b5310',
  B: '#8a0c03',
  C: '#9e2f03',
  D: '#8c6606',
  E: '#286902',
  F: '#2a5c2a',
  G: '#027d58',
  H: '#03827a',
  I: '#06637a',
  J: '#043d63',
  K: '#0d2066',
  L: '#240273',
  M: '#600273',
  N: '#b013a6',
  O: '#85095e',
  P: '#910632',
  Q: '#750510',
  R: '#e36f02',
  S: '#0471bf',
  T: '#163bf5',
  U: '#f59816',
  V: '#e30b74',
  W: '#0492b5',
  X: '#56234e',
  Y: '#5e0f57',
  Z: '#615844'
}

export const getAvatarColors = (name: string) => {
  const firstLetter = name[0]?.toUpperCase()
  if (!textColors[firstLetter as keyof typeof textColors]) {
    return {
      color: '#4B5565',
      backgroundColor: '#EEF2F6'
    }
  }
  const color = textColors[firstLetter as keyof typeof textColors]
  return {
    color,
    backgroundColor: `${color}33`
  }
}

import { Autocomplete, Box, Button, Dialog, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import { X } from 'react-feather'
import { trpc } from '../../../core/trpc'
import { useState } from 'react'
import { GetStageAutoMoveRulesOutput } from '../../../types/procedureOutputs'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'

type Props = {
  open: boolean
  closeModal: () => void
  existingAutoStageRule?: GetStageAutoMoveRulesOutput[number]
  refetch: () => void
}

const AddEditAutoStageModal = ({ open, closeModal, existingAutoStageRule, refetch }: Props) => {
  const [autoStageRule, setAutoStageRule] = useState({
    startingStageId: existingAutoStageRule?.starting_stage_id || '',
    targetStageId: existingAutoStageRule?.target_stage_id || '',
    inactiveDays: existingAutoStageRule?.days_of_inactivity ? String(existingAutoStageRule?.days_of_inactivity) : '',
    reason: existingAutoStageRule?.reason || ''
  })

  const updateMutation = trpc.stageAutoMove.updateStageAutoMoveRule.useMutation({
    onSuccess: () => {
      toast.success('Auto-stage updated')
      closeModal()
      refetch()
    }
  })
  const createMutation = trpc.stageAutoMove.createStageAutoMoveRule.useMutation({
    onSuccess: () => {
      toast.success('Auto-stage created')
      closeModal()
      refetch()
    }
  })
  const { data: startingStagesData } = trpc.stage.getStagesGroupedByPipeline.useQuery({})
  const { data: targetStagesData } = trpc.stage.getStagesGroupedByPipeline.useQuery({})
  const flatTargetStages = targetStagesData?.flatMap((pipeline) => pipeline.stages)
  const flatStartingStages = startingStagesData?.flatMap((pipeline) => pipeline.stages)

  const handleSave = () => {
    const { startingStageId, targetStageId, inactiveDays, reason } = autoStageRule
    if (existingAutoStageRule) {
      updateMutation.mutate({ id: existingAutoStageRule.id, startingStageId, targetStageId, inactiveDays: Number(inactiveDays), reason })
    } else {
      createMutation.mutate({ startingStageId, targetStageId, inactiveDays: Number(inactiveDays), reason })
    }
  }
  const title = existingAutoStageRule ? 'Edit' : 'New'
  const buttonText = existingAutoStageRule ? 'Save' : 'Set Rule'
  const isSaveDisabled = !existingAutoStageRule && (!autoStageRule.inactiveDays || !autoStageRule.startingStageId || !autoStageRule.targetStageId)
  const mutation = existingAutoStageRule ? updateMutation : createMutation

  const startingStageObject = flatStartingStages?.find((stage) => stage.id === autoStageRule.startingStageId)
  const targetStageObject = flatStartingStages?.find((stage) => stage.id === autoStageRule.targetStageId)
  return (
    <Dialog open={open}>
      <Box className="p-5 w-[560px]">
        <Box className="flex justify-between items-center mb-6">
          <Typography className="text-grey-900 text_xl_medium">{title} auto-stage rule</Typography>
          <IconButton onClick={() => closeModal()}>
            <X className="text-grey-700 w-5" />
          </IconButton>
        </Box>
        <Stack gap="24px">
          <Box>
            <Typography className="text-grey-700 mb-1.5">Select Starting Stage</Typography>
            <Autocomplete
              options={flatStartingStages || []}
              value={startingStageObject || null}
              onChange={(_, value) => {
                setAutoStageRule({ ...autoStageRule, startingStageId: value?.id || '' })
              }}
              getOptionLabel={(option) => `${option.pipeline.name}-${option.name}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Inactive Days</Typography>
            <TextField
              fullWidth
              value={autoStageRule.inactiveDays}
              onChange={(e) => setAutoStageRule({ ...autoStageRule, inactiveDays: e.target.value })}
              type="number"
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none'
                }
              }}
              onWheel={(e) => e.preventDefault()}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Target Stage</Typography>
            <Autocomplete
              options={flatTargetStages?.filter((stage) => stage.id !== autoStageRule.startingStageId) || []}
              value={targetStageObject || null}
              onChange={(_, value) => {
                setAutoStageRule({ ...autoStageRule, targetStageId: value?.id || '' })
              }}
              getOptionLabel={(option) => `${option.pipeline.name}-${option.name}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Reason</Typography>
            <TextField fullWidth value={autoStageRule.reason} onChange={(e) => setAutoStageRule({ ...autoStageRule, reason: e.target.value })} />
          </Box>
        </Stack>
        <Divider className="my-6" />
        <Box className="flex justify-end gap-2">
          <Button onClick={() => closeModal()} variant="outlined">
            Cancel
          </Button>
          <LoadingButton disabled={isSaveDisabled} loading={mutation.isLoading} onClick={handleSave} variant="contained">
            {buttonText}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddEditAutoStageModal

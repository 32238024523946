import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import { ArrowBackIcon, ChatBubbleOutlineOutlinedIcon, PhoneOutlinedIcon } from '../../../../assets/icons'
import { useState } from 'react'
import { ThumbsUp } from '../../../../icons/ThumbsUp'
import { ThumbsDown } from '../../../../icons/ThumbsDown'
import clsx from 'clsx'
import IconCircleBackground from '../../../../components/IconCircleBackground'
import ReachedLead from './reachedLead'
import { InferType, boolean, date, mixed, object, string } from 'yup'
import { Followup_Type } from '@prisma/client'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import NotReachedLead from './notReachedLead'
import { trpc } from '../../../../core/trpc'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import { useLocation, useParams } from 'react-router'
import { addBusinessDays, set } from 'date-fns'
import { X } from '../../../../icons/X'
import LeadActivity from '../activity'
import ContactInfo from './ContactInfo'
import { pick } from 'remeda'
import qs from 'qs'
import { isEmpty } from 'ramda'
import { Stage_Columns } from '@prisma/client'
import { LeadFilter } from '../../pipelineLeads'
import { NotReachedNewAction } from './types'

const schema = object({
  description: string().oneOf(['spoke', 'voicemail', 'noAnswer', 'wrongNumber', '']),
  addFollowup: boolean(),
  addTask: boolean(),
  followupType: mixed<Followup_Type>()
    .oneOf(['EMAIL', 'CALL'])
    .when('addFollowup', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable()
    }),
  followupDateVal: date().when('addFollowup', {
    is: true,
    then: (schema) => schema.required('Date is required'),
    otherwise: (schema) => schema.nullable()
  }),
  followupTimeVal: date().nullable(),
  followupUrgent: boolean(),
  taskTimeVal: date().nullable(),
  taskDateVal: date().when('addTask', {
    is: true,
    then: (schema) => schema.required('Date is required'),
    otherwise: (schema) => schema.nullable()
  }),
  followupNote: string(),
  followupReminder: boolean().nullable(),
  taskTitle: string().when('addTask', {
    is: true,
    then: (schema) => schema.required('Title is required'),
    otherwise: (schema) => schema.nullable()
  }),
  taskReminder: boolean().nullable()
})

export type FollowupSchema = InferType<typeof schema>

export type CallFeedbackContact = {
  contactId: string
  name: string
  birthday: Date | null
  email: string
  phone: { id: string; phoneNumber: string; thumbsUp: number; thumbsDown: number }
  other_phones: { id: string; phone_number: string; thumbs_up: number; thumbs_down: number }[]
}

type CallFeedbackProps = {
  type: 'SMS' | 'CALL'
  setType: React.Dispatch<React.SetStateAction<'SMS' | 'CALL' | undefined>>
  feedbackContact: CallFeedbackContact | undefined
  setFeedbackContact: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
  handleClose?: (newAction?: NotReachedNewAction) => void
  stageId?: string
  leadIdPassedIn?: string
}

const CallFeedback = ({
  type,
  setType,
  feedbackContact,
  setFeedbackContact,
  handleClose: handleModalClose,
  stageId,
  leadIdPassedIn
}: CallFeedbackProps) => {
  const client = useQueryClient()
  const { leadId } = useParams()
  const leadIdToUse = leadId || leadIdPassedIn
  const { data: leadData } = trpc.lead.getLeadById.useQuery({ id: leadIdToUse ?? '' })

  const [reached, setReached] = useState<boolean>()
  const [interested, setInterested] = useState<boolean>()
  const [contactInfo, setContactInfo] = useState(feedbackContact)
  const { search } = useLocation()
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true }) as qs.ParsedQs & LeadFilter

  const { data: leadNav } = trpc.lead.getLeadByIdNav.useQuery(
    {
      assignedToIds: parsedSearch.assignedToIds,
      id: leadIdToUse || '',
      stages: parsedSearch.stage_id ? [parsedSearch.stage_id] : isEmpty(parsedSearch) && stageId ? [stageId] : undefined,
      filters: parsedSearch.filters?.map((f) => ({ ...pick(f, ['operator', 'value']), field: f.field?.toUpperCase() as Stage_Columns })),
      sortBy: parsedSearch.sortBy,
      subsidiary_id: leadData?.company.subsidiary_id ?? ''
    },
    { trpc: { context: { skipBatch: true } } }
  )

  const { data: relatedLeadData } = trpc.lead.getRelatedLeads.useQuery({ leadId: leadNav?.lead_id ?? '' })

  const form = useForm({
    resolver: yupResolver<FollowupSchema>(schema),
    defaultValues: {
      description: '',
      followupTimeVal: set(new Date(), { hours: 10, minutes: 0 }),
      taskTimeVal: set(new Date(), { hours: 0, minutes: 0 }),
      followupNote: '',
      taskTitle: '',
      followupDateVal: addBusinessDays(new Date(), 1),
      followupType: 'CALL',
      followupUrgent: false
    }
  })

  const leadById = trpc.lead.getLeadById.useQuery({ id: leadIdToUse ?? '' })
  const createActivity = trpc.activity.createActivity.useMutation({
    onSuccess: () => {
      handleClose()
      client.refetchQueries([['activity', 'getActivitiesByLeadId'], { input: { activityTypes: [], leadIdToUse, skip: 0, take: 10 }, type: 'query' }])
    },
    onError: () => toast.error('Activity creation failed')
  })

  const createTask = trpc.task.createTasks.useMutation({
    onSuccess: () => {
      toast.success('Task successfully created')
      client.refetchQueries([['task', 'getTasksByLead'], { input: { leadIdToUse }, type: 'query' }])
    },
    onError: () => toast.error('Task creation failed')
  })
  const createFollowup = trpc.followup.setFollowup.useMutation({
    onSuccess: () => {
      toast.success('Followup successfully created')
      client.refetchQueries([['lead', 'getLeadById'], { input: { id: leadIdToUse } }])
    },
    onError: () => toast.error('Followup creation failed')
  })

  const createTaskAndActivity = async (data: FollowupSchema, callNotes?: string) => {
    const { followupDateVal, followupTimeVal, taskDateVal, followupNote, taskTimeVal, followupType, followupUrgent, followupReminder } = data
    await createActivity.mutateAsync({
      type: 'CALL',
      lead_id: String(leadIdToUse),
      notes: callNotes,
      phone_number: feedbackContact?.phone?.phoneNumber
    })

    const addTask = form.getValues('addTask')
    const addFollowup = form.getValues('addFollowup')

    if (addTask && taskDateVal && taskTimeVal) {
      const dueDate = set(taskDateVal, {
        hours: taskTimeVal.getHours(),
        minutes: taskTimeVal.getMinutes(),
        seconds: taskTimeVal.getSeconds()
      })
      const taskData = addTask && data.taskTitle ? [{ due_date: dueDate, title: data.taskTitle, reminder_email: data.taskReminder ?? undefined }] : []
      await createTask.mutateAsync({ lead_id: String(leadIdToUse), tasks: taskData })
    }

    if (addFollowup && followupDateVal && followupTimeVal && followupType) {
      const dueDate = set(followupDateVal, {
        hours: followupTimeVal.getHours(),
        minutes: followupTimeVal.getMinutes(),
        seconds: followupTimeVal.getSeconds()
      })
      await createFollowup.mutateAsync({
        lead_id: leadIdToUse || '',
        due_date: dueDate,
        type: followupType,
        details: followupNote,
        is_urgent: followupUrgent,
        reminder: followupReminder ?? undefined
      })
    }
  }

  const handleClose = (newAction?: NotReachedNewAction) => {
    setType(undefined)
    setFeedbackContact(undefined)
    handleModalClose?.(newAction)
  }

  const communication = {
    reachedText: type === 'SMS' ? 'Lead responded' : 'I reached the lead',
    notReachedText: type === 'SMS' ? "Lead didn't respond" : "I didn't reach the lead",
    type: type === 'SMS' ? 'SMS' : 'Call',
    icon: type === 'SMS' ? <ChatBubbleOutlineOutlinedIcon className="text-success-800" /> : <PhoneOutlinedIcon className="text-success-800" />
  }

  const handleBack = () => {
    if (interested !== undefined) return setInterested(undefined)
    setReached(undefined)
  }

  const relatedLeads = relatedLeadData && relatedLeadData.length > 0

  return (
    <Dialog open={Boolean(type)} fullWidth maxWidth="md">
      <Box className="flex justify-between px-4 py-2">
        {reached !== undefined ? (
          <IconButton onClick={handleBack}>
            <ArrowBackIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        ) : (
          <Box />
        )}
        <IconButton onClick={() => handleClose()}>
          <X />
        </IconButton>
      </Box>

      <Box className="px-6 py-3 overflow-y-scroll">
        <Box className="flex flex-col">
          <Box
            className={clsx({
              'flex self-start items-center gap-1': reached !== undefined,
              'flex flex-col items-center gap-2': reached === undefined
            })}
          >
            <IconCircleBackground>{communication.icon}</IconCircleBackground>
            <Typography className="text_xl_medium text-grey-900">{communication.type} feedback</Typography>
          </Box>
          {reached === undefined && (
            <Box className="flex flex-col gap-4">
              <Typography className="text-grey-800 mt-2 text-center">How did the {communication.type.toLowerCase()} go?</Typography>
              <Box className="flex justify-center gap-5 w-full">
                <Button
                  onClick={() => {
                    setReached(true)
                  }}
                  className={`flex flex-col items-center box-border w-[140px] p-3 rounded-xl border-solid border-grey-200 border-[1px]`}
                >
                  <ThumbsUp />
                  <Typography className="text-grey-600  text-center">{communication.reachedText}</Typography>
                </Button>

                <Button
                  onClick={() => {
                    setReached(false)
                  }}
                  className={`flex flex-col items-center box-border w-[140px] p-3 rounded-xl border-solid border-grey-200 border-[1px]`}
                >
                  <ThumbsDown />
                  <Typography className="text-grey-600 text-center">{communication.notReachedText}</Typography>
                </Button>
              </Box>
              <Box className="border-[1px] border-solid border-grey-200 rounded-xl p-4">
                {contactInfo && (
                  <ContactInfo leadId={leadIdToUse || ''} title="Contact Info" contactInfo={contactInfo} setContactInfo={setContactInfo} />
                )}
              </Box>
              <LeadActivity collapsible leadId={leadIdToUse || ''} defaultDisplayType="file" />
            </Box>
          )}
        </Box>
        {reached && (
          <ReachedLead
            relatedLeads={relatedLeads ?? false}
            leadId={leadIdToUse || ''}
            createTaskAndActivity={createTaskAndActivity}
            interested={interested}
            setInterested={setInterested}
            handleClose={handleClose}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            form={form}
            nextId={leadNav?.next_id}
            stageId={parsedSearch.stage_id}
            subsidiaryId={leadById?.data?.company.subsidiary_id}
            stageAllowReassignment={leadById?.data?.stage.allow_reassignment ?? false}
          />
        )}
        {reached === false && (
          <NotReachedLead
            relatedLeads={relatedLeads ?? false}
            handleClose={handleClose}
            isLoading={createActivity.isLoading || createTask.isLoading}
            handleBack={handleBack}
            contact={contactInfo}
            form={form}
            createTaskAndActivity={createTaskAndActivity}
            nextId={leadNav?.next_id}
            stageId={parsedSearch.stage_id}
            subsidiaryId={leadById?.data?.company.subsidiary_id}
            stageAllowReassignment={leadById?.data?.stage.allow_reassignment ?? false}
          />
        )}
      </Box>
    </Dialog>
  )
}

export default CallFeedback

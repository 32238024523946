import { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@mui/material'
import { useSession } from 'next-auth/react'
import UserAvatar from '../../../components/UserAvatar'

function Account() {
  const ref = useRef<Element>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const { data } = useSession()
  const user = data?.user
  const account = data?.account

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = () => {
    try {
      // logout next auth
      window.location.href = '/api/auth/signout'
    } catch (err) {
      toast.error('Unable to logout')
    }
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <UserAvatar
          dontDisplayName={true}
          id={user?.id || ''}
          microsoftUserId={account?.microsft_user_id || undefined}
          name={user?.name || ''}
          className="h-8 w-8"
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit" />
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{
          sx: { width: 200 }
        }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} onClick={handleClose} to={'/profile'}>
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogout()
            handleClose()
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default Account

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import frustrated from '../../assets/head-angry.gif'

type FileNotFoundProps = {
    open: boolean
    setOpen: (open: boolean) => void
}
const FileNotFoundDialog = (props: FileNotFoundProps) => {
    return <Dialog open={Boolean(props.open)} onClose={() => props.setOpen(false)} className="flex justify-center">
              <Box>
                <DialogTitle>
                    We are working on getting this file for you
                </DialogTitle>
                <DialogContent>
                  <Box display="flex" gap={2}>
                    <img alt='frustrated' src={frustrated} />
                    <Box display="flex" flexDirection="column" gap={4} justifyContent="center">
                      <Typography className="text_md_semibold">We know how you are feeling!</Typography>
                      <Typography className="text_md_normal text-grey-600">We are working hard on getting this file for you. It will be here soon. We apologize for the inconvenience.</Typography>
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
              </Box>
          </Dialog>
}

export default FileNotFoundDialog
import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import { AccessTimeOutlinedIcon, CalendarTodayOutlinedIcon } from '../../assets/icons'
import clsx from 'clsx'
import { DateTimePicker } from '@mui/x-date-pickers'
import { trpc } from '../../core/trpc'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { isOverdue } from '../../utils'
import { Task } from '../../types/procedureOutputs'

type TaskDueDateProps = {
  editMode: boolean
  task: Task
  setEditMode: React.Dispatch<React.SetStateAction<Task | null>>
}

const TaskDueDate = ({ editMode, task, setEditMode }: TaskDueDateProps) => {
  const [value, setValue] = useState(task.due_date)
  const updateMutation = trpc.task.updateTask.useMutation({
    onSuccess: (data) => {
      setValue(data.due_date)
    },
    onError: () => toast.error('task update failed')
  })
  const handleUpdate = (val: string) => {
    const dateVal = new Date(val)
    if (!isNaN(dateVal.getTime())) {
      updateMutation.mutate({ id: task.id, due_date: dateVal })
    } else {
      toast.error('Invalid date/time value')
    }
    setEditMode(null)
  }

  if (!editMode) {
    return (
      <Box onClick={() => setEditMode(task)} className="flex gap-[14px] mt-2 cursor-pointer">
        <Box
          className={`flex gap-2 ${clsx({
            'text-grey-500': !isOverdue(new Date(value)),
            'text-error-600': isOverdue(new Date(value))
          })}`}
        >
          <CalendarTodayOutlinedIcon fontSize="small" />
          <Typography data-cy="dueDate" className="text_sm_normal">{`${isOverdue(new Date(value)) ? 'Overdue, ' : ''} ${format(
            new Date(value),
            'MMMM dd'
          )}`}</Typography>
        </Box>
        {!isOverdue(new Date(value)) && (
          <Box className="flex gap-2 text-grey-500">
            <AccessTimeOutlinedIcon fontSize="small" />
            <Typography className="text_sm_normal">{format(new Date(value), 'p')}</Typography>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <DateTimePicker
      value={new Date(value)}
      onAccept={(v) => handleUpdate(String(v))}
      slotProps={{
        textField: {
          variant: 'standard',
          autoFocus: true,
          onBlur: (e) => {
            if (!e.relatedTarget?.ariaLabel?.includes('Choose date')) {
              handleUpdate(e.target.value)
            }
          }
        }
      }}
    />
  )
}

export default TaskDueDate

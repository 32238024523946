import { Box, Chip, Stack } from '@mui/material'
import StyledCheckbox from '../../components/StyledCheckbox'
import TaskName from './TaskName'
import { useState } from 'react'
import TaskDueDate from './TaskDueDate'
import TaskCompleteDialog from './TaskCompleteDialog'
import { useNavigate } from 'react-router'
import { Task } from '../../types/procedureOutputs'

type TaskListProps = {
  refetch: () => void
  tasks: Task[] | undefined
}

const TaskList = ({ refetch, tasks }: TaskListProps) => {
  const [nameEditMode, setNameEditMode] = useState<Task | null>(null)
  const [dueDateEditMode, setDueDateEditMode] = useState<Task | null>(null)
  const [selectedTask, setSelectedTask] = useState<Task | null>(null)
  const navigate = useNavigate()


  return (
    <Stack gap="24px">
      {tasks?.map((task) => (
        <Box data-cy="taskContainer" key={task.id}>
          <Box className="flex gap-1 items-start">
            <StyledCheckbox checked={false} onChange={() => setSelectedTask(task)} />
            <Box>
              <TaskName editMode={nameEditMode?.id === task.id} setEditMode={setNameEditMode} task={task} />
              <Chip
                onClick={() => navigate(`/pipeline/${task.lead.stage.pipeline_id}/lead/${task.lead.id}`)}
                size="small"
                label={`${task.lead.company.name} - ${task.lead.name}`}
              />
              <TaskDueDate editMode={dueDateEditMode?.id === task.id} setEditMode={setDueDateEditMode} task={task} />
            </Box>
          </Box>
        </Box>
      ))}

      {selectedTask && <TaskCompleteDialog task={selectedTask} setTask={() => setSelectedTask(null)} refetch={refetch} />}
    </Stack>
  )
}

export default TaskList

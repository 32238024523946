import { Autocomplete, Button, TextField } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { trpc } from '../../core/trpc'
import debounce from 'lodash.debounce'
import { LeadsByUser } from '../../types/procedureOutputs'

type AssociateLeadProps = {
  setLeadId: React.Dispatch<React.SetStateAction<string>>
  leadId?: string | null
}

const AssociateLead = ({ setLeadId, leadId }: AssociateLeadProps) => {
  const [lead, setLead] = useState<LeadsByUser['results'][number]>()
  const [showSelect, setShowSelect] = useState(false)
  const [search, setSearch] = useState('')
  const { data: leads } = trpc.lead.getLeadsBySearch.useQuery({ search })

  const debouncedSearch = useMemo(() => debounce((input: string) => setSearch(input), 300), [])

  useEffect(() => {
    if (leadId) {
      setLead(leads?.results.find((l) => l.id === leadId))
      setShowSelect(true)
    }
  }, [leadId])

  if (!showSelect) {
    return (
      <Button onClick={() => setShowSelect(true)} className="shadow-none text_sm_medium text-primary-500 normal-case whitespace-nowrap">
        Link To A Lead
      </Button>
    )
  }

  return (
    <Autocomplete
      className="w-64"
      placeholder="Search Leads"
      options={leads?.results || []}
      onInputChange={(_, newInputValue) => debouncedSearch(newInputValue)}
      onChange={(_e, v) => {
        if (v) {
          setLeadId(v.id)
          setLead(v)
        }
      }}
      filterOptions={(x) => x}
      value={lead || null}
      getOptionLabel={(option) => `${option.company.name}-${option.name}`}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

export default AssociateLead

import { Badge, Box, Button, ClickAwayListener, Divider, IconButton, Paper, Popper, Stack } from '@mui/material'
import { useState } from 'react'
import { Bell } from 'react-feather'
import { trpc } from '../../../core/trpc'
import { useNavigate } from 'react-router'
import NotificationCard from '../../../components/notifications/NotificationCard'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import { LoadingButton } from '@mui/lab'

const Notifications = () => {
  const navigate = useNavigate()
  const client = useQueryClient()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { data } = trpc.notification.getUserNotifications.useQuery({}, { refetchInterval: 120000 })
  const mutation = trpc.notification.dismissAllNotifications.useMutation({
    onSuccess: () => {
      client.refetchQueries({ queryKey: [['notification', 'getUserNotifications'], { type: 'query' }] })
    },
    onError: (e) => toast.error(`Failed to dismiss: ${e.message}`)
  })

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box>
        <IconButton
          className="p-0 mx-4 cursor-pointer"
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
            if (!data?.results?.length) {
              setAnchorEl(null)
              navigate('/notifications')
            }
          }}
        >
          <Badge badgeContent={data?.count || 0} color="error">
            <Bell className="text-grey-50" />
          </Badge>
        </IconButton>
        <Popper
          className="z-[2000] h-[calc(100vh-100px)] overflow-scroll"
          open={Boolean(anchorEl && data?.results.length)}
          anchorEl={anchorEl}
          placement="bottom-end"
        >
          <Paper className="p-5 w-[400px]" elevation={20}>
            <Box className="flex justify-end mb-2">
              <LoadingButton
                size="small"
                disabled={!data?.results.length}
                loading={mutation.isLoading}
                onClick={() => mutation.mutate()}
                variant="outlined"
              >
                Dismiss All
              </LoadingButton>
            </Box>
            <Stack divider={<Divider />} gap="16px">
              {data?.results.map((notification) => (
                <NotificationCard key={notification.id} notification={notification} origin="topBar" setAnchorEl={setAnchorEl} />
              ))}
              <Box className="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAnchorEl(null)
                    navigate('/notifications')
                  }}
                >
                  View all notifications
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export default Notifications

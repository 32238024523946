import { Chip, Modal } from '@mui/material'
import React, { useState } from 'react'
import { Phone } from 'react-feather'
import CallFeedback, { CallFeedbackContact } from '../views/pipelines/leadDetails/callFeedback'
import ContactPhonePopper from '../views/pipelines/components/ContactPhonesPopper'
import { pickAll } from 'ramda'
import { Box } from '@mui/system'
import AddPhoneNote from '../views/pipelines/components/AddPhoneNote'
import { trpc } from '../core/trpc'
import { useSearchParams } from 'react-router-dom'
import CallSmsModal from '../views/pipelines/leadDetails/CallSmsModal'
import NewEmailDrawer from './email/NewEmailDrawer'
import { autoRequestInfo } from '../views/pipelines/leadDetails/callFeedback/autoRequestInfo'
import { useEmailStore } from '../stores/emailStore'

const CallAndCallFeedback = ({ companyId, leadId }: { companyId: string; leadId: string }) => {
  const [feedbackContact, setFeedbackContact] = useState<CallFeedbackContact>()
  const setSearchParams = useSearchParams()[1]
  const [callModalType, setCallModalType] = useState<'CALL' | 'SMS'>()
  const [callFeedbackType, setCallFeedbackType] = useState<'CALL' | 'SMS' | undefined>('CALL')
  const [isNewEmailOpen, setNewEmailOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [openPhoneNote, setOpenPhoneNote] = useState<string | null>(null)
  const { data: primaryContact } = trpc.contact.getContactsByCompanyId.useQuery({ companyId: companyId || '' })
  const handleCall = (contact: CallFeedbackContact | undefined) => {
    setAnchorEl(null)
    setFeedbackContact(contact)
  }
  const { data: lead } = trpc.lead.getLeadById.useQuery({ id: leadId || '' }, { enabled: Boolean(leadId) })

  const contact = primaryContact?.find((c) => !!c.primary_contact_for)
  const { mailboxEmailAddress } = useEmailStore()

  return (
    <>
      <Chip
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="px-1 bg-primary-50 text-primary-600"
        avatar={<Phone className="text-primary-600" />}
        size="small"
        label={'CALL'}
      />{' '}
      {Boolean(anchorEl) && (
        <ContactPhonePopper
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          data={contact?.phones || []}
          name={contact?.name || ''}
          setOpenPhoneNote={(x: string | null) => {
            setOpenPhoneNote(x)
            setAnchorEl(null)
          }}
          handleCall={(phone) =>
            handleCall(
              contact
                ? {
                    contactId: contact.id,
                    email: contact.primary_email || '',
                    name: contact.name,
                    birthday: contact.birthday ? new Date(contact.birthday as string) : null,
                    phone,
                    other_phones: contact.phones
                      ?.filter((p) => p.id !== phone.id)
                      ?.map((p) => pickAll(['id', 'thumbs_up', 'thumbs_down', 'phone_number'], p))
                  }
                : undefined
            )
          }
        />
      )}
      <Modal open={Boolean(openPhoneNote)} onClose={() => setOpenPhoneNote(null)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            borderRadius: '8px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <AddPhoneNote
            open={openPhoneNote !== null}
            setOpen={setOpenPhoneNote}
            phoneId={openPhoneNote}
            note={contact?.phones.find((item) => item.id === openPhoneNote)?.notes ?? ''}
            contact={contact}
          />
        </Box>
      </Modal>
      {feedbackContact && callFeedbackType && (
        <CallFeedback
          leadIdPassedIn={leadId}
          handleClose={async (newAction) => {
            if (!newAction) return
            if (newAction.name === 'CALL' || newAction.name === 'SMS') {
              setCallModalType(newAction.name)
            }
            if (newAction.name === 'EMAIL') {
              setNewEmailOpen(true)
            }
            if (newAction.name === 'REQUEST_INFO') {
              if (newAction.requestInfo === 'OTHER') {
                setSearchParams({ contactName: feedbackContact?.name || '', contactPhone: feedbackContact?.phone.phoneNumber || '' })
                setNewEmailOpen(true)
              } else {
                await autoRequestInfo(mailboxEmailAddress, newAction.requestInfo, {
                  contact: feedbackContact,
                  lead: lead!,
                  company: lead!.company
                })
              }
            }
          }}
          type={callFeedbackType}
          setType={() => {}}
          feedbackContact={feedbackContact}
          setFeedbackContact={setFeedbackContact}
        />
      )}
      {Boolean(callModalType) && lead && (
        <CallSmsModal
          type={callModalType}
          setType={setCallModalType}
          lead={lead}
          setCallFeedbackType={setCallFeedbackType}
          setFeedbackContact={setFeedbackContact}
        />
      )}
      <NewEmailDrawer
        open={isNewEmailOpen}
        refetch={() => {}}
        setOpen={(o) => setNewEmailOpen && setNewEmailOpen(o)}
        contact={feedbackContact?.name}
        phone={feedbackContact?.phone.phoneNumber}
        pipelineId={lead?.stage.pipeline_id}
        leadIdPassedIn={leadId}
      />
    </>
  )
}
export default CallAndCallFeedback

import { Typography, IconButton, CircularProgress, Switch } from '@mui/material'
import { trpc } from '../../../../core/trpc'
import { groupBy } from 'ramda'
import { LeadsByUserResultOutput } from '../../../../types/procedureOutputs'
import { format } from 'date-fns'
import StyledPagination from '../../../../components/StyledPagination'
import { useState } from 'react'
import { AddCircleOutlineOutlinedIcon } from '../../../../assets/icons'
import FollowUpItem from './FollowUpItem'
import AddFollowUp from '../../../../components/AddFollowUp'
import { Box } from '@mui/system'

type FollowupListProps = {
  startDate?: Date
}

const FollowUpList = (props: FollowupListProps) => {
  const [skip, setSkip] = useState(0)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [onlyUrgent, setOnlyUrgent] = useState(false)
  const { data, isLoading, refetch } = trpc.followup.getMyFollowupLeads.useQuery(
    { fuStartDate: props.startDate?.toISOString() || undefined, take: 30, skip, onlyUrgent },
    { trpc: { context: { skipBatch: true } } }
  )

  return (
    <>
      <Box display="flex" alignItems="center" marginTop={'12px'}>
        <Switch
          size="small"
          onChange={(_, checked) => {
            setOnlyUrgent(checked)
          }}
        />
        <Typography className="text-grey-700">Show only Urgent</Typography>
      </Box>
      {isLoading && <CircularProgress size={30} />}
      {data && data.count === 0 && <Typography variant="caption">You do not have any followups</Typography>}
      {data &&
        Object.entries(
          groupBy((l: LeadsByUserResultOutput) => (l.followup_date ? format(new Date(l.followup_date), 'yyyy-MM-dd') : ''), data.results)
        ).map(([date, followUps], index) => <FollowUpItem key={index} date={date} followUps={followUps} refetch={refetch} />)}
      {(data?.count || 0) > 30 && <StyledPagination take={30} skip={skip} dataCount={data?.count || 0} onChange={(e, p) => setSkip((p - 1) * 30)} />}
      <IconButton onClick={() => setAddModalOpen(true)} className="text-primary-500 px-0 flex">
        <AddCircleOutlineOutlinedIcon fontSize="small" />
        <Typography className="text_sm_medium ml-2">New Follow Up</Typography>
      </IconButton>
      {addModalOpen && <AddFollowUp open={true} setOpen={setAddModalOpen} refetch={refetch} />}
    </>
  )
}
export default FollowUpList

import { SendEmailPayload, SendEmailResult, EmailProvider, MicrosoftProvider, GetEmailResult } from "./provider";
import { SaveEmailToLeadActivityInput } from "../../types/procedureInputs";
import { standaloneClient } from "../../core/trpc";
import * as azureSvc from "../azureServices";
import { v4 as uuidv4 } from "uuid";

export default class Mailer {
  private readonly defaultProvider: EmailProvider;

  constructor() {
    this.defaultProvider = new MicrosoftProvider();
  }

  public async sendEmail(emailPayload: SendEmailPayload): Promise<SendEmailResult> {
    const emailResult = await this.defaultProvider.sendEmail(emailPayload);
    if (!emailResult.sent) {
      return emailResult;
    }

    if (emailPayload.leadId) {
      const attachments = await this.uploadAttachments(emailPayload)

      const sentEmail = await this.defaultProvider.getEmail(emailResult.id, emailPayload.from.emailAddress.address)
      if (sentEmail !== null) {
        const emailBodyContentURL = await this.uploadBodyContentToAzure(sentEmail)
        await this.linkEmailToLead(emailPayload.leadId, sentEmail, attachments, emailBodyContentURL)
      }
    }

    return emailResult;
  }

  private generateNewFilePath = (leadId: string) => `Lead/${leadId}/${new Date().getTime()}-${uuidv4()}`

  private async uploadBodyContentToAzure(email: GetEmailResult): Promise<string> {
    const filePath = `Emails/MSFT-${email.id}`

    const { containerUrl, sasQuery } = await standaloneClient.file.createUploadFileLink.mutate()

    await azureSvc.uploadEmailContentToAzure({
      contents: email.body.content,
      size: email.body.content.length,
      filePath: filePath,
      containerUrl,
      sasQuery
    })

    return filePath
  }

  private async uploadAttachments(emailPayload: SendEmailPayload): Promise<SaveEmailToLeadActivityInput['email']['files']> {
    if (!emailPayload.attachments || emailPayload.attachments.length < 1) return []

    const { containerUrl, sasQuery } = await standaloneClient.file.createUploadFileLink.mutate()

    const attachmentsWithFilePaths = emailPayload.attachments.map((attachment) => ({
      ...attachment,
      url: this.generateNewFilePath(emailPayload.leadId!)
    }))

    const fileUploads = attachmentsWithFilePaths.map((attachment) =>
      azureSvc.uploadFileToAzure({
        containerUrl,
        sasQuery,
        filePath: attachment.url,
        contents: `data:${attachment.contentType};base64,${attachment.contentBytes}`,
        size: attachment.size
      })
    )

    const resolvedPromises = await Promise.all(fileUploads)
    const azureUploadRes = resolvedPromises.every((promise) => !!promise)

    if (!azureUploadRes) return []

    return attachmentsWithFilePaths.map((attachment) => ({
      lead_id: String(emailPayload.leadId),
      type: attachment.contentType,
      name: attachment.name,
      url: attachment.url,
      size: attachment.size
    }))
  }

  private async linkEmailToLead(leadId: string, emailSent: GetEmailResult, attachments: SaveEmailToLeadActivityInput['email']['files'], bodyContentURL: string) {
    return await standaloneClient.activity.saveEmailToLeadActivity.mutate({
      lead_id: leadId,
      email: {
        subject: emailSent.subject,
        from_address: emailSent.from,
        microsoft_id: emailSent.id,
        parent_folder_id: emailSent.parentFolderId,
        to_addresses: emailSent.toRecipients,
        internet_message_id: emailSent.internetMessageId,
        body_preview: emailSent.bodyPreview,
        bcc_recipients: emailSent.bccRecipients,
        cc_recipients: emailSent.ccRecipients,
        conversation_id: emailSent.conversationId,
        created_date_time: emailSent.createdDateTime,
        body_content_url: bodyContentURL,
        files: attachments
      }
    })
  }
}

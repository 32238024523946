import { create } from 'zustand'

type EmailStore = {
  unreadEmailCount: number
  mailboxEmailAddress: string
  hasGraphHeaders: boolean
  setHasGraphHeader: (hasGraphHeaders: boolean) => void
  setUnreadEmailCount: (num: number) => void
  decrementUnreadEmails: () => void
  incrementUnreadEmails: () => void
  setMailboxEmailAddress: (email?: string) => void
}

const useEmailStore = create<EmailStore>((set) => ({
  mailboxEmailAddress: '',
  unreadEmailCount: 0,
  hasGraphHeaders: false,
  setHasGraphHeader: (hasGraphHeaders) => set({ hasGraphHeaders }),
  setUnreadEmailCount: (num) => set({ unreadEmailCount: num }),
  decrementUnreadEmails: () => set((state) => ({ unreadEmailCount: state.unreadEmailCount - 1 < 0 ? 0 : state.unreadEmailCount - 1 })),
  incrementUnreadEmails: () => set((state) => ({ unreadEmailCount: state.unreadEmailCount + 1 })),
  setMailboxEmailAddress: (email) => set((state) => ({ mailboxEmailAddress: email }))
}))

export { useEmailStore }

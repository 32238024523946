/**
 * @param  {File} file
 * @returns {Promise} -
 * promise that resolves to an Object with a data key that holds the
 * base64 or an object with an error key that contains the rejected error
 */
export const getBase64FromFile = (file: File | Blob): Promise<{ data: string | null | undefined; error: unknown }> =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve({
        data: e.target?.result as string,
        error: undefined
      })
    }
    try {
      reader.readAsDataURL(file)
    } catch (e: unknown) {
      resolve({
        data: undefined,
        error: e
      })
    }
  })

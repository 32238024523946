import { Box, Button, Divider, FormControlLabel, FormControl, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { ThumbsDown } from '../../../../../icons/ThumbsDown'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import TaskFollowup from '../TaskFollowup'
import { CallFeedbackContact, FollowupSchema } from '..'
import { UseFormReturn } from 'react-hook-form'
import { trpc } from '../../../../../core/trpc'
import { ChatBubbleOutlineOutlinedIcon, MailOutlineOutlinedIcon, PhoneOutlinedIcon } from '../../../../../assets/icons'
import LeadReassignmentButton from '../../../../../components/LeadReassignmentButton'
import { useNavigate, useParams } from 'react-router'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import { Search } from 'react-feather'
import DoNotCallButton from '../../../../../components/DoNotCallButton'
import { NotReachedLeadActionName, NotReachedLeadRequestInfo, NotReachedNewAction } from '../types'

type NotReachedLeadProps = {
  contact: CallFeedbackContact | undefined
  form: UseFormReturn<FollowupSchema>
  createTaskAndActivity: (data: FollowupSchema, callNotes?: string) => void
  isLoading: boolean
  handleBack: () => void
  handleClose: (newAction?: NotReachedNewAction) => void
  nextId?: string
  stageId?: string
  relatedLeads?: boolean
  subsidiaryId: string | undefined
  stageAllowReassignment: boolean
}

const NotReachedLead = ({
  form,
  createTaskAndActivity,
  contact,
  isLoading,
  handleBack,
  handleClose,
  nextId,
  stageId,
  relatedLeads,
  subsidiaryId,
  stageAllowReassignment
}: NotReachedLeadProps) => {
  const { leadId, pipelineId } = useParams()
  const [error, setError] = useState(false)
  const [reassign, setReassign] = useState(false)
  const [dnc, setDnc] = useState(false)
  const [callNotes, setCallNotes] = useState('')
  const [reason, setReason] = useState<'NO_ANSWER' | 'WRONG_NUMBER'>('NO_ANSWER')
  const [addFollowupNote, setAddFollowupNote] = useState(false)
  const [newAction, setNewAction] = useState<NotReachedLeadActionName>()
  const [requestInfo, setRequestInfo] = useState<NotReachedLeadRequestInfo | undefined>()
  const client = useQueryClient()
  const thumbsDownMutation = trpc.contactPhone.thumbsDown.useMutation({
    onSuccess: (phone) =>
      client.refetchQueries([
        ['contactPhone', 'getCompanyPhoneNumbers'],
        {
          input: { companyId: phone.contact.company_id },
          type: 'query'
        }
      ])
  })
  const { getValues } = form
  const navigate = useNavigate()
  const [initialPhone] = useState(contact?.phone.phoneNumber)

  const reassignLead = trpc.lead.moveLeadToReassignmentPipeline.useMutation({
    onSuccess: () => {
      toast.success('Lead moved to Reassignment Pipeline')
      client.refetchQueries([['stage', 'getStagesGroupedByPipeline'], { input: {}, type: 'query' }])
      client.refetchQueries([['lead', 'getLeadById'], { input: { id: leadId }, type: 'query' }])
      if (nextId) {
        navigate(`/pipeline/${pipelineId}/lead/${nextId}`)
      } else {
        navigate(`/subsidiary/${subsidiaryId}/pipeline/${pipelineId}/leads/view/default?stage_id=${stageId}`)
      }
    },
    onError: (e) => toast.error(String(e))
  })

  const contactUpdateMutation = trpc.contact.update.useMutation({
    onSuccess: (contact) => {
      toast.success('Contact successfully updated')
      client.refetchQueries([
        ['contact', 'getContactsByCompanyId'],
        {
          input: { companyId: contact.contactResponse.company_id },
          type: 'query'
        }
      ])
      handleClose()
    },
    onError: () => toast.error('Contact update failed')
  })

  const doNotCallLead = trpc.lead.moveLeadToDncPipeline.useMutation({
    onSuccess: () => {
      toast.success('Lead moved to Do Not Call Pipeline')
      client.refetchQueries([['stage', 'getStagesGroupedByPipeline'], { input: {}, type: 'query' }])
      client.refetchQueries([['lead', 'getLeadById'], { input: { id: leadId }, type: 'query' }])
    },
    onError: (e) => toast.error(String(e))
  })
  const editContactPhoneMutation = trpc.contactPhone.updatePhone.useMutation()

  const handleSave = (data: FollowupSchema) => {
    if (hasError()) {
      return setError(true)
    }
    setError(false)
    createTaskAndActivity(data, `${reason ? reason.replace('_', ' ') : ''} ${callNotes}`)
    if (contact) {
      thumbsDownMutation.mutate({ contactPhoneId: contact?.phone.id })

      contactUpdateMutation.mutate({
        id: contact.contactId,
        name: contact.name,
        primary_email: contact.email
      })
      if (reason === 'WRONG_NUMBER') {
        editContactPhoneMutation.mutate({
          phoneId: contact.phone.id,
          notes: 'Wrong number'
        })
      } else if (contact.phone.phoneNumber !== initialPhone) {
        editContactPhoneMutation.mutate({ phoneNumber: contact.phone.phoneNumber, phoneId: contact.phone.id })
      }
    }
    if (reassign) {
      reassignLead.mutate({ leadId: leadId || '' })
    }
    if (dnc) {
      doNotCallLead.mutate({ leadId: leadId || '' })
    }

    if (newAction !== undefined) {
      if (newAction === 'REQUEST_INFO') {
        handleClose({ name: newAction, requestInfo: requestInfo! })
      } else {
        handleClose({ name: newAction })
      }
    } else {
      handleClose()
    }
  }

  const actionsButtons = [
    {
      type: 'CALL' as const,
      icon: <PhoneOutlinedIcon fontSize="small" className="text-secondary-600" />,
      label: 'Call another number',
      action: () => {
        setNewAction('CALL')
        setRequestInfo(undefined)
      }
    },
    {
      type: 'EMAIL' as const,
      icon: <MailOutlineOutlinedIcon fontSize="small" className="text-secondary-600" />,
      label: 'Send Email',
      action: () => {
        setNewAction('EMAIL')
        setRequestInfo(undefined)
      }
    },
    {
      type: 'SMS' as const,
      icon: <ChatBubbleOutlineOutlinedIcon fontSize="small" className="text-secondary-600" />,
      label: 'Send SMS',
      action: () => {
        setNewAction('SMS')
        setRequestInfo(undefined)
      }
    },
    {
      type: 'INFO' as const,
      icon: <Search fontSize="small" className="text-secondary-600" />,
      label: 'Request Info',
      action: () => {
        setNewAction('REQUEST_INFO')
        setRequestInfo('PHONE')
      }
    }
  ] as const

  const hasError = () => {
    if (reason === 'WRONG_NUMBER') {
      return false
    }
    const addFollowup = getValues('addFollowup')
    const followupDateVal = getValues('followupDateVal')
    const followupTimeVal = getValues('followupTimeVal')
    return (!reassign && !dnc && !newAction && !addFollowup) || (addFollowup && (!followupDateVal || !followupTimeVal))
  }

  const saveButtonText =
    newAction === 'EMAIL' || newAction === 'REQUEST_INFO'
      ? 'Save and Email'
      : newAction === 'CALL'
      ? 'Save and Call'
      : newAction === 'SMS'
      ? 'Save and Text'
      : 'Save'

  return (
    <form onSubmit={form.handleSubmit(handleSave)}>
      <Box className="mt-5 w-full">
        <Button
          className={`flex items-center box-border p-2 rounded-xl border-solid border-[3px] border-primary-400 w-full flex-row justify-start gap-2`}
        >
          <ThumbsDown />
          <Typography className="text-grey-600 text-center text_sm_normal">I didn't reach the lead</Typography>
        </Button>
        <Box className="my-4">
          <Typography className="text-grey-700">Call Description</Typography>
          <Box>
            <FormControlLabel onChange={() => setReason('NO_ANSWER')} label="No answer" control={<Radio checked={reason === 'NO_ANSWER'} />} />
            <FormControlLabel
              onChange={() => setReason('WRONG_NUMBER')}
              label="Wrong number"
              control={<Radio checked={reason === 'WRONG_NUMBER'} />}
            />
          </Box>
        </Box>
        <Typography className="text_md_medium text-grey-800 mb-4">Would you like to:</Typography>
        <Box className="flex gap-2">
          {actionsButtons.map((button) => (
            <Button
              type={button.type === 'CALL' ? 'submit' : 'button'}
              onClick={() => {
                setError(false)
                if (newAction === button.type) {
                  setNewAction(undefined)
                } else {
                  button.action()
                }
              }}
              className={`flex w-full justify-start ${clsx({
                'border-success-800 bg-success-100': newAction === button.type
              })} items-center p-[14px] gap-3 border-[1px] border-grey-200 border-solid rounded-xl`}
              key={button.label}
            >
              <Box className="p-1.5 flex justify-center items-center rounded-full bg-secondary-100 border-secondary-50 border-solid border-4">
                {button.icon}
              </Box>
              <Typography>{button.label}</Typography>
            </Button>
          ))}
        </Box>
        {newAction === 'REQUEST_INFO' && (
          <FormControl className="mt-4">
            <FormLabel id="tlo">Request</FormLabel>
            <RadioGroup row onChange={(e) => setRequestInfo((e.target as HTMLInputElement).value as NotReachedLeadRequestInfo)} value={requestInfo}>
              <FormControlLabel value="PHONE" control={<Radio />} label="Phone Number" />
              <FormControlLabel value="EMAIL" control={<Radio />} label="Email Address" />
              <FormControlLabel value="DOB" control={<Radio />} label="Date of Birth" />
              <FormControlLabel value="OTHER" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
        )}
        <Typography className="my-2 text-grey-600 text_sm_normal text-center">or</Typography>
        <Box>
          <TaskFollowup form={form} addFollowupNote={addFollowupNote} setAddFollowupNote={setAddFollowupNote} />
          <Box className="grid grid-cols-2 gap-3 mt-3">
            <LeadReassignmentButton
              selected={reassign}
              setSelected={setReassign}
              relatedLeads={relatedLeads}
              stageAllowReassignment={stageAllowReassignment}
            />

            <DoNotCallButton selected={dnc} setSelected={setDnc} />
          </Box>
        </Box>
        <Box className="mt-4">
          <TextField
            fullWidth
            minRows={1}
            multiline
            label="Call Notes"
            placeholder="Enter a note..."
            value={callNotes}
            onChange={(e) => setCallNotes(e.target.value)}
          />
        </Box>
        <Divider className="my-6" />
        <Box className="flex justify-end">
          <Box className="flex flex-col items-end">
            <LoadingButton
              className={clsx({ 'border-error-600 border-solid border-[1px]': error })}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {saveButtonText}
            </LoadingButton>
            {error && (
              <Typography className="text_xs_medium text-error-600 mt-1.5">You must either schedule a followup or call/sms another number</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default NotReachedLead

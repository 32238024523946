import { Button, CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useState } from 'react'
import OfferList from '../../../offers/OfferList'
import { OfferStatus } from '@prisma/client'
import { trpc } from '../../../../core/trpc'
import { AbilityContext, Can } from '../../../../contexts/AbilityContext'
import { useQueryClient } from '@tanstack/react-query'
import AddOffer from '../../../offers/AddOffer'
import { AddIcon } from '../../../../assets/icons'
import { uniq } from 'ramda'

const Offers = ({
  total_offer_count,
  leadId,
  pipelineId,
  stageId
}: {
  total_offer_count: number
  leadId: string | undefined
  pipelineId?: string | undefined
  stageId?: string | undefined
}) => {
  const [offerOpen, setOfferOpen] = useState(false)
  const client = useQueryClient()
  const { data } = trpc.stage.getStagesGroupedByPipeline.useQuery({ pipelineId })
  const filterChips = Object.values(OfferStatus || {})
  const [selectedFilter, setSelectedFilter] = useState<OfferStatus | undefined>(undefined)
  const ability = useContext(AbilityContext)
  const canViewOffer = ability.can('view', 'Offer')

  const { isLoading, error } = trpc.offer.getOffers.useQuery(
    { leadId: leadId, statuses: selectedFilter ? [selectedFilter] : undefined, skip: 0, take: 20 },
    { enabled: Boolean(leadId && canViewOffer) }
  )
  if ((!total_offer_count && !isLoading) || !canViewOffer) return null
  if (error) return <Typography className="pt-5 pl-5 text-error-800">There was an error loading the offers. {error?.message || ''}</Typography>
  const allColumns = uniq(data?.[0]?.stages.filter((stage) => stage.id === stageId).flatMap((s) => s.columns) || [])

  return (
    <Box className="p-5 flex flex-col gap-6 w-full max-h-[400px] mt-4 border-[1px] border-solid bg-white border-grey-200 rounded-xl">
      <Typography className="text_lg_semibold text-grey-900">Offers</Typography>
      <Box className="flex justify-between items-center">
        <Box className="flex gap-4 items-center">
          <Button
            data-cy="allOffersButton"
            onClick={() => setSelectedFilter(undefined)}
            sx={{
              ':hover': {
                backgroundColor: !selectedFilter ? '#424BF5' : 'white',
                opacity: 0.6
              },
              borderRadius: '24px',
              color: !selectedFilter ? 'white' : '#4B5565',
              backgroundColor: !selectedFilter ? '#424BF5' : 'white',
              border: '1px solid #CDD5DF',
              boxShadow: '0px 2px 4px 0px rgba(33, 36, 41, 0.05)',
              width: 'fit',
              fontSize: '14px',
              fontWeight: 500,
              letterSpacing: '0.98px',
              px: '12px',
              py: '2px'
            }}
          >
            ALL
          </Button>
          {filterChips.map((filter) => {
            const isSelected = selectedFilter === filter
            return (
              <Button
                data-cy={filter}
                onClick={() => setSelectedFilter(filter)}
                sx={{
                  ':hover': {
                    backgroundColor: isSelected ? '#424BF5' : 'white',
                    opacity: 0.6
                  },
                  borderRadius: '24px',
                  color: isSelected ? 'white' : '#4B5565',
                  backgroundColor: isSelected ? '#424BF5' : 'white',
                  border: '1px solid #CDD5DF',
                  boxShadow: '0px 2px 4px 0px rgba(33, 36, 41, 0.05)',
                  width: 'fit',
                  fontSize: '14px',
                  fontWeight: 500,
                  letterSpacing: '0.98px',
                  px: '12px',
                  py: '2px'
                }}
                key={filter}
              >
                {filter === 'DENIED' ? 'DECLINED' : filter}
              </Button>
            )
          })}
        </Box>
        <Button startIcon={<AddIcon />} onClick={() => setOfferOpen(true)} variant="contained" className="w-fit self-end">
          Add Offer
        </Button>
      </Box>
      {isLoading ? (
        <Box className="flex justify-center my-6">
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Can I="view" a="Offer">
          <Box className="overflow-auto">
            <OfferList
              lead_id={leadId}
              showDate={true}
              statuses={selectedFilter ? [selectedFilter] : undefined}
              inLeadDetails
              refetch={() => {
                client.refetchQueries([['lead', 'getLeadRequiredColumnValues'], { input: { leadId }, type: 'query' }])
                client.refetchQueries([['lead', 'getLeadsByPipeline'], { input: { lead_id: leadId, columns: allColumns }, type: 'query' }])
              }}
              pipelineId={pipelineId}
              stageId={stageId}
            />
          </Box>
        </Can>
      )}
      {offerOpen && (
        <AddOffer
          leadId={leadId}
          open={offerOpen}
          setOpen={setOfferOpen}
          refetch={() => {
            client.refetchQueries([['offer', 'getOffers'], { input: { leadId, skip: 0, take: 20 }, type: 'query' }])
            client.refetchQueries([['lead', 'getLeadRequiredColumnValues'], { input: { leadId }, type: 'query' }])
            client.refetchQueries([['lead', 'getLeadsByPipeline'], { input: { lead_id: leadId, columns: allColumns }, type: 'query' }])
          }}
        />
      )}
    </Box>
  )
}
export default Offers

import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions'
import './notesinput.css'
import { List, MenuItem, Paper } from '@mui/material'
import { ReactNode, useMemo } from 'react'
import { trpc } from '../core/trpc'
import debounce from 'lodash.debounce'

type NotesInputProps = {
  onChange: (value: string) => void
  value: string
  placeholder?: string
  autoFocus?: boolean
}

const NotesInput = ({ onChange, value, placeholder, autoFocus }: NotesInputProps) => {
  const trpcContext = trpc.useContext()

  const debouncedSearch = useMemo(
    () =>
      debounce((query, callback) => {
        trpcContext.user.users
          .fetch({ search: query })
          .then((response) => {
            if (response.results) return response.results.map((user) => ({ id: user.id, display: user.email || '' }))
            else return []
          })
          .then(callback)
      }, 300),
    []
  )

  const fetchUsers = (search: string, callback: (data: SuggestionDataItem[]) => void) => {
    debouncedSearch(search, callback)
  }

  return (
    <MentionsInput
      autoFocus={autoFocus}
      customSuggestionsContainer={Container}
      placeholder={placeholder}
      value={value}
      onChange={(event, newValue, newPlainTextValue, mentions) => {
        onChange(newPlainTextValue)
      }}
    >
      <Mention
        displayTransform={(id, label) => `@${label}`}
        renderSuggestion={(suggestion) => <MenuItem className="text-grey-700 hover:bg-grey-100">{suggestion.display}</MenuItem>}
        data={fetchUsers}
        trigger={'@'}
        style={{ backgroundColor: 'blue !important' }}
        appendSpaceOnAdd
      />
    </MentionsInput>
  )
}

export default NotesInput

const Container = (children: ReactNode) => {
  return (
    <Paper elevation={7} className="py-2">
      <List>{children}</List>
    </Paper>
  )
}

import { Box, Button, Dialog, IconButton, TextField, Typography } from '@mui/material'
import { ChatBubbleOutlineOutlinedIcon, CheckIcon, CloseIcon, EditOutlinedIcon } from '../../../assets/icons'
import { PhoneOutgoing } from '../../../icons/PhoneOutgoing'
import { useState } from 'react'
import { trpc } from '../../../core/trpc'
import { Lead } from '.'
import PhoneRow from '../components/PhoneRow'
import { CallFeedbackContact } from './callFeedback'
import { formatPhoneOnInput, getStrippedPhone } from '../../../utils'
import { pickAll } from 'ramda'
import { uniqBy } from 'remeda'

type CallSmsModalProps = {
  type: 'CALL' | 'SMS' | undefined
  setType: React.Dispatch<React.SetStateAction<'CALL' | 'SMS' | undefined>>
  lead: Lead
  setCallFeedbackType: React.Dispatch<React.SetStateAction<'SMS' | 'CALL' | undefined>>
  setFeedbackContact: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
}

const CallSmsModal = ({ type, setType, lead, setCallFeedbackType, setFeedbackContact }: CallSmsModalProps) => {
  const [phoneVal, setPhoneVal] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [showPhoneInput, setShowPhoneInput] = useState(false)
  const { data } = trpc.contactPhone.getCompanyPhoneNumbers.useQuery({ companyId: lead?.company_id || '' }, { enabled: Boolean(lead?.company_id) })

  const handleCall = (contact: CallFeedbackContact | undefined) => {
    if (type) {
      setCallFeedbackType(type)
      setFeedbackContact(contact)
      setType(undefined)
    }
    if (!contact && type) triggerKixieCall(phoneVal, type)
  }

  const triggerKixieCall = (phoneNumber: string, type: 'SMS' | 'CALL') => {
    const phoneEl = window.document
      .getElementById(`phone-number-text-${phoneNumber}`)
      ?.querySelectorAll('[class=kixie-click-image-bind]')?.[0] as HTMLElement

    const smsEl = window.document
      .getElementById(`phone-number-text-${phoneNumber}`)
      ?.querySelectorAll('[class=sms-kixie-click-image-sms-bind]')?.[0] as HTMLElement

    if (type === 'CALL') phoneEl?.click()
    else smsEl?.click()
  }

  const communication = {
    title: type === 'SMS' ? 'SMS' : 'Call',
    text: type === 'SMS' ? 'Text' : 'Call',
    icon:
      type === 'SMS' ? (
        <ChatBubbleOutlineOutlinedIcon fontSize="small" className="text-grey-500" />
      ) : (
        <PhoneOutgoing fontSize="small" className="text-grey-500" />
      )
  }

  const uniqData = !data ? undefined : uniqBy(data, (contact) => contact.phone_number)
  const getDuplicateContact = (phoneNumber: string, contactId: string) => {
    const duplicate = data?.find((num) => num.phone_number === phoneNumber && num.contact_id !== contactId)
    if (!duplicate) return undefined
    return { name: duplicate.contact.name, id: duplicate.contact_id }
  }

  return (
    <Dialog open={Boolean(type)}>
      <Box className="w-[600px] p-6">
        <Box className="flex justify-between mb-5">
          <Typography className="text-grey-900 text_xl_medium">{communication.title}</Typography>
          <IconButton
            onClick={() => {
              setType(undefined)
              setFeedbackContact(undefined)
            }}
          >
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <Typography className="text-grey-900 text_md_medium mb-5">Select a number</Typography>
        <Box className="flex flex-col gap-3">
          {uniqData?.map((num) => (
            <PhoneRow
              key={num.id}
              handleCall={() =>
                handleCall({
                  contactId: num.contact_id,
                  name: num.contact.name,
                  birthday: num.contact.birthday ? new Date(num.contact.birthday as string) : null,
                  email: num.contact.primary_email || '',
                  phone: { id: num.id, phoneNumber: num.phone_number, thumbsUp: num.thumbs_up, thumbsDown: num.thumbs_down },
                  other_phones:
                    data
                      ?.filter((c) => c.contact_id === num.contact_id && c.id !== num.id)
                      .map((p) => pickAll(['id', 'thumbs_up', 'thumbs_down', 'phone_number'], p)) || []
                })
              }
              num={num}
              name={num.contact.name}
              type={type || 'CALL'}
              duplicateContact={getDuplicateContact(num.phone_number, num.contact_id)}
            />
          ))}
        </Box>
        <Button
          onClick={() => {
            setShowPhoneInput(true)
            setEditMode(true)
          }}
          className="mt-3 text-primary-500 text_sm_medium px-0"
        >
          {communication.text} A Different Phone Number
        </Button>
        {showPhoneInput && (
          <Box className="flex mt-2 justify-between">
            {editMode ? (
              <Box className="flex gap-2">
                <TextField variant="standard" value={formatPhoneOnInput(phoneVal)} onChange={(e) => setPhoneVal(getStrippedPhone(e.target.value))} />
                <Button
                  onClick={() => {
                    setEditMode(false)
                  }}
                  variant="outlined"
                  className="min-w-fit px-2"
                >
                  <CheckIcon fontSize="small" />
                </Button>
              </Box>
            ) : (
              <>
                <Box className="flex items-center gap-2">
                  <Typography id={`phone-number-text-${phoneVal}`}>{formatPhoneOnInput(phoneVal)}</Typography>
                  <Button
                    onClick={() => {
                      setEditMode(true)
                    }}
                    variant="outlined"
                    className="min-w-fit px-2"
                  >
                    <EditOutlinedIcon className="text-grey-700" fontSize="small" />
                  </Button>
                </Box>
                <Button
                  onClick={() => handleCall(undefined)}
                  disabled={!phoneVal}
                  className="border-grey-300 border-[1px] border-solid rounded-lg py-2 px-[14px]"
                >
                  {communication.icon}
                  <Typography className="text-grey-700 text_sm_medium ml-2">{communication.text}</Typography>
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default CallSmsModal

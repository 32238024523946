import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { formatPhoneOnInput } from '../../../utils'
import { ContactPhone, Phone_Type } from '@prisma/client'
import { PhoneOutgoing } from '../../../icons/PhoneOutgoing'
import { ThumbsUp } from '../../../icons/ThumbsUp'
import { ThumbsDown } from '../../../icons/ThumbsDown'
import { ChatBubbleOutlineOutlinedIcon } from '../../../assets/icons'
import { Copy, Edit2, Plus } from 'react-feather'
import ContactAvatar from '../../../components/ContactAvatar'
import { StarRounded } from '../../../assets/icons'

type PhoneRowProps = {
  num: ContactPhone | undefined
  name: string | undefined
  handleCall: (type: Exclude<Phone_Type, 'ALL'>) => void
  type: 'CALL' | 'SMS' | 'both'
  isPopper?: boolean
  dontShowNotes?: boolean
  setOpenNote?: (x: string | null) => void
  duplicateContact?: { name: string; id: string } | undefined
}

const PhoneRow = ({ num, handleCall, type, name, isPopper, setOpenNote, dontShowNotes, duplicateContact }: PhoneRowProps) => {
  const triggerKixieCall = (phoneNumber: string, type: 'SMS' | 'CALL') => {
    const phoneEl = window.document
      .getElementById(`phone-number-text-${phoneNumber}`)
      ?.querySelectorAll('[class=kixie-click-image-bind]')?.[0] as HTMLElement

    const smsEl = window.document
      .getElementById(`phone-number-text-${phoneNumber}`)
      ?.querySelectorAll('[class=sms-kixie-click-image-sms-bind]')?.[0] as HTMLElement

    if (type === 'CALL') phoneEl?.click()
    else smsEl?.click()
  }
  return (
    <Box className="flex justify-between items-center" key={num?.id}>
      <Box className="grow flex justify-between items-center">
        <Typography id={`phone-number-text-${num?.phone_number}`} className="w-32 text-grey-700 mr-2">
          {formatPhoneOnInput(num?.phone_number || '')}
        </Typography>
        {name ? (
          <Tooltip title={name}>
            <Box>
              <ContactAvatar className="w-6 h-6 text_xs_medium mr-1" name={name} />
            </Box>
          </Tooltip>
        ) : (
					<Box className="w-6 h-6" />
				)}
        {duplicateContact ? (
          <Tooltip
            slotProps={{ tooltip: { className: 'bg-white shadow-sm px-3 py-1.5' } }}
            title={
              <Typography className="text_xs_medium text-grey-700 mb-1.5">
                This phone number is also associated with {duplicateContact.name}
              </Typography>
            }
          >
            <Box className="w-6 h-6 rounded-full bg-secondary-50 p-1 w-fit h-fit flex items-center justify-center">
              <Copy size="15" className="text-secondary-600" />
            </Box>
          </Tooltip>
        ) : (
					<Box className="w-6 h-6" />
				)}
        <Box className="flex gap-2 items-center">
          {num?.is_primary_phone ? 
            <StarRounded fontSize="large" className="fill-warning-300" /> :
            <StarRounded fontSize="large" className="text-grey-400" />
          }
          {num?.thumbs_up ? (
            <Box className="w-12 h-6 flex gap-1 items-center">
              <ThumbsUp />
              <Typography data-cy="phoneThumbsUp" className="text_xs_medium text-grey-500">
                {num?.thumbs_up}
              </Typography>
            </Box>
          ) : (
						<Box className="w-12 h-6" />
					)}
          {num?.thumbs_down ? (
            <Box className="w-12 h-6 flex gap-1 items-center">
              <ThumbsDown />
              <Typography data-cy="phoneThumbsDown" className="text_xs_medium text-grey-500">
                {num?.thumbs_down}
              </Typography>
            </Box>
          ) : (
						<Box className="w-12 h-6" />
					)}
        </Box>
        {!dontShowNotes && (
          <Box className="flex ml-2">
            <Tooltip title={num?.notes} placement="top">
              <Typography className="text-grey-700 text-base italic inline truncate max-w-[200px]">{num?.notes || ''}</Typography>
            </Tooltip>
            {isPopper && (
              <Button
                className="p-0 text-primary-500"
                variant="text"
                onClick={() => {
                  setOpenNote && setOpenNote(num?.id ?? '')
                }}
              >
                {num?.notes ? <Edit2 className="text-primary-500 align-middle p-0" size="14" /> : 'Add Note'}
              </Button>
            )}
          </Box>
        )}
      </Box>

      {type === 'CALL' && (
        <Box>
          <Button
            onClick={() => {
              if (num?.phone_number) {
                handleCall('CALL')
                triggerKixieCall(num.phone_number, 'CALL')
              }
            }}
            className="border-grey-300 border-[1px] border-solid rounded-lg py-2 px-[14px]"
          >
            <PhoneOutgoing fontSize="small" className="text-grey-500" />
            <Typography className="text-grey-700 text_sm_medium ml-2">Call</Typography>
          </Button>
          <Tooltip title={'Add manual call log'} placement="top">
            <IconButton
              className="h-8 w-8 border border-solid border-grey-300 rounded-lg mx-2"
              onClick={() => {
                if (num?.phone_number) {
                  handleCall('CALL')
                }
              }}
            >
              <Plus className="text-grey-500 h-[16px] w-[16px]" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {type === 'SMS' && (
        <Button
          onClick={() => {
            if (num?.phone_number) {
              triggerKixieCall(num.phone_number, 'SMS')
            }
          }}
          className="border-grey-300 border-[1px] border-solid rounded-lg py-2 px-[14px]"
        >
          <ChatBubbleOutlineOutlinedIcon fontSize="small" className="text-grey-500" />
          <Typography className="text-grey-700 text_sm_medium ml-2">Text</Typography>
        </Button>
      )}
      {type === 'both' && (
        <Box className="flex gap-1">
          <IconButton
            className="h-8 w-8 border border-solid border-grey-300 rounded-lg mx-2"
            onClick={() => {
              if (num?.phone_number) {
                handleCall('CALL')
                triggerKixieCall(num.phone_number, 'CALL')
              }
            }}
          >
            <PhoneOutgoing className="text-grey-500 h-[16px] w-[16px]" />
          </IconButton>
          <IconButton
            className="h-8 w-8 border border-grey-300 border-solid rounded-lg mr-2"
            onClick={() => {
              if (num?.phone_number) {
                triggerKixieCall(num.phone_number, 'SMS')
              }
            }}
          >
            <ChatBubbleOutlineOutlinedIcon fontSize="small" className="text-grey-500 h-[16px] w-[16px]" />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default PhoneRow

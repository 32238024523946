import { Box, Button, Stack, Typography } from '@mui/material'
import { GetMessagesOutput } from '../../../../../types/procedureOutputs'
import UserAvatar from '../../../../../components/UserAvatar'
import { trpc } from '../../../../../core/trpc'
import clsx from 'clsx'
import { formatDurationShorthand, getAzureAccount } from '../../../../../utils/helpers'
import { CornerUpLeft, Edit2, Trash } from 'react-feather'
import { useState } from 'react'
import ReactMentionsInput from '../../../../../components/ReactMentionsInput'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'
import ChildMessage from './ChildMessage'
import { useSession } from 'next-auth/react'
import TaggedText from '../../../../../components/TaggedText'

type Props = {
  message: GetMessagesOutput['results'][number]
  fetchMessages: ({ concat }: { concat: boolean }) => Promise<void>
  leadId: string | undefined
}

const Message = ({ message, fetchMessages, leadId }: Props) => {
  const user = useSession().data?.user
  const [isReply, setReply] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [newMessageVal, setNewMessageVal] = useState('')
  const [editedMessageVal, setEditedMessageVal] = useState(message.message)
  const createMutation = trpc.message.createMessage.useMutation({
    onSuccess: () => {
      toast.success('Comment created')
      fetchMessages({ concat: false })
      setReply(false)
      setNewMessageVal('')
    },
    onError: (e) => toast.error(String(e.message))
  })
  const deleteMutation = trpc.message.deleteMessage.useMutation({
    onSuccess: () => {
      toast.success('Comment deleted')
      fetchMessages({ concat: false })
    },
    onError: (e) => toast.error(String(e.message))
  })

  const updateMutation = trpc.message.updateMessage.useMutation({
    onSuccess: () => {
      toast.success('Comment updated')
      setEdit(false)
      fetchMessages({ concat: false })
    },
    onError: (e) => toast.error(String(e.message))
  })

  const actionButtons = [
    {
      id: 'reply',
      icon: <CornerUpLeft className="w-4 text-grey-700" />,
      action: () => setReply(true)
    },

    {
      id: 'edit',
      icon: <Edit2 className="w-4 text-grey-700" />,
      action: () => setEdit(true),
      hidden: message.created_by_id !== user?.id
    },
    {
      id: 'delete',
      icon: <Trash className="w-4 text-grey-700" />,
      action: () => deleteMutation.mutate({ id: message.id }),
      loading: deleteMutation.isLoading,
      hidden: message.created_by_id !== user?.id
    }
  ]
  return (
    <Box className="flex items-start gap-3 mb-2">
      <UserAvatar
        id={message.created_by_id}
        name={message.created_by.name || ''}
        microsoftUserId={getAzureAccount(message.created_by.accounts)?.microsft_user_id || undefined}
        dontDisplayName
      />
      <Box
        sx={{ '&:hover .actions': { visibility: 'visible' } }}
        className={` w-full rounded-lg p-3 border-[1px] border-solid ${clsx({
          'bg-grey-50 border-grey-200': message.child_messages.length,
          'bg-warning-50 border-warning-100': !message.child_messages.length
        })}`}
      >
        <Box className="flex justify-between items-center mb-2">
          <Typography className="text_sm_medium text-grey-800">{message.created_by.name}</Typography>
          <Box className="flex items-center gap-4">
            {!isEdit && (
              <Box className="flex items-center gap-2 actions" sx={{ visibility: 'hidden' }}>
                {actionButtons.map((actionButton) => (
                  <LoadingButton
                    loading={actionButton.loading}
                    onClick={actionButton.action}
                    className={`p-1 min-w-fit ${clsx({ hidden: actionButton.hidden })}`}
                    key={actionButton.id}
                  >
                    {actionButton.icon}
                  </LoadingButton>
                ))}
              </Box>
            )}
            <Typography className="text-grey-500 text_sm_normal">{formatDurationShorthand(new Date(message.created_at))} ago</Typography>
          </Box>
        </Box>
        {isEdit ? (
          <Box>
            <Box className="border-[1px] border-grey-300 border-solid py-3 px-[14px] rounded-lg w-full shadow-sm">
              <ReactMentionsInput autoFocus value={editedMessageVal} onChange={(val) => setEditedMessageVal(val)} />
            </Box>
            <Box className="flex gap-3 mt-3 justify-end">
              <Button onClick={() => setEdit(false)} size="small" variant="outlined">
                Cancel
              </Button>
              <LoadingButton
                loading={updateMutation.isLoading}
                disabled={!editedMessageVal}
                onClick={() => {
                  updateMutation.mutate({ message: editedMessageVal, id: message.id })
                }}
                size="small"
                variant="contained"
              >
                Update
              </LoadingButton>
            </Box>
          </Box>
        ) : (
          <TaggedText text={message.message} />
        )}
        {isReply && (
          <Box className="mt-2">
            <Box className="border-[1px] border-grey-300 border-solid bg-white py-3 px-[14px] rounded-lg w-full shadow-sm">
              <ReactMentionsInput autoFocus value={newMessageVal} onChange={(val) => setNewMessageVal(val)} placeholder="Add comment" />
            </Box>
            <Box className="mt-4 flex justify-end gap-2">
              <Button onClick={() => setReply(false)} variant="outlined">
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  if (leadId) {
                    createMutation.mutate({ message: newMessageVal, leadId, parentMessageId: message.id })
                  }
                }}
                disabled={!newMessageVal}
              >
                Reply
              </LoadingButton>
            </Box>
          </Box>
        )}
        <Stack className="gap-4 mt-4">
          {message.child_messages.map((childMessage) => (
            <ChildMessage key={childMessage.id} childMessage={childMessage} fetchMessages={fetchMessages} />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default Message

import { useMemo } from 'react'

export function useReassignmentAllowed(hasRelatedLeads: boolean, stageAllowReassignment: boolean): [disabled: boolean, message?: string] {
  const [disabled, disabledMessage] = useMemo(() => {
    if (hasRelatedLeads) {
      return [true, 'Reassignment is not allowed for a linked lead']
    } else if (!stageAllowReassignment) {
      return [true, 'Reassignment is not allowed in the current pipeline stage']
    }

    return [false]
  }, [hasRelatedLeads, stageAllowReassignment])

  return [disabled, disabledMessage]
}

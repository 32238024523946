import { AvatarGroup, Box, CircularProgress, Dialog, IconButton, Typography } from '@mui/material'
import LeadDetailsContent from '../views/pipelines/leadDetails/LeadDetailsContent'
import { Suspense, useState } from 'react'
import { CloseIcon } from '../assets/icons'
import useLeadDetailsSearchParams from '../hooks/useLeadDetailsSearchParams'
import { OpenInNewRounded } from '@mui/icons-material'
import { trpc } from '../core/trpc'
import { useNavigate } from 'react-router'
import UserAvatar from './UserAvatar'
import PriorityChip from './PriorityChip'
import { getAzureAccount } from '../utils'

const LeadDetailsModal = () => {
  const [isNewEmailOpen, setNewEmailOpen] = useState<
    | {
        isOpen: boolean
        requestInfo?: boolean | undefined
      }
    | undefined
  >()
  const { leadId, handleClose } = useLeadDetailsSearchParams()
  const { data, refetch } = trpc.lead.getLeadById.useQuery({ id: leadId || '' }, { enabled: Boolean(leadId) })
  const navigate = useNavigate()

  if (!leadId) return null

  return (
    <Suspense fallback={<CircularProgress />}>
      <Dialog
        maxWidth={'xl'}
        open
        sx={{
          zIndex: 1100
        }}
      >
        <Box className="flex justify-between mx-5 mt-5 gap-3 min-w-11/12">
          <Box className="flex overflow-hidden">
            <Typography className="text_lg_semibold text-grey-900">
              {data?.company.name || ''}-{data?.name || ''}
            </Typography>
            {data?.priority && <PriorityChip priority={data?.priority.priority} />}
            <AvatarGroup max={20} className="justify-end cursor-pointer ml-[18px]">
              {data?.company.companyToUser.map(({ assigned_to: user }) => (
                <UserAvatar
                  key={user.id}
                  id={user?.id}
                  microsoftUserId={getAzureAccount(user.accounts)?.microsft_user_id || undefined}
                  name={user?.name || ''}
                  className="text_xs_medium w-7 h-7"
                />
              ))}
            </AvatarGroup>
          </Box>
          <Box className="flex justify-end">
            <IconButton onClick={() => navigate(`/pipeline/${data?.stage.pipeline_id}/lead/${leadId}`)}>
              <OpenInNewRounded />
            </IconButton>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {data && (
          <LeadDetailsContent
            lead={data}
            refetchLead={refetch}
            isModal
            leadId={leadId as string}
            isNewEmailOpen={isNewEmailOpen}
            setNewEmailOpen={setNewEmailOpen}
          />
        )}
      </Dialog>
    </Suspense>
  )
}

export default LeadDetailsModal

import { Grid, Typography, Chip, Button } from '@mui/material'
import { statesAndAreaCodes } from '../../../../utils'
import { PlusCircle } from 'react-feather'
import ContactDetails from './contactInfo'
import { useState } from 'react'
import { ContactGetContactByCompanyOutput } from '../../../../types/procedureOutputs'
import { useEffect } from 'react'
import AddContact from '../../../contactsAndCompanies/contacts/AddContact'
import ContactModal from './contactModal'
import { Lead } from '..'
import CallSmsModal from '../CallSmsModal'
import { CallFeedbackContact } from '../callFeedback'
import ContactAvatar from '../../../../components/ContactAvatar'

type ContactsProps = {
  company_id: string | undefined
  lead: Lead
  setNewEmailOpen: React.Dispatch<
    React.SetStateAction<
      | {
          isOpen: boolean
          requestInfo?: boolean | undefined
        }
      | undefined
    >
  >
  data: ContactGetContactByCompanyOutput[] | undefined
  refetch: () => void
}
const Contacts = ({ data, company_id, lead, setNewEmailOpen, refetch }: ContactsProps) => {
  const [primaryContact, setPrimaryContact] = useState<undefined | ContactGetContactByCompanyOutput>(undefined)
  const [openContact, setOpenContact] = useState<undefined | ContactGetContactByCompanyOutput>(undefined)
  const [expanded, setExpanded] = useState(true)
  const [addContact, setAddContact] = useState(false)
  const [callModalType, setCallModalType] = useState<'CALL' | 'SMS'>()
  const [callFeedbackType, setCallFeedbackType] = useState<'CALL' | 'SMS'>()
  const [feedbackContact, setFeedbackContact] = useState<CallFeedbackContact>()

  useEffect(() => {
    setPrimaryContact(data?.find((c) => !!c.primary_contact_for) || data?.[0])
  }, [data])

  return (
    <Grid
      container
      className="flex flex-col gap-6"
      sx={{ backgroundColor: 'white', borderRadius: '12px', border: '1px solid #E3E8EF', padding: '20px' }}
    >
      <ContactDetails
        setNewEmailOpen={setNewEmailOpen}
        setOpenContact={setOpenContact}
        contact={primaryContact}
        showExpanedIcon={true}
        isPrimary={!!primaryContact?.primary_contact_for}
        refetch={refetch}
        lead={lead}
        expanded={expanded}
        setExpanded={setExpanded}
        contactState={Object.keys(statesAndAreaCodes).find((stateName) =>
          statesAndAreaCodes[stateName].includes(parseInt(primaryContact?.phones[0]?.phone_number?.substring(0, 3) ?? ''))
        )}
      />
      {expanded && (
        <Grid item xs={12} sx={{ borderTop: '1px #E3E8EF solid', paddingTop: '12px' }}>
          <Typography className="text-grey-900 font-medium text-base inline mr-3">More Contacts</Typography>
          {data
            ?.filter((c) => c.id !== primaryContact?.id)
            ?.map((c) => (
              <Chip
                data-cy="otherContactsChip"
                variant="outlined"
                className="text-base font-medium text-grey-600 border border-grey-300 bg-white mr-3"
                key={c.id}
                onClick={() => setOpenContact(c)}
                avatar={<ContactAvatar name={c.name} />}
                label={c.name}
              />
            ))}
          <Button className="text-primary-500 text-sm font-medium" startIcon={<PlusCircle />} onClick={() => setAddContact(true)}>
            Add New Contact
          </Button>
        </Grid>
      )}
      {openContact && (
        <ContactModal
          contact={openContact}
          isPrimary={openContact.primary_contact_for?.id === company_id}
          setContact={setOpenContact}
          refetch={refetch}
          callFeedbackType={callFeedbackType}
          setCallFeedbackType={setCallFeedbackType}
          feedbackContact={feedbackContact}
          setFeedbackContact={setFeedbackContact}
          setCallModalType={setCallModalType}
          setNewEmailOpen={setNewEmailOpen}
          stageId={lead.stage_id}
          lead={lead}
        />
      )}
      {Boolean(callModalType) && (
        <CallSmsModal
          type={callModalType}
          setType={setCallModalType}
          lead={lead}
          setCallFeedbackType={setCallFeedbackType}
          setFeedbackContact={setFeedbackContact}
        />
      )}
      {addContact && <AddContact refetchContacts={refetch} open={addContact} setOpen={setAddContact} companyId={company_id} />}
    </Grid>
  )
}
export default Contacts
